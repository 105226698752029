import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import Style from './pipeline.module.scss';
import { PipelineNodeProps } from './pipeline';
import { IFillingProgress, RouteProgressStateEnum } from '../../services/api/api-client';
import { Tooltip } from '../uikit/tooltip/tooltip.component';
import { formatDateRange, subTimeUnitFromDate } from '../../helpers/date-helpers';
import { useTranslation } from 'react-i18next';
import { LocalizedResourceDictionaryKeys } from '../../application/localisation/i18next';
import { AppButton } from 'uikit/buttons';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from '../../helpers/auth/auth-helper';
import { TypographyStyles } from '../../styles';

import { ReactComponent as Init } from '../../assets/img/pipeline/available_16.svg';
import { ReactComponent as Done } from '../../assets/img/pipeline/done_16.svg';
import { ReactComponent as Filled } from '../../assets/img/pipeline/filled_16.svg';
import { ReactComponent as FinishInit } from '../../assets/img/pipeline/finish_available_16.svg';
import { ReactComponent as AddedManually } from '../../assets/img/pipeline/unknown_16.svg';
import { ReactComponent as FinishDone } from '../../assets/img/pipeline/finish_done_16.svg';
import { ReactComponent as Missed } from '../../assets/img/pipeline/missed_16.svg';
import { ReactComponent as Plus } from '../../assets/img/records/fill_add_24.svg';
import { ReactComponent as Edit } from '../../assets/img/records/edit_24.svg';
import { ReactComponent as Eye } from '../../assets/img/common/eye_24.svg';

import { ReactComponent as CrossIcon } from 'src/assets/img/formState/cross_16.svg';
import { ReactComponent as DotIcon } from 'src/assets/img/formState/dot_16.svg';
import { ReactComponent as CheckIcon } from 'src/assets/img/formState/check_16.svg';

import { ITooltipHost } from '@fluentui/react';
import { useFormEditingAndOverview } from '../../features/forms/useFormEditingAndOverview';
import { FillingProgress } from '../../features/forms/components/FillingProgress';
import { startOfToday } from 'date-fns';
import { getFormState } from '../../features/forms/form-helper';
import { stepIsStarted } from '../../features/studyRoutes/studyRoute-helpers';

const icon: { [key in RouteProgressStateEnum]: (stepIsEditable: boolean) => React.JSX.Element } = {
  [RouteProgressStateEnum.None]: () => <></>,
  [RouteProgressStateEnum.Init]: () => <Init />,
  [RouteProgressStateEnum.InProgress]: () => <Init />,
  [RouteProgressStateEnum.Done]: (stepIsEditable) => (stepIsEditable ? <Filled /> : <Done />),
  [RouteProgressStateEnum.Missed]: () => <Missed />,
  [RouteProgressStateEnum.InProgressManually]: () => <Init />,
  [RouteProgressStateEnum.Unexpected]: () => <AddedManually />,
};

const finishIcon: { [key in RouteProgressStateEnum]: React.JSX.Element } = {
  [RouteProgressStateEnum.None]: <></>,
  [RouteProgressStateEnum.Init]: <FinishInit />,
  [RouteProgressStateEnum.InProgress]: <FinishInit />,
  [RouteProgressStateEnum.Done]: <FinishDone />,
  [RouteProgressStateEnum.Missed]: <></>,
  [RouteProgressStateEnum.InProgressManually]: <FinishInit />,
  [RouteProgressStateEnum.Unexpected]: <></>,
};

const getNodeStyleColor = (state: RouteProgressStateEnum) =>
  clsx({
    [Style.init]: state === RouteProgressStateEnum.Init,
    [Style.missed]: state === RouteProgressStateEnum.Missed,
    [Style.active]:
      state === RouteProgressStateEnum.Done ||
      state === RouteProgressStateEnum.InProgress ||
      state === RouteProgressStateEnum.InProgressManually,
    [Style.unexpected]: state === RouteProgressStateEnum.Unexpected,
  });

const getDashStyleColor = (state: RouteProgressStateEnum, prevState?: RouteProgressStateEnum) =>
  clsx({
    [Style.blueDash]:
      state === RouteProgressStateEnum.InProgress ||
      state === RouteProgressStateEnum.InProgressManually ||
      state === RouteProgressStateEnum.Done ||
      state === RouteProgressStateEnum.Missed ||
      state === RouteProgressStateEnum.Unexpected,
    [Style.redDash]: state === RouteProgressStateEnum.Missed && prevState === RouteProgressStateEnum.Missed,
    [Style.greyDash]: state === RouteProgressStateEnum.Init,
  });

export const PipelineNode = (props: PipelineNodeProps) => {
  const { name, shortName, step, isFinishNode, prevStep, patientId, patientUniqueId, routeProgress } = props;
  const { t } = useTranslation();
  const [editFormId, setEditFormId] = useState<number | undefined>();
  const [editFormResultId, setEditFormResultId] = useState<number>();
  const [viewFormId, setViewFormId] = useState<number | undefined>();
  const tooltipRef = useRef<ITooltipHost>(null);

  const formEditingAndOverview = useFormEditingAndOverview();

  const fillForm = useCallback(
    async (formConfigId: number) => {
      setEditFormId(formConfigId);

      await formEditingAndOverview.startFilling({
        stepName: name,
        formId: formConfigId,
        routeProgress: routeProgress,
        patientId,
        patientUniqueId,
      });

      setEditFormId(undefined);
    },
    [formEditingAndOverview, name, patientId, patientUniqueId, routeProgress],
  );

  const editForm = useCallback(
    async (formResultId: number) => {
      setEditFormResultId(formResultId);
      try {
        await formEditingAndOverview.startEditing({
          formResultId,
          routeProgress: routeProgress,
          patientUniqueId,
        });
      } finally {
        setEditFormResultId(undefined);
      }
    },
    [formEditingAndOverview, routeProgress, patientUniqueId],
  );

  const viewForm = useCallback(
    async (formId: number, formResultId: number) => {
      setViewFormId(formId);
      await formEditingAndOverview.openOverview({ formId, formResultId, patientId, patientUniqueId, stepName: name });
      setViewFormId(undefined);
    },
    [formEditingAndOverview, name, patientId, patientUniqueId],
  );

  const tooltipContent = useMemo(() => {
    const stepIsActive =
      step.state === RouteProgressStateEnum.InProgress || step.state === RouteProgressStateEnum.InProgressManually;

    return (
      <div className={clsx(TypographyStyles.plainText14)} data-test-id={`pipeline-tooltip-${shortName}`}>
        <div className={Style.nodeTooltipHeader} data-test-id={'name'}>
          {name}
        </div>
        <div className={clsx(Style.nodeTooltipState)} data-test-id={'period'}>
          <div
            className={clsx(Style.stepState, {
              [Style.unexpectedText]: step.state === RouteProgressStateEnum.Unexpected,
            })}
          >
            {t(`Dashboard.PipelineTooltip.${RouteProgressStateEnum[step.state]}` as LocalizedResourceDictionaryKeys)}
          </div>
          {step.startFrom && (
            <div>{formatDateRange(step.startFrom, subTimeUnitFromDate(step.editableUntil, 'days'))}</div>
          )}
        </div>
        <div className={Style.forms}>
          {step.forms?.map((form) => {
            const isFilled = !!form.formResultId;
            const fillingProgress = form.formResultId
              ? form.fillingProgress
              : stepIsStarted(step.state) && form.initialTotalFieldsCount
              ? { filled: 0, total: form.initialTotalFieldsCount }
              : null;

            return (
              <>
                <FormTypeAndProgress
                  formType={form.formType}
                  state={getFormState(form.formResultId, fillingProgress, step.state)}
                  fillingProgress={fillingProgress}
                />
                <PermissionsCheck permissions={Permissions.EditPatient}>
                  {stepIsActive && !isFilled && (
                    <FillButton
                      loading={formEditingAndOverview.formEditIsLoading && editFormId === form.formConfigId}
                      disabled={
                        formEditingAndOverview.formEditIsLoading || formEditingAndOverview.formOverviewIsLoading
                      }
                      onClick={() => fillForm(form.formConfigId).then(() => tooltipRef.current?.dismiss())}
                    />
                  )}

                  {form.isEditable && (
                    <EditButton
                      loading={formEditingAndOverview.formEditIsLoading && editFormResultId === form.formResultId}
                      disabled={
                        formEditingAndOverview.formEditIsLoading || formEditingAndOverview.formOverviewIsLoading
                      }
                      onClick={() => editForm(form.formResultId!).then(() => tooltipRef.current?.dismiss())}
                    />
                  )}
                </PermissionsCheck>

                {isFilled && (
                  <ViewButton
                    loading={formEditingAndOverview.formOverviewIsLoading && viewFormId === form.formConfigId}
                    disabled={formEditingAndOverview.formEditIsLoading || formEditingAndOverview.formOverviewIsLoading}
                    onClick={() =>
                      viewForm(form.formConfigId, form.formResultId!).then(() => tooltipRef.current?.dismiss())
                    }
                  />
                )}
              </>
            );
          })}
        </div>
        {step.state === RouteProgressStateEnum.Unexpected && (
          <div className={clsx(TypographyStyles.paragraph10, Style.description)}>
            {t('Dashboard.PipelineTooltip.Description')}
          </div>
        )}
      </div>
    );
  }, [
    shortName,
    name,
    step.state,
    step.startFrom,
    step.editableUntil,
    step.forms,
    t,
    formEditingAndOverview.formEditIsLoading,
    formEditingAndOverview.formOverviewIsLoading,
    editFormId,
    viewFormId,
    fillForm,
    viewForm,
  ]);

  const stepIsEditable = !!step.editableUntil && step.editableUntil > startOfToday();

  return (
    <div className={clsx(Style.nodeContainer, getDashStyleColor(step.state, prevStep?.state))}>
      <Tooltip
        componentRef={tooltipRef}
        calloutContainerStyles={Style.tooltipContainer}
        tooltipContent={tooltipContent}
        text={''}
      >
        <div data-text={shortName} className={clsx(Style.node, getNodeStyleColor(step.state))}>
          {isFinishNode ? finishIcon[step.state] : icon[step.state](stepIsEditable)}
        </div>
      </Tooltip>
      {formEditingAndOverview.element}
    </div>
  );
};

const FillButton: FC<{ loading: boolean; onClick: () => void; disabled: boolean }> = ({
  loading,
  onClick,
  disabled,
}) => {
  return (
    <AppButton
      className={Style.editAndFill}
      variant={'icon-link'}
      colorSchema={'primary'}
      Icon={Plus}
      onClick={onClick}
      disabled={disabled}
      isLoading={loading}
      loaderClassName={Style.loader}
    />
  );
};

const EditButton: FC<{ loading: boolean; onClick: () => void; disabled: boolean }> = ({
  loading,
  onClick,
  disabled,
}) => {
  return (
    <AppButton
      className={Style.editAndFill}
      variant={'icon-link'}
      colorSchema={'primary'}
      Icon={Edit}
      onClick={onClick}
      disabled={disabled}
      isLoading={loading}
      loaderClassName={Style.loader}
    />
  );
};

const ViewButton: FC<{ loading: boolean; onClick: () => void; disabled: boolean }> = ({
  loading,
  onClick,
  disabled,
}) => {
  return (
    <AppButton
      className={Style.view}
      variant={'icon-link'}
      colorSchema={'primary'}
      Icon={Eye}
      onClick={onClick}
      disabled={disabled}
      isLoading={loading}
      loaderClassName={Style.loader}
    />
  );
};

const FormTypeAndProgress: FC<{
  formType: string;
  state: 'filled' | 'missed' | 'none';
  fillingProgress: IFillingProgress | null | undefined;
}> = ({ formType, state, fillingProgress }) => {
  return (
    <div className={Style.formTypeAndProgress} data-test-id={'form-for-step'}>
      <div className={Style.formType}>
        <FormStateIcon state={state} />
        <span
          className={clsx({
            [Style.filled]: state === 'filled',
            [Style.missed]: state === 'missed',
          })}
        >
          {formType}
        </span>
      </div>
      {fillingProgress && <FillingProgress value={fillingProgress} />}
    </div>
  );
};

const FormStateIcon: FC<{ state: 'filled' | 'missed' | 'none' }> = ({ state }) => {
  return (
    <>
      {state === 'filled' && <CheckIcon />}
      {state === 'missed' && <CrossIcon />}
      {state === 'none' && <DotIcon />}
    </>
  );
};
