import React, { FC, PropsWithChildren, useEffect } from 'react';
import { QueryFactory } from '../services/api';
import { useAppSelector } from '../application/redux-store/store-types';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AppEvents } from '../features/app/app-reducer';
import { useDispatch } from 'react-redux';
import { AppRoutes, navigateToRoute } from '../navigation/routes';
import _ from 'lodash';
import { Permissions, useHasPermissions } from '../helpers/auth/auth-helper';
import { useScopedTranslation } from '../application/localisation/useScopedTranslation';
import { InformationPageContainer } from 'src/components/informationPageContainer/InformationPage.Container';
import image from 'src/assets/img/patient/empty_table.svg';

export const ProfileProvider: FC<PropsWithChildren> = (props) => {
  const { t } = useScopedTranslation('ProfileProvider');
  const dispatch = useDispatch();
  const { studyId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const navigate = useNavigate();
  const hasPermissions = useHasPermissions();

  const profile = QueryFactory.ProfileQuery.useGetDoctorProfileInfoQuery({
    suspense: true,
  });

  useEffect(() => {
    dispatch(AppEvents.setTagFilters(profile.data?.tagFilters ?? null));
  }, [dispatch, profile.data?.tagFilters]);

  useEffect(() => {
    const study = profile.data?.studies?.find((x) => x.id === Number(id));
    if (study) {
      dispatch(AppEvents.selectStudy(study));
      return;
    }

    if (profile.data?.studies?.length === 1 && !hasPermissions(Permissions.AdministerApp)) {
      const singleStudy = _.first(profile.data?.studies);
      if (singleStudy) {
        if (hasPermissions(Permissions.ViewPatient)) {
          navigate(navigateToRoute.dashboard(singleStudy.id), { replace: true });
          return;
        }

        if (hasPermissions(Permissions.AdministerStudy)) {
          navigate(navigateToRoute.studySettings(singleStudy.id), { replace: true });
          return;
        }
      }

      return;
    }

    dispatch(AppEvents.resetStudy());
    navigate(AppRoutes.Studies, { replace: true });
  }, [dispatch, hasPermissions, id, navigate, profile.data?.studies]);

  if (!profile.isInitialLoading && profile.data?.studies && profile.data?.studies?.length > 0) {
    return (
      <>
        {props.children}
        <Outlet />
      </>
    );
  } else {
    return studyId ? (
      <InformationPageContainer header={t('NoGroups')} text={''} />
    ) : (
      <InformationPageContainer header={t('NoStudyTitle')} text={t('NoStudyText')} img={image} />
    );
  }
};
