import React, { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import { TypographyStyles } from 'src/styles';
import clsx from 'clsx';
import { useContextSelector } from 'use-context-selector';
import { UIEditorBlockWidth } from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import { ReactComponent as WarningIcon } from '../../../../assets/img/common/warning.svg';
import styles from '../controlsStyle.module.css';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { IAttentionText, IAttentionTextProps } from '../../../../features/forms/base/controls/content/AttentionText';
import { TextSettings } from './Text';

export const AttentionText: IAttentionText = (props) => {
  const { t } = useTranslation();
  const { connectors, selected, actions } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));

  const { connect, drag } = connectors;
  const { setProp } = actions;

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const isSubmitting = useContextSelector(FormFillContext, (x) => x.isSubmitting);

  const { size, text, isHiddenOnOverview } = {
    ...AttentionTextDefaultProps,
    ...props,
  };

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');

  useEffect(() => {
    setProp((itemProps: IAttentionTextProps) => {
      if (text.length > 0) {
        itemProps.text = text;
      } else {
        itemProps.text = t('Forms.Controls.emptyTitle');
      }
    });
  }, [setProp, text, t]);

  const notEditableText = isHiddenOnOverview ? (
    <></>
  ) : (
    <div className={clsx(styles.previewRow, 'preview-row')}>
      <span className={clsx(TypographyStyles.paragraph14, styles.textOverview)}>{text}</span>
    </div>
  );

  const editableText = (
    <div
      className={clsx({ [styles.container]: true, [styles.selected]: selected && enabled })}
      style={{
        width: size,
      }}
      draggable={enabled}
      ref={(ref) => enabled && connect(drag(ref!))}
    >
      <div
        aria-disabled={isSubmitting}
        className={clsx(TypographyStyles.paragraph14, styles.textContainer, styles.attentionTextContainer)}
      >
        <WarningIcon />
        <div>{text}</div>
      </div>
    </div>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

export const AttentionTextDefaultProps: IAttentionTextProps = {
  text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi, quos ipsam quas consequuntur placeat, quasi vitae ipsa provident dolor dolores deserunt maxime assumenda sed, culpa soluta odio! Laborum, aut maiores!',
  size: UIEditorBlockWidth.Quarter,
  isHiddenOnOverview: true,
};

AttentionText.craft = {
  defaultProps: AttentionTextDefaultProps,
  related: {
    settings: TextSettings,
  },
};
