import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { editReasonsSelectOptions } from './controlOptions';
import { AppButton } from 'uikit/buttons';
import { useForm } from 'react-hook-form';
import Style from './reasonModal.module.css';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { HookFormTextInput } from 'src/hookFormControls/hookFormTextInput';
import { UseModalProps, useModal } from 'src/application/hooks/useModal';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { ValidationFormRules } from 'src/helpers/validation-helpers';
import { HookFormDropDown } from 'src/hookFormControls/hookFormDropDown';
import { EditReasonEnum } from 'src/services/api/api-client';
import { Deferred } from '../../../../helpers/Deferred';

export type ReasonModalResult = {
  reason: EditReasonEnum;
  comment?: string;
};

type ReasonModalType = {
  onSave: (value: ReasonModalResult) => void;
  modalManager: UseModalProps;
};

export const useReasonModal = () => {
  const modal = useModal('CLOSED');
  const deferred = useRef<Deferred<ReasonModalResult>>();

  return useMemo(() => {
    return {
      open: () => {
        modal.openModal();
        deferred.current = new Deferred<ReasonModalResult>();
        return deferred.current.promise;
      },
      element: (
        <ReasonModal
          onSave={(value) => {
            modal.closeModal();
            deferred.current?.resolve(value);
          }}
          modalManager={modal}
        />
      ),
    };
  }, [modal]);
};

type ReasonModalFormType = { editReason: DropdownOption; editReasonDescription: string | undefined };

const ReasonModal = (props: ReasonModalType) => {
  const { t } = useScopedTranslation('Forms.EditReasonModal');
  const commonLocalizer = useCommonLocalization();
  const {
    onSave,
    modalManager: { visible, closeModal },
  } = props;

  const {
    handleSubmit,
    formState: { errors, isValid },
    watch,
    register,
    unregister,
    control,
  } = useForm<ReasonModalFormType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      editReason: undefined,
      editReasonDescription: undefined,
    },
  });

  const currentValue = useMemo(() => {
    return (watch('editReason')?.key as EditReasonEnum) || null;
  }, [watch('editReason')]);

  useLayoutEffect(() => {
    if (currentValue !== EditReasonEnum.Update) {
      unregister('editReasonDescription');
    }
  }, [currentValue]);

  const onSubmit = handleSubmit((data: ReasonModalFormType) => {
    onSave({
      reason: data.editReason.key as EditReasonEnum,
      comment: data.editReason.key === EditReasonEnum.Update ? data.editReasonDescription : undefined,
    });
  });

  const resetReason = useCallback(() => {
    unregister('editReason');
    unregister('editReasonDescription');
  }, [unregister]);

  return (
    <AppModalContainer
      visible={visible}
      onHide={closeModal}
      testId={'reason-modal'}
      title={t('ModalHeader')}
      isClickOutside={false}
      containerClassName={Style.modal}
      bodyClassName={Style.modalBody}
      onDismissed={resetReason}
      footer={
        <div className={Style.buttonGroup}>
          <AppButton
            text={commonLocalizer('Common_Cancel')}
            variant={'button'}
            colorSchema={'secondary'}
            type={'reset'}
            onClick={closeModal}
          />
          <AppButton
            text={commonLocalizer('Common_Save')}
            variant={'button'}
            colorSchema={'primary'}
            type={'submit'}
            disabled={!isValid}
            form={'reasonForm'}
          />
        </div>
      }
    >
      <form id={'reasonForm'} onSubmit={onSubmit} autoComplete={'off'}>
        <HookFormDropDown
          labelProps={{ text: t('Caption') }}
          placeholder={t('Placeholder')}
          name={'editReason'}
          control={control}
          isRequired={true}
          rules={{
            ...ValidationFormRules().requiredRule,
          }}
          options={editReasonsSelectOptions()}
          errorProps={{ errors: (errors?.editReason as any)?.message }}
        />

        <div className={Style.commentBlock}>
          {currentValue === EditReasonEnum.Update && (
            <HookFormTextInput
              control={control}
              name={'editReasonDescription'}
              type={'text-area'}
              rules={{
                ...ValidationFormRules().requiredRule,
                validate: ValidationFormRules().notEmpty,
              }}
              labelProps={{
                text: t('UpdateReasonCaption'),
              }}
              placeholder={t('UpdateReasonPlaceholder')}
            />
          )}
        </div>
      </form>
    </AppModalContainer>
  );
};
