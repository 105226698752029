/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useCallback, useMemo } from 'react';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { ReportHeader } from '../components/ReportHeader';
import { ReportPagination } from '../components/ReportPagination';
import { RPDF } from '../react-pdf';
import i18n from 'i18next';
import { FirstPage } from './recordsFirstPage';
import { FilledRecord, RecordsReportOptions } from './recordsReport';
import { RecordsReportContext } from './recordsReport.context';
import { recordReportFormControlsResolver } from './recordReport.resolver';
import { deserializeNodes, renderNode } from './recordReport-helper';
import {
  FormScore,
  IFormResultDto,
  IFormScore,
  ScoreCalculationStatusEnum,
  ScoreGroupEnum,
} from '../../../services/api/api-client';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { ReportColors } from '../report-colors';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { useTranslation } from 'react-i18next';
import { Editor } from '@craftjs/core';
import { WarningScoreIcon } from '../components/svg/WarningScoreIcon';
import { ScoreProblemLegendSection } from '../components/ReportScoreProblemLegend';
import { RecordNoScoreIcon } from '../components/svg/RecordNoScoreIcon';
import { DateField } from 'src/components/expandedRow/notesSection/formControls/header/DateField';

export const RecordsReportTemplate: FC<RecordsReportOptions> = ({
  filledRecords,
  studyNumber,
  patientUid,
  profileFullName,
  missedRecords,
  groups,
  patientTags,
}) => {
  const { t } = useScopedTranslation('Reports.Records');
  const { t: globalTranslation } = useTranslation();

  const isSingleTypeForms = useMemo(() => [...new Set(filledRecords.map((x) => x.form.type))].length === 1, []);

  const headerText = useMemo(() => {
    return isSingleTypeForms ? filledRecords[0].form.type : t('DocumentHeader');
  }, [filledRecords, t]);

  const firstPage = (
    <FirstPage
      data={{
        headerText,
        patientUid,
        filledRecordsCount: filledRecords.length,
        missedRecordsCount: missedRecords.length,
        studyNumber: studyNumber,
        profileFullName,
        patientTags: patientTags ?? [],
      }}
    />
  );

  const renderRecordInfo = useCallback(
    (formType: string, resultDto: IFormResultDto, stepName: string) => {
      const localStyles = RPDF.StyleSheet.create({
        container: {
          flexDirection: 'column',
          backgroundColor: ReportColors.ultraLightGrey,
          paddingHorizontal: 8,
          paddingTop: 4,
          paddingBottom: 4,
          gap: 4,
        },
        leftHeader: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          columnGap: 4,
        },
      });

      return (
        <RPDF.View style={localStyles.container}>
          <RPDF.View style={localStyles.leftHeader}>
            {!isSingleTypeForms && <RPDF.Text style={RPDF.typography.heading2}>{formType}</RPDF.Text>}
            <RPDF.Text style={RPDF.typography.text14}>{stepName}</RPDF.Text>
          </RPDF.View>
          <RPDF.View>
            <RPDF.Text style={[RPDF.typography.text10, { color: ReportColors.darkGrey }]}>
              {t(resultDto.editReason ? 'Updated' : 'Filled', {
                date: localFormat(resultDto.updatedAt!, DateFormats.longDate),
                name: resultDto?.doctor.fullName,
              })}
            </RPDF.Text>
          </RPDF.View>
        </RPDF.View>
      );
    },
    [isSingleTypeForms, t],
  );

  const renderScoreSection = useCallback(
    (formScore: IFormScore | null, scoreStatus: ScoreCalculationStatusEnum | null) => {
      if (!formScore && scoreStatus === ScoreCalculationStatusEnum.Done) {
        return <RPDF.View />;
      }

      const localStyles = RPDF.StyleSheet.create({
        container: {
          backgroundColor: ReportColors.extraLightGrey,
          paddingHorizontal: 8,
        },
        scoreSection: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          columnGap: 20,
          justifyContent: 'space-between',
        },
        singleSection: {
          flex: 1,
          minWidth: '30%',
          height: 24,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        totalScoreSection: {
          height: 24,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      });

      const renderScoreContent = (score?: number | null) => {
        if (scoreStatus !== ScoreCalculationStatusEnum.Done) {
          return <WarningScoreIcon />;
        }

        if (score === null) {
          return (
            <RPDF.View style={{ gap: 4, flexDirection: 'row' }}>
              <RPDF.Text style={RPDF.typography.text8}>{globalTranslation('Common_dash')}</RPDF.Text>
              <RecordNoScoreIcon />
            </RPDF.View>
          );
        }

        return <RPDF.Text style={RPDF.typography.text8}>{score?.toFixed(formScore?.decimal ?? 0)}</RPDF.Text>;
      };

      return (
        <RPDF.View style={localStyles.container}>
          <RPDF.View style={localStyles.scoreSection}>
            {formScore?.sectionScore.map((section) => {
              const localScoreStyle = [localStyles.singleSection, {}];
              if (formScore?.hasTotalScore) {
                localScoreStyle.push(RPDF.tableStyles.divider);
              }

              return (
                <RPDF.View key={section.scoreGroup} style={localScoreStyle}>
                  <RPDF.Text style={RPDF.typography.heading5}>
                    {globalTranslation(
                      `Forms.Score.ScoreGroup.${ScoreGroupEnum[section.scoreGroup]}` as LocalizedResourceDictionaryKeys,
                    )}
                  </RPDF.Text>
                  {renderScoreContent(section?.score)}
                </RPDF.View>
              );
            })}
          </RPDF.View>
          {(formScore?.hasTotalScore || scoreStatus !== ScoreCalculationStatusEnum.Done) && (
            <RPDF.View style={localStyles.totalScoreSection}>
              <RPDF.Text style={RPDF.typography.heading5}>{globalTranslation('Forms.Score.TotalScore')}</RPDF.Text>
              {renderScoreContent(formScore?.totalScore)}
            </RPDF.View>
          )}
        </RPDF.View>
      );
    },
    [globalTranslation],
  );

  const renderLegendSection = useCallback(
    (legend: string[] | null, scoreStatus: ScoreCalculationStatusEnum | null, score: FormScore | null) => {
      const isAnyLowScore = score?.sectionScore.some((x) => x.score === null) || score?.totalScore === null;
      const isCalculationError = scoreStatus !== ScoreCalculationStatusEnum.Done;

      if (!legend && !isAnyLowScore && !isCalculationError) {
        return <RPDF.View />;
      }

      const localStyles = RPDF.StyleSheet.create({
        container: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          columnGap: 12,
          rowGap: 4,
        },
      });

      return (
        <RPDF.View style={{ flexDirection: 'column', marginTop: 12 }}>
          {legend && (
            <RPDF.View style={localStyles.container}>
              {legend.map((text) => (
                <RPDF.Text key={text} style={[RPDF.typography.text8, { color: ReportColors.plain }]}>
                  {text}
                </RPDF.Text>
              ))}
            </RPDF.View>
          )}
          <ScoreProblemLegendSection
            scoreStatus={scoreStatus}
            isLowScore={isAnyLowScore}
            view={'records'}
            style={!!legend ? { marginTop: 4 } : {}}
          />
        </RPDF.View>
      );
    },
    [],
  );

  const missedRecordsPage = useMemo(() => {
    const localStyles = RPDF.StyleSheet.create({
      container: {
        height: 24,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: ReportColors.ultraLightGrey,
        justifyContent: 'space-between',
        paddingHorizontal: 8,
        marginBottom: 12,
      },
      leftHeader: {
        flexDirection: 'row',
        gap: 4,
      },
    });

    return (
      <RPDF.Page size="A4" style={RPDF.styles.page}>
        <ReportHeader captionText={headerText} />
        <RPDF.View style={localStyles.container}>
          <RPDF.View style={localStyles.leftHeader}>
            <RPDF.Text style={RPDF.typography.heading2}>{t('MissedRecords')}</RPDF.Text>
          </RPDF.View>
          <RPDF.Text style={[RPDF.typography.text10, { color: ReportColors.darkGrey }]}>
            {missedRecords.length}
          </RPDF.Text>
        </RPDF.View>
        {missedRecords.map((mr, i) => (
          <RPDF.View key={i}>
            <RPDF.View style={RPDF.controlStyles.recordRow}>
              <RPDF.Text style={RPDF.controlStyles.questionCell}>{mr.type}</RPDF.Text>
              <RPDF.Text style={RPDF.controlStyles.answerCell}>{mr.stepName}</RPDF.Text>
            </RPDF.View>
          </RPDF.View>
        ))}
        <ReportPagination />
      </RPDF.Page>
    );
  }, [headerText, missedRecords, t]);

  const renderSubPageHeader = useCallback(
    (subPageNumber: number | undefined, record: FilledRecord) => {
      return subPageNumber !== 1
        ? `${record.form.type} (${record.stepName}), ${t(record.resultDto.editReason ? 'Updated' : 'Filled', {
            date: localFormat(record.resultDto.updatedAt!, DateFormats.longDate),
            name: record.resultDto?.doctor.fullName,
          })}`
        : '';
    },
    [t],
  );

  const recordPage = useMemo(
    () =>
      filledRecords.map((record, i) => {
        const nodes = deserializeNodes(record.form.layoutSchema);

        return (
          <RPDF.Page key={i} size="A4" style={RPDF.styles.page}>
            <ReportHeader captionText={headerText} />
            <RPDF.View style={{ flexDirection: 'column' }}>
              {renderRecordInfo(record.form.type, record.resultDto, record.stepName)}
              {renderScoreSection(record.resultDto.score, record.resultDto.scoreStatus)}
              {renderLegendSection(record.form.legend, record.resultDto.scoreStatus, record.resultDto.score)}
            </RPDF.View>
            <RPDF.Text
              fixed={true}
              style={[RPDF.typography.text10, { color: ReportColors.plain, marginBottom: 12 }]}
              render={({ subPageNumber }) => renderSubPageHeader(subPageNumber, record)}
            />
            <RecordsReportContext.Provider value={{ formResult: record.resultDto.result, studyGroups: groups, nodes }}>
              <Editor>{renderNode(nodes, recordReportFormControlsResolver, 'ROOT')}</Editor>
            </RecordsReportContext.Provider>

            <ReportPagination />
          </RPDF.Page>
        );
      }),
    [filledRecords, groups, headerText, renderLegendSection, renderRecordInfo, renderScoreSection, renderSubPageHeader],
  );

  return (
    <RPDF.Document
      language={i18n.language}
      producer={'LM Study'}
      title={headerText}
      author={profileFullName || 'LM User'}
      subject={studyNumber}
    >
      {firstPage}
      {filledRecords.length > 0 && recordPage}
      {missedRecords.length > 0 && missedRecordsPage}
    </RPDF.Document>
  );
};
