import React, { useContext, useMemo, FC } from 'react';
import { FormResultDto } from '../../../../services/api/api-client';
import { PatientTableContext } from '../../PatientTableContext';
import { Editor, Frame } from '@craftjs/core';
import { lastNoteColumnFormControlsResolver } from './formControls/lastNoteColumnFormControlsResolver';
import { PropType } from '../../../../helpers/typeUtils';
import Style from './lastNote.module.css';

export const LastNote: FC<{ lastNoteFields: PropType<FormResultDto, 'result'> | null }> = ({ lastNoteFields }) => {
  const patientTableContext = useContext(PatientTableContext);

  const vm = useMemo(() => {
    return {
      answers: lastNoteFields ?? null,
      formLayout: patientTableContext.formConfigForNotes?.layoutSchema,
    };
  }, [lastNoteFields, patientTableContext.formConfigForNotes?.layoutSchema]);

  if (!vm.formLayout || !vm.answers) return <></>;

  return (
    <LastNoteContext.Provider value={vm}>
      <div className={Style.lastNote}>
        <Editor resolver={lastNoteColumnFormControlsResolver}>
          <Frame data={vm.formLayout} />
        </Editor>
      </div>
    </LastNoteContext.Provider>
  );
};

export const LastNoteContext = React.createContext<{
  readonly answers: PropType<FormResultDto, 'result'> | null;
}>({
  answers: {},
});
