import React, { useMemo, useState, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseModalProps } from '../../../application/hooks/useModal';
import { AppButton } from '../../uikit/buttons/button/button.component';
import Style from './resolveIssueForm.module.css';
import { ValidationConstants, ValidationFormRules } from '../../../helpers/validation-helpers';
import { ReactComponent as Warning } from '../../../assets/img/common/warning.svg';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import { logger } from '../../../application/logging/logging';
import { AppTextField } from '../../uikit/fields/text/appTextField.component';
import { AppModalContainer } from '../../uikit/modal/modal.component';
import { AppInputError } from '../../uikit/wrappers';
import { TypographyStyles } from 'styles';

export type IssueResolveFormParams = {
  issueId: number;
  callback: (data: UseFormResolveIssueType) => Promise<void>;
};

export type UseFormResolveIssueType = {
  action: string;
  comment?: string;
};

type IssueResolveFormType = {
  modal: UseModalProps<IssueResolveFormParams>;
};

export const IssueResolveForm: FC<IssueResolveFormType> = ({ modal }) => {
  const { t } = useTranslation();
  const [internalError, setInternalError] = useState(false);

  const { issueId, callback } = modal.params;

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<UseFormResolveIssueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      action: '',
      comment: undefined,
    },
  });

  const onSubmit = handleSubmit(async (data: UseFormResolveIssueType) => {
    setInternalError(false);
    try {
      await callback(data);
      modal.closeModal();
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof UseFormResolveIssueType;
          setError(formKey, {
            type: 'server',
            message: ex.errors[errorKey][0],
          });
        }
      } else {
        logger().error(ex);
        setInternalError(true);
      }
    }
  });

  const buttons = useMemo(() => {
    return (
      <div className={Style.footer}>
        <AppInputError errors={internalError ? t('Issues.GeneralError') : undefined} hideBorder position={'top'}>
          <div className={Style.buttonGroup}>
            <AppButton
              text={t('Common_Cancel')}
              variant={'button'}
              colorSchema={'secondary'}
              onClick={modal.closeModal}
              disabled={isSubmitting}
            />
            <AppButton
              text={t('Common_Finish')}
              variant={'button'}
              colorSchema={'primary'}
              type={'submit'}
              disabled={isSubmitting}
              isLoading={isSubmitting}
              form={'resolveIssueForm'}
            />
          </div>
        </AppInputError>
      </div>
    );
  }, [internalError, isSubmitting, modal.closeModal, t]);

  const content = useMemo(() => {
    return (
      <form id={'resolveIssueForm'} onSubmit={onSubmit} autoComplete={'off'}>
        <>
          <Controller
            control={control}
            name={'action'}
            rules={{
              ...ValidationFormRules().maxLengthRule,
              ...ValidationFormRules().requiredRule,
            }}
            render={({ field: { onChange, value } }) => (
              <AppTextField
                labelProps={{ text: t('Issues.ResolveModal.ActionLabel') }}
                placeholder={t('Issues.ResolveModal.ActionPlaceholder')}
                value={value}
                onChange={onChange}
                disabled={isSubmitting}
                errorProps={{ errors: errors?.action?.message }}
              />
            )}
          />

          <Controller
            control={control}
            name={'comment'}
            rules={{
              ...ValidationFormRules().maxLengthRule,
            }}
            render={({ field: { onChange, value } }) => (
              <AppTextField
                maxLength={ValidationConstants.textInputMaxLength}
                type={'text-area'}
                labelProps={{ text: t('Issues.ResolveModal.CommentLabel') }}
                placeholder={t('Issues.ResolveModal.CommentPlaceholder')}
                value={value}
                onChange={onChange}
                disabled={isSubmitting}
                errorProps={{ errors: errors?.comment?.message }}
              />
            )}
          />

          <div className={Style.attentionContainer}>
            <Warning />
            <div className={TypographyStyles.paragraph14}>{t('Issues.ResolveModal.AttentionText')}</div>
          </div>
        </>
      </form>
    );
  }, [control, errors?.action?.message, errors?.comment?.message, isSubmitting, onSubmit, t]);

  return (
    <AppModalContainer
      bodyClassName={Style.modalBody}
      title={t('Issues.ResolveModal.Title', { id: issueId })}
      visible={modal.visible}
      onHide={modal.closeModal}
      footer={buttons}
      onDismissed={reset}
      isDisabled={isSubmitting}
      testId={'resolve-issue-modal'}
    >
      {content}
    </AppModalContainer>
  );
};
