import React, { FC, PropsWithChildren } from 'react';
import Style from './appInputUnit.module.scss';

export type AppInputUnitProps = {
  units?: string;
  disabled?: boolean;
};

export const AppInputUnit: FC<PropsWithChildren<AppInputUnitProps>> = ({ units, disabled, children }) => {
  if (!units) {
    return <>{children}</>;
  }

  return (
    <div className={Style.unitsContainer}>
      {children}
      {units && (
        <span className={Style.units} data-disabled={disabled}>
          {units}
        </span>
      )}
    </div>
  );
};
