import { IDateInput } from '../../../../../features/forms/base/controls/inputs/DateInput';
import React, { useContext } from 'react';
import { NoteContext } from '../../NoteContext';
import { DateFormats, localFormat, toDateOrNull } from '../../../../../helpers/date-helpers';
import { NoteField } from '../../NoteField';

export const DateInput: IDateInput = (props) => {
  const noteContext = useContext(NoteContext);

  if (noteContext.noteDateDataKey === props.dataKey) return <></>;

  const inputValue = noteContext.formResult.result[props.dataKey] as string | string[] | undefined;
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;
  const parsedDate = toDateOrNull(singleInputValue);

  if (!parsedDate) return <></>;

  return (
    <NoteField
      label={props.label ?? props.dataKey}
      value={parsedDate && localFormat(parsedDate, DateFormats.longDate)}
    />
  );
};
