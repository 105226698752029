import { add, format, formatRelative, isSameDay, isSameYear, sub } from 'date-fns';
import { getCurrentDateLocale } from 'src/application/localisation/localization';
import { Language } from '../application/localisation/locales';

export enum DateFormats {
  shortDate = 'P',
  longDate = 'PP',
  timeOnly = 'p',
  dateTime = 'PPp',
  forFiles = 'dd-MM-yyyy',
  longWithoutYear = 'd MMM YYYY',
}

export function isValidDate(d: Date | null): boolean {
  if (d === null) {
    return true;
  }
  return d instanceof Date && !isNaN(d as any);
}

export function toDateOrNull(value: string | number | Date | null | undefined): Date | null {
  if (value instanceof Date) {
    return isNaN(value.getTime()) ? null : new Date(value);
  } else if (typeof value === 'number' || typeof value === 'string') {
    const date = new Date(value);
    return isNaN(date.getTime()) ? null : date;
  } else {
    return null;
  }
}

export function convertDateToString(date: Date | null | undefined, withTime?: boolean) {
  if (date === undefined) return undefined;

  if (!date) return '';

  return format(date, withTime === true ? "yyyy-MM-dd'T'HH:mm" : 'yyyy-MM-dd');
}

/*
 * Use `localFormat` everywhere, otherwise it's not possible to specify locale globally
 * That's an advice from https://github.com/date-fns/date-fns/issues/816#issuecomment-961280538
 */
export function localFormat(date: Date, dateFormat?: DateFormats): string {
  if (dateFormat === DateFormats.longWithoutYear) {
    const yearRegex = /[,]*\s\d{4}$/;
    return format(date, DateFormats.longDate, getCurrentDateLocale()).replace(yearRegex, '');
  }

  return format(date, dateFormat || DateFormats.shortDate, getCurrentDateLocale());
}

/**
 * @param startDate Inclusive date range start
 * @param endDate Inclusive date range end
 */
export function formatDateRange(startDate?: Date | null, endDate?: Date | null): string {
  if (!startDate && !endDate) {
    return '';
  }

  if (startDate && endDate) {
    if (isSameDay(startDate, endDate)) {
      return localFormat(startDate, DateFormats.longDate);
    }

    if (isSameYear(startDate, endDate)) {
      return `${localFormat(startDate, DateFormats.longWithoutYear)} - ${localFormat(endDate, DateFormats.longDate)}`;
    }
  }

  return `${startDate ? localFormat(startDate, DateFormats.longDate) : ''} - ${
    endDate ? localFormat(endDate, DateFormats.longDate) : ''
  }`;
}

const enFormatRelativeDateOnly = {
  lastWeek: "'last' cccc",
  yesterday: "'yesterday'",
  today: "'today'",
  tomorrow: "'tomorrow'",
  nextWeek: 'cccc',
  other: DateFormats.longDate,
};

const frFormatRelativeDateOnly = {
  lastWeek: "'last' cccc",
  yesterday: "'yesterday'",
  today: "'today'",
  tomorrow: "'tomorrow'",
  nextWeek: 'cccc',
  other: DateFormats.longDate,
};

const deFormatRelativeDateOnly = {
  lastWeek: "'letzten' cccc",
  yesterday: "'gestern'",
  today: "'meute'",
  tomorrow: "'morgen'",
  nextWeek: "'nächsten' cccc",
  other: DateFormats.longDate,
};

const formatRelativeDateOnlyLocales: { [key in Exclude<Language, 'en'> | 'en-US']: any } = {
  'en-US': enFormatRelativeDateOnly,
  de: deFormatRelativeDateOnly,
  // fr: frFormatRelativeDateOnly,
};

export const formatRelativeDateOnly = (
  date: Date | number,
  baseDate: Date | number,
  currentLocale: { locale: Locale },
) => {
  const formatRelativeLocale = formatRelativeDateOnlyLocales[currentLocale.locale.code!];

  return formatRelative(date, baseDate, {
    locale: {
      ...currentLocale.locale,
      ...(formatRelativeLocale && { formatRelative: (token: any) => formatRelativeLocale[token] }),
    },
  });
};

export const getMinDate = () => new Date(-8640000000000000);
export const getMaxDate = () => new Date(8640000000000000);

export type TimeUnits = 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds';

/**
 * In many places we store and deal with an right (upper, end) bound of a date time interval (range)
 * as an exclusive bound, but we can need to display an inclusive bound
 * and in that case we need to subtract one time unit (e.g. day) before use
 */
export function subTimeUnitFromDate<T extends Date | number | null | undefined>(exclusiveEndDate: T, units: TimeUnits) {
  return exclusiveEndDate instanceof Date || typeof exclusiveEndDate === 'number'
    ? sub(exclusiveEndDate, { [units]: 1 })
    : (exclusiveEndDate as Exclude<T, number>);
}

/** Inverse operation of {@link subTimeUnitFromDate} */
export function addTimeUnitToDate<T extends Date | number | null | undefined>(inclusiveEndDate: T, units: TimeUnits) {
  return inclusiveEndDate instanceof Date || typeof inclusiveEndDate === 'number'
    ? add(inclusiveEndDate, { [units]: 1 })
    : (inclusiveEndDate as Exclude<T, number>);
}
