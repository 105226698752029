import { BooleanParam, QueryParamConfig, StringParam } from 'use-query-params';
import { NumericArrayParam } from 'react-router-url-params';

export const userFilterParams = () => ({
  isDoctorRole: BooleanParam,
  isMonitorRole: BooleanParam,
  isStudyAdminRole: BooleanParam,
  isAdminRole: BooleanParam,
  isExaminerRole: BooleanParam,

  withoutStudy: BooleanParam,
  predefinedUser: BooleanParam,
  studyIds: NumericArrayParam,

  searchQuery: StringParam,
});

export type UsersFilterQueryParameters = {
  isDoctorRole: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  isMonitorRole: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  isStudyAdminRole: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  isAdminRole: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  isExaminerRole: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  withoutStudy: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  predefinedUser: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  studyIds: QueryParamConfig<number[] | null | undefined, number[] | null | undefined>;
  searchQuery: QueryParamConfig<string | null | undefined, string | null | undefined>;
};

export type GetAllUsersUsersQueryParameters = {
  searchQuery?: string | null;
  isDoctorRole?: boolean | null;
  isMonitorRole?: boolean | null;
  isStudyAdminRole?: boolean | null;
  isAdminRole?: boolean | null;
  isExaminerRole?: boolean | null;
  withoutStudy?: boolean | null;
  predefinedUser?: boolean | null;
  studyIds?: number[] | null;
};

export function userFilterToBackendRequest(query: {
  isDoctorRole?: boolean | null;
  isMonitorRole?: boolean | null;
  isStudyAdminRole?: boolean | null;
  isAdminRole?: boolean | null;
  isExaminerRole?: boolean | null;
  withoutStudy?: boolean | null;
  predefinedUser?: boolean | null;
  studyIds?: (number | null)[] | null;
}): GetAllUsersUsersQueryParameters {
  return {
    isDoctorRole: query.isDoctorRole ?? undefined,
    isMonitorRole: query.isMonitorRole ?? undefined,
    isStudyAdminRole: query.isStudyAdminRole ?? undefined,
    isAdminRole: query.isAdminRole ?? undefined,
    isExaminerRole: query.isExaminerRole ?? undefined,
    withoutStudy: query.withoutStudy ?? undefined,
    predefinedUser: query.predefinedUser ?? undefined,
    studyIds: (query.studyIds as number[]) ?? undefined,
  };
}
