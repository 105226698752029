import React from 'react';
import { RPDF } from '../react-pdf';
import { IssueQueryParamsType } from '../../../components/issue/bar/issueFilters-helper';
import { ReportColors } from '../report-colors';
import { FilterIcon } from '../components/svg/FilterIcon';
import { useTranslation } from 'react-i18next';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { IGroupDto } from '../../../services/api/api-client';

export const IssueReportFilters = (props: { filters: IssueQueryParamsType; groups: IGroupDto[] }) => {
  const { t } = useTranslation();
  const { filters, groups } = props;

  const localStyles = RPDF.StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 2,
    },
    text: {
      ...RPDF.typography.text8,
      color: ReportColors.darkGrey,
    },
  });

  const filterText = () => {
    const definedFilters: string[] = [];

    if (filters.patientGroupList) {
      definedFilters.push(
        t('Reports.Issue.Filters.patientGroupList', {
          value: filters.patientGroupList.map((x) => groups.find((g) => g.id === x)?.title).join(', '),
        }),
      );
    }
    if (filters.patientUid) {
      definedFilters.push(t('Reports.Issue.Filters.patientUid', { value: filters.patientUid }));
    }
    if (filters.state !== undefined) {
      definedFilters.push(
        t('Reports.Issue.Filters.state', {
          value: t(`Issues.Status.${filters.state}` as LocalizedResourceDictionaryKeys),
        }),
      );
    }
    if (filters.subjectList) {
      definedFilters.push(
        t('Reports.Issue.Filters.subjectList', {
          value: filters.subjectList.map((x) => t(`Issues.Subject.${x}` as LocalizedResourceDictionaryKeys)).join(', '),
        }),
      );
    }
    if (filters.unreadOnly) {
      definedFilters.push(t('Reports.Issue.Filters.unreadOnly'));
    }

    return definedFilters.join(', ');
  };
  if (!filterText()) {
    return <></>;
  }

  return (
    <RPDF.View style={localStyles.container}>
      <FilterIcon />
      <RPDF.Text style={localStyles.text}>{filterText()}</RPDF.Text>
    </RPDF.View>
  );
};
