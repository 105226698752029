﻿/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import clsx from 'clsx';
import { useContextSelector } from 'use-context-selector';
import styles from '../controlsStyle.module.css';
import { useTranslation } from 'react-i18next';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import {
  IFieldGroupContainer,
  IFieldGroupContainerProps,
} from '../../../../features/forms/base/controls/containers/FieldGroupContainer';
import { TypographyStyles } from '../../../../styles';
import { Container } from './Container';
import { ExpandedRowHeader } from '../../../expandedRow/header/expandedRowHeader.component';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { PanelContainer } from '../../toolbox/components/panelContainer';

export const FieldGroupContainer: IFieldGroupContainer = (props) => {
  const {
    connectors: { connect, drag },
    childrenIds,
    linkedNodes,
  } = useNode((state) => ({
    selected: state.events.selected,
    childrenIds: state.data.nodes,
    linkedNodes: state.data.linkedNodes,
  }));

  const {
    actions: { setProp },
    builderMode,
  } = useEditor((state) => ({
    builderMode: state.options.enabled,
  }));

  const { title, hideTitle, isDisabled } = {
    ...FieldGroupContainerProps,
    ...props,
  };

  useEffect(() => {
    if (builderMode) return;

    [...Object.values(linkedNodes), ...childrenIds].forEach((id) => {
      setProp(id, (p: any) => (p.isDisabled = isDisabled));
    });
  }, [builderMode, childrenIds, isDisabled, linkedNodes, setProp]);

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const isSubmitting = useContextSelector(FormFillContext, (x) => x.isSubmitting);

  const editableComponent = (
    <div draggable={builderMode} ref={(ref: HTMLDivElement) => builderMode && connect(drag(ref))}>
      {!hideTitle && (
        <span aria-disabled={isSubmitting || isDisabled} className={clsx(TypographyStyles.heading2, styles.title)}>
          {title}
        </span>
      )}
      <Element id="drop" canvas is={Container} flexBoxGap={12} isDisabled={isDisabled} />
    </div>
  );

  const viewComponent = (
    <div className={styles.fieldGroupPreview}>
      {!hideTitle && <ExpandedRowHeader styles={styles.title} text={title || ''} />}
      <Element id="drop" canvas is={Container} isDisabled={isDisabled} />
    </div>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

export const FieldGroupContainerSettings = () => {
  const { t } = useTranslation('dev');
  const { props, actions } = useNode((node) => ({
    props: node.data.props as IFieldGroupContainerProps,
    id: node.id,
  }));
  const { setProp } = actions;

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')}>
      <ControlsGroup
        collapsible
        expanded={!!props.title}
        header={t('StudySettingsPage.FormBuilder.settings.title')}
        flexible
        onOpen={() => {
          setProp((prop: IFieldGroupContainerProps) => {
            prop.title = 'Title';
            prop.hideTitle = false;
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IFieldGroupContainerProps) => {
            prop.title = undefined;
            prop.hideTitle = true;
          }, 500);
        }}
      >
        <SettingsInput
          multiple
          value={props.title || ''}
          onChange={(e) => {
            setProp((setProps: IFieldGroupContainerProps) => {
              setProps.title = e.target?.value ? e.target.value : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

export const FieldGroupContainerProps: IFieldGroupContainerProps = {
  title: 'Field group',
};

FieldGroupContainer.craft = {
  props: FieldGroupContainerProps,
  related: {
    settings: FieldGroupContainerSettings,
  },
};
