﻿import React, { FC, useCallback, useMemo } from 'react';
import { NotificationGroup, notificationGroupToTypeMapping } from '../notificationGroups';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { QueryFactory } from '../../../services/api';
import { INotificationDto } from '../../../services/api/api-client';
import { ReactComponent as PlaceholderIcon } from '../../../assets/img/notification/notification_placeholder_120.svg';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Style from './NotificationBellTooltipContent.module.scss';
import { TypographyStyles } from 'styles';
import { NotificationMessage } from '../message/notificationMessage.component';
import { AppButton } from 'uikit/buttons';
import { Loading } from 'uikit/suspense/Loading';

const LIMIT_SIZE = 20;
export const NotificationList: FC<{ group: NotificationGroup | null }> = ({ group }) => {
  const { t } = useTranslation();

  const { studyId } = useAppSelector((state) => state.app);

  const notificationTypes = group ? notificationGroupToTypeMapping[group] : undefined;

  const notificationsQuery = useInfiniteQuery(
    QueryFactory.NotificationQuery.getNotificationsQueryKey(
      undefined,
      studyId,
      notificationTypes,
      undefined,
      LIMIT_SIZE,
    ),
    ({ pageParam = 0 }) =>
      QueryFactory.NotificationQuery.Client.getNotifications(
        undefined,
        studyId,
        notificationTypes,
        pageParam,
        LIMIT_SIZE,
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * LIMIT_SIZE < lastPage.totalCount) {
          return pages.length * LIMIT_SIZE;
        }

        return undefined;
      },
      suspense: false,
      enabled: studyId !== null,
    },
  );

  const onLoadMore = useCallback(async () => {
    await notificationsQuery.fetchNextPage();
  }, [notificationsQuery]);

  const notifications = useMemo(() => {
    const flatList = notificationsQuery.data?.pages.flatMap((x) => x.data) || [];
    const unreadNotifications = flatList.filter((x) => !x.isRead);
    const readNotifications = flatList.filter((x) => x.isRead);

    return {
      unread: unreadNotifications,
      read: readNotifications,
    };
  }, [notificationsQuery.data?.pages]);

  const hasMore = notificationsQuery.hasNextPage;
  const hasNotifications = notifications.read.length + notifications.unread.length > 0;
  const isLoading = notificationsQuery.isInitialLoading || notificationsQuery.isFetchingNextPage;

  return (
    <Loading loading={isLoading} containerClassName={Style.notificationList}>
      <div className={Style.notificationsContainer}>
        {notifications.unread.length > 0 && (
          <NotificationListSection notifications={notifications.unread} type={'new'} />
        )}
        {notifications.read.length > 0 && <NotificationListSection notifications={notifications.read} type={'read'} />}
        {!isLoading && !hasNotifications && <NotificationBellTabPlaceholder />}
        {hasMore && (
          <div className={Style.loadMoreContainer}>
            <AppButton
              variant={'icon-link'}
              colorSchema={'primary'}
              text={t('Common_ShowMore')}
              onClick={onLoadMore}
              isLoading={isLoading}
              disabled={!hasMore || isLoading}
            />
          </div>
        )}
      </div>
    </Loading>
  );
};

export const NotificationListSection: FC<{ notifications: INotificationDto[]; type: 'new' | 'read' }> = ({
  notifications,
  type,
}) => {
  const { t } = useTranslation();

  if (notifications.length === 0) return <></>;

  return (
    <div
      className={clsx(Style.notificationGroupContainer, {
        [Style.readNotificationContainer]: type === 'new',
      })}
      data-test-id={`notification-section-${type}`}
    >
      <div className={clsx(TypographyStyles.heading3, Style.groupHeading)}>
        {type === 'new' ? t('Notifications.NewNotifications') : t('Notifications.ViewedNotifications')}
      </div>
      {notifications.map((n) => (
        <NotificationListItem notificationDto={n} key={n.id} />
      ))}
    </div>
  );
};

export const NotificationBellTabPlaceholder: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={Style.placeholderContainer} data-test-id={'notification-placeholder'}>
      <PlaceholderIcon />
      <div className={TypographyStyles.plainText14}>{t('Notifications.Placeholder')}</div>
    </div>
  );
};

const NotificationListItem: FC<{ notificationDto: INotificationDto }> = ({ notificationDto }) => {
  return (
    <div className={Style.notificationWrapper} data-test-id={'notification-item'}>
      <NotificationMessage {...notificationDto} />
    </div>
  );
};
