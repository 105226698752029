﻿import { IRouteProgressDto, RouteProgressStateEnum } from 'src/services/api/api-client';

export const getStepNameByResultId = (routeProgress: IRouteProgressDto, resultId: number | null) => {
  const step = routeProgress.steps.find((s) => s.forms.some((x) => x.formResultId === resultId));
  return step?.name;
};

export const stepIsStarted = (stepState: RouteProgressStateEnum) => {
  const index: Record<RouteProgressStateEnum, boolean> = {
    [RouteProgressStateEnum.None]: false,
    [RouteProgressStateEnum.Init]: false,
    [RouteProgressStateEnum.InProgress]: true,
    [RouteProgressStateEnum.Done]: true,
    [RouteProgressStateEnum.Missed]: true,
    [RouteProgressStateEnum.InProgressManually]: true,
    [RouteProgressStateEnum.Unexpected]: false,
  };

  return index[stepState];
};
