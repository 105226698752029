import React, { FC } from 'react';
import styles from './modal.module.scss';
import { AppInputError } from 'uikit/wrappers';
import { AppButton } from 'uikit/buttons';

type ModalButtonGroupProps = {
  onCancel: () => void;
  onSubmit: () => void;
  cancelButtonText: string;
  submitButtonText: string;
  generalError?: string;
  isSubmitting?: boolean;
};

export const ModalButtonGroup: FC<ModalButtonGroupProps> = (props) => {
  const { onCancel, onSubmit, generalError, isSubmitting, cancelButtonText, submitButtonText } = props;

  return (
    <div className={styles.footer}>
      <AppInputError errors={generalError} hideBorder position={'top'}>
        <div className={styles.buttonGroup}>
          <AppButton
            text={cancelButtonText}
            variant={'button'}
            colorSchema={'secondary'}
            onClick={onCancel}
            disabled={isSubmitting}
          />
          <AppButton
            text={submitButtonText}
            variant={'button'}
            colorSchema={'primary'}
            type={'button'}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={onSubmit}
          />
        </div>
      </AppInputError>
    </div>
  );
};
