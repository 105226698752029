import React from 'react';
import { AppRoutes } from './routes';
import { FormBuilder } from '../containers/formBuilder/formBuilder';
import { QuestionSchemeBuilder } from '../containers/questionSchemeBuilder/questionSchemeBuilder';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { Permissions } from '../helpers/auth/auth-helper';
import { PrivateRoute } from './PrivateRoute';
import { ProfileProvider } from '../providers/profileProvider';
import { Dashboard } from '../containers/dashboard/dashboard.container';
import { NavStudyBar } from '../containers/navigationMenu/navStudyBar.component';
import { Studies } from '../containers/studies/studies.component';
import { FAQ } from '../containers/faq/faqPage.component';
import { Analyzes } from '../containers/analyzes/Analyzes.component';
import Style from './index.module.css';
import { PatientPipelineList } from '../containers/pipelineEditor/PatientPipelineList';
import { useAppSelector } from '../application/redux-store/store-types';

export function StudyRouter() {
  const { hasDbImport } = useAppSelector((state) => state.app);
  return (
    <Route
      path={AppRoutes.Root}
      element={
        <PrivateRoute permissions={Permissions.StudyAccess}>
          <ProfileProvider />
        </PrivateRoute>
      }
    >
      <Route path={AppRoutes.Studies} element={<Studies />} />
      <Route path={AppRoutes.FAQPage} element={<FAQ />} />
      <Route
        path={AppRoutes.Dashboard}
        element={
          <PrivateRoute permissions={Permissions.ViewPatient}>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path={AppRoutes.StudySettings}
        element={
          <PrivateRoute permissions={Permissions.AdministerStudy}>
            <div className={Style.studySettingsLayout}>
              <NavStudyBar />
              <Outlet />
            </div>
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to={AppRoutes.FormBuilder} replace={true} />} />
        <Route path={AppRoutes.FormBuilder} element={<FormBuilder />} />
        <Route path={AppRoutes.QuestionSchemeBuilder} element={<QuestionSchemeBuilder />} />
        {hasDbImport && <Route path={AppRoutes.Analyzes} element={<Analyzes />} />}

        <Route
          path={AppRoutes.PatientPipelines}
          element={
            <PrivateRoute permissions={[Permissions.ViewPatient, Permissions.AdministerStudy]}>
              <PatientPipelineList />
            </PrivateRoute>
          }
        />
      </Route>
    </Route>
  );
}
