import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { IssuesStateEnum } from '../../../services/api/api-client';
import { ReactComponent as Opened } from '../../../assets/img/issues/opened_16.svg';
import { ReactComponent as Closed } from '../../../assets/img/issues/closed_16.svg';
import { ReactComponent as Processed } from '../../../assets/img/issues/pre_close_16.svg';
import { FunctionComponent, SVGProps } from 'react';

export type IssueQueryParamsType = {
  state?: IssuesStateEnum;
  subjectList?: string[];
  sortBy?: string;
  desc?: boolean;
  patientUid?: string;
  patientGroupList?: number[];
  unreadOnly?: boolean;
};

export type GroupedIssueQueryParamsType = {
  state?: IssuesStateEnum;
  subjectList?: string[];
  sortBy?: string;
  desc?: boolean;
  patientUid?: string;
  patientGroupList?: number[];
  unreadOnly?: boolean;
};

export type PageParamsType = {
  page: number;
  perPage: number;
};

export const defaultIssueQueryParams: IssueQueryParamsType = {
  sortBy: 'createdAt',
  desc: true,
  state: undefined,
  subjectList: undefined,
  patientUid: undefined,
  patientGroupList: undefined,
  unreadOnly: false,
};

export const defaultGroupedIssueQueryParams: GroupedIssueQueryParamsType = {
  sortBy: 'uniqueId',
  desc: true,
  state: undefined,
  subjectList: undefined,
  patientUid: undefined,
  patientGroupList: undefined,
  unreadOnly: false,
};

export const defaultPageParams: PageParamsType = {
  page: 1,
  perPage: 20,
};

export type IssueFilterProps = {
  type: 'table' | 'bar';
  queryParams: IssueQueryParamsType;
  onChange: (params: IssueQueryParamsType) => void;
};

type IIssueFilterOption = {
  key: string | number;
  icon?: FunctionComponent<SVGProps<any>>;
  localizationKey: LocalizedResourceDictionaryKeys;
};

type IIssueSortingOption = IIssueFilterOption & {
  desc: boolean;
};

export const issueSubjects = ['Patient', 'Study'] as const;
export type IssueSubjectsType = (typeof issueSubjects)[number];

export const issueTopicsForPatient = ['GeneralInfo', 'Records', 'Analyzes', 'Surveys', 'Notes', 'Other'] as const;
export type IssueTopicsForPatientType = (typeof issueTopicsForPatient)[number];
export const issueTopicsForStudy = ['SourceDataControl', 'Documentation', 'Other'] as const;
export type IssueTopicsForStudyType = (typeof issueTopicsForStudy)[number];

export const statusOptions: IIssueFilterOption[] = [
  { key: 'All', localizationKey: 'Issues.Filters.AllStatuses' as LocalizedResourceDictionaryKeys },
  {
    key: IssuesStateEnum.Opened,
    icon: Opened,
    localizationKey: 'Issues.Status.Opened' as LocalizedResourceDictionaryKeys,
  },
  {
    key: IssuesStateEnum.Processed,
    icon: Processed,
    localizationKey: 'Issues.Status.Processed' as LocalizedResourceDictionaryKeys,
  },
  {
    key: IssuesStateEnum.Closed,
    icon: Closed,
    localizationKey: 'Issues.Status.Closed' as LocalizedResourceDictionaryKeys,
  },
];

export const sortingOptions: IIssueSortingOption[] = [
  {
    key: 'createdAt',
    desc: true,
    localizationKey: 'Issues.Sorting.ByNewest' as LocalizedResourceDictionaryKeys,
  },
  {
    key: 'updatedAt',
    desc: true,
    localizationKey: 'Issues.Sorting.ByUpdate' as LocalizedResourceDictionaryKeys,
  },
  {
    key: 'deadlineAt',
    desc: false,
    localizationKey: 'Issues.Sorting.ByDeadline' as LocalizedResourceDictionaryKeys,
  },
];
