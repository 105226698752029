import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { localFormat, toDateOrNull } from '../../../../../helpers/date-helpers';
import { _isSkipped } from '../../../../forms/base/controls/inputs/base/baseControlSettings';
import { IDateInput } from '../../../../forms/base/controls/inputs/DateInput';
import { RPDF } from '../../../react-pdf';
import { RecordsReportContext } from '../../recordsReport.context';

export const DateInput: IDateInput = ({ label, dataKey, skipCheckBoxText }) => {
  const { t } = useTranslation();

  const inputValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  const answerValue = useMemo(() => {
    if (!singleInputValue) {
      return null;
    }

    if (singleInputValue === _isSkipped) {
      return skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox');
    }

    const date = toDateOrNull(singleInputValue);
    return date ? localFormat(date) : '';
  }, [singleInputValue, t, skipCheckBoxText]);

  return (
    <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
      <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? dataKey}</RPDF.Text>
      <RPDF.Text style={RPDF.controlStyles.answerCell}>{answerValue}</RPDF.Text>
    </RPDF.View>
  );
};
