﻿import React, { FC, useCallback, useState } from 'react';
import { CardSectionsEnum, IGroupDto, TableColumnsEnum } from '../../../../services/api/api-client';
import { useModal } from '../../../../application/hooks/useModal';
import Style from '../studyList.module.css';
import { ExpandedRowHeader } from '../../../../components/expandedRow/header/expandedRowHeader.component';
import { AppButton } from 'uikit/buttons';
import { CommonStyles } from 'styles';
import { getAllPresentedFlags } from '../../../../helpers/enum-helper';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { EditStudyGroupsModalContent } from '../EditStudyGroupsModalContent';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';
import { ReactComponent as AddIcon } from 'src/assets/img/common/add.svg';
import { useGroupCreating } from '../../../../features/study/useGroupCreating';
import { useStudyRouteCreating } from 'src/features/study/useStudyRouteCreating';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { removeGroup } from 'src/services/api/api-client/StudyClient';
import { getStudyListQueryKey } from 'src/services/api/api-client/StudyQuery';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { logger } from 'src/application/logging/logging';

export const GroupsSection: FC<{ studyId: number; groups: IGroupDto[] }> = (props) => {
  const { t } = useScopedTranslation('Admin.StudyList.Groups', 'dev');
  const queryClient = useQueryClient();
  const groupsModalManager = useModal();

  const groupCreating = useGroupCreating();
  const studyRouteCreating = useStudyRouteCreating();

  const [isGroupRemoving, setIsGroupRemoving] = useState<boolean>();

  const removeGroupHandler = useCallback(
    async (groupId: number) => {
      try {
        setIsGroupRemoving(true);
        await removeGroup(groupId);
        await queryClient.invalidateQueries(getStudyListQueryKey());
      } catch (e: any) {
        logger().error('Remove study group is failed', e);
      } finally {
        setIsGroupRemoving(false);
      }
    },
    [queryClient],
  );

  return (
    <div className={Style.section}>
      <ExpandedRowHeader
        text={t('Caption')}
        rightHeader={
          <div className={Style.groupSectionButtons}>
            <AppButton
              onClick={() => groupCreating.start(props.studyId)}
              variant={'icon-link'}
              colorSchema={'primary'}
              text={t('Add')}
              Icon={AddIcon}
            />
            <AppButton
              onClick={() => groupsModalManager.openModal()}
              variant={'icon-link'}
              colorSchema={'primary'}
              text={t('Edit')}
              Icon={EditIcon}
            />
          </div>
        }
      />

      {props.groups?.map((x, i, array) => (
        <React.Fragment key={x.title}>
          <div className={CommonStyles.tableRow}>
            <div className={CommonStyles.tableItemLeft}>{t('Id')}</div>
            <div className={clsx(CommonStyles.tableItemRight, Style.groupIdContainer)}>
              <div>{x.id}</div>
              <AppButton
                colorSchema={'decline'}
                variant={'icon-link'}
                text={t('RemoveGroup')}
                onClick={() => removeGroupHandler(x.id)}
                isLoading={isGroupRemoving}
                disabled={isGroupRemoving}
              />
            </div>
          </div>
          <div className={CommonStyles.tableRow}>
            <div className={CommonStyles.tableItemLeft}>{t('Title')}</div>
            <div className={CommonStyles.tableItemRight}>{x.title}</div>
          </div>
          <div className={CommonStyles.tableRow}>
            <div className={CommonStyles.tableItemLeft}>{t('TableColumns')}</div>
            <div className={CommonStyles.tableItemRight}>
              {getAllPresentedFlags(x.tableColumns)
                .map((f) => TableColumnsEnum[f])
                .join(', ')}
            </div>
          </div>
          <div className={CommonStyles.tableRow}>
            <div className={CommonStyles.tableItemLeft}>{t('SurveysColumns')}</div>
            <div className={CommonStyles.tableItemRight}>{x.surveyColumns.join(', ')}</div>
          </div>
          <div className={CommonStyles.tableRow}>
            <div className={CommonStyles.tableItemLeft}>{t('PatientCard')}</div>
            <div className={CommonStyles.tableItemRight}>
              {x.patientCardSections.map((cardColumn, index) => (
                <li key={`${x.title}_${index}`}>
                  <span>{`Card column #${index + 1}: `}</span>
                  {cardColumn.map((card) => CardSectionsEnum[card]).join(', ')}
                </li>
              ))}
            </div>
          </div>
          <div className={CommonStyles.tableRow}>
            <div className={CommonStyles.tableItemLeft}>{t('SurveysCards')}</div>
            <div className={CommonStyles.tableItemRight}>{x.surveysToPatientCard.join(', ')}</div>
          </div>
          <div className={CommonStyles.tableRow}>
            <div className={CommonStyles.tableItemLeft}>{t('StudyRoute')}</div>
            <div className={CommonStyles.tableItemRight}>
              {x.studyRouteId ? (
                <AppButton
                  colorSchema={'primary'}
                  variant={'icon-link'}
                  text={t('EditStudyRoute')}
                  onClick={() => studyRouteCreating.start(props.studyId, x.id, x.studyRouteId)}
                />
              ) : (
                <AppButton
                  colorSchema={'primary'}
                  variant={'icon-link'}
                  text={t('AddStudyRoute')}
                  onClick={() => studyRouteCreating.start(props.studyId, x.id)}
                />
              )}
            </div>
          </div>
          {i + 1 < array.length && <hr className={Style.dataBreak} />}
        </React.Fragment>
      ))}
      <AppModalContainer
        visible={groupsModalManager.visible}
        onHide={groupsModalManager.closeModal}
        title={t('Modal.Header')}
      >
        <EditStudyGroupsModalContent groups={props.groups} onClose={groupsModalManager.closeModal} />
      </AppModalContainer>
      {groupCreating.element}
      {studyRouteCreating.element}
    </div>
  );
};
