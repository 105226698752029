import React, { useCallback, useMemo, useState } from 'react';
import { DecodedValueMap, SetQuery } from 'use-query-params';
import Style from './userFilter.module.scss';
import UserListStyle from './userlist.module.scss';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import clsx from 'clsx';
import { usersQueryParamsConfig } from './UserList.component';
import TablePageLayout from '../../../components/tablePageLayout/TablePageLayout';
import { DirectionalHint } from '@fluentui/react';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import { DropDownMenuOption } from 'uikit/dropDownMenu/dropDownMenu';
import { ReactComponent as CheckboxUnchecked } from 'src/assets/img/issues/checkbox_unchecked_16.svg';
import { ReactComponent as CheckboxChecked } from 'src/assets/img/issues/checkbox_checked_16.svg';
import { ReactComponent as Reset } from 'src/assets/img/issues/reset_16.svg';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useGetStudyListQuery } from '../../../services/api/api-client/StudyQuery';
import { Chips } from 'uikit/chips/chips.component';
import { AppTextInput } from 'uikit/inputs';

const roleFilters = ['isDoctorRole', 'isMonitorRole', 'isExaminerRole', 'isAdminRole', 'isStudyAdminRole'];

export const UserFilters = (props: {
  testId?: string;
  queryParams: DecodedValueMap<typeof usersQueryParamsConfig>;
  setQueryParams: SetQuery<typeof usersQueryParamsConfig>;
}) => {
  const { setQueryParams, queryParams, testId } = props;
  const { t } = useScopedTranslation('Admin.UserList.Filters', 'dev');
  const commonLocalizer = useCommonLocalization();

  const { data: studies } = useGetStudyListQuery({
    // TODO: This hack must be removed when we will have a dropdown with virtualization
    limit: 99999999,
  });

  const [studyOptionsSearch, setStudyOptionsSearch] = useState('');

  const resetRoleFilters = useCallback(() => {
    setQueryParams({
      ...roleFilters.reduce((acc, value) => {
        return { ...acc, [value]: undefined };
      }, {}),
      page: 1,
    });
  }, [setQueryParams]);

  const roleOptions = useMemo(() => {
    return roleFilters?.map(
      (f) =>
        ({
          key: f,
          icon: queryParams[f] === true ? <CheckboxChecked /> : <CheckboxUnchecked />,
          text: t(`Role.${f}` as LocalizedResourceDictionaryKeys),
          checked: queryParams[f] === true,
          action: () =>
            setQueryParams({
              [f]: queryParams[f] === true ? undefined : true,
              page: 1,
            }),
          className: clsx(Style.commonOption, Style.fillNone),
        } as DropDownMenuOption),
    );
  }, [queryParams, setQueryParams, t]);

  const resetStudyFilters = useCallback(() => {
    setQueryParams({
      withoutStudy: undefined,
      studyIds: undefined,
      page: 1,
    });
  }, [setQueryParams]);

  const renderResetButton = useCallback(
    (action: () => void) => (
      <div
        data-test-id={'menu-item'}
        className={Style.resetButton}
        onClick={(e) => {
          e?.stopPropagation();
          action();
        }}
      >
        <Reset />
        <div>{commonLocalizer('Common_Reset')}</div>
      </div>
    ),
    [commonLocalizer],
  );

  const studyOptions = useMemo(
    () =>
      studies?.data?.map((s) => {
        const checked = queryParams?.studyIds?.includes(s.id);
        const filterState = queryParams.studyIds || [];
        return {
          key: s.id,
          icon: checked ? <CheckboxChecked /> : <CheckboxUnchecked />,
          text: s.studyNumber,
          checked: checked,
          action: () =>
            setQueryParams({
              studyIds: checked ? filterState.filter((x) => x !== s.id) : [...filterState, s.id],
              page: 1,
            }),
          className: clsx(Style.commonOption, Style.fillNone),
        } as DropDownMenuOption;
      }) || [],
    [studies?.data, queryParams.studyIds, setQueryParams],
  );

  const selectedRoleFilters = useMemo(() => {
    const roleFilter = Object.entries(queryParams).filter(([key, value]) => roleFilters.includes(key) && !!value);
    const anySelected = roleFilter.length > 0;
    return {
      anySelected,
      filtersCount: roleFilter.length,
      textCounter: roleFilter.length > 1 ? `(+${roleFilter.length - 1})` : '',
      filterText: anySelected ? t(`Role.${roleFilter[0][0]}` as LocalizedResourceDictionaryKeys) : t('Role.Header'),
    };
  }, [queryParams, t]);

  const selectedStudyFilters = useMemo(() => {
    const studyListFilter = queryParams?.studyIds ?? [];
    const anySelected = studyListFilter.length > 0 && !!studies?.data;
    return {
      anySelected,
      filtersCount: studyListFilter.length,
      textCounter: studyListFilter.length > 1 ? `(+${studyListFilter.length - 1})` : '',
      filterText: anySelected ? studies?.data.find((x) => x.id === studyListFilter[0])?.studyNumber : t('Study.Header'),
    };
  }, [queryParams?.studyIds, studies?.data, t]);

  const filteredStudyOptions = useMemo(
    () =>
      studyOptionsSearch.length
        ? studyOptions?.filter((x) => x.text.toLowerCase().includes(studyOptionsSearch.toLowerCase()))
        : studyOptions,
    [studyOptions, studyOptionsSearch],
  );

  const resetFilter = useCallback((state: boolean) => {
    if (!state) setStudyOptionsSearch('');
  }, []);

  return (
    <TablePageLayout.Header.Row>
      <div data-test-id={testId} className={clsx(Style.filterGroup, UserListStyle.userFilters)}>
        <DropDownMenu
          colorSchema={selectedRoleFilters.anySelected ? 'primary' : 'black'}
          text={selectedRoleFilters.filterText}
          counter={selectedRoleFilters.textCounter}
          options={roleOptions}
          closeCalloutByClick={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
          hasChevron={true}
          testId={'role-filter-menu'}
          calloutFooter={renderResetButton(resetRoleFilters)}
        />
        <DropDownMenu
          colorSchema={selectedStudyFilters.anySelected ? 'primary' : 'black'}
          text={selectedStudyFilters.filterText}
          counter={selectedStudyFilters.textCounter}
          options={[...filteredStudyOptions]}
          closeCalloutByClick={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
          hasChevron={true}
          testId={'study-filter-menu'}
          calloutHeader={
            <AppTextInput
              type={'search'}
              onChange={(e) => setStudyOptionsSearch(e.target.value)}
              placeholder={commonLocalizer('Common_Search')}
            />
          }
          calloutFooter={renderResetButton(resetStudyFilters)}
          onExpendChange={resetFilter}
        />

        <Chips
          text={t('Study.withoutStudy')}
          checked={queryParams.withoutStudy === true}
          onClick={() => {
            setQueryParams({
              withoutStudy: queryParams.withoutStudy === true ? undefined : true,
              studyIds: queryParams.withoutStudy === true ? queryParams.studyIds : undefined,
              page: 1,
            });
          }}
        />

        <Chips
          text={t('PredefinedUsers')}
          checked={queryParams.predefinedUser === true}
          onClick={() => {
            setQueryParams({
              predefinedUser: queryParams.predefinedUser === true ? undefined : true,
              page: 1,
            });
          }}
        />
      </div>
      <TablePageLayout.Header.Search
        value={queryParams.searchQuery || ''}
        placeholder={t('Search')}
        onChange={(e) => {
          e.preventDefault();
          setQueryParams({ searchQuery: e.target.value, page: undefined });
        }}
      />
    </TablePageLayout.Header.Row>
  );
};
