import { createContext } from 'use-context-selector';
import { IFormDto, IFormResultDto, PatientDto } from '../../../../services/api/api-client';
import { LayoutProps } from '../uiEditor';

export type DataBlockValidation = {
  [nodeId: string]: { validationState: 'Ok' | 'Validating' | 'Fail'; text: string };
};

export type Page = {
  nodeId: string;
  pageNumber: number;
};

export type PageStack = Page[];
export interface FormFillContextInterface {
  mode: 'Create' | 'Edit' | 'Overview';
  formConfig: IFormDto;
  formResult: IFormResultDto;
  stepName?: string;
  resetState: () => void;

  patient: PatientDto | undefined;

  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;

  values: { [key: string]: string | string[] | undefined };
  notEditableValues: LayoutProps[];
  setValue: (key: string, value: string | string[] | undefined) => void;
  removeValue: (key: string) => void;

  touchedFields: { [nodeId: string]: boolean };
  markFieldAsTouched: (nodeId: string) => void;

  dataBlockValidation: DataBlockValidation;
  setDataBlockValidation: (data: DataBlockValidation) => void;
  removeDataBlockValidation: (nodeId: string) => void;
  triggerValidation: (callback: (validationResult: DataBlockValidation) => void) => void;

  currentPageStack: PageStack | undefined;
  pageStacks: PageStack[];
  goToPage: (page: Page) => void;
  goToNextPage: () => void;
  goToPrevPage: () => void;

  addRefToDataBlockNavigation: (id: string, ref: any) => void;
  navigateToBlock: (dataKey: string | undefined) => void;
}

const defaultValues: Partial<FormFillContextInterface> = {
  mode: 'Create',
  values: {},
  notEditableValues: [],
  touchedFields: {},
  dataBlockValidation: {},
  currentPageStack: undefined,
  formResult: undefined,
};

export const FormFillContext = createContext(defaultValues);

export const OptionalDataKey = (dataKey: string) => `${dataKey}_optional`;
export const CommentDataKey = (dataKey: string) => `${dataKey}_comment`;
