import React, { useCallback, useMemo, useState } from 'react';
import Style from './analysisImport.module.css';
import { useContextSelector } from 'use-context-selector';
import { useModal } from '../../../application/hooks/useModal';
import { logger } from '../../../application/logging/logging';
import { AccessDbContext } from '../../../providers/accessDbProvider/accessDb.context';
import { QueryFactory } from '../../../services/api';
import { AnalysisCreationDto, AnalysisPresetDto, AnalysisPresetScheme } from '../../../services/api/api-client';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { Tooltip } from '../../uikit/tooltip/tooltip.component';
import { TypographyStyles } from '../../../styles';
import { Value } from 'mdb-reader';
import { getColumnValues, getDataFromDatabase } from '../analysis-helper';
import { ReactComponent as Add } from '../../../assets/img/records/add_24.svg';
import { useQueryClient } from '@tanstack/react-query';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppModalContainer } from '../../uikit/modal/modal.component';
import { DropdownOption } from '../../uikit/inputs/dropdown/appDropdownInput';
import { AppInputLabel } from '../../uikit/wrappers/label/appInputLabel.component';
import { strArrToDropdownOptions } from '../../uikit/inputs/dropdown/dropdown-helper';
import { AppInputError } from '../../uikit/wrappers';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs';

export type AnalysisImportProps = {
  preset: AnalysisPresetDto;
  patientId: string;
};

export const AnalysisImportComponent = (props: AnalysisImportProps) => {
  const { t } = useScopedTranslation('ExternalAnalysis.Presets');
  const commonLocalizer = useCommonLocalization();
  const { preset, patientId } = props;
  const modal = useModal();
  const { data: database, fileName } = useContextSelector(AccessDbContext, (x) => x);
  const queryClient = useQueryClient();

  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<{ [x: string]: Value }>();
  const [error, setError] = useState<string>();
  const [schemeList, setSchemeList] = useState(preset.scheme);
  const { data: analyzes } = QueryFactory.AnalyzesQuery.useGetAllPatientAnalyzesQuery(patientId, preset.id, {
    suspense: false,
  });

  const nextStep = () => {
    setError(undefined);
    setStep((s) => s + 1);
  };

  const prevStep = () => {
    setError(undefined);
    setStep((s) => s - 1);
  };

  const resetState = useCallback(() => {
    setStep(0);
    setLoading(false);
    setResult(undefined);
    setError(undefined);
    setSchemeList(preset.scheme);
  }, [preset.scheme]);

  const onSelectedOptionHandler = useCallback(
    (option: DropdownOption | undefined, scheme: AnalysisPresetScheme, keyColumn: string) => {
      setError(undefined);

      const newSchemeList: AnalysisPresetScheme[] = JSON.parse(JSON.stringify(schemeList));
      const tableName = newSchemeList.find((x) => x.tableName === scheme.tableName);
      if (!tableName) {
        return;
      }
      tableName.keyColumnsWithValue![keyColumn] = option?.key as string;
      setSchemeList(newSchemeList);
    },
    [schemeList],
  );

  const importContent = useMemo(() => {
    if (!database) {
      return;
    }

    return (
      <div className={Style.importContainer}>
        {schemeList?.map((scheme) => {
          return (
            <div key={scheme.tableName}>
              <div className={Style.dbNameRow}>
                <div className={TypographyStyles.heading2}>{t('PresetNameLabel')}</div>
                <div>{preset.name}</div>
              </div>
              <div className={Style.dbNameRow}>
                <div className={TypographyStyles.heading2}>{t('TableNameLabel')}</div>
                <div>{scheme.tableName}</div>
              </div>
              {Object.entries(scheme.keyColumnsWithValue!).map(([key, value]) => {
                const keyColumnValues = getColumnValues(database, scheme.tableName, key);
                const keyColumnValuesArr = strArrToDropdownOptions(Array.from(new Set(keyColumnValues)));

                return (
                  <div key={key}>
                    <AppInputLabel text={key}>
                      <AppDropDownWithSuggestionInput
                        placeholder={t('KeyColumnValuePlaceholder')}
                        options={keyColumnValuesArr}
                        value={keyColumnValuesArr.find((x) => x.key === value)}
                        onChange={(option: DropdownOption | undefined) => onSelectedOptionHandler(option, scheme, key)}
                      />
                    </AppInputLabel>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }, [t, database, onSelectedOptionHandler, preset.name, schemeList]);

  const previewContent = useMemo(() => {
    return (
      <div>
        {result ? (
          Object.entries(result).map(([key, value]) => (
            <div key={key} className={Style.previewRow}>
              <span className={TypographyStyles.heading2}>{key}</span>
              <span className={TypographyStyles.plainText14}>
                {value?.toString() ?? commonLocalizer('Common_dash')}
              </span>
            </div>
          ))
        ) : (
          <div>{commonLocalizer('Common_NoData')}</div>
        )}
      </div>
    );
  }, [commonLocalizer, result]);

  const getData = useCallback(() => {
    if (!database || !schemeList) {
      return;
    }

    try {
      const data = getDataFromDatabase(database, [...schemeList]);

      setResult(data);
      nextStep();
    } catch (ex: any) {
      setError(ex.message);
    }
  }, [database, schemeList]);

  const saveData = useCallback(async () => {
    if (!result) {
      return;
    }

    setLoading(true);
    try {
      await QueryFactory.AnalyzesQuery.Client.createAnalysis(
        { presetId: preset.id, data: result } as AnalysisCreationDto,
        props.patientId,
      );

      await queryClient.invalidateQueries(QueryFactory.AnalyzesQuery.getAllPatientAnalyzesQueryKey());
      modal.closeModal();
      resetState();
    } catch (ex: any) {
      logger().error(ex);
      setError(Object.values(ex.errors).join('\n\r'));
    } finally {
      setLoading(false);
    }
  }, [modal, preset.id, props.patientId, queryClient, resetState, result]);

  const buttons = useMemo(() => {
    return (
      <div className={Style.footer}>
        <AppInputError errors={error} hideBorder position={'top'}>
          <div className={Style.buttonGroup}>
            {step === 0 && (
              <>
                <AppButton
                  text={commonLocalizer('Common_Cancel')}
                  variant={'button'}
                  colorSchema={'secondary'}
                  onClick={modal.closeModal}
                />
                <AppButton
                  text={commonLocalizer('Common_Next')}
                  variant={'button'}
                  colorSchema={'primary'}
                  onClick={getData}
                />
              </>
            )}
            {step === 1 && (
              <>
                <AppButton
                  text={commonLocalizer('Common_Back')}
                  variant={'button'}
                  colorSchema={'secondary'}
                  onClick={prevStep}
                  disabled={loading}
                />
                <AppButton
                  text={commonLocalizer('Common_Finish')}
                  variant={'button'}
                  colorSchema={'primary'}
                  onClick={saveData}
                  disabled={loading}
                />
              </>
            )}
          </div>
        </AppInputError>
      </div>
    );
  }, [commonLocalizer, error, step, modal.closeModal, getData, loading, saveData]);

  const steps = [importContent, previewContent];
  const modalTitle = [t('ImportModalTitle'), t('PreviewModalTitle')];

  const canAddAnalysis = !preset.analysisQuantityLimit || (analyzes?.length ?? 0) < preset.analysisQuantityLimit;
  if (!canAddAnalysis) {
    return <></>;
  }

  return (
    <div className={Style.container}>
      <div className={Style.buttonWrapper}>
        <Tooltip
          text={t('DbWithNameDisconnected', { dbName: preset.databaseFilename })}
          disabled={fileName === preset.databaseFilename}
        >
          <AppButton
            onClick={() => modal.openModal()}
            variant={'icon-link'}
            colorSchema={'primary'}
            disabled={fileName !== preset.databaseFilename}
            Icon={Add}
            testId={'add-analysis-button'}
          />
        </Tooltip>
      </div>

      <AppModalContainer
        bodyClassName={Style.modalBody}
        title={modalTitle[step]}
        visible={modal.visible}
        onHide={modal.closeModal}
        footer={buttons}
        onDismissed={resetState}
      >
        {steps[step]}
      </AppModalContainer>
    </div>
  );
};
