import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import Style from './appTextField.module.scss';
import { AppTextInput, AppTextInputProps } from 'uikit/inputs';
import { AppInputErrorProps, AppInputError, ErrorSign } from 'uikit/wrappers';
import { AppInputLabelProps, AppInputLabel } from 'uikit/wrappers';
import { AppInputTriggerProps, AppInputTrigger } from 'uikit/wrappers';
import { AppInputUnit } from 'uikit/wrappers';
import { SkipCheckbox, SkipCheckboxProps } from 'uikit/wrappers';

export type AppTextFieldProps = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  triggerProps?: AppInputTriggerProps;
  skipCheckboxProps?: SkipCheckboxProps;
  maxLength?: number;
  units?: string;
} & Omit<AppTextInputProps, 'invalid'>;

export const AppTextField: FC<AppTextFieldProps> = ({
  maxLength,
  errorProps,
  triggerProps,
  labelProps,
  skipCheckboxProps,
  value,
  units,
  disabled,
  ref,
  ...rest
}) => {
  const lengthSection = useMemo(() => {
    if (!maxLength) {
      return <></>;
    }

    return (
      <span
        className={clsx(Style.maxLengthSection, {
          [Style.maxLengthError]: (value as string)?.length > maxLength,
        })}
      >{`${(value as string)?.length ?? 0}/${maxLength}`}</span>
    );
  }, [value, maxLength]);

  const rightSection = useMemo(() => {
    return (
      <div className={Style.rightSection}>
        {lengthSection}
        {errorProps?.errors && <ErrorSign />}
      </div>
    );
  }, [errorProps?.errors, lengthSection]);

  return (
    <AppInputTrigger disabled={disabled} {...triggerProps}>
      <AppInputError {...errorProps}>
        <SkipCheckbox disabled={disabled} {...skipCheckboxProps}>
          <AppInputLabel
            disabled={disabled}
            rightSection={rightSection}
            className={clsx({ [Style.textAreaWithCross]: triggerProps?.type })}
            {...labelProps}
          >
            <AppInputUnit units={units} disabled={skipCheckboxProps?.isSkipped || disabled}>
              <AppTextInput
                disabled={skipCheckboxProps?.isSkipped || disabled}
                value={skipCheckboxProps?.isSkipped ? undefined : value}
                invalid={!!errorProps?.errors || (!!errorProps?.errors?.length && errorProps?.errors?.length > 0)}
                {...rest}
              />
            </AppInputUnit>
          </AppInputLabel>
        </SkipCheckbox>
      </AppInputError>
    </AppInputTrigger>
  );
};
