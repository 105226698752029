import { RPDF } from '../react-pdf';
import React from 'react';

export const ReportHeader: React.FC<{ captionText: string; footer?: JSX.Element }> = ({ captionText, footer }) => {
  return (
    <RPDF.View fixed style={RPDF.styles.headerContainer}>
      <RPDF.Text style={RPDF.typography.heading1}>{captionText}</RPDF.Text>
      {footer && <RPDF.View>{footer}</RPDF.View>}
    </RPDF.View>
  );
};
