﻿import React, { FC, useMemo, useState } from 'react';
import { NotificationGroup, notificationTypeToGroupMapping } from '../notificationGroups';
import { NotificationList } from './NotificationList';
import styles from './NotificationBellTooltipContent.module.scss';
import { useGetNotificationCountsQuery } from '../../../services/api/api-client/NotificationQuery';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { groupBy } from '../../../helpers/arrayHelpers';
import { useTranslation } from 'react-i18next';

export const NotificationBellTooltipContent: FC = () => {
  const { studyId } = useAppSelector((state) => state.app);

  const countsQuery = useGetNotificationCountsQuery(studyId ?? undefined, {
    enabled: !!studyId,
  });

  const groupVms = useMemo(() => {
    if (!countsQuery.data) return [];

    return countsQuery.data
      .reduce(
        groupBy(
          (x) => notificationTypeToGroupMapping[x.type],
          (x) => x,
        ),
        [],
      )
      .map((x) => ({
        group: x.key,
        unread: x.values.reduce((sum, y) => sum + y.unread, 0),
        read: x.values.reduce((sum, y) => sum + y.read, 0),
      }));
  }, [countsQuery.data]);

  const [currentGroup, setCurrentGroup] = useState<NotificationGroup | null>(null);
  return (
    <div className={styles.notificationBellTooltip} data-test-id="notification-modal">
      {groupVms.length > 1 && <Tabs currentGroup={currentGroup} groups={groupVms} onChangeGroup={setCurrentGroup} />}
      <NotificationList group={currentGroup} />
    </div>
  );
};

const Tabs: FC<{
  currentGroup: NotificationGroup | null;
  groups: { group: NotificationGroup; unread: number }[];
  onChangeGroup: (group: NotificationGroup | null) => void;
}> = ({ currentGroup, groups, onChangeGroup }) => {
  const totalUnread = useMemo(() => {
    return groups.reduce((sum, x) => sum + x.unread, 0);
  }, [groups]);

  return (
    <div className={styles.tabs}>
      <Tab isCurrent={currentGroup === null} group={null} unread={totalUnread} onClick={() => onChangeGroup(null)} />
      {groups.map((x) => (
        <Tab
          key={x.group}
          isCurrent={x.group === currentGroup}
          group={x.group}
          unread={x.unread}
          onClick={() => onChangeGroup(x.group)}
        />
      ))}
    </div>
  );
};

const Tab: FC<{
  isCurrent: boolean;
  group: NotificationGroup | null;
  unread: number;
  onClick: () => void;
}> = ({ group, unread, isCurrent, onClick }) => {
  const { t } = useTranslation();

  return (
    <button type="button" data-is-current={isCurrent} className={styles.tab} onClick={onClick}>
      {t(`Notifications.Tabs.${group ?? 'All'}`) + (unread ? ` (${unread})` : '')}
    </button>
  );
};
