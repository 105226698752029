import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFactory } from '../../services/api';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { IDoctorStudyDto } from '../../services/api/api-client';
import { AppTable } from 'uikit/table/AppTable';
import { useStudiesColumns } from './study.columns';
import TablePageLayout from '../../components/tablePageLayout/TablePageLayout';

export const Studies = () => {
  const { t } = useTranslation();
  const { data: profile } = QueryFactory.ProfileQuery.useGetDoctorProfileInfoQuery({
    suspense: true,
  });

  const studiesList = profile?.studies?.map((s) => s.studyNumber);

  const rowDataTable = useReactTable<IDoctorStudyDto>({
    data: profile?.studies ?? [],
    columns: useStudiesColumns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TablePageLayout testId={'App'}>
      <TablePageLayout.Header>
        <TablePageLayout.Header.Row>
          <TablePageLayout.Header.Title
            text={t('StudiesList.Caption')}
            secondaryText={studiesList?.length.toString()}
          />
        </TablePageLayout.Header.Row>
      </TablePageLayout.Header>
      <TablePageLayout.TableContainer>
        <AppTable table={rowDataTable} placeholder={{ text: t('StudiesList.NoData') }} />
      </TablePageLayout.TableContainer>
    </TablePageLayout>
  );
};
