import React, { useMemo } from 'react';
import _ from 'lodash';
import { RouteProgressStateEnum } from '../../services/api/api-client';
import { PipelineProps } from './pipeline';
import Style from './pipeline.module.scss';
import { PipelineNode } from './pipelineNode.component';

export const PipelineComponent = (props: PipelineProps) => {
  const { routeProgress, patientId, patientUniqueId } = props;

  const content = useMemo(() => {
    if (!routeProgress) {
      return <></>;
    }

    const nodesToView = routeProgress.steps.filter((step) => {
      return step.state !== RouteProgressStateEnum.None;
    });

    return (
      <>
        {nodesToView.map((step, index) => {
          return (
            <PipelineNode
              patientId={patientId}
              patientUniqueId={patientUniqueId}
              routeProgress={routeProgress}
              key={step.name}
              name={step.name}
              shortName={step.shortName ?? ''}
              step={step}
              prevStep={index ? nodesToView[index - 1] : undefined}
              isFinishNode={_.last(routeProgress.steps)?.name === step.name}
            />
          );
        })}
      </>
    );
  }, [patientId, routeProgress]);

  return (
    <div className={Style.container} data-test-id={'pipeline-component'}>
      {content}
    </div>
  );
};
