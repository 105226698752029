import React from 'react';
import { RPDF } from '../../react-pdf';

export const RecordNoScoreIcon: React.FC = () => {
  return (
    <RPDF.Svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        fillRule="evenodd"
        d="M8.4 5C8.4 6.87777 6.87777 8.4 5 8.4C3.12223 8.4 1.6 6.87777 1.6 5C1.6 3.12223 3.12223 1.6 5 1.6C6.87777 1.6 8.4 3.12223 8.4 5ZM9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5ZM4.56094 5.7088V5.89337H5.14395V5.75861C5.14395 5.65704 5.15566 5.57013 5.1791 5.49786C5.20449 5.4256 5.24941 5.35431 5.31387 5.284C5.38027 5.21173 5.475 5.12677 5.59805 5.02911C5.74844 4.91388 5.87637 4.8045 5.98184 4.70099C6.08926 4.59552 6.17031 4.48126 6.225 4.35822C6.27969 4.23322 6.30703 4.0838 6.30703 3.90997C6.30703 3.59357 6.19766 3.33868 5.97891 3.14532C5.76211 2.95197 5.45449 2.85529 5.05605 2.85529C4.80605 2.85529 4.57656 2.88556 4.36758 2.94611C4.16055 3.0047 3.96816 3.08282 3.79043 3.18048L4.03359 3.69611C4.18789 3.61993 4.34414 3.55646 4.50234 3.50568C4.66055 3.4549 4.82949 3.4295 5.00918 3.4295C5.2123 3.4295 5.36953 3.47345 5.48086 3.56134C5.59414 3.64728 5.65078 3.77325 5.65078 3.93927C5.65078 4.04474 5.63223 4.13751 5.59512 4.21759C5.55996 4.29572 5.50137 4.37384 5.41934 4.45197C5.33926 4.53009 5.23086 4.62286 5.09414 4.73029C4.96133 4.8338 4.85586 4.93341 4.77773 5.02911C4.69961 5.12286 4.64395 5.22345 4.61074 5.33087C4.57754 5.43829 4.56094 5.56427 4.56094 5.7088ZM4.57266 6.49982C4.49062 6.56427 4.44961 6.67657 4.44961 6.83673C4.44961 6.99298 4.49062 7.10626 4.57266 7.17657C4.65664 7.24493 4.75918 7.27911 4.88027 7.27911C4.99746 7.27911 5.09805 7.24493 5.18203 7.17657C5.26602 7.10626 5.30801 6.99298 5.30801 6.83673C5.30801 6.67657 5.26602 6.56427 5.18203 6.49982C5.09805 6.43341 4.99746 6.40021 4.88027 6.40021C4.75918 6.40021 4.65664 6.43341 4.57266 6.49982Z"
        fill="#90A1E5"
      />
    </RPDF.Svg>
  );
};
