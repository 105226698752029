import { useEditor } from '@craftjs/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UndoIcon } from 'assets/img/formBuilder/undo.svg';
import clsx from 'clsx';
import styles from './UIEditorSaveResetControls.module.scss';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { AppButton } from 'uikit/buttons';

type Props = {
  disabled: boolean;
  isShowControls: boolean;
  canSave?: boolean;
  save: (config: Record<string, any>) => void;
  saveAs: (config: string) => void;
  canDelete?: boolean;
  delete?: () => void;
  resetToDefault: () => void;
};

export const UIEditorSaveResetControls: React.FC<Props> = (props) => {
  const { actions, query, canUndo, canRedo } = useEditor((state, queryParam) => ({
    enabled: state.options.enabled,
    canUndo: queryParam.history.canUndo(),
    canRedo: queryParam.history.canRedo(),
  }));

  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();

  if (!props.isShowControls) {
    return null;
  }

  return (
    <div className={styles.controls}>
      <AppButton
        variant={'icon-link'}
        colorSchema={'primary'}
        onClick={() => actions.history.undo()}
        disabled={props.disabled || !canUndo}
        Icon={UndoIcon}
        className={styles.button}
      />
      <AppButton
        variant={'icon-link'}
        colorSchema={'primary'}
        onClick={() => actions.history.redo()}
        disabled={props.disabled || !canRedo}
        Icon={UndoIcon}
        className={clsx(styles.button, styles.redo)}
      />
      <AppButton
        variant={'icon-link'}
        colorSchema={'primary'}
        onClick={props.resetToDefault}
        text={t('StudySettingsPage.FormBuilder.default_reset')}
        className={styles.button}
        disabled={props.disabled}
      />
      <AppButton
        variant={'icon-link'}
        colorSchema={'primary'}
        onClick={() => {
          const json = query.serialize();
          props.saveAs(json);
        }}
        text={commonLocalizer('Common_SaveAs')}
        className={styles.button}
        disabled={props.disabled || !canUndo}
      />
      <AppButton
        variant={'button'}
        colorSchema={'primary'}
        disabled={props.disabled || !canUndo || !props.canSave}
        text={commonLocalizer('Common_Save')}
        onClick={() => {
          const json = query.serialize();
          props.save(JSON.parse(json));
        }}
        className={styles.saveButton}
        isLoading={props.disabled}
      />
      <AppButton
        variant={'button'}
        colorSchema={'destroy'}
        text={commonLocalizer('Common_Delete')}
        onClick={props.delete}
        className={styles.saveButton}
        disabled={props.disabled || !props.canDelete}
      />
    </div>
  );
};
