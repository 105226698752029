import React, { FC, useContext, useMemo } from 'react';
import { TypographyStyles } from 'src/styles';
import { AppButton } from 'uikit/buttons/button/button.component';
import { FormResultVm, RecordRowVm } from './recordRow';
import Style from './recordRow.module.scss';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import clsx from 'clsx';
import { Permissions } from 'src/helpers/auth/auth-helper';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { PatientCardContext } from '../PatientCardContext';
import { useFormEditingAndOverview } from 'src/features/forms/useFormEditingAndOverview';
import { ReactComponent as Eye } from 'src/assets/img/common/eye_24.svg';
import { FillingProgress } from 'src/features/forms/components/FillingProgress';
import { ReactComponent as CrossIcon } from 'src/assets/img/formState/cross_16.svg';
import { ReactComponent as DotIcon } from 'src/assets/img/formState/dot_16.svg';
import { ReactComponent as CheckIcon } from 'src/assets/img/formState/check_16.svg';
import { ReactComponent as FilledAddIcon } from 'src/assets/img/records/fill_add_24.svg';
import { ReactComponent as AddIcon } from 'src/assets/img/records/add_24.svg';
import { ReactComponent as EditIcon } from 'src/assets/img/records/edit_24.svg';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const RecordRow: FC<{
  vm: RecordRowVm;
  withEditButton?: boolean;
  type: 'groupedByStep' | 'groupedByType' | 'multiple';
}> = ({ vm, withEditButton, type }) => {
  const { formType, formConfigId, formResults, fillingProgress, state } = vm;
  const {
    patient: { uniqueId: patientUniqueId, studyRouteProgress, id: patientId },
  } = useContext(PatientCardContext);

  const formEditingAndOverview = useFormEditingAndOverview();
  const filledFormsCount = useMemo(() => {
    return formResults.filter((x) => x.formResultId).length;
  }, [formResults]);

  const formsToView = useMemo(() => formResults.filter((x) => x.formResultId), [formResults]);
  const formsToFill = useMemo(() => formResults.filter((x) => !x.formResultId && x.stepIsActive), [formResults]);
  const formsToEdit = useMemo(() => formResults.filter((x) => x.formResultId && x.isEditable), [formResults]);

  return (
    <>
      <div className={Style.row} data-test-id={`record-${formType}`} data-id={'record-row'}>
        <LeftPart
          resultsCount={filledFormsCount}
          formType={formType}
          state={state}
          isMultiple={type === 'multiple'}
          stepName={type === 'groupedByStep' ? formResults[0].stepName : undefined}
        />
        <div className={Style.rightPart}>
          {fillingProgress && <FillingProgress value={fillingProgress} />}

          {formsToFill.length === 0 && (!withEditButton || formsToEdit.length === 0) && <ButtonPlaceholder />}

          <PermissionsCheck permissions={Permissions.EditPatient}>
            {withEditButton && formsToEdit.length > 0 && (
              <EditButton
                formsToEdit={formsToEdit}
                onEdit={(formResult) =>
                  formEditingAndOverview.startEditing({
                    formResultId: formResult.formResultId!,
                    patientUniqueId: patientUniqueId,
                  })
                }
                loading={formEditingAndOverview.formEditIsLoading}
              />
            )}
            {formsToFill.length > 0 && (
              <FillButton
                formsToFill={formsToFill}
                onFill={(form) => {
                  formEditingAndOverview.startFilling({
                    patientId: patientId,
                    patientUniqueId: patientUniqueId,
                    routeProgress: studyRouteProgress,
                    formId: formConfigId,
                    stepName: form.stepName,
                  });
                }}
                loading={formEditingAndOverview.formEditIsLoading}
              />
            )}
          </PermissionsCheck>

          {formsToView.length === 0 && <ButtonPlaceholder />}
          {formsToView.length > 0 && (
            <ViewButton
              loading={formEditingAndOverview.formOverviewIsLoading}
              onClick={() =>
                formEditingAndOverview.openOverview({
                  formId: formConfigId,
                  patientId,
                  routeProgress: studyRouteProgress,
                  stepName: formResults[0].stepName,
                  patientUniqueId,
                })
              }
            />
          )}
        </div>
      </div>

      {formEditingAndOverview.element}
    </>
  );
};

const ViewButton: FC<{ onClick: () => void; loading: boolean }> = ({ loading, onClick }) => {
  return (
    <AppButton
      onClick={onClick}
      Icon={Eye}
      variant={'icon-link'}
      colorSchema={'primary'}
      testId={'form-view-button'}
      isLoading={loading}
      loaderClassName={Style.loader}
    />
  );
};

const FillButton: FC<{
  formsToFill: FormResultVm[];
  onFill: (form: FormResultVm) => void;
  loading: boolean;
}> = ({ formsToFill, onFill, loading }) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');

  const icon = useMemo(() => {
    return formsToFill.some((x) => x.isMultiInstance) ? AddIcon : FilledAddIcon;
  }, [formsToFill]);

  return (
    <>
      {formsToFill.length === 1 && (
        <AppButton
          testId={'form-fill-button'}
          onClick={() => onFill(formsToFill[0])}
          variant={'icon-link'}
          colorSchema={'primary'}
          Icon={icon}
          isLoading={loading}
          loaderClassName={Style.loader}
        />
      )}

      {formsToFill.length > 1 && (
        <DropDownMenu
          Icon={icon}
          colorSchema={'primary'}
          isLoading={loading}
          loaderClassName={Style.loader}
          options={formsToFill.map((form) => ({
            key: form.stepName!,
            text: t('StepName', { step: form.stepName }),
            action: () => onFill(form),
          }))}
        />
      )}
    </>
  );
};

const EditButton: FC<{ formsToEdit: FormResultVm[]; onEdit: (formResult: FormResultVm) => void; loading: boolean }> = ({
  formsToEdit,
  onEdit,
  loading,
}) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');

  return (
    <>
      {formsToEdit.length === 1 && (
        <AppButton
          testId={'form-edit-button'}
          onClick={() => onEdit(formsToEdit[0])}
          variant={'icon-link'}
          colorSchema={'primary'}
          Icon={EditIcon}
          isLoading={loading}
          loaderClassName={Style.loader}
        />
      )}

      {formsToEdit.length > 1 && (
        <DropDownMenu
          Icon={EditIcon}
          colorSchema={'primary'}
          isLoading={loading}
          loaderClassName={Style.loader}
          options={formsToEdit.map((form) => ({
            key: form.stepName!,
            text: t('StepName', { step: form.stepName }),
            action: () => onEdit(form),
          }))}
        />
      )}
    </>
  );
};

const ButtonPlaceholder: FC = () => <div className={Style.buttonPlaceholder} />;

const LeftPart: FC<{
  resultsCount: number;
  formType: string;
  state: 'filled' | 'missed' | 'none' | undefined;
  stepName: string | undefined;
  isMultiple?: boolean;
}> = ({ resultsCount, formType, state, stepName, isMultiple }) => {
  const {
    patient: { uniqueId },
  } = useContext(PatientCardContext);

  return (
    <IssueMark
      key={formType}
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        linkedPatientUniqId: uniqueId,
        topicAdditional: formType,
        stepName: isMultiple ? 'multiple' : stepName,
      }}
      containerClassName={Style.issueMarkContainer}
    >
      <div data-test-id={'form-title'} className={Style.leftPart}>
        <FormStateIcon state={state} />
        <span>
          <span
            className={clsx(TypographyStyles.plainText14, Style.formType, {
              [Style.filled]: state === 'filled',
              [Style.missed]: state === 'missed',
            })}
          >
            {formType}
          </span>
          {resultsCount > 1 && (
            <span className={clsx(TypographyStyles.heading2, Style.formsCounter)}>{`(${resultsCount})`}</span>
          )}
        </span>
      </div>
    </IssueMark>
  );
};

const FormStateIcon: FC<{ state: 'filled' | 'missed' | 'none' | undefined }> = ({ state }) => {
  return (
    <>
      {state === 'filled' && <CheckIcon />}
      {state === 'missed' && <CrossIcon />}
      {state === 'none' && <DotIcon />}
    </>
  );
};
