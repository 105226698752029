/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useNode } from '@craftjs/core';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { CommentDataKey, OptionalDataKey } from '../../../../components/formEditor/uiEditor/provider/formFill.context';
import { _isSkipped, ConditionalFieldBehaviorEnum } from '../../../forms/base/controls/inputs/base/baseControlSettings';
import { IRadioInput } from '../../../forms/base/controls/inputs/RadioInput';
import { RPDF } from '../../react-pdf';
import { renderNode } from '../recordReport-helper';
import { recordReportFormControlsResolver } from '../recordReport.resolver';
import { RecordsReportContext } from '../recordsReport.context';
import { ReportColors } from '../../report-colors';

export const RadioInput: IRadioInput = ({
  dataKey,
  label,
  text,
  options,
  numerationStartIndex,
  numerationOrder,
  skipCheckBoxText,
}) => {
  const { t } = useTranslation();
  const { id } = useNode();

  const nodes = useContextSelector(RecordsReportContext, (v) => v.nodes);
  const linkedNodes = nodes.find((n) => n.id === id)?.linkedNodes;
  const inputValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);
  const additionalTextValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[OptionalDataKey(dataKey)]);
  const commentValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[CommentDataKey(dataKey)]);
  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  const displayValue = useMemo(() => {
    return options.filter((x) => x.value === singleInputValue).map((x) => x.displayValue)[0];
  }, [singleInputValue, options]);

  const answerIndex = useMemo(() => {
    let optionIndex = options.findIndex((x) => x.value === singleInputValue);
    if (!numerationOrder || numerationOrder === 'NoNum' || optionIndex === -1) {
      return null;
    }

    if (numerationOrder === 'Rtl') {
      optionIndex = options.reverse().findIndex((x) => x.value === singleInputValue);
    }

    return (numerationStartIndex || 0) + optionIndex;
  }, [options, numerationOrder, numerationStartIndex, singleInputValue]);

  const optionTextInputName = useMemo(
    () => options.find((x) => x.value === singleInputValue)?.additionalFieldLabel,
    [singleInputValue, options],
  );

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = options.filter((x) => x.withConditionalField);

    const finalArray = optionsWithConditionalField.filter(
      (option) =>
        option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
        multipleInputValue?.includes(option.value),
    );

    return Array.from(finalArray, (option) => {
      const childId = _.get(linkedNodes, option.key);
      if (!childId) {
        return <RPDF.View key={option.key} />;
      }

      return <RPDF.View key={option.key}>{renderNode(nodes, recordReportFormControlsResolver, childId)}</RPDF.View>;
    });
  }, [options, multipleInputValue, linkedNodes, nodes]);

  return (
    <RPDF.View>
      <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
        <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? text ?? dataKey}</RPDF.Text>
        {singleInputValue === _isSkipped ? (
          <RPDF.Text style={RPDF.controlStyles.answerCell}>
            {skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox')}
          </RPDF.Text>
        ) : (
          <RPDF.View style={[RPDF.controlStyles.answerCell, { flexDirection: 'row', columnGap: 4 }]}>
            {answerIndex !== null && <RPDF.Text style={{ color: ReportColors.plain }}>{`(${answerIndex})`}</RPDF.Text>}
            <RPDF.Text>{displayValue}</RPDF.Text>
          </RPDF.View>
        )}
      </RPDF.View>
      {additionalTextValue && (
        <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
          <RPDF.Text style={RPDF.controlStyles.questionCell}>{optionTextInputName}</RPDF.Text>
          <RPDF.Text style={RPDF.controlStyles.answerCell}>{additionalTextValue}</RPDF.Text>
        </RPDF.View>
      )}
      {commentValue && (
        <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
          <RPDF.Text style={RPDF.controlStyles.questionCell}>{t('Forms.Controls.CommentFieldCaption')}</RPDF.Text>
          <RPDF.Text style={RPDF.controlStyles.answerCell}>{commentValue}</RPDF.Text>
        </RPDF.View>
      )}
      {conditionalContainer.length > 0 && conditionalContainer}
    </RPDF.View>
  );
};
