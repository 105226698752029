import { useNode } from '@craftjs/core';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISliderInput } from 'src/features/forms/base/controls/inputs/SliderInput';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { useContextSelector } from 'use-context-selector';
import {
  ConditionalFieldBehaviorEnum,
  _isSkipped,
} from '../../../../forms/base/controls/inputs/base/baseControlSettings';
import { RPDF } from '../../../react-pdf';
import { renderNode } from '../../recordReport-helper';
import { recordReportFormControlsResolver } from '../../recordReport.resolver';
import { RecordsReportContext } from '../../recordsReport.context';

export const SliderInput: ISliderInput = ({
  label,
  dataKey,
  skipCheckBoxText,
  captionStartText,
  captionEndText,
  minValue,
  maxValue,
  options,
}) => {
  const { t } = useTranslation();
  const { id } = useNode();

  const nodes = useContextSelector(RecordsReportContext, (v) => v.nodes);
  const linkedNodes = nodes.find((n) => n.id === id)?.linkedNodes;

  const inputValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  const answerValue = useMemo(() => {
    if (!singleInputValue) return null;
    if (singleInputValue === _isSkipped) return skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox');

    return singleInputValue;
  }, [singleInputValue, skipCheckBoxText, t]);

  const labelOnOverview = useMemo(() => {
    const leftText = isNullOrEmpty(captionStartText) ? undefined : `${minValue}: ${captionStartText}`;
    const rightText = isNullOrEmpty(captionEndText) ? undefined : `${maxValue}: ${captionEndText}`;
    const caption =
      [leftText, rightText].filter(Boolean).length > 0 ? ` (${[leftText, rightText].filter(Boolean).join(', ')})` : '';

    return `${label ?? dataKey}${caption}`;
  }, [captionEndText, captionStartText, dataKey, label, maxValue, minValue]);

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = options.filter((x) => x.withConditionalField);
    const finalArray = optionsWithConditionalField.filter(
      (option) =>
        option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
        Number.parseFloat(singleInputValue) === Number.parseFloat(option.displayValue),
    );

    return Array.from(finalArray, (option) => {
      const childId = _.get(linkedNodes, option.key);
      if (!childId) {
        return <RPDF.View key={option.key} />;
      }

      return <RPDF.View key={option.key}>{renderNode(nodes, recordReportFormControlsResolver, childId)}</RPDF.View>;
    });
  }, [options, singleInputValue, linkedNodes, nodes]);

  return (
    <RPDF.View>
      <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
        <RPDF.Text style={RPDF.controlStyles.questionCell}>{labelOnOverview}</RPDF.Text>
        <RPDF.Text style={RPDF.controlStyles.answerCell}>{answerValue}</RPDF.Text>
      </RPDF.View>
      {conditionalContainer.length > 0 && conditionalContainer}
    </RPDF.View>
  );
};
