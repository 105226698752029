import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { AppTextField, AppTextFieldProps } from 'uikit/fields';

export type HookFormInputProps = {
  control: Control<any>;
  name: string;
  rules?: any;
  testId?: string;
  hideErrorBorder?: boolean;
} & Omit<AppTextFieldProps, 'errorProps'>;

export const HookFormTextInput: React.FC<HookFormInputProps> = (props: HookFormInputProps) => {
  const { control, name, rules, testId, hideErrorBorder, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <AppTextField
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          data-test-id={testId}
          errorProps={{ errors: error?.message, hideBorder: hideErrorBorder }}
          {...rest}
        />
      )}
    />
  );
};
