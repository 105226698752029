import React, { useCallback } from 'react';
import { AppButton } from 'uikit/buttons';
import { StartStudyComponentProps } from './startStudy';
import { ReactComponent as StartIcon } from '../../assets/img/patient/start_20.svg';
import { MetricActions } from '../../application/analitics/matomo/matomo-actions';
import { MetricCategories } from '../../application/analitics/matomo/matomo-categories';
import { trackEvent } from '../../application/analitics/matomo/matomo-tracker';
import { useAppSelector } from '../../application/redux-store/store-types';
import { QueryFactory } from '../../services/api';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { useFormEditing } from '../../features/forms/editing/useFormEditing';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';

export const StartStudyComponent = (props: StartStudyComponentProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.StartStudy');
  const { patientCreationFormId } = useAppSelector((state) => state.app);

  const formEditing = useFormEditing();

  const onShow = useCallback(async () => {
    if (typeof patientCreationFormId !== 'number') return;

    const results = await QueryFactory.FormsQuery.Client.getFormResults(
      patientCreationFormId,
      undefined,
      props.patientId,
      1,
    );

    await formEditing.startFormEditing({ formResult: results.data[0] });

    trackEvent({ action: MetricActions.ButtonPressed, category: MetricCategories.Patient, name: 'StartStudyButton' });
  }, [formEditing, patientCreationFormId, props.patientId]);

  return (
    <div>
      <Tooltip text={t('Tooltip')}>
        <AppButton
          testId="patient-card-start-study"
          variant={'icon-link'}
          colorSchema={'primary'}
          Icon={StartIcon}
          text={t('Button')}
          onClick={onShow}
        />
      </Tooltip>

      {formEditing.element}
    </div>
  );
};
