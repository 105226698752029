﻿import { QueryParamConfig } from 'use-query-params';

export const TagFiltersParam: QueryParamConfig<
  { [key: string]: string[] | undefined } | null | undefined,
  { [key: string]: string[] }
> = {
  encode(obj) {
    // null or undefined
    if (!obj) return obj;

    return JSON.stringify(obj);
  },
  decode(encoded): { [key: string]: string[] } {
    const json = Array.isArray(encoded) ? encoded[0] : encoded;

    if (json === null || json === undefined) return {};

    try {
      const obj = JSON.parse(json);

      if (typeof obj !== 'object') return {};

      for (const key of Object.keys(obj)) {
        const value = obj[key];

        if (!Array.isArray(value) || value.length === 0) {
          delete obj[key];
        }
      }

      return obj;
    } catch (e: any) {
      console.error(e);
      return {};
    }
  },
};
