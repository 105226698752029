import React, { useCallback, useMemo, useState } from 'react';
import { AppButton } from 'uikit/buttons';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { ErrorScoreCalculationProps } from './errorScoreCalculation';
import { ReactComponent as Trigon } from '../../assets/img/common/trigon_filled_24.svg';
import Style from './errorScoreCalculation.module.css';
import { TypographyStyles } from '../../styles';
import clsx from 'clsx';
import { logger } from '../../application/logging/logging';
import { QueryFactory } from '../../services/api';
import { useQueryClient } from '@tanstack/react-query';
import { Permissions } from '../../helpers/auth/auth-helper';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';

export const ErrorScoreCalculation = (props: ErrorScoreCalculationProps) => {
  const { retryHandlerType, onClick, isInProcess, canRetry } = props;
  const { t } = useScopedTranslation('Forms.Score.ScoreCalculation');
  const queryClient = useQueryClient();
  const [retrying, setRetrying] = useState(false);

  const retryHandler = useCallback(async () => {
    setRetrying(true);

    if (retryHandlerType === 'RetrySurvey') {
      try {
        await QueryFactory.SurveyQuery.Client.recalculateScore(props.answerId!);

        await Promise.all([
          queryClient.invalidateQueries(QueryFactory.SurveyQuery.getQuestionnairesQueryKey()),
          queryClient.invalidateQueries(QueryFactory.SurveyQuery.getLastSurveysStatisticsQueryKey()),
          queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientsQueryKey()),
          queryClient.invalidateQueries(QueryFactory.SurveyQuery.getAnswerStatisticQueryKey(props.answerId!)),
          queryClient.invalidateQueries(QueryFactory.SurveyQuery.getAnswersStatisticQueryKey()),
        ]);
      } catch (error: any) {
        logger().error('Survey score calculation is failed', error);
      }
    }

    if (retryHandlerType === 'RetryFormResult') {
      try {
        await props.retryHandler();
      } catch (error: any) {
        logger().error('Form score calculation is failed', error);
      }
    }

    setRetrying(false);
    return;
  }, [props, queryClient, retryHandlerType]);

  const tooltipContent = useMemo(() => {
    return (
      <div className={Style.container}>
        <div className={TypographyStyles.plainText12}>{canRetry ? t('ErrorCalculation') : t('CantRetryMessage')}</div>
        {isInProcess ? (
          <div className={clsx(TypographyStyles.plainText12, Style.inProgressText)}>{t('InProgress')}</div>
        ) : (
          canRetry && (
            <PermissionsCheck permissions={Permissions.EditPatient}>
              <AppButton
                variant={'icon-link'}
                colorSchema={'primary'}
                text={t('Retry')}
                onClick={retryHandler}
                disabled={retrying}
                isLoading={isInProcess}
                textClassName={TypographyStyles.plainText12}
              />
            </PermissionsCheck>
          )
        )}
      </div>
    );
  }, [canRetry, isInProcess, retryHandler, retrying, t]);

  return (
    <Tooltip text={''} tooltipContent={tooltipContent}>
      <Trigon className={clsx(Style.redIcon, { [Style.cursorPointer]: !!onClick })} onClick={onClick} />
    </Tooltip>
  );
};
