import MontserratRegular from 'src/assets/fonts/Montserrat-Regular.ttf';
import MontserratMedium from 'src/assets/fonts/Montserrat-Medium.ttf';
import MontserratSemiBold from 'src/assets/fonts/Montserrat-SemiBold.ttf';
import MontserratLight from 'src/assets/fonts/Montserrat-Light.ttf';
import type {
  pdf,
  Document,
  Page,
  Image,
  View,
  Text,
  Link,
  Note,
  Canvas,
  Line,
  Polyline,
  Polygon,
  StyleSheet,
  Font,
  Svg as SvgInner,
  Path,
  Rect,
  Circle,
  Ellipse,
  Tspan,
  G,
  Stop,
  Defs,
  ClipPath,
  LinearGradient,
  RadialGradient,
  PDFDownloadLink,
  PDFRenderer,
} from '@react-pdf/renderer';

import type { Style } from '@react-pdf/types/style';

import React from 'react';
import { ReportColors } from 'src/features/report/report-colors';
import { createControlStyles } from './records/controls/controlStyle';

type Svg = React.Component<React.PropsWithChildren<React.ComponentProps<typeof SvgInner>>>;
export type {
  pdf,
  Document,
  Page,
  Image,
  View,
  Text,
  Link,
  Note,
  Canvas,
  Svg,
  Line,
  Polyline,
  Polygon,
  StyleSheet,
  Font,
  Path,
  Rect,
  Circle,
  Ellipse,
  Tspan,
  G,
  Stop,
  Defs,
  ClipPath,
  LinearGradient,
  RadialGradient,
  PDFDownloadLink,
  PDFRenderer,
  Style,
};

export let RPDF: {
  pdf: typeof pdf;
  PDFRenderer: typeof PDFRenderer;
  Document: typeof Document;
  Page: typeof Page;
  StyleSheet: typeof StyleSheet;
  Font: typeof Font;
  Image: typeof Image;
  View: typeof View;
  Text: typeof Text;
  Link: typeof Link;
  Note: typeof Note;
  Canvas: typeof Canvas;
  Svg: any;
  Line: typeof Line;
  Polyline: typeof Polyline;
  Polygon: typeof Polygon;
  Path: typeof Path;
  Rect: typeof Rect;
  Circle: typeof Circle;
  Ellipse: typeof Ellipse;
  Tspan: typeof Tspan;
  G: typeof G;
  Stop: typeof Stop;
  Defs: typeof Defs;
  ClipPath: typeof ClipPath;
  LinearGradient: typeof LinearGradient;
  RadialGradient: typeof RadialGradient;
  PDFDownloadLink: typeof PDFDownloadLink;
  styles: ReturnType<typeof createStyles>['styles'];
  tableStyles: ReturnType<typeof createStyles>['tableStyles'];
  typography: ReturnType<typeof createStyles>['typography'];
  controlStyles: ReturnType<typeof createControlStyles>['controlStyles'];
} = {} as any;

let _promise: Promise<any> | null;
let _isLoaded: boolean;
let _error: any;

export function useReactPdf() {
  if (!_isLoaded) {
    if (!_promise) {
      _promise = initReactPdf();
    }
    // will trigger Suspense
    throw _promise;
  }
  if (_error) {
    throw _error;
  }
}

export function initReactPdf() {
  return Promise.all([
    import('@react-pdf/renderer').then((x) => {
      RPDF = x as any;
      RPDF = { ...x, ...createStyles(), controlStyles: {} as any };
      // To access RPDF.typography in controlStyles
      RPDF = { ...RPDF, ...createControlStyles() };

      RPDF.Font.register({
        family: 'Montserrat',
        fonts: [
          {
            fontWeight: 'light',
            src: MontserratLight as any,
          },
          {
            fontWeight: 'normal',
            src: MontserratRegular as any,
          },
          {
            fontWeight: 'medium',
            src: MontserratMedium as any,
          },
          {
            fontWeight: 'semibold',
            src: MontserratSemiBold as any,
          },
        ],
      });
    }),
  ])
    .finally(() => (_isLoaded = true))
    .catch((e) => (_error = e));
}

function createStyles() {
  return {
    styles: RPDF.StyleSheet.create({
      page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '32px 60px',
      },
      landscapePage: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '60px 32px 32px 32px',
      },
      headerContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
        minHeight: 36,
      },

      infoContainer: {
        marginTop: '20px',
        flexDirection: 'row',
        marginBottom: '20px',
      },
      paginationMarginTop: {
        marginTop: 'auto',
      },
      paginationContainer: {
        marginTop: '16px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '36px',
      },
      paginationDataContainer: {
        width: '50%',
        flexDirection: 'row',
      },
      paginationDate: {
        marginLeft: 'auto',
      },
      paginationPages: {
        marginRight: 'auto',
        width: '100px',
        marginLeft: '-50px',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      emptyContainer: {
        borderColor: ReportColors.lightBlue,
        borderWidth: '3px',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
      emptyTextContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '38px',
      },
    }),

    tableStyles: RPDF.StyleSheet.create({
      divider: {
        borderBottom: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: ReportColors.ultraLightGrey,
      },
      columnsContainer: {
        marginTop: '4px',
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center',
        padding: '6px 0',
        height: '24px',
      },
      rowContainer: {
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center',
        padding: '7px 0',
        minHeight: '26px',
      },
    }),

    typography: RPDF.StyleSheet.create({
      heading1: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'semibold',
        fontSize: 20,
        lineHeight: 1.2,
      },
      heading2: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'medium',
        fontSize: 14,
        lineHeight: 1.2,
      },
      heading3: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'medium',
        fontSize: 12,
        lineHeight: 1.2,
      },
      heading4: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'medium',
        fontSize: 10,
        lineHeight: 1.2,
      },
      heading4b: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'semibold',
        fontSize: 10,
        lineHeight: 1.2,
      },
      heading5: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'medium',
        fontSize: 8,
        lineHeight: 1.2,
      },
      heading5b: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'semibold',
        fontSize: 8,
        lineHeight: 1.2,
      },
      text14: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'light',
        fontSize: 14,
        lineHeight: 1.2,
      },
      text10: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 10,
        lineHeight: 1.2,
      },
      text8: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 8,
        lineHeight: 1.2,
      },
    }),
  };
}
