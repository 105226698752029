import React, { useCallback, useEffect, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { TypographyStyles } from 'src/styles';
import clsx from 'clsx';
import { useContextSelector } from 'use-context-selector';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import { UIEditorBlockWidth } from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import { QueryFactory } from '../../../../services/api';
import { FileToFileParameter } from '../../../../helpers/file-helper';
import { useTranslation } from 'react-i18next';
import styles from '../controlsStyle.module.css';
import { useAppSelector } from '../../../../application/redux-store/store-types';
import { useNodeSharedState } from '../../../../helpers/craftjs/nodeSharedState';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { IImageBlock, IImageBlockProps } from 'src/features/forms/base/controls/content/ImageBlock';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { Loading } from 'uikit/suspense/Loading';

export interface IImageBlockSharedState {
  imageName?: string;
}

export const ImageBlock: IImageBlock = (props) => {
  const { t } = useTranslation();
  const { connectors, selected, actions } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const currentStudyId = useAppSelector((state) => state.app.studyId)!;

  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>();
  const setImageObjectUrl = useCallback((blob?: Blob) => {
    setImageSrc((oldImageSrc) => {
      if (oldImageSrc) URL.revokeObjectURL(oldImageSrc);
      return blob ? URL.createObjectURL(blob) : undefined;
    });
  }, []);
  const [errorText, setErrorText] = useState<string>();

  const { setProp } = actions;
  const { connect, drag } = connectors;

  const [, setSharedState] = useNodeSharedState<IImageBlockSharedState>();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { size, isHiddenOnOverview } = {
    ...ImageBlockDefaultProps,
    ...props,
  };

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');

  const notEditableText = isHiddenOnOverview ? (
    <></>
  ) : (
    <div className={styles.previewRow}>
      <span className={clsx(TypographyStyles.plainText14, styles.textOverview)}>{'Image here'}</span>
    </div>
  );

  useEffect(() => {
    if (props.fileId) {
      setIsLoading(true);
      QueryFactory.FilesQuery.Client.getFileById(props.fileId)
        .catch(() => {
          setErrorText(t('LocalErrors.FileNotFound'));
        })
        .then((result) => {
          if (result?.data) {
            setImageObjectUrl(result.data);
            setSharedState(() => ({ imageName: result.fileName }));
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setImageObjectUrl();
      setSharedState(() => undefined);
    }

    return () => {
      setImageObjectUrl();
    };
  }, [setSharedState, props.fileId, t, setImageObjectUrl]);

  const dropHandler = useCallback<React.DragEventHandler<HTMLDivElement>>(
    async (e) => {
      e.preventDefault();

      const [file] = e.dataTransfer.files;
      if (!file) return;

      try {
        const fileDto = await QueryFactory.FormsQuery.Client.uploadFile(currentStudyId, FileToFileParameter(file));

        setProp((setProps: IImageBlockProps) => {
          setProps.fileId = fileDto.fileId;
        });
      } catch (error: any) {
        setErrorText(error.title ?? t('Forms.Controls.ImageBlock.unexpectedError'));
      }
    },
    [setProp, t, currentStudyId],
  );

  const editableText = (
    <>
      <div
        data-border={enabled}
        className={clsx(styles.containerBlock, {
          [styles.selected]: selected && enabled,
        })}
        style={{
          width: size,
        }}
        draggable={enabled}
        onDrop={dropHandler}
        ref={(ref) => enabled && connect(drag(ref!))}
      >
        <Loading loading={isLoading}>
          {imageSrc ? (
            <img
              alt={t('Forms.Controls.ImageBlock.noFileAlt', { src: props.fileId })}
              src={imageSrc}
              className={clsx({
                [styles.imageBlockFill]: props.isFill,
              })}
            />
          ) : (
            <div className={clsx(styles.imageBlockText, { [styles.imageBlockBorder]: enabled })}>
              {enabled && <div>{t('Forms.Controls.ImageBlock.dropImageHere')}</div>}
              {errorText && <div className={styles.imageBlockError}>{errorText}</div>}
            </div>
          )}
        </Loading>
      </div>
    </>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

const ImageBlockSettings = () => {
  const { t } = useTranslation('dev');
  const { actions, props } = useNode((node) => ({
    props: node.data.props as IImageBlockProps,
  }));
  const { setProp } = actions;
  const [sharedState] = useNodeSharedState<IImageBlockSharedState>();

  return (
    <>
      <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
        <ControlsGroup flexible>
          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.settings.hideOnOverview')}
            isChecked={!!props.isHiddenOnOverview}
            setValue={(newValue) => {
              setProp((itemProps: IImageBlockProps) => {
                itemProps.isHiddenOnOverview = newValue ? newValue : undefined;
              });
            }}
          />

          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.settings.imageBlock.fillImage')}
            isChecked={!!props.isFill}
            setValue={(newValue) => {
              setProp((itemProps: IImageBlockProps) => {
                itemProps.isFill = newValue ? newValue : undefined;
              });
            }}
          />
        </ControlsGroup>

        <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.imageBlock.imageName')} flexible>
          <SettingsInput value={sharedState?.imageName ?? ''} disabled={true} />
        </ControlsGroup>
      </PanelContainer>

      <LayoutSettings />
    </>
  );
};

export const ImageBlockDefaultProps: IImageBlockProps = {
  size: UIEditorBlockWidth.Stretch,
  isHiddenOnOverview: true,
  fileId: null,
  isFill: true,
};

ImageBlock.craft = {
  defaultProps: ImageBlockDefaultProps,
  related: {
    settings: ImageBlockSettings,
  },
};
