import React, { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';
import Style from './appInputLabel.module.scss';
import { ReactComponent as TooltipIcon } from '../../../../assets/img/common/tip_16.svg';
import { TypographyStyles } from '../../../../styles';
import { Tooltip } from '../../tooltip/tooltip.component';

export type AppInputLabelProps = {
  text?: string;
  prefix?: string;
  tooltip?: string;
  isBold?: boolean;
  disabled?: boolean;
  className?: string;
  rightSection?: JSX.Element | string;
};

export const AppInputLabel: FC<PropsWithChildren<AppInputLabelProps>> = ({
  prefix,
  text,
  tooltip,
  className,
  isBold,
  disabled,
  rightSection,
  children,
}) => {
  if (!text) {
    return <>{children}</>;
  }

  return (
    <div className={clsx(Style.labelContainer, className)}>
      <div data-disabled={disabled} className={clsx(TypographyStyles.paragraph14, Style.label)}>
        {prefix && <div className={Style.prefix}>{prefix}</div>}
        <div>
          <label className={Style.text} data-is-bold={isBold}>
            {text}
          </label>
          {tooltip && (
            <Tooltip hostStyles={Style.tooltip} text={tooltip}>
              <TooltipIcon className={Style.icon} />
            </Tooltip>
          )}
        </div>
        <div className={Style.rightComponent}>{rightSection}</div>
      </div>
      {children}
    </div>
  );
};
