import {
  IFormControlsResolver,
  StubContainer,
  StubContent,
  StubFieldGroupContainer,
} from 'src/features/forms/base/controls/IFormControlsResolver';
import { DateField } from './DateField';
import { FieldWithOptions } from './FieldWithOptions';
import { StubFormField } from '../StubFormField';

export const noteHeaderFormControlsResolver: IFormControlsResolver = {
  EmptyBlock: StubContent,
  Container: StubContainer,
  FieldGroupContainer: StubFieldGroupContainer,
  Text: StubContent,
  AttentionText: StubContent,
  Title: StubContent,
  TextInput: StubFormField,
  NumberInput: StubFormField,
  SliderInput: StubFormField,
  DateInput: DateField,
  TimeInput: StubFormField,
  CheckBoxInput: StubFormField,
  CheckBoxGroupInput: FieldWithOptions,
  RadioInput: FieldWithOptions,
  SelectInput: FieldWithOptions,
  Files: StubFormField,
  StepContainer: StubContainer,
  TabsContainer: StubContainer,
  DateRangeInput: StubFormField,
  BmiInput: StubFormField,
  CalculatedBlock: StubFormField,
  GroupInput: StubFormField,
  ImageBlock: StubContent,
  TagInput: StubFormField,
};
