import React, { FC } from 'react';
import { DialogModal } from '../../../components/dialogModal/dialogModal.component';
import { AppButton } from '../../../components/uikit/buttons/button/button.component';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';

export const DeletingConfirmationDialog: FC<{
  onCancel: () => void;
  onApply: () => void;
  isLoading: boolean;
  isVisible: boolean;
}> = (props) => {
  const { t } = useScopedTranslation('StudySettingsPage.AnalyzesPreset.DeletingConfirmationDialog', 'dev');
  const commonLocalizer = useCommonLocalization();

  return (
    <DialogModal
      isVisible={props.isVisible}
      onClose={props.isLoading ? () => {} : props.onCancel}
      buttons={[
        <AppButton
          key={0}
          disabled={props.isLoading}
          variant={'button'}
          colorSchema={'secondary'}
          text={commonLocalizer('Common_Cancel')}
          type={'reset'}
          onClick={props.onCancel}
        />,
        <AppButton
          key={1}
          variant={'button'}
          colorSchema={'destroy'}
          disabled={props.isLoading}
          isLoading={props.isLoading}
          hasLoaded={props.isLoading}
          text={commonLocalizer('Common_Delete')}
          onClick={props.onApply}
        />,
      ]}
      mainText={t('MainText')}
      title={t('Title')}
    />
  );
};
