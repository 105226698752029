﻿import React, { FC } from 'react';
import { IAdminStudyDto } from '../../../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import Style from '../studyList.module.css';
import { ExpandedRowHeader } from '../../../../components/expandedRow/header/expandedRowHeader.component';
import { AppButton } from 'uikit/buttons';
import { CommonStyles } from 'styles';
import { formatDateRange, localFormat } from '../../../../helpers/date-helpers';
import { addDays } from 'date-fns';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';

export const StudyDetailsSection: FC<{ study: IAdminStudyDto; onEdit: () => void }> = ({ study, onEdit }) => {
  const { t } = useTranslation('dev');

  return (
    <div className={Style.section}>
      <ExpandedRowHeader
        text={t('Admin.StudyList.Details.Caption')}
        rightHeader={
          <AppButton
            onClick={onEdit}
            variant={'icon-link'}
            colorSchema={'primary'}
            text={t('Admin.StudyList.Details.Action')}
            Icon={EditIcon}
          />
        }
      />

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Details.Id')}</div>
        <div className={CommonStyles.tableItemRight}>{study.id.toString().padStart(3, '0')}</div>
      </div>
      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Details.CreatedAt')}</div>
        <div className={CommonStyles.tableItemRight}>{localFormat(study.createdAt)}</div>
      </div>
      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Details.StudyNumber')}</div>
        <div className={CommonStyles.tableItemRight}>{study.studyNumber}</div>
      </div>
      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Details.Period')}</div>
        <div className={CommonStyles.tableItemRight}>
          {formatDateRange(study.startDate, addDays(study.startDate, study.duration))}
        </div>
      </div>
      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Details.Duration')}</div>
        <div className={CommonStyles.tableItemRight}>{study.duration.toString()}</div>
      </div>
      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Details.Groups')}</div>
        <div className={CommonStyles.tableItemRight}>
          {study.groups?.map((x) => (
            <li key={x.title}>{x.title}</li>
          ))}
        </div>
      </div>
      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Details.Description')}</div>
        <div className={CommonStyles.tableItemRight}>{study.description}</div>
      </div>
    </div>
  );
};
