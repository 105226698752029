﻿import React, { FC, PropsWithChildren, useContext } from 'react';
import { NotificationBatchDto, NotificationType } from '../services/api/api-client';
import { Trans, useTranslation } from 'react-i18next';
import { LocalizedResourceDictionaryKeys } from '../application/localisation/i18next';
import styles from './UserNotificationBatchToastMessage.module.scss';
import { useContextSelector } from 'use-context-selector';
import { IssuesContext } from '../components/issue/provider/issues.context';
import { Permissions, useHasPermissions } from '../helpers/auth/auth-helper';
import { notificationsContext } from './notificationProvider';

export const NotificationBatchToastMessage: FC<{
  batch: NotificationBatchDto;
}> = ({ batch }) => {
  const { t } = useTranslation();
  const openIssueView = useContextSelector(IssuesContext, (x) => x.openIssueView);

  const type = NotificationType[batch.type] as keyof typeof NotificationType;

  return (
    <Trans
      t={t}
      count={batch.sourceNotificationIds.length}
      i18nKey={`Notifications.GroupedMessage.${type}` as LocalizedResourceDictionaryKeys}
      values={batch.payload}
      components={{
        bold: <span className={styles.bold} />,
        IssueLink: <a className={styles.link} onClick={() => openIssueView?.(parseInt(batch.payload.IssueId))} />,
        FormLink: batch.type === NotificationType.FormDeadline ? <FormLink payload={batch.payload} /> : <></>,
      }}
    />
  );
};

const FormLink: FC<
  PropsWithChildren<{
    payload: Record<string, string | null | undefined>;
  }>
> = ({ payload, children }) => {
  const { openForm } = useContext(notificationsContext);
  const hasPermissions = useHasPermissions();

  const formId = parseInt(payload.FormId!);
  const formType = payload.FormType!;
  const patientId = payload.PatientId!;
  const patientUniqueId = payload.PatientUniqueId!;
  const stepName = payload.StepName!;
  const resultId = payload.FormResultId;

  if (!resultId && !hasPermissions(Permissions.EditPatient)) return <>{children}</>;

  return (
    <a className={styles.link} onClick={() => openForm({ formId, patientId, patientUniqueId, formType, stepName })}>
      {children}
    </a>
  );
};
