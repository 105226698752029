import i18n from 'i18next';
import { fileSizeValidation, fileTypeValidation } from './fileValidation-helpers';
import { toDate } from 'date-fns';
import { isValidDate } from './date-helpers';
import { isNullOrEmpty } from './string-helper';
import { AppAttachmentValue } from '../components/uikit/fields/attachments/attachmentsField/appAttachmentsField';
import { ValidationProps } from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';

export const ValidationConstants = {
  textInputMaxLength: 120,
  minPersonMeasureValue: 1,
  maxWeightValue: 999,
  maxHeightValue: 250,
  maxCATValue: 40,
  minPatientId: 1,
  maxPatientId: 999,
  minAnalysisQuantityLimit: 1,
};

const isValidationRulePresent = (validationSettings: ValidationProps, field: keyof ValidationProps) => {
  const validationRules = Object.keys(validationSettings);

  return (
    validationRules.map((x) => x).includes(field) &&
    validationSettings[field] !== null &&
    validationSettings[field] !== undefined
  );
};

export const ValidationCustomForm = (
  validationSettings: ValidationProps,
  value?: string | string[] | null | number | AppAttachmentValue[],
): string[] => {
  const messages: string[] = [];

  if (isValidationRulePresent(validationSettings, 'isRequired')) {
    if (
      (!value || (typeof value === 'string' && isNullOrEmpty(value)) || (Array.isArray(value) && value.length === 0)) &&
      validationSettings.isRequired
    ) {
      messages.push(i18n.t('LocalErrors.FieldRequired'));
    }
  }

  if (isValidationRulePresent(validationSettings, 'minValue')) {
    // @ts-ignore
    if (value && value < validationSettings.minValue) {
      messages.push(i18n.t('LocalErrors.OutOfPossibleValues'));
    }
  }

  if (isValidationRulePresent(validationSettings, 'maxValue')) {
    // @ts-ignore
    if (value && value > validationSettings.maxValue) {
      messages.push(i18n.t('LocalErrors.OutOfPossibleValues'));
    }
  }

  if (isValidationRulePresent(validationSettings, 'maxLength')) {
    // @ts-ignore
    if (value && typeof value === 'string' && value.length > validationSettings.maxLength) {
      messages.push(i18n.t('LocalErrors.LengthIsExceeded'));
    }
  }

  if (isValidationRulePresent(validationSettings, 'pattern')) {
    if (value && validationSettings.pattern) {
      const regexValue = new RegExp(validationSettings.pattern);
      const checkRegexRule = (val: string) => {
        if (!regexValue.test(val)) {
          messages.push(i18n.t('LocalErrors.InvalidPattern'));
        }
      };

      if (Array.isArray(value)) {
        value.forEach((x) => checkRegexRule(x.toString()));
      } else {
        checkRegexRule(value.toString());
      }
    }
  }

  if (isValidationRulePresent(validationSettings, 'minDate')) {
    if (value && typeof value === 'string') {
      const valueAsDate = new Date(Date.parse(value));
      const validationValueAsDate = new Date(Date.parse(validationSettings.minDate!));
      if (isValidDate(validationValueAsDate) && isValidDate(valueAsDate)) {
        if (toDate(valueAsDate) < toDate(validationValueAsDate)) {
          // @ts-ignore
          messages.push(i18n.t('LocalErrors.DateIsTooSmall').toString());
        }
      }
    }
  }

  if (isValidationRulePresent(validationSettings, 'maxDate')) {
    if (value && typeof value === 'string') {
      const valueAsDate = new Date(Date.parse(value));
      const validationValueAsDate = new Date(Date.parse(validationSettings.maxDate!));
      if (isValidDate(validationValueAsDate) && isValidDate(valueAsDate)) {
        if (toDate(valueAsDate) > toDate(validationValueAsDate)) {
          // @ts-ignore
          messages.push(i18n.t('LocalErrors.DateIsTooBig').toString());
        }
      }
    }
  }

  if (Array.isArray(value) && typeof value[0] !== 'string' && value.some((x) => !!(x as AppAttachmentValue)?.file)) {
    const fileValidationResult = ValidationFormRules().filesValidate(value as AppAttachmentValue[]);
    if (fileValidationResult) {
      messages.push(fileValidationResult);
    }
  }

  return messages;
};

export const ValidationDateRange = (startDate: string, endDate: string): string => {
  if (startDate && typeof startDate === 'string' && endDate && typeof endDate === 'string') {
    const start = new Date(Date.parse(startDate));
    const end = new Date(Date.parse(endDate));

    if (isValidDate(start) && isValidDate(end) && toDate(start) > toDate(end)) {
      return i18n.t('LocalErrors.WrongDateRange');
    }
  }

  return '';
};

export const ValidationFormRules = () => {
  return {
    requiredRule: {
      required: {
        value: true,
        message: i18n.t('LocalErrors.FieldRequired'),
      },
    },
    maxLengthRule: {
      maxLength: {
        value: ValidationConstants.textInputMaxLength,
        message: i18n.t('LocalErrors.LengthIsExceeded'),
      },
    },
    deviceSerialNumberRule: {
      pattern: {
        value: /^[0-9\b]+$/,
        message: i18n.t('LocalErrors.DeviceFormat'),
      },
    },
    patientIdRule: {
      pattern: {
        value: /^[0-9\b]+$/,
        message: i18n.t('LocalErrors.PatientIdFormat'),
      },
      min: {
        value: ValidationConstants.minPatientId,
        message: i18n.t('ServerErrors.wrong_patient_uniq_id'),
      },
      max: {
        value: ValidationConstants.maxPatientId,
        message: i18n.t('ServerErrors.wrong_patient_uniq_id'),
      },
    },
    digitsOnlyRule: {
      pattern: {
        value: /^[0-9\b]+$/,
        message: i18n.t('LocalErrors.Digits'),
      },
    },
    bodyMeasurementRule: {
      pattern: {
        value: /^(?=[1-9])\d*\.?\d+$/gm,
        message: i18n.t('LocalErrors.Digits'),
      },
      min: {
        value: ValidationConstants.minPersonMeasureValue,
        message: i18n.t('LocalErrors.OutOfPossibleValues'),
      },
    },
    maxHeightRule: {
      max: {
        value: ValidationConstants.maxHeightValue,
        message: i18n.t('LocalErrors.OutOfPossibleValues'),
      },
    },
    maxWeightRule: {
      max: {
        value: ValidationConstants.maxWeightValue,
        message: i18n.t('LocalErrors.OutOfPossibleValues'),
      },
    },
    maxCATRule: {
      max: {
        value: ValidationConstants.maxCATValue,
        message: i18n.t('LocalErrors.OutOfPossibleValues'),
      },
    },
    minAnalysisQuantityRule: {
      min: {
        value: ValidationConstants.minAnalysisQuantityLimit,
        message: i18n.t('LocalErrors.OutOfPossibleValues'),
      },
    },
    filesValidate: (value: AppAttachmentValue[] | null) => {
      const files = value?.filter((f) => f.file != null && f.file.name != null).map((v) => v.file);
      if (!files) {
        return undefined;
      }

      const errors = [...fileSizeValidation(files as File[]).errors, ...fileTypeValidation(files as File[]).errors];
      return errors.length > 0 ? errors.join('\n\r') : undefined;
    },
    multipleDropdownFieldLengthValidate: (values: string[] | null) => {
      if (!values) {
        return undefined;
      }
      const notValidStr = values.find((x) => {
        return x ? x.length > ValidationConstants.textInputMaxLength : false;
      });
      return notValidStr ? i18n.t('LocalErrors.LengthIsExceeded') : undefined;
    },
    notEmpty: (value: string | undefined) => {
      return value?.trim().length === 0 ? i18n.t('LocalErrors.FieldRequired') : undefined;
    },
    dropDownWithOtherFieldValidate: (val: string | null) => {
      if (!val || val.length < ValidationConstants.textInputMaxLength) {
        return undefined;
      }

      return i18n.t('LocalErrors.LengthIsExceeded');
    },
  };
};
