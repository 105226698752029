import React from 'react';
import { AppRoutes } from './routes';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { Permissions } from '../helpers/auth/auth-helper';
import { PrivateRoute } from './PrivateRoute';
import { StudyList } from '../containers/adminDashboard/StudyList/StudyList.component';
import { NavAdminBar } from '../containers/navigationMenu/navAdminBar.component';
import { Users } from '../containers/adminDashboard/UserList/UserList.component';

export function AdminRouter() {
  return (
    <Route
      path={AppRoutes.Admin}
      element={
        <PrivateRoute permissions={Permissions.AdministerApp}>
          <NavAdminBar />
          <div style={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', overflow: 'auto' }}>
            <Outlet />
          </div>
        </PrivateRoute>
      }
    >
      <Route index element={<Navigate to={AppRoutes.AdminStudies} />} />
      <Route path={AppRoutes.Users} element={<Users />} />
      <Route path={AppRoutes.AdminStudies} element={<StudyList />} />
    </Route>
  );
}
