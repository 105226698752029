import clsx from 'clsx';
import React, { useMemo, FunctionComponent, SVGProps } from 'react';
import Style from './button.module.scss';
import LoadingAnimation from 'src/assets/animations/save.json';
import Lottie, { LottieProps } from 'react-lottie-player';

export type ButtonColorSchema = 'primary' | 'secondary' | 'destroy';
export type LinkButtonColorSchema =
  | 'primary'
  | 'secondary'
  | 'basic'
  | 'confirm'
  | 'decline'
  | 'destroy'
  | 'transparent'
  | 'black';

export type AppButtonProps = Pick<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'onClick' | 'disabled' | 'form' | 'type' | 'className' | 'id'
> & {
  testId?: string;
  isLoading?: boolean;
  hasLoaded?: boolean;
  textClassName?: string;
  loaderClassName?: string;
  readOnly?: boolean;
} & (
    | {
        variant?: 'button';
        colorSchema: ButtonColorSchema;
        text: string;
        showTextOnHover?: never;
        Icon?: never;
        iconClassName?: never;
        verticalAlign?: never;
      }
    | {
        variant: 'icon-link';
        colorSchema: LinkButtonColorSchema;
        text?: string;
        showTextOnHover?: boolean;
        Icon?: FunctionComponent<SVGProps<any>>;
        iconClassName?: string;
        verticalAlign?: boolean;
      }
  );

export const AppButton = React.forwardRef<HTMLButtonElement, AppButtonProps>(function AppButton(props, ref) {
  const {
    Icon,
    testId,
    variant = 'button',
    isLoading,
    hasLoaded,
    text,
    textClassName,
    iconClassName,
    className,
    colorSchema,
    verticalAlign,
    type = 'button',
    onClick,
    readOnly,
    showTextOnHover,
    loaderClassName,
    ...rest
  } = props;

  const Loader = useMemo(() => {
    let lottieProps: LottieProps = {};
    if (!hasLoaded && isLoading) {
      lottieProps = { loop: true, segments: [1, 114], goTo: 25 };
    }

    if (hasLoaded) {
      lottieProps = { loop: false, segments: [114, 190] };
    }

    return (
      <Lottie
        animationData={LoadingAnimation}
        style={{ width: 20, height: 20, flexShrink: 0 }}
        play={true}
        {...lottieProps}
      />
    );
  }, [hasLoaded, isLoading]);

  const commonStyles = useMemo(() => {
    return clsx(
      {
        [Style.button]: variant === 'button',
        [Style.link]: variant === 'icon-link',

        [Style.primary]: colorSchema === 'primary',
        [Style.secondary]: colorSchema === 'secondary',
        [Style.destroy]: colorSchema === 'destroy',
        [Style.basic]: colorSchema === 'basic',
        [Style.confirm]: colorSchema === 'confirm',
        [Style.decline]: colorSchema === 'decline',
        [Style.black]: colorSchema === 'black',
        [Style.transparent]: colorSchema === 'transparent',
      },
      className,
    );
  }, [className, colorSchema, variant]);

  const icon = useMemo(() => {
    if (isLoading || hasLoaded) return <div className={clsx(Style.loader, loaderClassName)}>{Loader}</div>;

    if (Icon) {
      return <Icon className={iconClassName} />;
    }

    return undefined;
  }, [Icon, Loader, hasLoaded, iconClassName, isLoading, loaderClassName]);

  return (
    <button
      {...rest}
      ref={ref}
      type={type}
      className={commonStyles}
      data-test-id={testId}
      aria-readonly={readOnly}
      onClick={
        onClick &&
        ((e) => {
          if (readOnly) {
            e.preventDefault();
            return;
          }

          e.stopPropagation();
          onClick?.(e);
        })
      }
    >
      <div
        className={clsx(Style.textWrapper, {
          [Style.verticalAlign]: verticalAlign,
        })}
      >
        {icon && <div className={Style.iconWrapper}>{icon}</div>}
        {text && <span className={clsx({ [Style.showOnHover]: showTextOnHover }, textClassName)}>{text}</span>}
      </div>
    </button>
  );
});
