﻿import clsx from 'clsx';
import React, { FC, useContext, useMemo } from 'react';
import { RouteProgressStateEnum } from 'src/services/api/api-client';
import { TypographyStyles } from 'src/styles';
import { ExpandedRowHeader } from '../header/expandedRowHeader.component';
import { RecordRow } from './recordRow.component';
import Style from './recordRow.module.scss';
import { PatientCardContext } from '../PatientCardContext';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { IssueMark } from '../../issue/issueTarget/issueMark.component';
import { groupBy } from 'src/helpers/arrayHelpers';
import { FormResultVm, RecordRowVm } from './recordRow';
import { MultiInstanceFormRows } from './MultiInstanceFormRows';

export const RecordsGroupedByType: FC = () => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');

  const {
    patient: { uniqueId },
  } = useContext(PatientCardContext);

  return (
    <div data-test-id={'patient-card-records'}>
      <ExpandedRowHeader
        text={
          <IssueMark
            issueContext={{
              subject: 'Patient',
              linkedPatientUniqId: uniqueId,
              topic: 'Records',
            }}
            countDescendants={false}
            containerClassName={Style.issueMarkContainer}
          >
            {t('Caption')}
          </IssueMark>
        }
      />

      <div className={Style.recordsBlock}>
        <div>
          <MandatoryFormRows />
        </div>
        <div>
          <MultiInstanceFormRows />
        </div>
      </div>
    </div>
  );
};

const MandatoryFormRows: FC = () => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');

  const {
    patient: { uniqueId, studyRouteProgress },
  } = useContext(PatientCardContext);

  const rowVms = useMemo<RecordRowVm[]>(() => {
    return studyRouteProgress.steps
      .flatMap(
        (step) =>
          step.forms?.map((form) => ({
            formConfigId: form.formConfigId,
            formType: form.formType,
            formResultId: form.formResultId,
            step,
          })) ?? [],
      )
      .reduce(
        groupBy(
          (x) => x.formConfigId,
          (x) => x,
        ),
        [],
      )
      .map<RecordRowVm>((tuple) => {
        return {
          formConfigId: tuple.key,
          formType: tuple.values[0].formType,
          fillingProgress: undefined,
          state: undefined,
          formResults: tuple.values
            .map<FormResultVm>((y) => ({
              stepName: y.step.name,
              stepIsActive:
                y.step.state === RouteProgressStateEnum.InProgress ||
                y.step.state === RouteProgressStateEnum.InProgressManually,
              formResultId: y.formResultId ?? undefined,
              isMultiInstance: false,
            }))
            .filter((result) => result.formResultId || result.stepIsActive),
        };
      })
      .filter((x) => x.formResults.length > 0);
  }, [studyRouteProgress]);

  if (rowVms.length === 0) return null;

  return (
    <>
      <div className={clsx(TypographyStyles.plainText14, Style.header)}>{t('ObligatoryForms')}</div>
      {rowVms.map((row) => (
        <RecordRow key={row.formConfigId} type={'groupedByType'} vm={row} />
      ))}
    </>
  );
};
