import React from 'react';
import { RPDF } from '../../react-pdf';

export const NoScoreIcon: React.FC = () => {
  return (
    <RPDF.Svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.G>
        <RPDF.Path
          fillRule="evenodd"
          d="M1 0.5C1 0.223858 1.22386 0 1.5 0H3V1H2V2H1V0.5ZM4 0H5.5C5.63261 0 5.75979 0.0526784 5.85355 0.146447L6.85355 1.14645L6.14645 1.85355L5.29289 1H4V0ZM4 4.5V2H5V4H7V5H4.5C4.22386 5 4 4.77614 4 4.5ZM7.85355 2.14645L8.85355 3.14645C8.94732 3.24021 9 3.36739 9 3.5V5H8V3.70711L7.14645 2.85355L7.85355 2.14645ZM1 4V3H2V4H1ZM1 7V5H2V7H1ZM9 6V7H8V6H9ZM1 9.5V8H2V9H3V10H1.5C1.22386 10 1 9.77614 1 9.5ZM9 8V9.5C9 9.77614 8.77614 10 8.5 10H7.96085H7V9H7.96085H8V8H9ZM6 9V10H4V9H6Z"
          fill="#7A8FE0"
        />
      </RPDF.G>
      <RPDF.Defs>
        <RPDF.ClipPath id="clip0_12579_129890">
          <RPDF.Rect width="10" height="10" fill="white" x={0} y={0} />
        </RPDF.ClipPath>
      </RPDF.Defs>
    </RPDF.Svg>
  );
};
