import React, { useState } from 'react';
import { useAppSelector } from 'src/application/redux-store/store-types';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import Style from './studyName.module.scss';

const isEllipsisActive = (e: HTMLElement | null) => {
  return e ? e.offsetWidth < e.scrollWidth : false;
};

export const StudyNameComponent = () => {
  const { studyNumber, studyName } = useAppSelector((state) => state.app);
  const [isEllipsis, setIsEllipsis] = useState(false);

  return (
    <div className={Style.container}>
      <Tooltip
        disabled={!isEllipsis}
        text={studyName || ''}
        hostStyles={Style.tooltipHost}
        styles={Style.tooltipCommonContainer}
      >
        <div className={Style.text} ref={(x) => setIsEllipsis(isEllipsisActive(x))}>
          {studyName}
        </div>
      </Tooltip>
      <div className={Style.number}>{studyNumber}</div>
    </div>
  );
};
