export enum AppRoutes {
  Login = '/Login',
  AccessDenied = '/forbidden',
  Impressum = '/impressum',

  Root = '/',
  Studies = '/studies',
  Dashboard = '/studies/:id',
  FAQPage = '/studies/:id/faq',

  StudySettings = '/studies/:id/settings',
  FormBuilder = 'form-builder',
  QuestionSchemeBuilder = 'question-scheme-builder',
  Analyzes = 'analyzes',
  PatientPipelines = 'patient-pipelines',
  Admin = '/admin',
  Users = '/admin/users',
  AdminStudies = '/admin/studies',
}

export const navigateToRoute = {
  dashboard: (studyId: number) => `${AppRoutes.Dashboard.replace(':id', studyId.toString())}`,
  studySettings: (studyId: number) => `${AppRoutes.StudySettings.replace(':id', studyId.toString())}`,
  faqPage: (studyId: number) => `${AppRoutes.FAQPage.replace(':id', studyId.toString())}`,
};
