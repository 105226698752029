import React, { useCallback, useMemo, useState } from 'react';
import { useEditor } from '@craftjs/core';
import { Trans, useTranslation } from 'react-i18next';
import {
  CreateFormConfigDto,
  FormDto,
  ICreateFormConfigDto,
  IPatchFormConfigDto,
  PatchFormConfigDto,
  ValidationProblemDetails,
} from '../../../services/api/api-client';
import { QueryFactory } from '../../../services/api';
import { logger } from '../../../application/logging/logging';
import { useModal } from '../../../application/hooks/useModal';
import { AppButton } from 'uikit/buttons';
import styles from './barStyles.module.css';
import { TypographyStyles } from '../../../styles';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { DialogModal } from '../../dialogModal/dialogModal.component';
import { isNullOrEmpty } from '../../../helpers/string-helper';
import useStateWithDeps from '../../../helpers/hooks/useStateWithDeps';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppTextField } from 'uikit/fields';
import { AppTextInput } from 'uikit/inputs';
import { AppCheckboxInput } from 'uikit/inputs';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { AppInputError } from 'uikit/wrappers';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';
import { UIEditorSaveResetControls } from './saveResetComponent/UIEditorSaveResetControls';
import { StudyNameComponent } from 'src/components/studyName/studyName.component';

export const TopBar = (props: {
  studyId: number;
  formConfigs: FormDto[];
  selectedConfig: FormDto | null;
  onConfigSaved?: (dto: FormDto) => void;
  onConfigDeleted?: () => void;
  onConfigSelect?: (configId: number | null) => void;
}) => {
  const dropDownOptions: DropdownOption<number>[] = useMemo(() => {
    return props.formConfigs.map((x) => ({
      text: x.type,
      key: x.id,
    }));
  }, [props.formConfigs]);

  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const saveAsModal = useModal();
  const deleteModal = useModal();
  const conflictModal = useModal();

  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useStateWithDeps(() => '', [props.selectedConfig]);
  const { studyNumber } = useAppSelector((state) => state.app);
  const { actions, query } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [isPatientCreationForm, setIsPatientCreationForm] = useStateWithDeps(
    () => props.selectedConfig?.isPatientCreationForm ?? false,
    [props.selectedConfig],
  );

  const [formType, setFormType] = useStateWithDeps(() => props.selectedConfig?.type ?? '', [props.selectedConfig]);

  const [isMulti, setMulti] = useStateWithDeps(
    () => props.selectedConfig?.isMultiInstance ?? false,
    [props.selectedConfig],
  );

  const [confirmationInput, setConfirmationInput] = useState<string>();

  const handleConfigSelection = useCallback(
    (value: (typeof dropDownOptions)[0] | undefined) => {
      props.onConfigSelect?.(value?.key ?? null);
    },
    [props],
  );

  const resetUndoHistory = useCallback(() => {
    actions.history.clear();
    setErrorText('');
    props.onConfigSelect?.(null);
  }, [actions.history, props]);

  const saveConfig = useCallback(
    async (config: Record<string, any>, createNewConfig?: boolean) => {
      setIsLoading(true);
      setErrorText('');

      try {
        let formDto = null as FormDto | null;
        if (createNewConfig) {
          if (isNullOrEmpty(formType)) {
            setErrorText(t('StudySettingsPage.FormBuilder.topbar.errors.emptyFormType'));
            return;
          }

          const dto: ICreateFormConfigDto = {
            layoutSchema: config,
            studyId: props.studyId,
            type: formType,
            isPatientCreationForm: isPatientCreationForm,
            isMultiInstance: isMulti,
          };
          formDto = await QueryFactory.FormsQuery.Client.createForm(new CreateFormConfigDto(dto));
        } else if (props.selectedConfig) {
          const dto: IPatchFormConfigDto = {
            layoutSchema: config,
            type: props.selectedConfig?.type.toString(),
            isPatientCreationForm: isPatientCreationForm,
            isMultiInstance: isMulti,
          };
          formDto = await QueryFactory.FormsQuery.Client.patchForm(
            props.selectedConfig.id,
            new PatchFormConfigDto(dto),
          );
        } else {
          return;
        }

        saveAsModal.closeModal();
        actions.history.clear();
        setFormType('');
        props.onConfigSaved?.(formDto);
      } catch (error: any) {
        if (error instanceof ValidationProblemDetails && error.status === 409) {
          conflictModal.openModal();
        } else {
          logger().error(error);
          setErrorText(t('StudySettingsPage.FormBuilder.topbar.errors.cantSave', { detail: error.detail }));
        }
      } finally {
        setIsLoading(false);
      }
    },
    [props, actions.history, formType, isPatientCreationForm, isMulti, saveAsModal, conflictModal],
  );

  const deleteConfig = useCallback(async () => {
    setErrorText('');
    if (!props.selectedConfig) {
      return;
    }

    setIsLoading(true);
    try {
      await QueryFactory.FormsQuery.Client.removeForm(props.selectedConfig.id);
      deleteModal.closeModal();
      props.onConfigDeleted?.();
    } catch (error: any) {
      logger().error(error);
      setErrorText(t('StudySettingsPage.FormBuilder.topbar.errors.cantDelete', { detail: error.detail }));
    } finally {
      setIsLoading(false);
    }
  }, [deleteModal, props]);

  return (
    <>
      <div className={styles.topToolBox}>
        <div className={styles.newRecord}>
          <StudyNameComponent />

          <AppDropDownWithSuggestionInput
            className={styles.templateDropdown}
            placeholder={t('StudySettingsPage.FormBuilder.topbar.chooseTemplate')}
            options={dropDownOptions}
            value={dropDownOptions.find((x) => x.key === props.selectedConfig?.id)}
            onChange={handleConfigSelection}
            suggestion={true}
          />

          <AppCheckboxInput
            label={t('StudySettingsPage.FormBuilder.topbar.multiInstance')}
            checked={isMulti}
            onChange={(e) => setMulti(e.target.checked)}
          />

          <AppCheckboxInput
            label={t('StudySettingsPage.FormBuilder.topbar.patientCreation')}
            checked={isPatientCreationForm}
            onChange={(e) => setIsPatientCreationForm(e.target.checked)}
          />

          <AppInputError className={styles.errorMessage} errors={errorText} hideBorder />
        </div>

        <UIEditorSaveResetControls
          isShowControls={true}
          canSave={!!props.selectedConfig}
          save={saveConfig}
          saveAs={() => saveAsModal.openModal()}
          resetToDefault={resetUndoHistory}
          disabled={isLoading}
          canDelete={!!props.selectedConfig}
          delete={deleteModal.openModal}
        />
      </div>

      <DialogModal
        isVisible={conflictModal.visible}
        onClose={conflictModal.closeModal}
        buttons={[
          <AppButton
            key={0}
            variant={'button'}
            colorSchema={'secondary'}
            text={commonLocalizer('Common_no')}
            type={'reset'}
            onClick={conflictModal.closeModal}
          />,
          <AppButton
            key={1}
            variant={'button'}
            colorSchema={'primary'}
            disabled={isLoading}
            isLoading={isLoading}
            hasLoaded={isLoading}
            text={commonLocalizer('Common_yes')}
            type={'submit'}
            onClick={() => {
              conflictModal.closeModal();
              saveAsModal.openModal();
            }}
          />,
        ]}
        mainText={t('StudySettingsPage.FormBuilder.topbar.ConflictModal.MainText')}
        title={t('StudySettingsPage.FormBuilder.topbar.ConflictModal.Header')}
      />

      <AppModalContainer
        title={t('StudySettingsPage.FormBuilder.topbar.NewFormModal.Title')}
        visible={saveAsModal.visible}
        onHide={saveAsModal.closeModal}
        bodyClassName={styles.modalBody}
        onDismissed={() => setFormType('')}
        isClickOutside={true}
        footer={
          <div className={styles.modalButtonGroup}>
            <AppButton
              key={0}
              variant={'button'}
              colorSchema={'secondary'}
              text={commonLocalizer('Common_Cancel')}
              onClick={saveAsModal.closeModal}
              disabled={isLoading}
            />
            <AppButton
              key={1}
              variant={'button'}
              colorSchema={'primary'}
              text={commonLocalizer('Common_Save')}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => saveConfig(JSON.parse(query.serialize()), true)}
            />
          </div>
        }
      >
        <>
          <AppTextField
            labelProps={{ text: t('StudySettingsPage.FormBuilder.topbar.NewFormModal.Label') }}
            placeholder={t('StudySettingsPage.FormBuilder.topbar.NewFormModal.Placeholder')}
            onChange={(e) => setFormType(e.target.value)}
            value={formType}
            errorProps={{
              errors: errorText,
            }}
          />
        </>
      </AppModalContainer>

      <AppModalContainer
        title={t('StudySettingsPage.FormBuilder.topbar.ConfirmModal.Title')}
        visible={deleteModal.visible}
        onHide={deleteModal.closeModal}
        isClickOutside={true}
        onDismissed={() => setConfirmationInput(undefined)}
        bodyClassName={styles.modalBody}
        footer={
          <div className={styles.modalButtonGroup}>
            <AppButton
              key={0}
              variant={'button'}
              colorSchema={'secondary'}
              text={commonLocalizer('Common_Cancel')}
              onClick={deleteModal.closeModal}
              disabled={isLoading}
            />
            <AppButton
              key={1}
              variant={'button'}
              colorSchema={'destroy'}
              text={commonLocalizer('Common_Delete')}
              isLoading={isLoading}
              disabled={isLoading || confirmationInput !== props.selectedConfig?.type}
              onClick={deleteConfig}
            />
          </div>
        }
      >
        <div className={styles.confirmationMessage}>
          <Trans
            ns={'dev'}
            i18nKey={'StudySettingsPage.FormBuilder.topbar.ConfirmModal.Message'}
            values={{ confirmation: props.selectedConfig?.type.toString() }}
          >
            <strong className={styles.awfulText} />
            <strong />
          </Trans>
        </div>
        <AppTextInput
          value={confirmationInput}
          onChange={(e) => setConfirmationInput(e.target.value)}
          placeholder={t('StudySettingsPage.FormBuilder.topbar.ConfirmModal.Placeholder')}
        />
      </AppModalContainer>
    </>
  );
};
