import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useModal, UseModalProps } from '../../../application/hooks/useModal';
import { logger } from '../../../application/logging/logging';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import { ValidationConstants, ValidationFormRules } from '../../../helpers/validation-helpers';
import { HookFormRadioGroup } from '../../../hookFormControls/hookFormRadioGroup';
import { QueryFactory } from '../../../services/api';
import { AppButton } from 'uikit/buttons';
import Style from './issueForm.module.scss';
import { isNullOrEmpty } from '../../../helpers/string-helper';
import { FileTypes } from '../../../helpers/fileValidation-helpers';
import { AppAttachmentValueToFileParameter } from '../../../helpers/file-helper';
import {
  issueSubjects,
  IssueSubjectsType,
  issueTopicsForPatient,
  IssueTopicsForPatientType,
  issueTopicsForStudy,
  IssueTopicsForStudyType,
} from '../bar/issueFilters-helper';
import {
  CreateIssueConfirmationModal,
  CreateIssueConfirmationModalParams,
} from '../createConfirmationDialog/createIssueConfirmationModal.component copy';
import { FileParameter } from '../../../services/api/api-client';
import { AppAttachmentsField, AppDropdownField, AppRadioGroupField, AppTextField } from 'uikit/fields';
import { RadioGroupOption } from 'uikit/inputs/radio/appRadioGroupInput';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { AppAttachmentValue } from 'uikit/fields/attachments/attachmentsField/appAttachmentsField';
import { AppInputError } from 'uikit/wrappers';
import { HookFormTextInput } from 'src/hookFormControls/hookFormTextInput';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { useGetFormsQuery } from 'src/services/api/api-client/FormsQuery';
import { useGetSurveysQuery } from 'src/services/api/api-client/SurveyQuery';
import { LocalizedResourceDictionaryKeys } from 'src/application/localisation/i18next';
import { convertDateToString } from 'src/helpers/date-helpers';
import { Loading } from 'uikit/suspense/Loading';

export type IssueFormType = {
  studyId: number;
  modal: UseModalProps<Partial<IssueCreationTypeWithId> | undefined>;
};

export type IssueCreationType = {
  subject: IssueSubjectsType;
  topic: IssueTopicsForPatientType | IssueTopicsForStudyType;
  linkedPatientUniqId?: string;
  topicAdditional?: string;
  description: string;
  deadlineAt: Date;
  recommendedAction: string;
  files: AppAttachmentValue[] | null;
  resultId?: number;
  fieldId?: string;
  stepName?: string | 'multiple' | null;
  fieldDescription?: string;
};

export type IssueCreationTypeWithId = IssueCreationType & {
  issueId?: number;
};

type IssueHookFormCreationType = Omit<IssueCreationType, 'deadlineAt'> & {
  deadlineAt: string;
};

export const IssueForm: FC<IssueFormType> = ({ studyId, modal }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [internalError, setInternalError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const confirmationModal = useModal<CreateIssueConfirmationModalParams>();

  const params = useMemo(
    () => ({ ...modal.params, deadlineAt: convertDateToString(modal.params?.deadlineAt) }),
    [modal.params],
  );

  const subjects: RadioGroupOption[] = useMemo(() => {
    return issueSubjects.map((subject, i) => ({
      key: i,
      value: subject,
      caption: t(`Issues.Subject.${subject}`),
    }));
  }, [t]);

  const {
    watch,
    setError,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    unregister,
    setValue,
  } = useForm<IssueHookFormCreationType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: params,
  });

  useLayoutEffect(() => {
    reset(params);
  }, [params, reset]);

  const subject = watch('subject');
  const isPatientSubject = subject === subjects[0].value;

  const topics: RadioGroupOption[] = useMemo(() => {
    return (isPatientSubject ? issueTopicsForPatient : issueTopicsForStudy).map((topic, i) => ({
      key: i,
      value: topic,
      caption: t(`Issues.Topic.${topic}`),
    }));
  }, [isPatientSubject, t]);

  const topic = watch('topic');
  const { data: records, isInitialLoading: isRecordsLoading } = useGetFormsQuery(
    { studyId },
    { enabled: topic === 'Records', suspense: false },
  );
  const { data: surveys, isInitialLoading: isSurveysLoading } = useGetSurveysQuery(
    {},
    { enabled: topic === 'Surveys', suspense: false },
  );

  const topicAdditionalOptions: DropdownOption[] = useMemo(() => {
    if (topic === 'Surveys') {
      return (
        surveys?.map(
          (x) =>
            ({
              key: x.id,
              text: x.typeString,
            } as DropdownOption),
        ) || []
      );
    }

    if (topic === 'Records')
      return (
        records?.map(
          (x) =>
            ({
              key: x.id,
              text: x.type,
            } as DropdownOption),
        ) || []
      );

    return [];
  }, [records, surveys, topic]);

  useEffect(() => {
    if (!isPatientSubject) {
      unregister('linkedPatientUniqId');
      setValue('linkedPatientUniqId', undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  const patchIssue = useCallback(
    async (
      data: IssueHookFormCreationType,
      uploadFileList: FileParameter[] | undefined,
      callback: () => Promise<void>,
    ) => {
      if (!params?.issueId) return;

      try {
        await QueryFactory.IssueQuery.Client.patchIssue(
          params.issueId,
          data.subject,
          data.topic,
          data.topicAdditional,
          data.description,
          data.resultId,
          data.fieldId,
          data.stepName,
          data.fieldDescription,
          new Date(Date.parse(data.deadlineAt)),
          data.linkedPatientUniqId,
          uploadFileList,
          undefined,
          data.recommendedAction,
        );

        await Promise.all([
          queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssueQueryKey(params.issueId)),
          queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssueHistoryQueryKey(params.issueId)),
        ]);

        await callback();
      } catch (ex: any) {
        if (ex.errors && Object.keys(ex.errors).length > 0) {
          for (const errorKey of Object.keys(ex.errors)) {
            const formKey = pascalToCamelCase(errorKey) as keyof IssueCreationType;
            setError(formKey, {
              type: 'server',
              message: ex.errors[errorKey][0],
            });
          }
        } else {
          logger().error(ex);
          setInternalError(true);
        }
      }
    },
    [params, queryClient, setError],
  );

  const createIssue = useCallback(
    async (
      data: IssueHookFormCreationType,
      uploadFileList: FileParameter[] | undefined,
      callback: () => Promise<void>,
    ) => {
      try {
        await QueryFactory.IssueQuery.Client.addIssue(
          studyId,
          data.subject,
          data.topic,
          data.topicAdditional,
          data.description,
          data.resultId,
          data.fieldId,
          data.stepName,
          data.fieldDescription,
          new Date(Date.parse(data.deadlineAt)),
          data.linkedPatientUniqId,
          uploadFileList,
          data.recommendedAction,
        );

        await callback();
      } catch (ex: any) {
        if (ex.errors && Object.keys(ex.errors).length > 0) {
          for (const errorKey of Object.keys(ex.errors)) {
            const formKey = pascalToCamelCase(errorKey) as keyof IssueCreationType;
            setError(formKey, {
              type: 'server',
              message: ex.errors[errorKey][0],
            });
          }
        } else {
          logger().error(ex);
          setInternalError(true);
        }
      }
    },
    [setError, studyId],
  );

  const onSubmit = handleSubmit(async (data: IssueHookFormCreationType) => {
    setInternalError(false);

    const isEditForm = params?.issueId;
    const uploadFileList = data.files?.map(AppAttachmentValueToFileParameter).filter((f) => f.data);
    const callback = async () => {
      await Promise.all([
        queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssuesForStudyQueryKey(studyId)),
        queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssuesGroupedByPatientsQueryKey(studyId)),
      ]);
      modal.closeModal();
    };

    if (isEditForm) {
      setIsSubmitting(true);
      await patchIssue(data, uploadFileList, callback);
      setIsSubmitting(false);
    } else {
      confirmationModal.openModal({
        issue: { ...data, deadlineAt: new Date(Date.parse(data.deadlineAt)) },
        callback: async () => {
          setIsSubmitting(true);
          await createIssue(data, uploadFileList, callback);
          setIsSubmitting(false);
        },
      });
    }
  });

  const filledFields = useMemo(
    () => (
      <div className={Style.predefinedFieldContainer}>
        {params?.subject && (
          <div className={Style.row}>
            <div>{t('Issues.CreateModal.SubjectLabel' as LocalizedResourceDictionaryKeys)}</div>
            <div>
              {t(`Issues.Subject.${params?.subject}` as LocalizedResourceDictionaryKeys)}{' '}
              {params?.linkedPatientUniqId && params?.linkedPatientUniqId}
            </div>
          </div>
        )}
        {params?.topic && (
          <div className={Style.row}>
            <div>{t('Issues.CreateModal.TopicLabel' as LocalizedResourceDictionaryKeys)}</div>
            <div>{t(`Issues.Topic.${params?.topic}` as LocalizedResourceDictionaryKeys)}</div>
          </div>
        )}
        {params?.topicAdditional && (
          <div className={Style.row}>
            <div>{t('Issues.CreateModal.TopicAdditionalLabel' as LocalizedResourceDictionaryKeys)}</div>
            <div>{params?.topicAdditional}</div>
          </div>
        )}
        {params?.fieldDescription && (
          <div className={Style.row}>
            <div>{t('Issues.CreateModal.FieldDescription')}</div>
            <div>{params?.fieldDescription}</div>
          </div>
        )}
      </div>
    ),
    [params, t],
  );

  const content = useMemo(() => {
    return (
      <form id={'issueCreationForm'} onSubmit={onSubmit} autoComplete={'off'}>
        {!params?.issueId && <>{filledFields}</>}
        {(!params?.subject || params.issueId) && (
          <HookFormRadioGroup<RadioGroupOption, IssueHookFormCreationType>
            labelProps={{ text: t('Issues.CreateModal.SubjectLabel') }}
            options={subjects}
            name={'subject'}
            labelField={'caption'}
            valueField={'value'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('LocalErrors.FieldRequired'),
              },
            }}
            disabled={isSubmitting || !!params?.subject}
            errorProps={{ errors: errors?.subject?.message }}
          />
        )}

        {(!params?.linkedPatientUniqId || params.issueId) && isPatientSubject && (
          <Controller
            control={control}
            name={'linkedPatientUniqId'}
            rules={{ ...ValidationFormRules().requiredRule }}
            render={({ field: { onChange, value } }) => (
              <AppTextField
                labelProps={{
                  text: t('Issues.CreateModal.PatientIdLabel'),
                }}
                placeholder={t('Issues.CreateModal.PatientIdPlaceholder')}
                value={value?.toString()}
                onChange={onChange}
                disabled={isSubmitting || !!params?.linkedPatientUniqId}
                errorProps={{
                  errors: errors?.linkedPatientUniqId?.message,
                }}
              />
            )}
          />
        )}

        {!isNullOrEmpty(subject) && (
          <>
            {(!params?.topic || params.issueId) && (
              <Controller
                control={control}
                name={'topic'}
                rules={{ ...ValidationFormRules().requiredRule }}
                render={({ field: { onChange, value } }) => (
                  <AppRadioGroupField<RadioGroupOption, 'value'>
                    valueField={'value'}
                    labelField={'caption'}
                    labelProps={{ text: t('Issues.CreateModal.TopicLabel') }}
                    isVertical={true}
                    options={topics}
                    disabled={isSubmitting || !!params?.topic}
                    errorProps={{ errors: errors?.topic?.message }}
                    value={value}
                    onChange={(x) => {
                      onChange(x.value);
                      setValue('topicAdditional', undefined);
                    }}
                  />
                )}
              />
            )}

            {(topic === 'Records' || topic === 'Surveys') && (!params?.topicAdditional || params.issueId) && (
              <Loading loading={isSurveysLoading || isRecordsLoading} loaderClassName={Style.fieldLoader}>
                {!!topicAdditionalOptions.length && (
                  <Controller
                    control={control}
                    name={'topicAdditional'}
                    rules={{ ...ValidationFormRules().requiredRule }}
                    render={({ field: { onChange, value } }) => (
                      <AppDropdownField<DropdownOption>
                        isMultiple={true}
                        labelProps={{ text: t('Issues.CreateModal.TopicAdditionalLabel') }}
                        placeholder={t('Issues.CreateModal.TopicAdditionalDropdownPlaceholder')}
                        isRequired={true}
                        options={topicAdditionalOptions}
                        disabled={isSubmitting || !!params?.topicAdditional}
                        errorProps={{ errors: errors?.topicAdditional?.message }}
                        value={topicAdditionalOptions.filter((x) => value?.includes(x.text))}
                        onChange={(x) => {
                          onChange(x?.map((o) => o.text).join(', '));
                        }}
                      />
                    )}
                  />
                )}
              </Loading>
            )}

            {topic === 'Other' && (
              <HookFormTextInput
                labelProps={{ text: t('Issues.CreateModal.TopicAdditionalLabel') }}
                placeholder={t('Issues.CreateModal.TopicAdditionalTextInputPlaceholder')}
                control={control}
                name={'topicAdditional'}
                rules={{
                  ...ValidationFormRules().maxLengthRule,
                  ...ValidationFormRules().requiredRule,
                }}
                disabled={isSubmitting || !!params?.topicAdditional}
              />
            )}

            <Controller
              control={control}
              name={'description'}
              rules={{
                ...ValidationFormRules().maxLengthRule,
                ...ValidationFormRules().requiredRule,
              }}
              render={({ field: { onChange, value } }) => (
                <AppTextField
                  type={'text-area'}
                  maxLength={ValidationConstants.textInputMaxLength}
                  labelProps={{ text: t('Issues.CreateModal.DescriptionLabel') }}
                  placeholder={t('Issues.CreateModal.DescriptionPlaceholder')}
                  value={value}
                  onChange={onChange}
                  disabled={isSubmitting || !!params?.description}
                  errorProps={{ errors: errors?.description?.message }}
                />
              )}
            />

            <Controller
              control={control}
              name={'recommendedAction'}
              rules={{
                ...ValidationFormRules().requiredRule,
                maxLength: {
                  value: 250,
                  message: t('LocalErrors.LengthIsExceeded'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <AppTextField
                  type={'text-area'}
                  maxLength={250}
                  labelProps={{ text: t('Issues.CreateModal.ActionLabel') }}
                  placeholder={t('Issues.CreateModal.ActionPlaceholder')}
                  value={value}
                  onChange={onChange}
                  disabled={isSubmitting || !!params?.recommendedAction}
                  errorProps={{ errors: errors?.recommendedAction?.message }}
                />
              )}
            />

            <HookFormTextInput
              type={'date'}
              control={control}
              name={'deadlineAt'}
              labelProps={{ text: t('Issues.CreateModal.DeadlineLabel') }}
              rules={{ ...ValidationFormRules().requiredRule }}
              disabled={isSubmitting}
            />

            <Controller
              control={control}
              name={'files'}
              rules={{
                validate: ValidationFormRules().filesValidate,
              }}
              render={({ field: { onChange, value } }) => (
                <AppAttachmentsField
                  label={t('Forms.Controls.Attachments.FieldLabel')}
                  onChange={onChange}
                  value={value}
                  disabled={isSubmitting || !!params?.files}
                  fileTypes={FileTypes}
                  multiple={true}
                  error={!!(errors.files as any)?.message}
                  errorText={(errors.files as any)?.message}
                />
              )}
            />
          </>
        )}
      </form>
    );
  }, [
    onSubmit,
    filledFields,
    t,
    subjects,
    control,
    isSubmitting,
    params,
    errors,
    isPatientSubject,
    subject,
    topic,
    isRecordsLoading,
    isSurveysLoading,
    topicAdditionalOptions,
    topics,
    setValue,
  ]);

  const buttons = useMemo(() => {
    return (
      <div className={Style.footer}>
        <AppInputError errors={internalError ? t('Issues.GeneralError') : undefined} hideBorder position={'top'}>
          <div className={Style.buttonGroup}>
            <AppButton
              text={t('Common_Cancel')}
              variant={'button'}
              colorSchema={'secondary'}
              onClick={modal.closeModal}
              disabled={isSubmitting}
            />
            <AppButton
              text={t('Common_Finish')}
              variant={'button'}
              colorSchema={'primary'}
              type={'submit'}
              disabled={isSubmitting}
              isLoading={isSubmitting}
              form={'issueCreationForm'}
            />
          </div>
        </AppInputError>
      </div>
    );
  }, [internalError, t, modal.closeModal, isSubmitting]);

  return (
    <>
      <AppModalContainer
        bodyClassName={Style.modalBody}
        title={
          params?.issueId
            ? t('Issues.CreateModal.EditTitle', { issueId: params.issueId })
            : t('Issues.CreateModal.CreateTitle')
        }
        visible={modal.visible}
        onHide={modal.closeModal}
        footer={buttons}
        onDismissed={reset}
        isDisabled={isSubmitting}
        testId={'add-issue-modal'}
      >
        {content}
      </AppModalContainer>

      <CreateIssueConfirmationModal modal={confirmationModal} />
    </>
  );
};
