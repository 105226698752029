import React, { useCallback, useContext, useMemo, FC } from 'react';
import Style from './notes.module.scss';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../assets/img/common/edit_20.svg';
import { FormDto, FormResultDto } from '../../../services/api/api-client';
import { noteBodyFormControlsResolver } from './formControls/body/noteBodyFormControlsResolver';
import { Editor, Element, Frame } from '@craftjs/core';
import { NoteContext, NoteContextType } from './NoteContext';
import { noteHeaderFormControlsResolver } from './formControls/header/noteHeaderFormControlsResolver';
import clsx from 'clsx';
import { EditReasonFormHeader } from '../../../features/forms/overview/EditReasonFormHeader';
import { useFormEditing } from 'src/features/forms/editing/useFormEditing';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const Note: FC<{
  formDto: FormDto;
  formResult: FormResultDto;
  testId?: string;
  isOverview?: boolean;
  noteDateDataKey: string;
  patientUniqueId: string;
  canEdit: boolean;
}> = (props) => {
  const noteContextValue = useMemo<NoteContextType>(
    () => ({
      formResult: props.formResult,
      noteDateDataKey: props.noteDateDataKey,
      mode: props.isOverview ? 'overview' : 'section',
      patientUniqueId: props.patientUniqueId,
    }),
    [props.formResult, props.isOverview, props.noteDateDataKey, props.patientUniqueId],
  );

  const formEditing = useFormEditing();
  const onEditNote = useCallback(() => {
    formEditing.startFormEditing({ formResult: props.formResult });
  }, [formEditing, props.formResult]);

  return (
    <NoteContext.Provider value={noteContextValue}>
      <>
        <div className={clsx(Style.note, { [Style.noteOverview]: props.isOverview })} data-test-id={props.testId}>
          <NoteHeader
            form={props.formDto}
            formResult={props.formResult}
            onEdit={props.canEdit ? onEditNote : undefined}
          />
          <NoteBody form={props.formDto} />
        </div>
        {formEditing.element}
      </>
    </NoteContext.Provider>
  );
};

const NoteHeader: FC<{ form: FormDto; formResult: FormResultDto; onEdit?: () => void }> = (props) => {
  const { t } = useTranslation();
  const noteContext = useContext(NoteContext);
  const isOverview = noteContext.mode === 'overview';

  return (
    <div data-test-id={'note-header'} className={Style.noteHeader}>
      <div className={Style.noteHeaderLeftPart}>
        <IssueMark
          issueContext={{
            subject: 'Patient',
            linkedPatientUniqId: noteContext.patientUniqueId,
            topic: 'Notes',
            resultId: props.formResult.id || undefined,
          }}
          countDescendants={false}
        >
          <div className={Style.statusIconsContainer}>
            <Editor resolver={noteHeaderFormControlsResolver}>
              <Frame data={props.form.layoutSchema}>
                <Element canvas={true} />
              </Frame>
            </Editor>
          </div>
        </IssueMark>
        <EditReasonFormHeader
          updatedAt={props.formResult?.updatedAt}
          editReason={isOverview ? props.formResult?.editReason : undefined}
          updateReasonText={isOverview ? props.formResult?.updateReasonText : undefined}
          editorName={isOverview ? props.formResult?.doctor?.fullName : undefined}
          testId={'note-update-date'}
        />
      </div>
      {props.onEdit && (
        <AppButton
          onClick={props.onEdit}
          variant={'icon-link'}
          colorSchema={'secondary'}
          text={t('Common_Edit')}
          Icon={EditIcon}
        />
      )}
    </div>
  );
};

const NoteBody: FC<{ form: FormDto }> = (props) => {
  const noteContext = useContext(NoteContext);
  const isOverview = noteContext.mode === 'overview';

  return (
    <Editor resolver={noteBodyFormControlsResolver}>
      <div data-test-id={'note-body'} className={clsx(Style.noteBody, { [Style.noteOverview]: isOverview })}>
        <Frame data={props.form.layoutSchema}>
          <Element canvas={true} />
        </Frame>
      </div>
    </Editor>
  );
};
