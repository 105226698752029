import React, { useMemo, useState, FC } from 'react';
import { QueryFactory } from '../../services/api';
import { TypographyStyles } from '../../styles';
import Style from './analyzes.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../application/redux-store/store-types';
import { usePresetColumns } from './Analyzes.columns';
import { PresetInfoModal } from './presetInfoModal/PresetInfoModal.component';
import clsx from 'clsx';
import { useAnalyzesPresetEditing } from '../../components/analyzes/preset/useAnalyzesPresetEditing';
import { useContextSelector } from 'use-context-selector';
import { AccessDbContext } from '../../providers/accessDbProvider/accessDb.context';
import { DropDownMenu } from '../../components/uikit/dropDownMenu/dropDownMenu.component';
import { Tooltip } from '../../components/uikit/tooltip/tooltip.component';

import { ReactComponent as Import } from '../../assets/img/common/import_20.svg';
import { ReactComponent as Settings } from '../../assets/img/common/settings_24.svg';
import { ReactComponent as AnalyzesIcon } from '../../assets/img/common/analysis_40.svg';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import TablePageLayout from '../../components/tablePageLayout/TablePageLayout';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { AppTable } from 'uikit/table/AppTable';
import { StudyNameComponent } from 'src/components/studyName/studyName.component';

export interface IAnalyzesPresetRowVm {
  id: number;
  name: string;
  databaseFilename: string;
  tableName: string;
  keyColumn: string;
  columnsWithData: string[];
  analysisQuantityLimit?: number | null;
  onButtonClick: () => void;
}
export const Analyzes: FC = () => {
  const { t } = useScopedTranslation('StudySettingsPage.AnalyzesPreset', 'dev');
  const studyId = useSelector((state: RootState) => state.app.studyId!);
  const [presetIdOpenedForView, setPresetIdOpenedForView] = useState<number | null>(null);
  const [presetIdOpenedForEdit, setPresetIdOpenedForEdit] = useState<number | null>(null);

  const presets = QueryFactory.AnalyzesQuery.useGetAllAnalysisPresetsForStudyQuery(studyId!, {
    suspense: true,
  });

  const analyzesPresetEditing = useAnalyzesPresetEditing({
    studyId,
    preset: presets.data?.find((x) => x.id === presetIdOpenedForEdit) ?? null,
    onSaved: (presetId) => {
      setPresetIdOpenedForEdit(null);
      setPresetIdOpenedForView(presetId);
    },
    onHide: () => {
      setPresetIdOpenedForEdit(null);
      setPresetIdOpenedForView(presetIdOpenedForEdit);
    },
  });

  const accessDbFileName = useContextSelector(AccessDbContext, (x) => x.fileName);
  const importAction = useContextSelector(AccessDbContext, (x) => x.importAction)!;

  const settingMenuOptions = useMemo(() => {
    return [
      {
        key: 0,
        icon: <Import />,
        text: t('Settings.ImportDb'),
        action: importAction,
      },
    ];
  }, [importAction, t]);

  const rowVms = useMemo<IAnalyzesPresetRowVm[]>(() => {
    return (
      presets.data?.map((x) => ({
        id: x.id,
        name: x.name,
        databaseFilename: x.databaseFilename,
        tableName: x.scheme![0].tableName,
        keyColumn: Object.keys(x.scheme![0].keyColumnsWithValue!)[0],
        columnsWithData: x.scheme![0].dataColumns!,
        analysisQuantityLimit: x.analysisQuantityLimit,
        onButtonClick: () => setPresetIdOpenedForView(x.id),
      })) ?? []
    );
  }, [presets.data]);

  const dataTable = useReactTable({
    data: rowVms,
    columns: usePresetColumns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TablePageLayout>
      <TablePageLayout.Header>
        <TablePageLayout.Header.Row>
          <StudyNameComponent />

          <div className={Style.dbSettingsMenu}>
            <DropDownMenu Icon={Settings} options={settingMenuOptions} colorSchema={'primary'} />
            {accessDbFileName && (
              <Tooltip text={accessDbFileName ?? ''} hostStyles={Style.dbConnectedTooltip}>
                <div className={clsx(TypographyStyles.plainText12, Style.dbConnected)}>
                  {t('Settings.DbIsConnected')}
                </div>
              </Tooltip>
            )}
          </div>
        </TablePageLayout.Header.Row>
      </TablePageLayout.Header>
      <TablePageLayout.TableContainer>
        <AppTable table={dataTable} />
        <TablePageLayout.TableContainer.FloatingButton
          Icon={AnalyzesIcon}
          tooltip={analyzesPresetEditing.databaseConnected ? undefined : t('NoDbConnection')}
          onClick={() => analyzesPresetEditing.openModal()}
          disabled={!analyzesPresetEditing.databaseConnected}
        />
      </TablePageLayout.TableContainer>
      <PresetInfoModal
        preset={presets.data?.find((x) => x.id === presetIdOpenedForView) ?? null}
        onEdit={() => {
          setPresetIdOpenedForEdit(presetIdOpenedForView);
          setPresetIdOpenedForView(null);
          analyzesPresetEditing.openModal();
        }}
        onHide={() => setPresetIdOpenedForView(null)}
      />
      {analyzesPresetEditing.modal}
    </TablePageLayout>
  );
};
