/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useModal } from '../../../application/hooks/useModal';
import { logger } from '../../../application/logging/logging';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { generateIssueReport } from '../../../features/report';
import { issueReportFileNameTemplate } from '../../../features/report/issues/issueReport.constants';
import { triggerDownloadFileDialog } from '../../../features/report/report-helpers';
import { QueryFactory } from '../../../services/api';
import {
  PerformIssueOperationDto,
  IssueOperationStateEnum,
  IIssueOperationDto,
  StudyStateFilterEnum,
} from '../../../services/api/api-client';
import { showErrorToast } from '../../toast/toast-helper';
import { IssueQueryParamsType } from '../bar/issueFilters-helper';
import { IssueDeleteDialog, IssueDeleteFormParams } from '../deleteDialog/issueDeleteDialog.component';
import { IssueForm, IssueCreationTypeWithId } from '../form/issueForm.component';
import {
  GenerateReportForm,
  GenerateReportFormParams,
  UseFormGenerateReportType,
} from '../generateReportForm/generateReportForm.component';
import { IssueRejectForm, IssueRejectFormParams, UseFormRejectIssueType } from '../rejectForm/rejectForm.component';
import {
  IssueResolveForm,
  IssueResolveFormParams,
  UseFormResolveIssueType,
} from '../resolveForm/resolveIssueForm.component';
import { IssueRevokeDialog, IssueRevokeDialogParams } from '../revokeDialog/issueRevokeDialog.component';
import { IssueTable } from '../table/issueTable.component';
import { IssueViewModal } from '../view/issueView.component';
import { IssuesContext, IssuesContextType } from './issues.context';
import { useGetIssuesForStudyQuery } from 'src/services/api/api-client/IssueQuery';
import { patientFilterParams } from 'src/components/patientTable/patientFilters.helpers';
import { useQueryParams, NumberParam } from 'use-query-params';
import { getIssue } from 'src/services/api/api-client/IssueClient';
import { useFormEditingAndOverview } from 'src/features/forms/useFormEditingAndOverview';
import { useNotesOverview } from 'src/components/expandedRow/notesSection/overview/useNotesOverview';
import { getFormResultById } from 'src/services/api/api-client/FormsClient';
import { getPatients } from 'src/services/api/api-client/PatientClient';
import { useLocalStorage } from 'src/helpers/useLocalStorage';
import { useHasPermissions, Permissions } from 'src/helpers/auth/auth-helper';

export const IssuesProvider: FC<PropsWithChildren<IssuesContextType>> = ({ children }) => {
  const queryClient = useQueryClient();
  const { studyId, groups, studyNumber, hasMonitoring } = useAppSelector((state) => state.app);
  const [loadedPatientsUid, setLoadedPatientsUid] = useState<string[]>();
  const hasPermissions = useHasPermissions();
  const [isIssuesVisible, setIssuesVisible] = useLocalStorage<boolean>(
    'isIssuesVisible',
    !hasPermissions(Permissions.EditPatient),
  );

  const { data: patientIssues } = useGetIssuesForStudyQuery(
    {
      studyId: studyId!,
      subjectList: ['Patient'],
      patientUniqIds: loadedPatientsUid,
      limit: 9999,
    },
    {
      enabled: hasMonitoring && hasPermissions(Permissions.GetIssues),
      suspense: false,
    },
  );

  const { data: studyIssues } = useGetIssuesForStudyQuery(
    {
      studyId: studyId!,
      subjectList: ['Study'],
      limit: 9999,
    },
    {
      enabled: hasMonitoring && hasPermissions(Permissions.GetIssues),
      suspense: false,
    },
  );

  const issues = useMemo(
    () => [...(patientIssues?.data || []), ...(studyIssues?.data || [])],
    [patientIssues?.data, studyIssues?.data],
  );

  const [, setQueryParams] = useQueryParams({
    ...patientFilterParams(),
    issueId: NumberParam,
  });

  const issueTableModal = useModal();
  const createIssueModal = useModal<Partial<IssueCreationTypeWithId> | undefined>();
  const deleteModal = useModal<IssueDeleteFormParams>();
  const resolveIssueModal = useModal<IssueResolveFormParams>();
  const rejectIssueModal = useModal<IssueRejectFormParams>();
  const issueRevokeDialogModal = useModal<IssueRevokeDialogParams>();
  const issueViewModal = useModal<number>();
  const generateReportModal = useModal<GenerateReportFormParams>();

  const formEditingAndOverview = useFormEditingAndOverview();
  const notesOverview = useNotesOverview();

  const [processingIssueId, setProcessingIssueId] = useState<number | undefined>();

  const invalidateQueries = useCallback(
    async (issueId: number) => {
      await Promise.all([
        queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssueQueryKey(issueId)),
        queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssueHistoryQueryKey(issueId)),
        queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssuesForStudyQueryKey(studyId!)),
        queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssuesGroupedByPatientsQueryKey(studyId!)),
        queryClient.invalidateQueries(QueryFactory.NotificationQuery.getNotificationCountsQueryKey(studyId!)),
        queryClient.invalidateQueries(
          QueryFactory.NotificationQuery.getNotificationBatchesQueryKey(undefined, [studyId!]),
        ),
      ]);
    },
    [queryClient, studyId],
  );

  const resumeOperationHandler = useCallback(
    async (issueOperation: IIssueOperationDto, newState: IssueOperationStateEnum, data?: UseFormRejectIssueType) => {
      setProcessingIssueId(issueOperation.issueId);
      try {
        await QueryFactory.IssueQuery.Client.resumeIssueOperation(issueOperation.id, newState, data?.comment);
        await invalidateQueries(issueOperation.issueId);
      } catch (err: any) {
        logger().error(err);
        showErrorToast(err);
      } finally {
        setProcessingIssueId(undefined);
      }
    },
    [invalidateQueries],
  );

  const deleteIssue = useCallback(
    (issueId: number) => {
      const callback = async () => {
        try {
          await QueryFactory.IssueQuery.Client.removeIssue(issueId);
          await Promise.all([
            queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssuesForStudyQueryKey(studyId!)),
            queryClient.invalidateQueries(QueryFactory.NotificationQuery.getNotificationsQueryKey(undefined, studyId!)),
            queryClient.invalidateQueries(QueryFactory.NotificationQuery.getNotificationCountsQueryKey(studyId!)),
            queryClient.invalidateQueries(
              QueryFactory.NotificationQuery.getNotificationBatchesQueryKey(undefined, [studyId!]),
            ),
          ]);

          deleteModal.closeModal();
          issueViewModal.closeModal();
        } catch (err: any) {
          logger().error(err);
          showErrorToast(err);
        }
      };

      deleteModal.openModal({ issueId, callback });
    },
    [deleteModal, issueViewModal, queryClient, studyId],
  );

  const performOperation = useCallback(
    (issueId: number) => {
      const callback = async (data: UseFormResolveIssueType) => {
        setProcessingIssueId(issueId);
        try {
          await QueryFactory.IssueQuery.Client.performIssueOperation(
            issueId,
            new PerformIssueOperationDto({ ...data }),
          );
          await invalidateQueries(issueId);
        } catch (err: any) {
          logger().error(err);
          showErrorToast(err);
        } finally {
          setProcessingIssueId(undefined);
        }
      };

      resolveIssueModal.openModal({ issueId, callback });
    },
    [invalidateQueries, resolveIssueModal],
  );

  const revokeOperation = useCallback(
    async (issueOperation: IIssueOperationDto) => {
      const callback = async () => {
        setProcessingIssueId(issueOperation.issueId);
        try {
          await QueryFactory.IssueQuery.Client.removeIssueOperation(issueOperation.id);
          await invalidateQueries(issueOperation.issueId);
        } catch (err: any) {
          logger().error(err);
          showErrorToast(err);
        } finally {
          setProcessingIssueId(undefined);
        }
      };

      issueRevokeDialogModal.openModal({ issueId: issueOperation.issueId, callback });
    },
    [invalidateQueries, issueRevokeDialogModal],
  );

  const approveOperation = useCallback(
    (issueOperation: IIssueOperationDto) => {
      resumeOperationHandler(issueOperation, IssueOperationStateEnum.Approved);
    },
    [resumeOperationHandler],
  );

  const rejectOperation = useCallback(
    (issueOperation: IIssueOperationDto) => {
      const callback = (data: UseFormRejectIssueType) =>
        resumeOperationHandler(issueOperation, IssueOperationStateEnum.Rejected, data);

      rejectIssueModal.openModal({ issueId: issueOperation.issueId, callback });
    },
    [rejectIssueModal, resumeOperationHandler],
  );

  const generateReport = useCallback(
    async (queryParams?: IssueQueryParamsType): Promise<void> => {
      const callback = async (data: UseFormGenerateReportType) => {
        const response = await QueryFactory.IssueQuery.Client.getIssuesForStudy(
          studyId!,
          queryParams?.patientUid ? queryParams.patientUid : undefined,
          undefined,
          queryParams?.state,
          queryParams?.subjectList,
          queryParams?.patientGroupList,
          queryParams?.unreadOnly ? true : undefined,
          0,
          9999,
          'id',
        );

        const blob = await generateIssueReport({
          data: response.data,
          studyNumber: studyNumber!,
          filters: queryParams,
          studyGroups: groups,
          furtherInfo: data.furtherInfo,
        });

        triggerDownloadFileDialog(blob, issueReportFileNameTemplate(studyNumber!));
      };

      generateReportModal.openModal({ callback });
    },
    [generateReportModal, groups, studyId, studyNumber],
  );

  const navigateToIssue = useCallback(
    async (issueId: number) => {
      const issue = await getIssue(issueId);
      const patientsQuery = await getPatients(
        issue?.linkedPatientUniqId,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        issue?.studyId,
      );
      const patient = patientsQuery.data.find((p) => p.uniqueId === issue?.linkedPatientUniqId);

      setQueryParams(
        {
          issueId: issueId,
          searchQuery: issue?.linkedPatientUniqId,
          patientGroup: patient?.group,
          studyState: !patient?.isStudyStarted
            ? StudyStateFilterEnum.NotStarted
            : patient?.isStudyFinished
            ? StudyStateFilterEnum.Finished
            : StudyStateFilterEnum.InProgress,
        },
        'replace',
      );

      if (issue.topic === 'Records' && issue.resultId && issue.fieldId) {
        const formResult = await getFormResultById(issue.resultId);
        formEditingAndOverview.openOverview({
          formResultId: issue.resultId,
          patientId: patient!.id!,
          formId: formResult.formConfigId,
          navigateToNodeId: issue.fieldId,
          patientUniqueId: issue.linkedPatientUniqId || undefined,
          stepName: issue.stepName || undefined,
        });
      }

      const patientsGroup = groups.find((g) => g.id === patient?.group);
      if (issue.topic === 'Notes' && issue.resultId && patientsGroup?.formConfigIdForNotes) {
        notesOverview.open({
          resultId: issue.resultId,
          patientId: patient!.id,
          configId: patientsGroup.formConfigIdForNotes,
        });
      }

      // TODO: navigate to survey result
      // if (issue.topic === 'Surveys' && issue.resultId) {
      // }
    },
    [formEditingAndOverview, groups, notesOverview, setQueryParams],
  );

  return (
    <IssuesContext.Provider
      value={{
        processingIssueId,

        openIssueTable: issueTableModal.openModal,
        createIssue: createIssueModal.openModal,
        editIssue: createIssueModal.openModal,
        openIssueView: issueViewModal.openModal,

        deleteIssue,
        performOperation,
        rejectOperation,
        approveOperation,
        revokeOperation,
        generateReport,

        setLoadedPatientsUid,
        loadedIssues: issues,

        isIssuesVisible,
        setIssuesVisible,
        navigateToIssue,
      }}
    >
      {children}

      {hasMonitoring && (
        <>
          {studyId && createIssueModal.visible && <IssueForm modal={createIssueModal} studyId={studyId} />}
          <IssueViewModal modal={issueViewModal} />
          <IssueRejectForm modal={rejectIssueModal} />
          <IssueDeleteDialog modal={deleteModal} />
          <IssueResolveForm modal={resolveIssueModal} />
          <IssueRevokeDialog modal={issueRevokeDialogModal} />
          <GenerateReportForm modal={generateReportModal} />

          <IssueTable modal={issueTableModal} />
        </>
      )}
      {formEditingAndOverview.element}
      {notesOverview.element}
    </IssuesContext.Provider>
  );
};
