﻿import { IFillingProgress, RouteProgressStateEnum } from '../../services/api/api-client';

export const getFormState = (
  formResultId: number | undefined | null,
  fillingProgress: IFillingProgress | null | undefined,
  stepState: RouteProgressStateEnum,
) => {
  const isPassed = stepState === RouteProgressStateEnum.Done || stepState === RouteProgressStateEnum.Missed;

  switch (true) {
    case formResultId && fillingProgress && fillingProgress.filled === fillingProgress.total:
      return 'filled';
    case !!formResultId:
      return 'none';
    case isPassed:
      return 'missed';
    default:
      return 'none';
  }
};
