import React, { FC } from 'react';
import { AppInputError, AppInputErrorProps } from '../../wrappers/error/appInputError.component';
import { AppInputLabel, AppInputLabelProps } from '../../wrappers/label/appInputLabel.component';
import { SkipCheckbox, SkipCheckboxProps } from '../../wrappers/skipCheckbox/skipCheckbox.component';
import { AppSliderInputProps } from '../../inputs/slider/appSliderInput';
import { AppSliderInput } from '../../inputs/slider/appSliderInput.component';
import Style from './appSliderField.module.scss';

export type AppSliderFieldType = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipCheckboxProps?: SkipCheckboxProps;
} & AppSliderInputProps;

export const AppSliderField: FC<AppSliderFieldType> = ({
  errorProps,
  labelProps,
  skipCheckboxProps,
  disabled,
  value,
  ...rest
}) => {
  return (
    <AppInputError {...errorProps}>
      <SkipCheckbox disabled={disabled} {...skipCheckboxProps}>
        <AppInputLabel className={Style.labelContainer} disabled={disabled} {...labelProps}>
          <AppSliderInput
            disabled={skipCheckboxProps?.isSkipped || disabled}
            value={skipCheckboxProps?.isSkipped ? undefined : value}
            {...rest}
          />
        </AppInputLabel>
      </SkipCheckbox>
    </AppInputError>
  );
};
