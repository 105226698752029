import * as Logger from 'js-logger';

export const setupDefaultLogger = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  Logger.useDefaults();
};

export interface IWrapperLogger {
  info(msg: string): void;
  error(msg: string, error?: Error): void;
  debug(msg: any): void;
}

class LoggerWrapper implements IWrapperLogger {
  private loggerImpl: any;

  constructor(loggerImpl: any) {
    if (loggerImpl == null) {
      throw Error('Logger is not configured');
    }
    this.loggerImpl = loggerImpl;
  }

  public info(msg: any) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.loggerImpl.info(msg);
    } catch {}
  }

  public debug(...args: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.loggerImpl.debug(...args);
  }

  public dir(msg: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.loggerImpl.dir(msg);
  }

  public error(msg: string, error?: Error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.loggerImpl.error(`${msg}. ${error && `Details: ${error.message}`}`);
  }
}

export const logger = () => {
  return new LoggerWrapper(console);
};
