/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useMemo } from 'react';
import { CreatePredefinedUserDto, ICreatePredefinedUserDto } from '../../../services/api/api-client';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QueryFactory } from '../../../services/api';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { useGetStudyListQuery } from 'src/services/api/api-client/StudyQuery';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { useForm } from 'react-hook-form';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import { logger } from '../../../application/logging/logging';
import { HookFormTextInput } from '../../../hookFormControls/hookFormTextInput';
import { ValidationFormRules } from '../../../helpers/validation-helpers';
import { HookFormDropDown } from '../../../hookFormControls/hookFormDropDown';
import Style from './userlist.module.scss';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { useModal } from '../../../application/hooks/useModal';
import { ModalButtonGroup } from 'uikit/modal/ModalButtonGroup';
import { AppInputLabel } from 'uikit/wrappers';
import { AppAutoCompleteField } from 'uikit/fields';
import { TypographyStyles } from 'styles';
import { UserRolesArr } from '../../../helpers/auth/auth-helper';

const FORM_ID = 'createPredefiendUser';
type FormFieldType = Omit<ICreatePredefinedUserDto, 'roles' | 'studyId'> & {
  roles: DropdownOption[];
  studies: DropdownOption[];
};
export const useCreatedUserModal = () => {
  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const client = useQueryClient();
  const { openModal, closeModal, visible } = useModal();

  const {
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<FormFieldType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const roleList = UserRolesArr;
  const { data: studies } = useGetStudyListQuery({
    // TODO: This hack must be removed when we will have a dropdown with virtualization
    limit: 99999999,
  });

  const onSubmit = handleSubmit(async (data: FormFieldType) => {
    try {
      clearErrors();
      await QueryFactory.UsersQuery.Client.createUser(
        new CreatePredefinedUserDto({
          ...data,
          roles: data.roles.map((x) => x.text),
          studyIds: data.studies.map((x) => x.key as number),
        }),
      );
      await client.invalidateQueries(QueryFactory.UsersQuery.getAllUsersQueryKey());
      closeModal();
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof FormFieldType;
          setError(formKey, {
            type: 'server',
            message: ex.errors[errorKey][0],
          });
        }
      } else {
        logger().error(ex);
        setError('root', { message: t('Admin.UserList.Predefined.Error') });
      }
    }
  });

  const studyOptions: DropdownOption[] = useMemo(
    () =>
      studies?.data.map((x) => {
        return { key: x.id, text: `${x.id}: ${x.studyNumber}` };
      }) || [],
    [studies?.data],
  );

  const roleOptions: DropdownOption[] = useMemo(
    () =>
      roleList?.map((x) => {
        return { key: x, text: `${x}` };
      }) || [],
    [roleList],
  );

  return {
    start: () => {
      openModal();
      reset({
        studyIds: [],
        studies: [],
        roles: [],
        email: '',
      });
    },
    modal: (
      <AppModalContainer
        visible={visible}
        onHide={closeModal}
        title={t('Admin.UserList.Predefined.Header')}
        bodyClassName={Style.modalBody}
        footer={
          <ModalButtonGroup
            onCancel={closeModal}
            onSubmit={onSubmit}
            cancelButtonText={commonLocalizer('Common_Cancel')}
            submitButtonText={commonLocalizer('Common_Save')}
            isSubmitting={isSubmitting}
            generalError={errors?.root?.message}
          />
        }
      >
        <form id={FORM_ID} onSubmit={onSubmit} autoComplete={'off'}>
          <HookFormTextInput
            control={control}
            name={'email'}
            labelProps={{ text: t('Admin.UserList.Predefined.Email.Label') }}
            placeholder={t('Admin.UserList.Predefined.Email.Placeholder')}
            rules={ValidationFormRules().requiredRule}
          />

          <HookFormDropDown
            suggestion={true}
            isMultiple={true}
            labelProps={{ text: t('Admin.UserList.Details.EditModal.StudyIds') }}
            options={studyOptions}
            rules={ValidationFormRules().requiredRule}
            name={'studies'}
            control={control}
          />

          <HookFormDropDown
            suggestion={true}
            isMultiple={true}
            labelProps={{ text: t('Admin.UserList.Details.EditModal.Role') }}
            options={roleOptions}
            rules={ValidationFormRules().requiredRule}
            name={'roles'}
            control={control}
          />

          <AppInputLabel
            text={t('Admin.UserList.Tags.EditModal.Tags.Label')}
            tooltip={t('Admin.UserList.Tags.EditModal.Tags.TooltipText')}
          >
            <div style={{ flexDirection: 'row', display: 'flex', gap: 20 }}>
              <AppAutoCompleteField
                labelProps={{
                  text: t('Admin.UserList.Tags.EditModal.KeyHeader'),
                }}
                suggestions={[]}
                disabled={true}
                onChange={() => {}}
                value={''}
              />
              <AppAutoCompleteField
                labelProps={{
                  text: t('Admin.UserList.Tags.EditModal.ValueHeader'),
                }}
                suggestions={[]}
                disabled={true}
                onChange={() => {}}
                value={''}
              />
            </div>
            <span className={TypographyStyles.plainText12}>{t('Admin.UserList.Predefined.TagsExplanation')}</span>
          </AppInputLabel>
        </form>
      </AppModalContainer>
    ),
  };
};
