﻿import React, { FC } from 'react';
import { IAdminStudyDto } from '../../../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import Style from '../studyList.module.css';
import { ExpandedRowHeader } from '../../../../components/expandedRow/header/expandedRowHeader.component';
import { AppButton } from 'uikit/buttons';
import { CommonStyles } from 'styles';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';

export const StudyConfigurationSection: FC<{ study: IAdminStudyDto; onEdit: () => void }> = ({ study, onEdit }) => {
  const { t } = useTranslation('dev');

  return (
    <div className={Style.section}>
      <ExpandedRowHeader
        text={t('Admin.StudyList.Configuration.Caption')}
        rightHeader={
          <AppButton
            onClick={onEdit}
            variant={'icon-link'}
            colorSchema={'primary'}
            text={t('Admin.StudyList.Configuration.Action')}
            Icon={EditIcon}
          />
        }
      />

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Configuration.TherapyAggregation')}</div>
        <div className={CommonStyles.tableItemRight}>{study.therapyAggregationPeriod}</div>
      </div>

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Configuration.HasTherapy')}</div>
        <div className={CommonStyles.tableItemRight}>{JSON.stringify(study.hasTherapy)}</div>
      </div>

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Configuration.HasMonitoring')}</div>
        <div className={CommonStyles.tableItemRight}>{JSON.stringify(study.hasMonitoring)}</div>
      </div>

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Configuration.HasPdfSigning')}</div>
        <div className={CommonStyles.tableItemRight}>{JSON.stringify(study.hasPdfSigning)}</div>
      </div>

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Configuration.HasDbImport')}</div>
        <div className={CommonStyles.tableItemRight}>{JSON.stringify(study.hasDbImport)}</div>
      </div>
    </div>
  );
};
