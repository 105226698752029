import React, { useMemo } from 'react';
import { RPDF } from '../react-pdf';
import i18n from 'i18next';
import { SurveysFirstPage } from './surveysFirstPage';
import { SurveysReportOptions } from './surveyReport';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { SurveyContentPage } from './surveyContentPage';
import { ReportColors } from '../report-colors';
import { ReportHeader } from '../components/ReportHeader';
import { ReportPagination } from '../components/ReportPagination';
import { localFormat } from '../../../helpers/date-helpers';

export const SurveyReportLayout = (props: SurveysReportOptions) => {
  const { t } = useScopedTranslation('Reports.Surveys');
  const { patientUid, profileFullName, studyNumber, allSurveys, includeMissingSurveys, patientTags } = props;

  const missedSurveys = useMemo(() => {
    return allSurveys.flatMap((x) =>
      x.answers
        .filter((a) => !a.answerId)
        .map((a) => {
          return { ...a, surveyTitle: x.surveyTitle };
        }),
    );
  }, [allSurveys]);

  const filledSurveysCount = useMemo(() => {
    return allSurveys.map((x) => x.answers.filter((a) => !!a.answerId).length).reduce((prev, acc) => prev + acc, 0);
  }, [allSurveys]);

  const headerText = useMemo(() => {
    return allSurveys.length === 1 ? allSurveys[0].surveyTitle : t('DocumentHeader');
  }, [allSurveys, t]);

  const firstPage = (
    <SurveysFirstPage
      patientUid={patientUid}
      filledSurveysCount={filledSurveysCount}
      profileFullName={profileFullName}
      missedSurveysCount={missedSurveys.length}
      studyNumber={studyNumber}
      headerText={headerText}
      patientTags={patientTags ?? []}
    />
  );

  const singleSurveyAnswerPage = <SurveyContentPage allSurveys={allSurveys} headerText={headerText} />;

  const missedSurveysPage = useMemo(() => {
    const localStyles = RPDF.StyleSheet.create({
      container: {
        height: 24,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: ReportColors.ultraLightGrey,
        justifyContent: 'space-between',
        paddingHorizontal: 8,
        marginBottom: 12,
      },
      leftHeader: {
        flexDirection: 'row',
        gap: 4,
      },
    });

    if (missedSurveys.length === 0 || !includeMissingSurveys) {
      return <></>;
    }

    return (
      <RPDF.Page size="A4" style={RPDF.styles.page}>
        <ReportHeader captionText={headerText} />
        <RPDF.View style={localStyles.container}>
          <RPDF.View style={localStyles.leftHeader}>
            <RPDF.Text style={RPDF.typography.heading2}>{t('MissedSurveys')}</RPDF.Text>
          </RPDF.View>
          <RPDF.Text style={[RPDF.typography.text10, { color: ReportColors.darkGrey }]}>
            {missedSurveys.length}
          </RPDF.Text>
        </RPDF.View>
        {missedSurveys.map((ms, i) => (
          <RPDF.View key={i}>
            <RPDF.View style={RPDF.controlStyles.recordRow}>
              <RPDF.Text style={RPDF.controlStyles.questionCell}>{ms.surveyTitle}</RPDF.Text>
              <RPDF.Text style={RPDF.controlStyles.answerCell}>{localFormat(ms.answerDate)}</RPDF.Text>
            </RPDF.View>
          </RPDF.View>
        ))}
        <ReportPagination />
      </RPDF.Page>
    );
  }, [headerText, missedSurveys, t, includeMissingSurveys]);

  return (
    <RPDF.Document
      language={i18n.language}
      producer={'LM Study'}
      title={headerText}
      author={profileFullName || 'LM User'}
      subject={studyNumber}
    >
      {firstPage}
      {singleSurveyAnswerPage}
      {missedSurveysPage}
    </RPDF.Document>
  );
};
