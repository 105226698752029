import React from 'react';
import { Location as RouterLocation, useLocation, useNavigate } from 'react-router-dom';
import { Location } from 'history';

// type Location = To & {
//   state: any;
// };

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
export const RouteAdapter: React.FunctionComponent<{
  children: React.FunctionComponent<{
    history: {
      replace(location: Location): void;
      push(location: Location): void;
    };
    location: RouterLocation;
  }>;
}> = ({ children }) => {
  const navigate = useNavigate();
  const routerLocation = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate({ ...location, pathname: routerLocation.pathname }, { replace: true, state: location.state });
      },
    }),
    [navigate, routerLocation],
  );
  if (!children) {
    return null;
  }

  return children({ history: adaptedHistory, location: routerLocation });
};
