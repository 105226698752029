﻿import React, { FC, useContext, useMemo } from 'react';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { PatientCardContext } from '../PatientCardContext';
import { useGetFormBindingsQuery } from '../../../services/api/api-client/FormsQuery';
import { FormResultVm, RecordRowVm } from './recordRow';
import { RecordsGroup } from './recordsGroup.component';
import { RecordRow } from './recordRow.component';

export const MultiInstanceFormRows: FC = () => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');

  const {
    patient: { id: patientId, uniqueId, studyFinishedAt },
    group: { formConfigIdForNotes },
  } = useContext(PatientCardContext);

  const { data: multipleForms } = useGetFormBindingsQuery(patientId, true, false, {
    suspense: false,
  });

  const rowVms = useMemo<RecordRowVm[]>(() => {
    return (
      multipleForms
        ?.filter((x) => !studyFinishedAt || x.formResultIds.length > 0)
        .filter((x) => x.formConfigId !== formConfigIdForNotes)
        .filter((x) => x.isAvailableForFilling || x.formResultIds.length > 0)
        .sort((a, b) => a.formType.localeCompare(b.formType))
        .map((x) => {
          const vms = x.formResultIds.map<FormResultVm>((y) => ({
            formResultId: y,
            stepName: undefined,
            stepIsActive: true,
            isMultiInstance: true,
          }));

          if (!studyFinishedAt)
            vms.push({
              formResultId: undefined,
              stepName: undefined,
              stepIsActive: true,
              isMultiInstance: true,
            });

          return {
            formConfigId: x.formConfigId,
            formType: x.formType,
            formResults: vms,
            fillingProgress: undefined,
            state: undefined,
          };
        }) ?? []
    );
  }, [formConfigIdForNotes, multipleForms, studyFinishedAt]);

  if (rowVms.length === 0) return null;

  return (
    <RecordsGroup title={t('OptionalForms')} isExpandDefault={true}>
      {rowVms.map((row) => (
        <RecordRow key={row.formConfigId} type={'multiple'} vm={row} />
      ))}
    </RecordsGroup>
  );
};
