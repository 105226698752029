import React, { useEffect } from 'react';
import { useEditor, useNode, Element } from '@craftjs/core';
import clsx from 'clsx';
import { defaultToolSettings } from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import { TextInput } from '../Inputs/TextInput';
import { useContextSelector } from 'use-context-selector';
import { CalculatedBlock } from '../Containers/CalculatedBlock';
import { Container } from '../Containers/Container';
import styles from '../controlsStyle.module.css';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { IBmiInput } from '../../../../features/forms/base/controls/specials/BmiInput';
import { textInputDefaultPropsFactory } from '../../../../features/forms/base/controls/inputs/TextInput';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';

export const BmiInput: IBmiInput = (props) => {
  const { t } = useScopedTranslation('Forms.Controls.BMI');
  const { connectors, selected } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
    blockId: state.id,
  }));

  const { connect, drag } = connectors;

  const { size } = {
    ...props,
  };

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const weightValue = useContextSelector(FormFillContext, (v) => (v.values ? v.values.Weight : undefined));
  const heightValue = useContextSelector(FormFillContext, (v) => (v.values ? v.values.Height : undefined));
  const setDataBlockFieldValue = useContextSelector(FormFillContext, (v) => v.setValue);

  useEffect(() => {
    const weight = Number(weightValue);
    const height = Number(heightValue);
    let bmi;
    if (weight && height) {
      const calc = weight / Math.pow(height / 100, 2);
      bmi = calc < 10 ? undefined : calc.toFixed(1);
      bmi = calc > 999 ? undefined : calc.toFixed(1);
    }

    setDataBlockFieldValue?.('BMI', bmi);
  }, [weightValue, heightValue, setDataBlockFieldValue]);

  return (
    <div
      className={clsx(styles.container, { [styles.selected]: enabled && selected })}
      style={{
        width: isOnlyView ? '100%' : size,
      }}
      draggable={enabled}
      ref={(ref) => enabled && connect(drag(ref!))}
    >
      <Container flexBoxGap={20} isFlexBox={true}>
        <Element
          is={TextInput}
          id={'Weight'}
          {...textInputDefaultPropsFactory()}
          isDisabled={false}
          hasSkipCheckBox={false}
          hideLabel={false}
          isBold={false}
          dataKey={'Weight'}
          size={'100%'}
          isEditable={props.isEditable}
          conditionalStateBehaviour={0}
          isMultiline={false}
          isNumberOnly={true}
          label={t('Weight')}
          units={'kg'}
          validation={{ pattern: '^[0-9]*\\.?[0-9]*$', maxValue: 300 }}
          ignoreInitialValuesFromPreviousForm={props.ignoreInitialValuesFromPreviousForm}
        />
        <Element
          is={TextInput}
          id={'Height'}
          {...textInputDefaultPropsFactory()}
          isDisabled={false}
          hasSkipCheckBox={false}
          hideLabel={false}
          isBold={false}
          dataKey={'Height'}
          size={'100%'}
          isEditable={props.isEditable}
          conditionalStateBehaviour={0}
          isMultiline={false}
          isNumberOnly={true}
          label={t('Height')}
          units={'cm'}
          validation={{ pattern: '^[0-9]*\\.?[0-9]*$', maxValue: 300 }}
          ignoreInitialValuesFromPreviousForm={props.ignoreInitialValuesFromPreviousForm}
        />

        <CalculatedBlock
          isDisabled={true}
          hasSkipCheckBox={false}
          hideLabel={false}
          isBold={false}
          isEditable={false}
          dataKey={'BMI'}
          size={'50%'}
          label={'BMI'}
        />
      </Container>
    </div>
  );
};

const BmiSettings = () => {
  return <LayoutSettings />;
};

export const BmiDefaultProps = {
  ...defaultToolSettings,
  isMandatory: false,
};

BmiInput.craft = {
  defaultProps: BmiDefaultProps,
  related: {
    settings: BmiSettings,
  },
};
