import React, { FC, useCallback, useState } from 'react';
import styles from './appTagInput.module.css';
import clsx from 'clsx';
import { ReactComponent as CommonAddIcon } from '../../../../assets/img/common/plus_tagger_20.svg';
import { ReactComponent as Cross } from '../../../../assets/img/common/cross_16.svg';
import { AppTagInputProps } from './appTagInput';
import { AppTextInput } from '../text/appTextInput.component';
import { TypographyStyles } from '../../../../styles';
import { AppButton } from '../../buttons/button/button.component';

export const AppTagInput: FC<AppTagInputProps> = (props) => {
  const [innerInputValue, setInnerInputValue] = useState<string>();
  const inputValue = props.onInputChanged ? props.inputValue : innerInputValue;

  useCallback(
    (value: string) => {
      if (props.onInputChanged) props.onInputChanged(value);
    },
    [props],
  );

  const onAddTagHandler = useCallback(() => {
    if (inputValue) {
      props.onAddTag(inputValue);
      props.onInputChanged?.('');
      setInnerInputValue('');
    }
  }, [inputValue, props]);

  const onDeleteTagHandler = useCallback(
    (index: number) => {
      props.onDeleteTag(index);
    },
    [props],
  );

  const onInputChangeHandler = useCallback(
    (value: string) => {
      props.onInputChanged?.(value);
      setInnerInputValue?.(value);
    },
    [props],
  );

  const onKeyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') onAddTagHandler();
    },
    [onAddTagHandler],
  );

  return (
    <div className={props.className}>
      <div className={styles.tagInputContainer}>
        <AppTextInput
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={inputValue}
          onChange={(e) => onInputChangeHandler(e.target?.value)}
          onKeyDown={onKeyDownHandler}
          className={styles.tagInputField}
          invalid={props.invalid}
        />
        <AppButton
          Icon={CommonAddIcon}
          variant={'icon-link'}
          colorSchema={'secondary'}
          className={styles.tagInputAddButton}
          onClick={onAddTagHandler}
          disabled={props.disabled}
        />
      </div>
      {props.values && props.values.length > 0 && (
        <div className={styles.tagInputValuesContainer}>
          {props.values?.map((item, i) => (
            <div key={i} className={clsx(styles.tagInputValueWrapper, { [styles.isDisabled]: props.disabled })}>
              <div className={TypographyStyles.plainText12}>{item}</div>
              <Cross className={styles.tagInputDeleteIcon} onClick={() => !props.disabled && onDeleteTagHandler(i)} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
