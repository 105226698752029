import React from 'react';
import clsx from 'clsx';
import { DateRangeProps } from './daterange';
import { useTranslation } from 'react-i18next';
import styles from './daterange.module.css';
import { AppInputError } from '../uikit/wrappers/error/appInputError.component';
import { AppInputLabel } from '../uikit/wrappers/label/appInputLabel.component';
import { AppTextInput } from '../uikit/inputs/text/appTextInput.component';
import { SkipCheckbox } from 'uikit/wrappers';

export const DateRangeComponent = (props: DateRangeProps) => {
  const { t } = useTranslation();
  const {
    onStartChange,
    onEndChange,
    startDate,
    endDate,
    label,
    disabled,
    startDateErrorText,
    endDateErrorText,
    tooltip,
    skipCheckboxProps,
  } = props;

  return (
    <div className={clsx(styles.container)}>
      <AppInputError errors={startDateErrorText || endDateErrorText}>
        <SkipCheckbox disabled={disabled} {...skipCheckboxProps}>
          <AppInputLabel isBold={props.isBold} text={label} tooltip={tooltip}>
            <div className={styles.wrapContainer}>
              <AppTextInput
                placeholder={t('Common_From')}
                type={'date'}
                disabled={skipCheckboxProps?.isSkipped || disabled}
                onChange={onStartChange}
                value={startDate}
                max={endDate}
                invalid={!!startDateErrorText}
              />

              <span className={styles.divider}>—</span>

              <AppTextInput
                placeholder={t('Common_Until')}
                type={'date'}
                disabled={skipCheckboxProps?.isSkipped || disabled}
                value={endDate}
                onChange={onEndChange}
                min={startDate}
                invalid={!!endDateErrorText}
              />
            </div>
          </AppInputLabel>
        </SkipCheckbox>
      </AppInputError>
    </div>
  );
};
