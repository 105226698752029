/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useNode } from '@craftjs/core';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { CommentDataKey } from '../../../../../components/formEditor/uiEditor/provider/formFill.context';
import { ConditionalFieldBehaviorEnum } from '../../../../forms/base/controls/inputs/base/baseControlSettings';
import { ISelectInput } from '../../../../forms/base/controls/inputs/SelectInput';
import { RPDF } from '../../../react-pdf';
import { renderNode } from '../../recordReport-helper';
import { recordReportFormControlsResolver } from '../../recordReport.resolver';
import { RecordsReportContext } from '../../recordsReport.context';

export const SelectInput: ISelectInput = ({ label, dataKey, options }) => {
  const { t } = useTranslation();
  const { id } = useNode();

  const nodes = useContextSelector(RecordsReportContext, (v) => v.nodes);
  const linkedNodes = nodes.find((n) => n.id === id)?.linkedNodes;

  const inputValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);
  const commentValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[CommentDataKey(dataKey)]);
  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  const displayValues = useMemo(() => {
    return options
      .filter((x) => multipleInputValue?.includes(x.value))
      .map((x) => x.displayValue)
      .join('\n');
  }, [multipleInputValue, options]);

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = options.filter((x) => x.withConditionalField);

    const finalArray = optionsWithConditionalField.filter(
      (option) =>
        option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
        multipleInputValue?.includes(option.value),
    );

    return Array.from(finalArray, (option) => {
      const childId = _.get(linkedNodes, option.key);
      if (!childId) {
        return <RPDF.View key={option.key} />;
      }

      return <RPDF.View key={option.key}>{renderNode(nodes, recordReportFormControlsResolver, childId)}</RPDF.View>;
    });
  }, [options, multipleInputValue, linkedNodes, nodes]);

  return (
    <RPDF.View>
      <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
        <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? dataKey}</RPDF.Text>
        <RPDF.Text style={RPDF.controlStyles.answerCell}>{displayValues}</RPDF.Text>
      </RPDF.View>
      {commentValue && (
        <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
          <RPDF.Text style={RPDF.controlStyles.questionCell}>{t('Forms.Controls.CommentFieldCaption')}</RPDF.Text>
          <RPDF.Text style={RPDF.controlStyles.answerCell}>{commentValue}</RPDF.Text>
        </RPDF.View>
      )}
      {conditionalContainer.length > 0 && conditionalContainer}
    </RPDF.View>
  );
};
