/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useContextSelector } from 'use-context-selector';
import { useModal } from '../../../application/hooks/useModal';
import { logger } from '../../../application/logging/logging';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import { ValidationConstants, ValidationFormRules } from '../../../helpers/validation-helpers';
import { HookFormDropDown } from '../../../hookFormControls/hookFormDropDown';
import { AccessDbContext } from '../../../providers/accessDbProvider/accessDb.context';
import { QueryFactory } from '../../../services/api';
import {
  AnalysisPresetCreationDto,
  AnalysisPresetDto,
  AnalysisPresetScheme,
  AnalysisPresetUpdateDto,
} from '../../../services/api/api-client';
import { TypographyStyles } from '../../../styles';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { strArrToDropdownOptions } from '../../uikit/inputs/dropdown/dropdown-helper';
import Style from './analysisPreset.module.css';
import { isNullOrEmpty } from '../../../helpers/string-helper';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppTextField } from '../../uikit/fields/text/appTextField.component';
import { AppModalContainer } from '../../uikit/modal/modal.component';
import { DropdownOption } from '../../uikit/inputs/dropdown/appDropdownInput';
import { AppInputError } from '../../uikit/wrappers';

export type PresetForm = {
  name: string;
  description: string | null;
  tableName: DropdownOption<string>;
  keyColumnName: DropdownOption<string>;
  analysisQuantityLimit: number | null;
  dataColumns: DropdownOption<string>[];
};

export const useAnalyzesPresetEditing = (props: {
  studyId: number;
  preset?: AnalysisPresetDto | null;
  onSaved: (presetId: number) => void;
  onHide: () => void;
}) => {
  const { t } = useScopedTranslation('StudySettingsPage.AnalyzesPreset', 'dev');
  const commonLocalizer = useCommonLocalization();
  const modal = useModal();
  const queryClient = useQueryClient();
  const { data: database, fileName } = useContextSelector(AccessDbContext, (x) => x);
  const [internalError, setInternalError] = useState(false);

  const tablesList = useMemo(() => strArrToDropdownOptions(database?.getTableNames()), [database]);
  let columnsList = useMemo(() => {
    const tableName = props.preset?.scheme![0].tableName;
    if (!tableName) {
      return [];
    }

    return strArrToDropdownOptions(database?.getTable(tableName).getColumnNames());
  }, [database, props.preset]);

  const defaultValues = useMemo<Partial<PresetForm>>(
    () =>
      props.preset
        ? {
            name: props.preset.name,
            description: props.preset.description,
            keyColumnName: columnsList.find(
              (x) => x.key === Object.keys(props.preset!.scheme![0].keyColumnsWithValue!)[0],
            )!,
            dataColumns: props.preset.scheme![0].dataColumns!.map((x) => columnsList.find((y) => y.key === x)!),
            analysisQuantityLimit: props.preset.analysisQuantityLimit,
            tableName: tablesList.find((x) => x.key === props.preset!.scheme![0].tableName)!,
          }
        : {},
    [columnsList, props.preset, tablesList],
  );

  const {
    watch,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    reset,
    control,
    register,
  } = useForm<PresetForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const tableName = watch('tableName');
  const keyColumnName = watch('keyColumnName');
  const columnsWithData = watch('dataColumns');

  columnsList = useMemo(() => {
    if (!tableName) {
      return [];
    }

    return strArrToDropdownOptions(database?.getTable(tableName.text).getColumnNames());
  }, [database, tableName]);

  const onSubmit = handleSubmit(async (data: PresetForm) => {
    try {
      setInternalError(false);
      const scheme = new AnalysisPresetScheme({
        tableName: data.tableName.key,
        keyColumnsWithValue: { [data.keyColumnName.key]: '' },
        dataColumns: data.dataColumns.map((x) => x.key),
      });

      let presetId: number;
      if (!props.preset) {
        const dto = new AnalysisPresetCreationDto({
          studyId: props.studyId,
          databaseFilename: fileName!,
          description: data.description,
          name: data.name,
          scheme: [scheme],
          analysisQuantityLimit: data.analysisQuantityLimit,
        });

        const createdPreset = await QueryFactory.AnalyzesQuery.Client.createAnalysisPreset(dto);
        presetId = createdPreset.id;
      } else {
        const dto = new AnalysisPresetUpdateDto({
          name: data.name,
          description: data.description,
          databaseFilename: fileName!,
          analysisQuantityLimit: data.analysisQuantityLimit,
          scheme: dirtyFields.dataColumns ? [scheme] : undefined,
        });

        const updatedPreset = await QueryFactory.AnalyzesQuery.Client.updateAnalysisPreset(props.preset.id, dto);
        presetId = updatedPreset.id;
      }

      await queryClient.invalidateQueries(QueryFactory.AnalyzesQuery.getAllAnalysisPresetsForStudyQueryKey());
      modal.closeModal();
      props.onSaved(presetId);
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof PresetForm;
          setError(formKey, {
            type: 'server',
            message: ex.errors[errorKey][0],
          });
        }
      } else {
        logger().error(ex);
        setInternalError(true);
      }
    }
  });

  const content = useMemo(() => {
    return (
      <form id={'analysisPresetForm'} onSubmit={onSubmit} autoComplete={'off'}>
        <div className={Style.dbNameRow}>
          <div className={TypographyStyles.heading2}>{t('DbFileNameLabel')}</div>
          <div>{fileName}</div>
        </div>

        <Controller
          control={control}
          name={'name'}
          render={({ field: { onChange, value } }) => (
            <AppTextField
              {...register('name', {
                ...ValidationFormRules().requiredRule,
                ...ValidationFormRules().maxLengthRule,
              })}
              maxLength={ValidationConstants.textInputMaxLength}
              labelProps={{ text: t('PresetNameLabel') }}
              placeholder={t('PresetNamePlaceholder')}
              value={value}
              onChange={onChange}
              errorProps={{ errors: errors?.name?.message }}
            />
          )}
        />

        <HookFormDropDown
          control={control}
          name={'tableName'}
          labelProps={{
            text: t('TableNameLabel'),
          }}
          placeholder={t('TableNamePlaceholder')}
          options={tablesList}
          rules={{
            ...ValidationFormRules().requiredRule,
          }}
          errorProps={{
            errors: (errors?.tableName as any)?.message,
          }}
        />

        <HookFormDropDown
          control={control}
          name={'keyColumnName'}
          labelProps={{ text: t('KeyColumnLabel') }}
          placeholder={t('KeyColumnPlaceholder')}
          options={columnsList}
          rules={{
            ...ValidationFormRules().requiredRule,
          }}
          disabled={!tableName}
          errorProps={{ errors: (errors?.keyColumnName as any)?.message }}
        />

        <HookFormDropDown
          isMultiple={true}
          control={control}
          name={'dataColumns'}
          labelProps={{
            text: t('ColumnsWithDataLabel'),
          }}
          placeholder={t('ColumnsWithDataPlaceholder')}
          options={columnsList}
          rules={{
            ...ValidationFormRules().requiredRule,
          }}
          disabled={!keyColumnName}
          errorProps={{ errors: (errors?.dataColumns as any)?.message }}
        />

        <Controller
          control={control}
          name={'analysisQuantityLimit'}
          rules={{
            ...ValidationFormRules().digitsOnlyRule,
            ...ValidationFormRules().minAnalysisQuantityRule,
          }}
          render={({ field: { onChange, value } }) => (
            <AppTextField
              type={'number'}
              labelProps={{ text: t('PresetQuantityLimitLabel') }}
              placeholder={t('PresetQuantityLimitPlaceholder')}
              value={value ?? ''}
              onChange={(x) => onChange(isNullOrEmpty(x.target.value) ? null : Number(x.target.value))}
              disabled={!columnsWithData?.length}
              errorProps={{ errors: errors?.analysisQuantityLimit?.message }}
            />
          )}
        />
      </form>
    );
  }, [
    onSubmit,
    t,
    fileName,
    control,
    tablesList,
    errors?.tableName,
    errors?.keyColumnName,
    errors?.dataColumns,
    errors?.name?.message,
    errors?.analysisQuantityLimit?.message,
    columnsList,
    tableName,
    keyColumnName,
    register,
    columnsWithData?.length,
  ]);

  const buttons = useMemo(() => {
    return (
      <div className={Style.footer}>
        <AppInputError
          errors={internalError ? t('Errors.PresetSavingIsFailed') : undefined}
          hideBorder
          position={'top'}
        >
          <div className={Style.buttonGroup}>
            <AppButton
              text={commonLocalizer('Common_Cancel')}
              variant={'button'}
              colorSchema={'secondary'}
              onClick={() => {
                modal.closeModal();
                props.onHide();
              }}
              disabled={isSubmitting}
            />
            <AppButton
              text={commonLocalizer('Common_Finish')}
              variant={'button'}
              colorSchema={'primary'}
              type={'submit'}
              disabled={isSubmitting}
              isLoading={isSubmitting}
              form={'analysisPresetForm'}
            />
          </div>
        </AppInputError>
      </div>
    );
  }, [internalError, t, isSubmitting, modal, commonLocalizer, props]);

  return {
    databaseConnected: !!database,
    openModal: modal.openModal,
    modal: (
      <AppModalContainer
        bodyClassName={Style.modalBody}
        title={props.preset ? t('PresetEditingModalTitle') : t('PresetCreationModalTitle')}
        visible={modal.visible}
        onHide={() => {
          modal.closeModal();
          props.onHide();
        }}
        footer={buttons}
        onDismissed={reset}
      >
        {content}
      </AppModalContainer>
    ),
  };
};
