import React from 'react';
import { RPDF } from '../../react-pdf';
import { SurveyReportAnswerContent } from './SurveyReportAnswerContent';
import {
  AnswerStatisticsListItem,
  BaseQuestion,
  ScoreCalculationStatusEnum,
} from '../../../../services/api/api-client';
import { DateFormats, localFormat } from '../../../../helpers/date-helpers';
import { WarningScoreIcon } from '../../components/svg/WarningScoreIcon';
import { NoScoreIcon } from '../../components/svg/NoScoreIcon';

export type SurveySingleAnswerProps = { answer: AnswerStatisticsListItem; question: BaseQuestion };

export const SurveyReportSingleAnswerRow: React.FC<SurveySingleAnswerProps> = (props: SurveySingleAnswerProps) => {
  const { answer, question } = props;

  const scoreContent = () => {
    if (!answer?.score && answer?.score !== 0) {
      return (
        <RPDF.View style={RPDF.controlStyles.answerCell}>
          {answer.scoreStatus !== ScoreCalculationStatusEnum.Done ? <WarningScoreIcon /> : <NoScoreIcon />}
        </RPDF.View>
      );
    }

    return (
      <RPDF.Text style={[RPDF.controlStyles.answerCell]}>
        <RPDF.Text style={RPDF.controlStyles.answerCell}>{answer.score}</RPDF.Text>
      </RPDF.Text>
    );
  };

  return (
    <>
      <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
        <RPDF.View style={[{ gap: 8, flexDirection: 'row', flex: 3 }]}>
          <RPDF.Text style={RPDF.controlStyles.questionCell}>
            {localFormat(answer.answerDate, DateFormats.shortDate)}
          </RPDF.Text>
        </RPDF.View>
        <RPDF.Text style={[RPDF.controlStyles.answerCell]}>
          <SurveyReportAnswerContent answers={[answer.rawAnswer]} question={question} />
        </RPDF.Text>
        {scoreContent()}
      </RPDF.View>
    </>
  );
};
