import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LoginContainer } from '../containers/login/login.container';
import { QueryParamProvider } from 'use-query-params';
import { RouteAdapter } from './RouterAdapter';
import { AppRoutes } from './routes';
import { AccessDenied } from '../containers/accessDenied/AccessDenied.component';
import { Layout } from './Layout';
import { StudyRouter } from './StudyRouter';
import { AdminRouter } from './AdminRouter';
import { Impressum } from '../containers/impressum/impressum.component';

export const AppRouter = () => {
  return (
    <BrowserRouter basename={'/'}>
      <QueryParamProvider ReactRouterRoute={RouteAdapter as unknown as React.FunctionComponent}>
        <Routes>
          <Route element={<Layout />}>
            {AdminRouter()}
            {StudyRouter()}
            <Route path={AppRoutes.AccessDenied} element={<AccessDenied />} />
            <Route path={'*'} element={<Navigate to={AppRoutes.Studies} />} />
            <Route path={AppRoutes.Impressum} element={<Impressum />} />
          </Route>

          <Route path={AppRoutes.Login} element={<LoginContainer />} />
        </Routes>
      </QueryParamProvider>
    </BrowserRouter>
  );
};
