import React from 'react';
import { useContextSelector } from 'use-context-selector';
import { formatDateRange, toDateOrNull } from '../../../../../helpers/date-helpers';
import { IDateRangeInput } from '../../../../forms/base/controls/inputs/DateRangeInput';
import { RPDF } from '../../../react-pdf';
import { RecordsReportContext } from '../../recordsReport.context';

export const DateRangeInput: IDateRangeInput = ({ label, dataKey }) => {
  const inputValueStartDate = useContextSelector(
    RecordsReportContext,
    (v) => v.formResult?.[`${dataKey}_from`] as string | undefined,
  );
  const inputValueEndDate = useContextSelector(
    RecordsReportContext,
    (v) => v.formResult?.[`${dataKey}_until`] as string | undefined,
  );

  return (
    <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
      <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? dataKey}</RPDF.Text>
      <RPDF.Text style={RPDF.controlStyles.answerCell}>
        {formatDateRange(toDateOrNull(inputValueStartDate), toDateOrNull(inputValueEndDate))}
      </RPDF.Text>
    </RPDF.View>
  );
};
