﻿import { FormResultDto, IRouteProgressDto } from 'src/services/api/api-client';
import { QueryFactory } from 'src/services/api';
import { getPatientRouteProgress } from 'src/services/api/api-client/StudyRoutesClient';

const getInitialAnswers = async (
  stepName: string,
  formType: string,
  routeProgress: IRouteProgressDto,
  patientId: string,
): Promise<FormResultDto | undefined> => {
  routeProgress ??= await getPatientRouteProgress(patientId);

  const formTypeForInitialAnswers = routeProgress.steps
    .find((x) => x.name === stepName)
    ?.forms?.find((f) => f.formType === formType)?.formTypeForInitialAnswers;

  if (formTypeForInitialAnswers) {
    const { data: results } = await QueryFactory.FormsQuery.Client.getFormResults(
      undefined,
      [formTypeForInitialAnswers],
      patientId,
      1,
    );

    if (results.length) {
      // TODO: think about how to avoid. Initials answers does not mean that it is real answer.
      const redactedObject = new FormResultDto({ ...results[0] });

      if (redactedObject) {
        redactedObject.id = null;
      }
      return redactedObject;
    }

    const currentStepIndex = routeProgress.steps.findIndex((x) => x.name === stepName);
    const prevStepName = routeProgress.steps[currentStepIndex - 1].name;
    return getInitialAnswers(prevStepName, formTypeForInitialAnswers, routeProgress, patientId);
  }

  return undefined;
};

export default getInitialAnswers;
