﻿import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import styles from './TablePageLayout.module.scss';
import clsx from 'clsx';
import { Loading } from 'uikit/suspense/Loading';
import { FloatingButton } from 'uikit/buttons/floatingButton/floatingButton.component';
import { AppPagination } from 'uikit/pagination/AppPagination';
import { AppTextInput, AppTextInputProps } from 'uikit/inputs';

type TablePageLayoutProps = PropsWithChildren<{
  /** @default normal */
  width?: 'full' | 'normal';
  className?: string;
  style?: CSSProperties;
  testId?: string;

  loading?: boolean;
}>;

type HeaderProps = PropsWithChildren<{
  className?: string;
  style?: CSSProperties;
  testId?: string;
}>;

type HeaderRowProps = PropsWithChildren<{
  className?: string;
  style?: CSSProperties;
  testId?: string;
}>;

type TableContainerProps = PropsWithChildren<{
  loading?: boolean;
  className?: string;
  style?: CSSProperties;
  testId?: string;
}>;

type TitleProps = {
  text: string;
  secondaryText?: string;
  className?: string;
  style?: CSSProperties;
  testId?: string;
};

const TablePageLayout: FC<TablePageLayoutProps> & {
  Header: FC<HeaderProps> & {
    Row: FC<HeaderRowProps>;
    Title: FC<TitleProps>;
    Search: FC<AppTextInputProps>;
  };
  TableContainer: FC<TableContainerProps> & {
    FloatingButton: typeof FloatingButton;
    Pagination: typeof AppPagination;
  };
} = (props) => {
  const width = props.width ?? 'normal';

  return (
    <Loading loading={props.loading ?? false} containerClassName={clsx(styles.scrollWrapper)} testId={props.testId}>
      <div
        style={props.style}
        className={clsx(styles.container, props.className, {
          [styles.normal]: width === 'normal',
          [styles.full]: width === 'full',
        })}
      >
        {props.children}
      </div>
    </Loading>
  );
};

const Header: typeof TablePageLayout.Header = (props) => (
  <div className={clsx(styles.header, props.className)} style={props.style} data-test-id={props.testId}>
    {props.children}
  </div>
);

Header.Row = (props) => (
  <div className={clsx(styles.headerRow, props.className)} style={props.style} data-test-id={props.testId}>
    {props.children}
  </div>
);

Header.Title = (props) => (
  <div className={clsx(styles.title, props.className)} style={props.style} data-test-id={props.testId}>
    <span>{props.text}</span>
    {props.secondaryText && <span>{props.secondaryText}</span>}
  </div>
);

Header.Search = ({ ref, ...props }) => {
  return <AppTextInput type={'search'} className={clsx(styles.search, props.className)} {...props} />;
};

TablePageLayout.Header = Header;

const TableContainer: typeof TablePageLayout.TableContainer = (props) => (
  <Loading
    loading={props.loading || false}
    testId={props.testId}
    containerStyle={props.style}
    containerClassName={clsx(styles.tableContainer, props.className)}
  >
    {props.children}
  </Loading>
);

TableContainer.FloatingButton = (props) => <FloatingButton {...props} className={clsx(styles.fab, props.className)} />;

TableContainer.Pagination = (props) => <AppPagination {...props} className={clsx(styles.paging, props.className)} />;

TablePageLayout.TableContainer = TableContainer;

export default TablePageLayout;
