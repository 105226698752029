/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useMemo } from 'react';
import { GeneralPatientInfoProps } from './generalInfo';
import Style from './generalInfo.module.css';
import { ExpandedRowHeader } from '../header/expandedRowHeader.component';
import { CommonStyles } from '../../../styles';
import { getCurrentDateLocale } from '../../../application/localisation/localization';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { LogExportModal } from '../exportPatientLog/logExportModal.component';
import { useModal } from '../../../application/hooks/useModal';
import { ReactComponent as ExportIcon } from '../../../assets/img/common/export.svg';
import { Permissions } from '../../../helpers/auth/auth-helper';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { DateFormats, formatRelativeDateOnly, localFormat } from '../../../helpers/date-helpers';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppCheckboxInput } from '../../uikit/inputs/checkbox/appCheckboxInput.component';
import { Chips } from 'uikit/chips/chips.component';
import { stringComparer } from '../../../helpers/string-helper';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import useApplyPatientFilter from '../../../features/patients/useApplyPatientFilter';

export const GeneralPatientInfo = React.memo((props: GeneralPatientInfoProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo');
  const { t: patientFiltersLocalizer } = useScopedTranslation('Dashboard.PatientFilters');
  const commonLocalizer = useCommonLocalization();
  const { surveys } = useAppSelector((state) => state.app);

  const applyPatientFilter = useApplyPatientFilter();
  const logExportModal = useModal();

  const groups = useAppSelector((state) => state.app.groups);
  const hasSurveys = surveys.length > 0;

  const tagsArray = useMemo(() => {
    return Object.keys(props.tags)
      .filter((key) => typeof props.tags[key] === 'string')
      .map((key) => ({ key, value: props.tags[key] }))
      .sort((a, b) => stringComparer(a.key, b.key));
  }, [props.tags]);

  return (
    <>
      <div className={Style.container} data-test-id={'patient-card-general-info'}>
        <div className={Style.tableWrapper}>
          <ExpandedRowHeader
            text={
              <IssueMark
                issueContext={{
                  subject: 'Patient',
                  linkedPatientUniqId: props.uniqueId,
                  topic: 'GeneralInfo',
                }}
                containerClassName={Style.issueMarkContainer}
              >
                {commonLocalizer('Dashboard.PatientAccordion.GeneralInfo.Header')}
              </IssueMark>
            }
            rightHeader={
              <AppButton
                onClick={logExportModal.openModal}
                variant={'icon-link'}
                colorSchema={'primary'}
                text={t('ExportAuditLogs')}
                Icon={ExportIcon}
              />
            }
          />

          <div className={CommonStyles.tableRow}>
            <div className={CommonStyles.tableItemLeft}>{t('DeviceSerial')}</div>
            <div className={CommonStyles.tableItemRight}>{props.deviceSerialNumber}</div>
          </div>

          <div className={CommonStyles.tableRow}>
            <div className={CommonStyles.tableItemLeft}>{t('PrismaSyncDate')}</div>
            <div className={CommonStyles.tableItemRight}>
              {props.lastTherapyDay
                ? formatRelativeDateOnly(props.lastTherapyDay, Date.now(), getCurrentDateLocale())
                : commonLocalizer('Common_Never')}
            </div>
          </div>

          {props.contractSignedAt && (
            <div className={CommonStyles.tableRow}>
              <div className={CommonStyles.tableItemLeft}>{t('ContractSignDate')}</div>
              <div className={CommonStyles.tableItemRight}>
                {localFormat(props.contractSignedAt, DateFormats.longDate)}
              </div>
            </div>
          )}

          {!props.isStudyFinished && props.isStudyStarted && (
            <div className={CommonStyles.tableRow}>
              <div className={CommonStyles.tableItemLeft}>{t('PatientFilters')}</div>
              <div className={CommonStyles.tableItemRight}>
                <div className={Style.checkboxWrapper}>
                  <PermissionsCheck permissions={Permissions.EditPatient}>
                    {(accessAllowed) => (
                      <>
                        <AppCheckboxInput
                          label={patientFiltersLocalizer('IsClinic')}
                          defaultChecked={props?.filters?.isClinic}
                          onChange={(e) => applyPatientFilter(props.id, 'isClinic', e.target.checked)}
                          readOnly={!accessAllowed}
                        />
                        <AppCheckboxInput
                          label={patientFiltersLocalizer('IsControlled')}
                          defaultChecked={props?.filters?.isControlled}
                          onChange={(e) => applyPatientFilter(props.id, 'isControlled', e.target.checked)}
                          readOnly={!accessAllowed}
                        />
                        {hasSurveys && (
                          <AppCheckboxInput
                            label={patientFiltersLocalizer('NoApp')}
                            defaultChecked={props?.filters?.hasNoApp}
                            onChange={(e) => applyPatientFilter(props.id, 'hasNoApp', e.target.checked)}
                            readOnly={!accessAllowed}
                          />
                        )}
                      </>
                    )}
                  </PermissionsCheck>
                </div>
              </div>
            </div>
          )}
          {tagsArray.map((tag) => (
            <div data-test-id="tag" key={tag.key} className={CommonStyles.tableRow}>
              <div data-test-id="tag-key" className={CommonStyles.tableItemLeft}>
                {tag.key}
              </div>
              <div data-test-id="tag-value" className={CommonStyles.tableItemRight}>
                <div className={Style.tagValueWrapper}>
                  <Chips checked text={tag.value} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <LogExportModal
        {...props}
        routeProgress={props.studyRouteProgress}
        patientTags={tagsArray}
        groups={groups}
        modal={logExportModal}
        modalTitle={t('ExportAuditLogs')}
      />
    </>
  );
});
