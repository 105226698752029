import { InternalAxiosRequestConfig } from 'axios';
import { RootState } from 'src/application/redux-store/store-types';

export const injectStudyId = (getState: () => RootState) => {
  return async (config: InternalAxiosRequestConfig) => {
    const state = getState();
    const appState = state.app;

    if (appState.studyId !== null) {
      config.headers = config?.headers || {};
      config.headers.StudyId = appState.studyId;
      config.headers.StudyNumber = appState.studyNumber;
    }
    return config;
  };
};
