import React, { useMemo } from 'react';
import { IStaffDto } from '../../../services/api/api-client';
import Style from '../../studies/tableColumns.module.css';
import { formatRelative } from 'date-fns';
import { getCurrentDateLocale } from '../../../application/localisation/localization';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { expandRowButtonColumn } from 'uikit/table/columns/ExpandRowButtonColumn';

export const useUserColumns = (): ColumnDef<IStaffDto>[] => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        accessorKey: 'fullName',
        header: 'Full Name',
        enableSorting: false,
        size: 200,
      },
      {
        id: 'studies',
        header: 'Studies',
        cell: ({ row }) => (
          <div className={Style.groupsContent}>
            {row.original.studies?.map((x) => (
              <p key={x.id}>{`${x.studyNumber} (ID: ${x.id} )`}</p>
            ))}
          </div>
        ),
        meta: {
          isColored: true,
          dontUseHeaderWidth: true,
        },
      },
      {
        id: 'roles',
        header: 'Roles',
        cell: ({ row }) => (
          <div className={Style.groupsContent}>
            {row.original.roles?.map((x) => (
              <p key={x}>{x}</p>
            ))}
          </div>
        ),
        size: 200,
      },
      {
        id: 'lastVisitDateTime',
        accessorFn: (staff) => staff.lastVisitDate,
        header: 'Last visit date',
        cell: ({ row }) => (
          <p>
            {row.original.lastVisitDate
              ? formatRelative(row.original.lastVisitDate, Date.now(), getCurrentDateLocale())
              : t('Common_Never')}
          </p>
        ),
        size: 300,
        meta: {
          isColored: true,
        },
      },
      expandRowButtonColumn({}),
    ],
    [t],
  );
};
