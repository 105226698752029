import React from 'react';
import { RPDF } from '../react-pdf';
import { ReportFirstPage } from '../components/ReportFirstPage';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { InfoField } from '../components/reportFields';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { ReportSignAndTitleField } from '../components/ReportSignAndTitleField';
import { StringKeyValueType } from '../../../helpers/typeUtils';

export type SurveysFirstPageDataType = {
  profileFullName: string;
  studyNumber: string;
  filledSurveysCount: number;
  missedSurveysCount: number;
  patientUid: string;
  headerText: string;
  patientTags: StringKeyValueType[];
};

export const SurveysFirstPage = (props: SurveysFirstPageDataType) => {
  const { studyNumber, filledSurveysCount, missedSurveysCount, profileFullName, patientUid, headerText, patientTags } =
    props;
  const { t } = useScopedTranslation('Reports.Surveys.FirstPage');

  const localStyles = RPDF.StyleSheet.create({
    fieldContainer: {
      flex: 1,
    },
  });

  const Header = (pages?: string | JSX.Element) => {
    return (
      <>
        <RPDF.View style={localStyles.fieldContainer}>
          <InfoField header={t('CreatedAt')} value={localFormat(new Date(), DateFormats.dateTime)} />
          <InfoField header={t('CreatedBy')} value={profileFullName} />
          <InfoField header={t('StudyNumber')} value={studyNumber} />
          <InfoField header={t('PatientId')} value={patientUid} />
          {patientTags.map((tag, index) => {
            return <InfoField key={index} header={tag.key} value={tag.value} />;
          })}
        </RPDF.View>
        <RPDF.View style={localStyles.fieldContainer}>
          <InfoField header={t('PageCount')} value={pages} />
          <InfoField header={t('FilledSurveysCount')} value={filledSurveysCount.toString()} />
          <InfoField header={t('MissedSurveysCount')} value={missedSurveysCount.toString()} />
        </RPDF.View>
      </>
    );
  };

  const SignComponent = () => (
    <ReportSignAndTitleField renderDateField={false} customText={t('Signature', { name: profileFullName })} />
  );

  return <ReportFirstPage headerText={headerText} HeaderComponent={Header} SignTitleComponent={SignComponent} />;
};
