import React, { ErrorInfo, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { InformationPageContainer } from 'src/components/informationPageContainer/InformationPage.Container';
import image from 'src/assets/img/common/maintenance.svg';
import { useScopedTranslation } from '../application/localisation/useScopedTranslation';
import { QueryFactory } from '../services/api';
import { convertToErrorString, NetworkError } from '../application/error-handling/useErrorHandler';
import i18n from 'i18next';
import { logger } from '../application/logging/logging';

type ErrorBoundaryFallbackProps = {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
};
export const errorBoundaryFallbackFunction = (props: ErrorBoundaryFallbackProps) => (
  <ErrorBoundaryFallback {...props} />
);

export const ErrorBoundaryFallback = (props: ErrorBoundaryFallbackProps) => {
  const { componentStack, error, eventId, resetError } = props;
  const location = useLocation();
  const localizer = useScopedTranslation('ErrorBoundary');
  const [initialLocation] = useState(location);

  useEffect(() => {
    if (initialLocation !== location) {
      resetError();
    }
  }, [resetError, initialLocation, location]);

  const errorString = convertToErrorString(error);
  const versionQuery = QueryFactory.VersionQuery.useVersionQuery();
  const isServerAvailable = !!versionQuery.data;

  // if error is NetworkError, but server is available, there's a high chance we are trying to request an old chunk of JS or CSS
  // (unfortunately it's impossible to get the error code of Network response and compare it with 404).
  // so we show proper error message and offer to reload the whole page
  const isServerUpdated = errorString === NetworkError && isServerAvailable;

  const onButtonClick = useCallback(() => {
    if (isServerUpdated) {
      document.location.reload();
      return;
    }

    resetError();
  }, [isServerUpdated, resetError]);

  return (
    <>
      <InformationPageContainer
        header={localizer.t('Header')}
        text={isServerUpdated ? localizer.t('Text') : errorString}
        buttonText={localizer.t('Button')}
        buttonAction={onButtonClick}
        img={image}
      />
    </>
  );
};

type ErrorBoundaryState = { hasError: true; errorInfo: ErrorInfo; error: Error } | { hasError: false };

export class ErrorBoundary extends React.Component<PropsWithChildren<any>, ErrorBoundaryState> {
  constructor(props: PropsWithChildren<any>) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger().error(`ErrorBoundary caught an error stack ${errorInfo.componentStack}`, error);
    this.setState({ hasError: true, errorInfo, error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            background: '#fcfcfc',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            position: 'relative',
            overflow: 'auto',
          }}
        >
          <InformationPageContainer
            header={i18n.t('ErrorBoundary.Header')}
            text={this.state?.error?.toString()}
            img={image}
            buttonAction={document.location.reload}
          />
        </div>
      );
    }

    return this.props.children;
  }
}
