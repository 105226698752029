import React, { useCallback, useEffect, useMemo, useState } from 'react';
import syncIcon from '../../assets/img/patient/indicators/sync.svg';
import { QueryFactory } from '../../services/api';
import { useAppSelector } from '../../application/redux-store/store-types';
import { useQueryClient } from '@tanstack/react-query';
import Style from './syncButton.module.css';
import { useSyncInProgress } from '../../features/study/study-selectors';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../uikit/tooltip/tooltip.component';
import clsx from 'clsx';

export const SyncButton = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const studyId = useAppSelector((state) => state.app.studyId);
  const syncingResult = useSyncInProgress(studyId);
  const [invalidated, setInvalidated] = useState<boolean>(false);
  const [isSyncInProgress, setSyncInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (!syncingResult.result && invalidated) {
      queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientsQueryKey()).then(() => {
        setInvalidated(false);
      });

      setSyncInProgress(false);
    }
  }, [invalidated, queryClient, syncingResult]);

  const onRefreshSync = useCallback(async () => {
    if (!studyId) {
      return;
    }

    setSyncInProgress(true);

    QueryFactory.StudyQuery.Client.syncStudyData(studyId).then(async () => {
      await Promise.all([
        queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientsQueryKey()),
        queryClient.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey()),
      ]);
      setInvalidated(true);
    });
  }, [queryClient, studyId]);

  const memorizedContent = useMemo(() => {
    return (
      <Tooltip text={syncingResult.date} headerText={t('Dashboard.PatientTable.SyncTooltip')}>
        <button
          onClick={onRefreshSync}
          className={Style.syncButton}
          disabled={isSyncInProgress || syncingResult.result}
        >
          <img
            alt={'Sync Button Icon'}
            src={syncIcon}
            className={clsx({ [Style.syncImageProgress]: isSyncInProgress || syncingResult.result })}
          />
        </button>
      </Tooltip>
    );
  }, [syncingResult.date, syncingResult.result, t, onRefreshSync, isSyncInProgress]);

  return <>{memorizedContent}</>;
};
