﻿import React, { FC, useCallback } from 'react';
import { IAdminStudyDto } from '../../../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import { useCommonLocalization } from '../../../../application/localisation/useCommonLocalization';
import { useQueryClient } from '@tanstack/react-query';
import { useModal } from '../../../../application/hooks/useModal';
import { QueryFactory } from '../../../../services/api';
import Style from '../studyList.module.css';
import { ExpandedRowHeader } from '../../../../components/expandedRow/header/expandedRowHeader.component';
import { AppButton, SmallButton } from 'uikit/buttons';
import { CommonStyles } from 'styles';
import { formatRelative } from 'date-fns';
import { getCurrentDateLocale } from '../../../../application/localisation/localization';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { EditStudySyncSettingsModalContent } from '../EditStudySyncSettingsModalContent';
import { ReactComponent as SyncIcon } from 'src/assets/img/patient/indicators/sync.svg';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';

export const StudySettingsSection: FC<{ study: IAdminStudyDto }> = ({ study }) => {
  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const queryClient = useQueryClient();
  const settingsModalManager = useModal();

  const syncStudyData = useCallback(async () => {
    if (study.isSyncInProgress) return;
    await QueryFactory.StudyQuery.Client.syncStudyData(study.id);
    await queryClient.invalidateQueries(QueryFactory.StudyQuery.getStudyListQueryKey());
  }, [study.id, study.isSyncInProgress, queryClient]);

  return (
    <div className={Style.section}>
      <ExpandedRowHeader
        text={t('Admin.StudyList.Settings.Caption')}
        rightHeader={
          <AppButton
            onClick={() => settingsModalManager.openModal()}
            variant={'icon-link'}
            colorSchema={'primary'}
            text={t('Admin.StudyList.Settings.Action')}
            Icon={EditIcon}
          />
        }
      />
      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Settings.PrismaUrl')}</div>
        <div className={CommonStyles.tableItemRight}>{study.prismaUrl}</div>
      </div>

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Settings.PrismaToken')}</div>
        <div className={CommonStyles.tableItemRight}>
          {study.isTokenSet ? '**********************' : commonLocalizer('Common_Never')}
        </div>
      </div>

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Settings.IsTokenActive')}</div>
        <div className={CommonStyles.tableItemRight}>{JSON.stringify(study.isTokenActive)}</div>
      </div>

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Settings.IsSync')}</div>
        <div className={CommonStyles.tableItemRight}>
          {JSON.stringify(study.isSyncInProgress)}
          {!study.isSyncInProgress && study.isTokenActive && (
            <div className={Style.syncButton}>
              <SmallButton onPress={syncStudyData} Icon={SyncIcon} />
            </div>
          )}
        </div>
      </div>

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('Admin.StudyList.Settings.LastSyncAt')}</div>
        <div className={CommonStyles.tableItemRight}>
          {study.lastSuccessSyncDate
            ? formatRelative(study.lastSuccessSyncDate, Date.now(), getCurrentDateLocale())
            : commonLocalizer('Common_Never')}
        </div>
      </div>

      <AppModalContainer
        visible={settingsModalManager.visible}
        onHide={settingsModalManager.closeModal}
        title={t('Admin.StudyList.Settings.Modal.Header')}
      >
        <EditStudySyncSettingsModalContent {...study} onClose={settingsModalManager.closeModal} />
      </AppModalContainer>
    </div>
  );
};
