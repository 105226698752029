import { useAuth } from './auth-interceptor';
import { useCallback, useMemo } from 'react';

export enum UserRoles {
  Doctor = 'Doctor',
  StudyAdmin = 'StudyAdmin',
  Admin = 'Admin',
  Monitor = 'Monitor',
  Examiner = 'Examiner',
}

export const UserRolesArr = [
  UserRoles.Doctor,
  UserRoles.StudyAdmin,
  UserRoles.Admin,
  UserRoles.Monitor,
  UserRoles.Examiner,
];

export const useUserRoles = (): string[] => {
  const authState = useAuth();
  return useMemo(() => {
    if (!authState) return [];

    const roleClaim = JSON.parse(window.atob(authState.access_token.split('.')[1])).role;
    return [].concat(roleClaim);
  }, [authState]);
};

export const useUserId = (): string => {
  const authState = useAuth();
  return useMemo(() => {
    if (!authState) return '';

    const parsedRoles: string | undefined = JSON.parse(window.atob(authState.access_token.split('.')[1]))?.id;
    return parsedRoles ? parsedRoles.toString() : '';
  }, [authState]);
};

export enum Permissions {
  ViewPatient,
  EditPatient,
  ImportDb,
  AdministerApp,
  AdministerStudy,
  ManageIssues,
  ProcessIssues,
  IssueDiscussion,
  StudyAccess,
  SignExport,
  GetIssues,
}

const PermissionsIndex: Record<Permissions, UserRoles[]> = {
  [Permissions.ViewPatient]: [UserRoles.Doctor, UserRoles.Monitor],
  [Permissions.EditPatient]: [UserRoles.Doctor],
  [Permissions.ImportDb]: [UserRoles.Doctor, UserRoles.StudyAdmin],
  [Permissions.AdministerApp]: [UserRoles.Admin],
  [Permissions.AdministerStudy]: [UserRoles.StudyAdmin],
  [Permissions.ManageIssues]: [UserRoles.Monitor],
  [Permissions.ProcessIssues]: [UserRoles.Examiner],
  [Permissions.IssueDiscussion]: [UserRoles.Examiner, UserRoles.Monitor],
  [Permissions.StudyAccess]: [UserRoles.StudyAdmin, UserRoles.Doctor, UserRoles.Monitor],
  [Permissions.SignExport]: [UserRoles.Examiner],
  [Permissions.GetIssues]: [UserRoles.Doctor, UserRoles.Monitor, UserRoles.Examiner],
};

const hasPermission = (permission: Permissions, roles: UserRoles[]): boolean => {
  return PermissionsIndex[permission].some((x) => roles.includes(x));
};

export const useHasPermissions = () => {
  const roles = useUserRoles();

  return useCallback(
    (...permissions: (Permissions | Permissions[])[]) => {
      return permissions.flat().every((permission) => {
        return hasPermission(permission, roles as UserRoles[]);
      });
    },
    [roles],
  );
};
