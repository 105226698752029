import React, { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { ITagInput } from '../../../../forms/base/controls/inputs/TagInputProps';
import { RPDF } from '../../../react-pdf';
import { RecordsReportContext } from '../../recordsReport.context';

export const TagInput: ITagInput = ({ label, dataKey }) => {
  const inputValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);
  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  return (
    <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
      <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? dataKey}</RPDF.Text>
      <RPDF.Text style={RPDF.controlStyles.answerCell}>{multipleInputValue?.join(', ')}</RPDF.Text>
    </RPDF.View>
  );
};
