import React, { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import { TypographyStyles } from 'src/styles';
import clsx from 'clsx';
import { useContextSelector } from 'use-context-selector';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import { UIEditorBlockWidth } from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import styles from '../controlsStyle.module.css';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { IText, ITextProps } from '../../../../features/forms/base/controls/content/Text';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';

export const Text: IText = (props) => {
  const { t } = useTranslation();
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const isSubmitting = useContextSelector(FormFillContext, (x) => x.isSubmitting);

  const { size, text, isHiddenOnOverview } = {
    ...TextDefaultProps,
    ...props,
  };

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');

  useEffect(() => {
    setProp((itemProps: ITextProps) => {
      if (text.length > 0) {
        itemProps.text = text;
      } else {
        itemProps.text = t('Forms.Controls.emptyTitle');
      }
    });
  }, [setProp, text]);

  const notEditableText = isHiddenOnOverview ? (
    <></>
  ) : (
    <div className={clsx(styles.previewRow, 'preview-row')}>
      <span className={clsx(TypographyStyles.plainText14, styles.textOverview, { [styles.bold]: props.isBold })}>
        {text}
      </span>
    </div>
  );

  const editableText = (
    <div
      className={clsx({ [styles.container]: true, [styles.selected]: selected && enabled })}
      style={{
        width: size,
      }}
      draggable={enabled}
      ref={(ref) => enabled && connect(drag(ref!))}
    >
      <div
        aria-disabled={isSubmitting}
        className={clsx(TypographyStyles.plainText14, styles.textContainer, { [styles.bold]: props.isBold })}
      >
        {text}
      </div>
    </div>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

export const TextSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ITextProps,
  }));

  return (
    <>
      <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
        <ControlsGroup flexible>
          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.settings.hideOnOverview')}
            isChecked={!!props.isHiddenOnOverview}
            setValue={(newValue) => {
              setProp((itemProps: ITextProps) => {
                itemProps.isHiddenOnOverview = newValue ? newValue : undefined;
              });
            }}
          />

          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.settings.isBold')}
            isChecked={!!props.isBold}
            setValue={(newValue) =>
              setProp((setProps: ITextProps) => {
                setProps.isBold = newValue ? newValue : undefined;
              })
            }
          />
        </ControlsGroup>

        <ControlsGroup flexible header={t('StudySettingsPage.FormBuilder.settings.text')}>
          <SettingsInput
            multiple
            type={'text-area'}
            value={props.text}
            onChange={(e) => {
              setProp((setProps: ITextProps) => {
                setProps.text = e.target?.value;
              });
            }}
          />
        </ControlsGroup>
      </PanelContainer>

      <LayoutSettings />
    </>
  );
};

export const TextDefaultProps: ITextProps = {
  text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi, quos ipsam quas consequuntur placeat, quasi vitae ipsa provident dolor dolores deserunt maxime assumenda sed, culpa soluta odio! Laborum, aut maiores!',
  size: UIEditorBlockWidth.Quarter,
};

Text.craft = {
  defaultProps: TextDefaultProps,
  related: {
    settings: TextSettings,
  },
};
