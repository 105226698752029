import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as PrismaCheck } from '../assets/img/notification/prisma_check_20.svg';
import { ReactComponent as ReportFilled } from '../assets/img/notification/report_filled_20.svg';
import { ReactComponent as NewComment } from '../assets/img/notification/new_comment_20.svg';
import { ReactComponent as IssueDeadline } from '../assets/img/notification/issue_notification_20.svg';
import { ReactComponent as FormDeadline } from '../assets/img/notification/form_notification_20.svg';
import { NotificationBell } from 'src/components/notifications/bell/NotificationBell';
import { NotificationType } from 'src/services/api/api-client';
import { useTranslation } from 'react-i18next';
import { LocalizedResourceDictionaryKeys } from '../application/localisation/i18next';
import { useAppSelector } from '../application/redux-store/store-types';
import { showNotificationToast } from '../components/toast/toast-helper';
import { useGetNotificationBatchesQuery } from '../services/api/api-client/NotificationQuery';
import { NotificationBatchToastMessage } from './NotificationBatchToastMessage';
import { useFormEditingAndOverview } from '../features/forms/useFormEditingAndOverview';
import { getPatientRouteProgress } from '../services/api/api-client/StudyRoutesClient';

const icon: { [key in NotificationType]: any } = {
  [NotificationType.Other]: undefined,
  [NotificationType.PrismaCloudCheck]: ReportFilled,
  [NotificationType.PrismaCheck]: PrismaCheck,
  [NotificationType.NewSurveyAssigned]: undefined,
  [NotificationType.SurveyIsExpired]: undefined,
  [NotificationType.NewIssueComment]: NewComment,
  [NotificationType.IssueDeadline]: IssueDeadline,
  [NotificationType.FormDeadline]: FormDeadline,
};

type NotificationsContextValue = {
  openForm: (args: {
    patientId: string;
    patientUniqueId: string;
    formId: number;
    formType: string;
    stepName: string;
  }) => void;
};

export const notificationsContext = React.createContext<NotificationsContextValue>({
  openForm: () => {},
});

export const NotificationProvider: FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const { studyId } = useAppSelector((state) => state.app);

  const batchesQuery = useGetNotificationBatchesQuery(
    {
      studyIds: [studyId!],
      isRead: false,
    },
    {
      suspense: false,
      enabled: !!studyId,
    },
  );

  const displayedToasts = useRef<{ toastId: React.ReactText; sourceNotificationIds: Set<number> }[]>([]);

  //#region Context value

  const formEditingAndFilling = useFormEditingAndOverview();

  const contextValue: NotificationsContextValue = {
    openForm: async (args) => {
      const routeProgress = await getPatientRouteProgress(args.patientId);
      const formResultId = routeProgress.steps
        .find((step) => step.name === args.stepName)!
        .forms?.find((x) => x.formType === args.formType)?.formResultId;

      if (formResultId) {
        formEditingAndFilling.openOverview({
          patientId: args.patientId,
          formId: args.formId,
          formResultId: formResultId,
        });
      } else {
        formEditingAndFilling.startFilling({
          routeProgress,
          formId: args.formId,
          patientId: args.patientId,
          patientUniqueId: args.patientUniqueId,
          stepName: args.stepName,
        });
      }
    },
  };

  //#endregion

  useEffect(() => {
    const batches = batchesQuery.data ?? [];

    const setsAreEqual = (set1: Set<number>, set2: Set<number>) => {
      for (const item1 of set1) {
        if (!set2.has(item1)) return false;
      }
      return true;
    };

    const sourceIdSets = batches.map((x, i) => ({
      batchIndex: i,
      set: new Set(x.sourceNotificationIds),
    }));

    //#region Dismiss toasts

    const toastsToDismiss = displayedToasts.current.filter(
      (x) => !sourceIdSets.some((y) => setsAreEqual(x.sourceNotificationIds, y.set)),
    );

    for (const toastToDismiss of toastsToDismiss) {
      toast.dismiss(toastToDismiss.toastId);
    }

    displayedToasts.current = displayedToasts.current.filter(
      (x) => !toastsToDismiss.some((y) => x.toastId === y.toastId),
    );

    //#endregion

    //#region Show toasts

    const newBatches = sourceIdSets
      .filter((x) => !displayedToasts.current.some((y) => setsAreEqual(x.set, y.sourceNotificationIds)))
      .map((x) => ({ index: x.batchIndex, batch: batches[x.batchIndex] }));

    for (const item of newBatches) {
      const batch = item.batch;
      const batchIndex = item.index;
      const type = NotificationType[batch.type] as keyof typeof NotificationType;

      const toastId = showNotificationToast(
        <NotificationBatchToastMessage batch={batch} />,
        t(`Notifications.Title.${type}` as LocalizedResourceDictionaryKeys, {
          count: batch.sourceNotificationIds.length,
        }),
        icon[batch.type],
      );

      displayedToasts.current.push({ toastId: toastId, sourceNotificationIds: sourceIdSets[batchIndex].set });
    }

    //#endregion
  }, [t, batchesQuery.data, formEditingAndFilling.startFilling]);

  return (
    <notificationsContext.Provider value={contextValue}>
      <NotificationBell />
      {props.children}
      {formEditingAndFilling.element}
    </notificationsContext.Provider>
  );
};
