import { createContext } from 'use-context-selector';
import { IGroupDto } from 'src/services/api/api-client';
import { SerializedNodeWithId } from './recordReport-helper';

export type RecordsReportContextType = {
  formResult: { [key: string]: any };
  studyGroups: IGroupDto[];
  nodes: SerializedNodeWithId[];
};

export const RecordsReportContext = createContext<RecordsReportContextType>(undefined as any);
