import React from 'react';
import { FormResultDto } from '../../../services/api/api-client';

export type NoteContextType = {
  formResult: FormResultDto;
  mode: 'overview' | 'section';
  noteDateDataKey: string;
  patientUniqueId: string;
};
export const NoteContext = React.createContext<NoteContextType>(undefined as any);
