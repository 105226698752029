import { ITextInput } from '../../../../../features/forms/base/controls/inputs/TextInput';
import React, { useContext } from 'react';
import { NoteContext } from '../../NoteContext';
import { NoteField } from '../../NoteField';

export const TextInput: ITextInput = (props) => {
  const noteContext = useContext(NoteContext);
  const inputValue = noteContext.formResult.result[props.dataKey] as string | string[] | undefined;
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  if (!singleInputValue) return <></>;

  return <NoteField label={props.label ?? props.dataKey} value={`${singleInputValue} ${props.units ?? ''}`} />;
};
