import { useEditor, useNode, Element } from '@craftjs/core';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { radioInputDefaultPropsFactory } from 'src/features/forms/base/controls/inputs/RadioInput';
import {
  ISliderInput,
  ISliderInputProps,
  sliderInputApplicableValidationRules,
} from 'src/features/forms/base/controls/inputs/SliderInput';
import { AppSliderField } from 'uikit/fields';
import { useContextSelector } from 'use-context-selector';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import {
  useFieldDataFromUiEditorContext,
  useFieldValidation,
  useRefForDataBlockNavigation,
  useSetDefaultValueToAnswer,
} from './base/hooks';
import styles from '../controlsStyle.module.css';
import { TypographyStyles } from 'styles';
import {
  ConditionalFieldBehaviorEnum,
  _isSkipped,
} from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { useTranslation } from 'react-i18next';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs';
import { enumToDropdownOptions } from 'uikit/inputs/dropdown/dropdown-helper';
import { SliderColorSchemeEnum } from 'uikit/inputs/slider/appSliderInput';
import { Container } from '../Containers/Container';
import OptionsSettingsSection from '../../toolbox/settingsPanel/content/OptionsSettingsSection';
import { IFormFieldOption } from 'src/features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';

export const SliderInput: ISliderInput = (props) => {
  const { size, dataKey, hasSkipCheckBox, isDisableWhenEditing, isDisabled, minValue, maxValue } = props;
  const { t } = useTranslation();
  const { enabled: isConstructorMode, builderMode } = useEditor((state) => ({
    enabled: state.options.enabled,
    builderMode: state.options.enabled,
  }));

  const {
    connectors: { connect, drag },
    actions: { setProp: setProps },
    id,
  } = useNode();

  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);

  const { isOnlyView, isEditMode, singleInputValue, setDataBlockFieldValue, markFieldAsTouched } =
    useFieldDataFromUiEditorContext(dataKey);

  useSetDefaultValueToAnswer(dataKey, props.defaultValue);
  const blockRef = useRefForDataBlockNavigation();
  const [initialValue] = useState(singleInputValue);
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const isDisabledComponent = useMemo(
    () => !props.isEditable || (isDisableWhenEditing && isEditMode && !!initialValue) || isDisabled || isSubmitting,
    [initialValue, isDisableWhenEditing, isDisabled, isEditMode, isSubmitting, props.isEditable],
  );

  const currentValue = useMemo(() => {
    if (isConstructorMode) {
      return isNullOrEmpty(props.defaultValue as string | undefined) ? undefined : Number(props.defaultValue);
    }

    return isNullOrEmpty(singleInputValue) ? undefined : Number(singleInputValue);
  }, [isConstructorMode, props.defaultValue, singleInputValue]);

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = props.options.filter((x) => x.withConditionalField);

    const finalArray = builderMode
      ? optionsWithConditionalField
      : optionsWithConditionalField.filter((option) => {
          return (
            option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
            currentValue === Number.parseFloat(option.displayValue)
          );
        }, true);

    return Array.from(finalArray, (option) => (
      <React.Fragment key={option.key}>
        {builderMode && <div className={styles.tabLabel}>{option.displayValue ?? ''}</div>}
        <Element key={option.value} id={option.key.toString()} is={Container} canvas={true} />
      </React.Fragment>
    ));
  }, [props.options, builderMode, currentValue]);

  const editableComponent = (
    <div
      data-test-field-type={'scale-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef}>
        <AppSliderField
          showMarks={true}
          startWith={minValue}
          endWith={maxValue}
          caption={{
            startText: props.captionStartText,
            endText: props.captionEndText,
          }}
          labelProps={{
            text: props.hideLabel ? undefined : props.label || dataKey,
            isBold: props.isBold,
            tooltip: props.tooltip,
          }}
          disabled={isDisabledComponent}
          errorProps={{
            errors: dataBlockValidation?.text,
          }}
          value={currentValue}
          colorScheme={props.colorScheme ?? SliderColorSchemeEnum.SingleColor}
          onChange={(v) => {
            markFieldAsTouched?.(id);
            setDataBlockFieldValue?.(dataKey, v.toString());
            if (isConstructorMode) {
              setProps((p: ISliderInputProps) => {
                p.defaultValue = v.toString();
              });
            }
          }}
          skipCheckboxProps={{
            hasSkipCheckBox: hasSkipCheckBox,
            skipText: props.skipCheckBoxText,
            isSkipped: singleInputValue === _isSkipped,
            onSkippedChange: (val) => {
              markFieldAsTouched?.(id);
              setDataBlockFieldValue?.(dataKey, val ? _isSkipped : undefined);
            },
          }}
        />
        {conditionalContainer}
      </div>
    </div>
  );

  const labelOnOverview = useMemo(() => {
    const leftText = isNullOrEmpty(props.captionStartText) ? undefined : `${props.minValue}: ${props.captionStartText}`;
    const rightText = isNullOrEmpty(props.captionEndText) ? undefined : `${props.maxValue}: ${props.captionEndText}`;
    const caption =
      [leftText, rightText].filter(Boolean).length > 0
        ? ` (${[leftText, rightText].filter(Boolean).join(', ')})`
        : undefined;

    return (
      <span>
        <span className={TypographyStyles.heading2}>{props.label ?? props.dataKey}</span>
        {caption && <span className={TypographyStyles.plainText14}>{caption}</span>}
      </span>
    );
  }, [props.captionEndText, props.captionStartText, props.dataKey, props.label, props.maxValue, props.minValue]);

  const notEditableComponent = (
    <>
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Records',
          topicAdditional: formConfig?.type,
          linkedPatientUniqId: patient?.patientId,
          fieldDescription: props.label ?? props.dataKey,
          resultId: formResult?.id ?? undefined,
          fieldId: id,
          stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
        }}
        ignoreFieldsForCount={['stepName']}
        position={{ right: 24, top: 6 }}
      >
        <div className={clsx(styles.previewRow, 'preview-row')}>
          <div>{labelOnOverview}</div>
          {singleInputValue &&
            (singleInputValue === _isSkipped ? (
              <span className={clsx(TypographyStyles.plainText14, styles.textPlain)}>
                {props.skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox')}
              </span>
            ) : (
              <span className={TypographyStyles.plainText14}>{singleInputValue}</span>
            ))}
        </div>
      </IssueMark>
      {conditionalContainer.length > 0 && conditionalContainer}
    </>
  );

  return <>{!isOnlyView ? editableComponent : notEditableComponent}</>;
};

const SliderInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ISliderInputProps,
  }));

  const options: IFormFieldOption[] = useMemo(
    () =>
      Array.from({ length: props.maxValue - props.minValue + 1 }, (_, i) => props.minValue + i).map(
        (x) =>
          ({
            ...(props.options.find((option) => option.key === x) ?? {}),
            key: x,
            value: x.toString(),
            displayValue: x.toString(),
          } satisfies IFormFieldOption),
      ),
    [props.maxValue, props.minValue, props.options],
  );

  // NOTE: This state allows to erase the "MinValue" field completely
  const [minValue, setMinValue] = useState<number | undefined>(props.minValue);
  useEffect(() => {
    if (minValue === undefined) return;
    setProp((setProps: ISliderInputProps) => {
      setProps.minValue = Math.floor(minValue);
    }, 500);
  }, [minValue, options, setProp]);

  // NOTE: This state allows to erase the "MaxValue" field completely
  const [maxValue, setMaxValue] = useState<number | undefined>(props.maxValue);
  useEffect(() => {
    if (maxValue === undefined) return;
    setProp((setProps: ISliderInputProps) => {
      setProps.maxValue = Math.floor(maxValue);
    }, 500);
  }, [maxValue, options, setProp]);

  useEffect(() => {
    setProp((setProps: ISliderInputProps) => {
      setProps.options = options;
    }, 500);
  }, [options, setProp]);

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.slider.minValue')} flexible>
        <SettingsInput
          type={'number'}
          value={minValue}
          onChange={(e) => {
            setMinValue(isNullOrEmpty(e.target.value) ? undefined : +e.target.value);
          }}
          onBlur={() => setMinValue(props.minValue)}
        />
      </ControlsGroup>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.slider.maxValue')} flexible>
        <SettingsInput
          type={'number'}
          value={maxValue}
          onChange={(e) => {
            setMaxValue(isNullOrEmpty(e.target.value) ? undefined : +e.target.value);
          }}
          onBlur={() => setMaxValue(props.maxValue)}
        />
      </ControlsGroup>
      <ControlsGroup
        flexible
        collapsible
        expanded={!!props.captionStartText}
        header={t('StudySettingsPage.FormBuilder.settings.slider.captionStartText')}
        onOpen={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionStartText = 'Left text';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionStartText = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          value={props.captionStartText}
          onChange={(e) => {
            setProp((setProps: ISliderInputProps) => {
              setProps.captionStartText = e.target.value;
            }, 500);
          }}
        />
      </ControlsGroup>
      <ControlsGroup
        flexible
        collapsible
        expanded={!!props.captionEndText}
        header={t('StudySettingsPage.FormBuilder.settings.slider.captionEndText')}
        onOpen={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionEndText = 'Right text';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionEndText = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          value={props.captionEndText}
          onChange={(e) => {
            setProp((setProps: ISliderInputProps) => {
              setProps.captionEndText = e.target.value;
            }, 500);
          }}
        />
      </ControlsGroup>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.slider.colorScheme')} flexible>
        <AppDropDownWithSuggestionInput
          isRequired={true}
          options={enumToDropdownOptions(SliderColorSchemeEnum)}
          value={
            enumToDropdownOptions(SliderColorSchemeEnum).find(
              (x) => x.key === SliderColorSchemeEnum[props.colorScheme],
            ) ??
            enumToDropdownOptions(SliderColorSchemeEnum).find(
              (x) => x.key === SliderColorSchemeEnum.SingleColor.toString(),
            )
          }
          onChange={(x) => {
            setProp((setProps: ISliderInputProps) => {
              setProps.colorScheme = SliderColorSchemeEnum[x!.key];
            });
          }}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const SliderInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <SliderInputGeneralSettings />
      <OptionsSettingsSection />
      <LayoutSettings />
      <ValidationSettings applicableRules={sliderInputApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

SliderInput.craft = {
  props: radioInputDefaultPropsFactory(),
  related: {
    settings: SliderInputSettings,
  },
};
