import React, { useCallback } from 'react';
import { AnswerStatisticsListItem, QualityMetric, ScoreCalculationStatusEnum } from '../../../services/api/api-client';
import { RPDF } from '../react-pdf';
import { ReportHeader } from '../components/ReportHeader';
import { ReportPagination } from '../components/ReportPagination';
import { SurveyReportOptionItem } from './surveyReport';
import { SurveyAnswerRow } from './components/SurveyReportAnswerRow';
import { SurveyInfoSection, SurveyLegendSection, SurveyScoreSection } from './components/surveySections';
import { questionSchemaToArray } from '../../../helpers/question-helper';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { SurveyReportSingleAnswerRow } from './components/SurveyReportSingleAnswerRow';
import { ScoreProblemLegendSection } from '../components/ReportScoreProblemLegend';

export const SurveyContentPage = (props: { allSurveys: SurveyReportOptionItem[]; headerText: string }) => {
  const { t } = useScopedTranslation('Reports.Surveys');
  const { allSurveys, headerText } = props;

  const questionsList = useCallback((surveyData: SurveyReportOptionItem) => {
    if (!surveyData) {
      return null;
    }

    const questionScheme = surveyData.questionScheme;
    if (!questionScheme) {
      return null;
    }

    return questionScheme.questions ?? null;
  }, []);

  const answerRow = useCallback(
    (answer: AnswerStatisticsListItem, survey: SurveyReportOptionItem) => {
      return questionsList(survey)?.map((q, i) => {
        if (!q) {
          return <React.Fragment key={i} />;
        }
        const schemas = [answer?.rawAnswer || null];

        return <SurveyAnswerRow key={i} index={i + 1} parentQuestion={undefined} question={q} answers={schemas} />;
      });
    },
    [questionsList],
  );

  const singleSurveyAnswerPage = allSurveys.map((survey, surveyIndex) => {
    const filledSurveys = survey.answers.filter((a) => !!a.answerId);
    const questions = questionSchemaToArray(survey.questionScheme);
    const isSingleQuestionSurvey = questions?.length === 1;

    if (filledSurveys.length > 0 && isSingleQuestionSurvey) {
      const questionText = t('SingleQuestionHeader', { questionText: questions[0].questionText });
      const surveyWithErrorScore = filledSurveys.find((x) => x.scoreStatus !== ScoreCalculationStatusEnum.Done);
      return (
        <RPDF.Page size="A4" style={RPDF.styles.page}>
          <ReportHeader captionText={headerText} />
          <RPDF.View style={{ flexDirection: 'column' }}>
            <SurveyInfoSection surveyTitle={survey.surveyTitle} resultDto={filledSurveys} />
            <RPDF.View style={{ marginTop: 12, marginBottom: 12 }}>
              <SurveyLegendSection legend={questionText} />
              {surveyWithErrorScore && (
                <ScoreProblemLegendSection
                  scoreStatus={surveyWithErrorScore.scoreStatus}
                  isLowScore={surveyWithErrorScore?.quality === QualityMetric.NotCalculated}
                  view={'surveys'}
                  style={{ marginTop: 4 }}
                />
              )}
            </RPDF.View>
          </RPDF.View>

          {filledSurveys.map((answer, answerIndex) => {
            return <SurveyReportSingleAnswerRow key={answerIndex} answer={answer} question={questions[0]} />;
          })}

          <ReportPagination />
        </RPDF.Page>
      );
    }

    return filledSurveys.map((answer, answerIndex) => {
      return (
        <RPDF.Page key={`${surveyIndex}-${answerIndex}`} size="A4" style={RPDF.styles.page}>
          <ReportHeader captionText={headerText} />
          <RPDF.View style={{ flexDirection: 'column', marginBottom: 12 }}>
            <SurveyInfoSection surveyTitle={survey.surveyTitle} resultDto={answer} />
            <SurveyScoreSection score={answer.score} scoreStatus={answer.scoreStatus} quality={answer.quality} />
            <RPDF.View style={{ marginTop: 12 }}>
              <SurveyLegendSection legend={survey.questionScheme?.legend} />
              <ScoreProblemLegendSection
                scoreStatus={answer.scoreStatus}
                isLowScore={answer?.quality === QualityMetric.NotCalculated}
                view={'surveys'}
                style={{ marginTop: 4 }}
              />
            </RPDF.View>
          </RPDF.View>

          <RPDF.View>{answerRow(answer, survey)}</RPDF.View>

          <ReportPagination />
        </RPDF.Page>
      );
    });
  });

  return <>{singleSurveyAnswerPage}</>;
};
