import * as AppEvents from './app-events';
import { ActionType, getType } from 'typesafe-actions';
import { ApplicationState, defaultAppState } from './app-state';
import _ from 'lodash';

export type AppActions = ActionType<typeof AppEvents>;
export { AppEvents };

export function appReducer(state: ApplicationState = defaultAppState, action: AppActions): ApplicationState {
  switch (action.type) {
    case getType(AppEvents.selectStudy): {
      return {
        ...state,
        studyId: action.payload?.id,
        studyName: action.payload?.title,
        faqId: action.payload?.faqId,
        studyNumber: action.payload?.studyNumber,
        hasTherapy: action.payload?.hasTherapy,
        hasMonitoring: action.payload?.hasMonitoring,
        hasPdfSigning: action.payload?.hasPdfSigning,
        hasDbImport: action.payload?.hasDbImport,
        isSyncInProgress: action.payload?.isSyncInProgress,
        isTokenActive: action.payload?.isTokenActive,
        groups: _.sortBy(action.payload?.groups || [], (x) => x.priority),
        surveys: action.payload?.surveys ?? [],
        patientCreationFormId: action.payload?.patientCreationFormBinding?.id,
        therapyAggregationPeriod: action.payload?.therapyAggregationPeriod,
      };
    }

    case getType(AppEvents.resetStudy): {
      return {
        ...state,
        studyId: null,
        faqId: null,
        studyNumber: null,
        hasTherapy: false,
        hasMonitoring: false,
        isSyncInProgress: false,
        isTokenActive: false,
        groups: [],
        surveys: [],
        patientCreationFormId: undefined,
      };
    }

    case getType(AppEvents.setTagFilters): {
      return {
        ...state,
        tagFilters: action.payload,
      };
    }

    default:
      return state;
  }
}
