/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import clsx from 'clsx';
import { useContextSelector } from 'use-context-selector';
import styles from '../controlsStyle.module.css';
import { useTranslation } from 'react-i18next';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import {
  containerSizes,
  IContainer,
  IContainerProps,
} from '../../../../features/forms/base/controls/containers/Container';
import { DropdownOption } from '../../../uikit/inputs/dropdown/appDropdownInput';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';

export const Container: IContainer = (props) => {
  const { connectors, actions, childrenIds } = useNode((state) => ({
    childrenIds: state.data.nodes,
  }));

  const {
    actions: { setProp },
  } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { connect, drag } = connectors;

  const { isPadding, children, formSize, overviewSize, isDisabled } = {
    ...ContainerDefaultProps,
    ...props,
  };

  useEffect(() => {
    childrenIds.forEach((id) => {
      setProp(id, (p: any) => (p.isDisabled = isDisabled));
    });
  }, [childrenIds, isDisabled, setProp]);

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  useEffect(() => {
    actions.setProp((itemProps: IContainerProps) => {
      itemProps.formSize = formSize;
      itemProps.overviewSize = overviewSize;
    });
  }, [formSize, overviewSize, actions]);

  const formWidth = useMemo(() => {
    switch (formSize) {
      case 'Small':
        return 460;
      case 'Medium':
        return 760;
      case 'Large':
        return 900;
      default:
        return '100%';
    }
  }, [formSize]);

  const overviewWidth = useMemo(() => {
    switch (overviewSize) {
      case 'Small':
        return 390;
      case 'Medium':
        return 720;
      case 'Large':
        return 1100;
      default:
        return '100%';
    }
  }, [overviewSize]);

  return (
    <div
      className={clsx(styles.containerBlock, {
        [styles.containerBlockPadding]: isPadding && !isOnlyView,
        [styles.previewContainer]: isOnlyView,
        [styles.flexbox]: props.isFlexBox,
      })}
      style={{
        width: !isOnlyView ? formWidth : overviewWidth,
        gap: !isOnlyView && props.flexBoxGap ? props.flexBoxGap : 0,
        paddingTop: !isOnlyView ? props.padding?.top : 0,
        paddingRight: !isOnlyView ? props.padding?.right : 0,
        paddingBottom: !isOnlyView ? props.padding?.bottom : 0,
        paddingLeft: !isOnlyView ? props.padding?.left : 0,
      }}
      draggable={enabled}
      ref={(ref: HTMLDivElement) => enabled && connect(drag(ref))}
      data-border={enabled}
    >
      {children}
    </div>
  );
};

export const ContainerSettings = () => {
  const { t } = useTranslation('dev');
  const { props, actions, id } = useNode((node) => ({
    props: node.data.props as IContainerProps,
    id: node.id,
  }));
  const { setProp } = actions;
  const [formSize, setFormSize] = useState<string>(props.formSize);
  const [overviewSize, setOverviewSize] = useState<string>(props.overviewSize);

  const options: DropdownOption[] = containerSizes.map((x) => {
    return { key: x, text: x } as DropdownOption;
  });

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.container.flexBox')}
          isChecked={!!props.isFlexBox}
          setValue={(newValue) =>
            setProp((itemProps: IContainerProps) => {
              itemProps.isFlexBox = newValue ? newValue : undefined;
            })
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={'Padding - top'}
        expanded={props.padding?.top !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, top: 20 };
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, top: undefined };
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.padding?.top}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.padding = { ...prop.padding, top: e.target?.value ? Number(e.target.value) : undefined };
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={'Padding - right'}
        expanded={props.padding?.right !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, right: 20 };
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, right: undefined };
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.padding?.right}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.padding = { ...prop.padding, right: e.target?.value ? Number(e.target.value) : undefined };
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={'Padding - bottom'}
        expanded={props.padding?.bottom !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, bottom: 20 };
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, bottom: undefined };
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.padding?.bottom}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.padding = { ...prop.padding, bottom: e.target?.value ? Number(e.target.value) : undefined };
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={'Padding - left'}
        expanded={props.padding?.left !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, left: 20 };
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, left: undefined };
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.padding?.left}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.padding = { ...prop.padding, left: e.target?.value ? Number(e.target.value) : undefined };
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={t('StudySettingsPage.FormBuilder.settings.container.flexBoxGap')}
        expanded={props.flexBoxGap !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.flexBoxGap = 20;
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.flexBoxGap = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.flexBoxGap}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.flexBoxGap = e.target?.value ? Number(e.target.value) : undefined;
            }, 500)
          }
        />
      </ControlsGroup>

      {id === 'ROOT' && (
        <>
          <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.container.modalSize')} flexible>
            <AppDropDownWithSuggestionInput
              isRequired
              options={options}
              value={options.find((x) => x.key === formSize)}
              onChange={(x) => {
                setFormSize(x?.key as string);
                // @ts-ignore
                setProp((setProps: IContainerProps) => (setProps.formSize = x?.key || 'Content'));
              }}
            />
          </ControlsGroup>
          <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.container.overviewSize')} flexible>
            <AppDropDownWithSuggestionInput
              isRequired
              options={options}
              value={options.find((x) => x.key === overviewSize)}
              onChange={(x) => {
                setOverviewSize(x?.key as string);
                // @ts-ignore
                setProp((setProps: IContainerProps) => (setProps.overviewSize = x?.key || 'Content'));
              }}
            />
          </ControlsGroup>
        </>
      )}
    </PanelContainer>
  );
};

export const ContainerDefaultProps: IContainerProps = {
  formSize: 'Content',
  overviewSize: 'Content',
};

Container.craft = {
  props: ContainerDefaultProps,
  related: {
    settings: ContainerSettings,
  },
};
