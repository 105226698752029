import React, { useCallback, useState, FC } from 'react';
import { CommonStyles } from '../../../styles';
import Style from './PresetInfoModal.module.scss';
import { IAnalysisPresetDto } from '../../../services/api/api-client';
import { ReactComponent as EditIcon } from '../../../assets/img/common/edit_20.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/common/delete_20.svg';
import { AppButton } from '../../../components/uikit/buttons/button/button.component';
import { DeletingConfirmationDialog } from './DeletingConfirmationDialog';
import { useModal } from '../../../application/hooks/useModal';
import { QueryFactory } from '../../../services/api';
import { useQueryClient } from '@tanstack/react-query';
import { useContextSelector } from 'use-context-selector';
import { AccessDbContext } from '../../../providers/accessDbProvider/accessDb.context';
import clsx from 'clsx';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { AppModalContainer } from '../../../components/uikit/modal/modal.component';
import { Tooltip } from 'uikit/tooltip/tooltip.component';

export const PresetInfoModal: FC<{
  preset: IAnalysisPresetDto | null;
  onEdit: () => void;
  onHide: () => void;
}> = (props) => {
  const { t } = useScopedTranslation('StudySettingsPage.AnalyzesPreset', 'dev');
  return (
    <AppModalContainer
      visible={!!props.preset}
      onHide={props.onHide}
      title={t('PresetInfoModal.Title')}
      bodyClassName={Style.presetInfoModal}
    >
      {props.preset && <ModalContent preset={props.preset} onHide={props.onHide} onEdit={props.onEdit} />}
    </AppModalContainer>
  );
};

const ModalContent: FC<{ preset: IAnalysisPresetDto; onHide: () => void; onEdit: () => void }> = (props) => {
  const { t } = useScopedTranslation('StudySettingsPage.AnalyzesPreset', 'dev');
  const queryClient = useQueryClient();
  const databaseFilename = useContextSelector(AccessDbContext, (x) => x.fileName);

  const vm = {
    name: props.preset.name,
    databaseFilename: props.preset.databaseFilename,
    tableName: props.preset.scheme![0].tableName,
    keyColumn: Object.keys(props.preset.scheme![0].keyColumnsWithValue!)[0],
    dataColumns: props.preset.scheme![0].dataColumns!,
    analysisQuantityLimit: props.preset.analysisQuantityLimit,
  };

  const deletingConfirmationModal = useModal();
  const [isDeleting, setIsDeleting] = useState(false);

  const onCancelDeleting = useCallback(() => {
    deletingConfirmationModal.closeModal();
  }, [deletingConfirmationModal]);

  const onApplyDeleting = useCallback(async () => {
    setIsDeleting(true);
    try {
      await QueryFactory.AnalyzesQuery.Client.deleteAnalysisPreset(props.preset.id);
    } catch (e: any) {
      alert(e.detail);
    } finally {
      setIsDeleting(false);
      await queryClient.invalidateQueries(QueryFactory.AnalyzesQuery.getAllAnalysisPresetsForStudyQueryKey());
      deletingConfirmationModal.closeModal();
      props.onHide();
    }
  }, [deletingConfirmationModal, props, queryClient]);

  return (
    <>
      <div className={Style.actions}>
        <Tooltip text={databaseFilename !== vm.databaseFilename ? t('PresetInfoModal.DbConnectionRequirement') : ''}>
          <AppButton
            disabled={databaseFilename !== vm.databaseFilename}
            onClick={props.onEdit}
            variant={'icon-link'}
            colorSchema={'secondary'}
            Icon={EditIcon}
          />
        </Tooltip>

        <AppButton
          onClick={() => deletingConfirmationModal.openModal()}
          variant={'icon-link'}
          colorSchema={'decline'}
          Icon={DeleteIcon}
        />
      </div>

      <div className={Style.dataGrid}>
        <div className={CommonStyles.tableRow}>
          <div className={CommonStyles.tableItemLeft}>{t('PresetNameLabel')}</div>
          <div className={CommonStyles.tableItemRight}>{vm.name}</div>
        </div>
        <div className={CommonStyles.tableRow}>
          <div className={CommonStyles.tableItemLeft}>{t('DbFileNameLabel')}</div>
          <div className={CommonStyles.tableItemRight}>{vm.databaseFilename}</div>
        </div>
        <div className={CommonStyles.tableRow}>
          <div className={CommonStyles.tableItemLeft}>{t('TableNameLabel')}</div>
          <div className={CommonStyles.tableItemRight}>{vm.tableName}</div>
        </div>
        <div className={CommonStyles.tableRow}>
          <div className={CommonStyles.tableItemLeft}>{t('KeyColumnLabel')}</div>
          <div className={CommonStyles.tableItemRight}>{vm.keyColumn}</div>
        </div>
        <div className={CommonStyles.tableRow}>
          <div className={CommonStyles.tableItemLeft}>{t('ColumnsWithDataLabel')}</div>
          <div className={clsx(CommonStyles.tableItemRight, 'overflow-hidden')}>
            <ul className={CommonStyles.list}>
              {vm.dataColumns.map((x, i) => (
                <li key={i}>{x}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={CommonStyles.tableRow}>
          <div className={CommonStyles.tableItemLeft}>{t('PresetQuantityLimitLabel')}</div>
          <div className={CommonStyles.tableItemRight}>{vm.analysisQuantityLimit}</div>
        </div>
      </div>

      <DeletingConfirmationDialog
        onCancel={onCancelDeleting}
        onApply={onApplyDeleting}
        isLoading={isDeleting}
        isVisible={deletingConfirmationModal.visible}
      />
    </>
  );
};
