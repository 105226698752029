/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import _ from 'lodash';
import React, { useMemo, useState, FC } from 'react';
import clsx from 'clsx';
import { DirectionalHint } from '@fluentui/react';
import { DropDownMenu } from '../../uikit/dropDownMenu/dropDownMenu.component';
import {
  defaultGroupedIssueQueryParams,
  defaultIssueQueryParams,
  IssueFilterProps,
  IssueQueryParamsType,
  issueSubjects,
  sortingOptions,
  statusOptions,
} from './issueFilters-helper';
import { IssuesStateEnum } from '../../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import { DropDownMenuOption } from '../../uikit/dropDownMenu/dropDownMenu';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { TypographyStyles } from '../../../styles';
import Style from './issueFilters.module.css';
import { ReactComponent as Dots } from '../../../assets/img/common/dots_24.svg';
import { ReactComponent as Table } from '../../../assets/img/common/table_16.svg';
import { ReactComponent as Export } from '../../../assets/img/common/export_16.svg';
import { ReactComponent as ExportBig } from '../../../assets/img/common/export_16.svg';
import { ReactComponent as Reset } from '../../../assets/img/issues/reset_16.svg';
import { ReactComponent as Check } from '../../../assets/img/issues/check_16.svg';
import { ReactComponent as CheckboxUnchecked } from '../../../assets/img/issues/checkbox_unchecked_16.svg';
import { ReactComponent as CheckboxChecked } from '../../../assets/img/issues/checkbox_checked_16.svg';
import { ReactComponent as Filter } from '../../../assets/img/common/filter_20.svg';
import { useContextSelector } from 'use-context-selector';
import { IssuesContext } from '../provider/issues.context';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { AppInputLabel } from '../../uikit/wrappers/label/appInputLabel.component';
import { AppTextInput } from '../../uikit/inputs/text/appTextInput.component';
import { AppCheckboxInput } from '../../uikit/inputs/checkbox/appCheckboxInput.component';
import { useHasPermissions, Permissions } from '../../../helpers/auth/auth-helper';

export const IssueFilters: FC<IssueFilterProps> = (props) => {
  const { type, onChange, queryParams } = props;
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const { groups } = useAppSelector((state) => state.app);
  const hasPermissions = useHasPermissions();

  const { openIssueTable, generateReport } = useContextSelector(IssuesContext, (x) => x);

  const statuses = useMemo(() => {
    return statusOptions.map((option) => {
      const { icon: Icon } = option;
      return {
        key: option.key,
        icon: Icon && <Icon />,
        text: t(option.localizationKey),
        action: () => onChange({ state: IssuesStateEnum[option.key] }),
        className: Style.commonOption,
      } as DropDownMenuOption;
    });
  }, [onChange, t]);

  const subjects = useMemo(() => {
    const paramsHasKey = (key: string) => queryParams.subjectList?.includes(key);

    const action = (key: string) => {
      if (paramsHasKey(key)) {
        onChange({ subjectList: [...queryParams.subjectList!.filter((x) => x !== key)] });
      } else {
        onChange({ subjectList: [...(queryParams.subjectList ?? []), key] });
      }
    };

    const options = issueSubjects.map((subject) => {
      return {
        key: subject,
        icon: paramsHasKey(subject) ? <CheckboxChecked /> : <CheckboxUnchecked />,
        text: t(`Issues.Subject.${subject}`),
        action: () => action(subject),
        className: clsx(Style.commonOption, Style.fillNone),
      } as DropDownMenuOption;
    });

    options.push({
      key: 'reset',
      icon: <Reset />,
      text: t('Issues.Topic.Reset'),
      action: () => onChange({ subjectList: undefined }),
      className: clsx(Style.resetOption, Style.resetTopicOption),
    });

    return options;
  }, [onChange, queryParams.subjectList, t]);

  const groupOptions = useMemo(() => {
    const paramsHasKey = (key: number) => queryParams.patientGroupList?.includes(key);

    const action = (id: number) => {
      if (paramsHasKey(id)) {
        onChange({ patientGroupList: [...queryParams.patientGroupList!.filter((x) => x !== id)] });
      } else {
        onChange({ patientGroupList: [...(queryParams.patientGroupList ?? []), id] });
      }
    };

    const groupList = groups.map((g) => {
      return {
        key: g.id,
        icon: paramsHasKey(g.id) ? <CheckboxChecked /> : <CheckboxUnchecked />,
        text: g.title,
        action: () => action(g.id),
        className: clsx(Style.commonOption, Style.fillNone),
      } as DropDownMenuOption;
    });

    groupList.push({
      key: 'reset',
      icon: <Reset />,
      text: t('Common_Reset'),
      action: () => onChange({ patientGroupList: undefined }),
      className: clsx(Style.resetOption, Style.resetTopicOption),
    });

    return groupList;
  }, [groups, onChange, queryParams.patientGroupList, t]);

  const applyFilterCount = useMemo(() => {
    const subjectFilterCount = +!!queryParams.subjectList?.length;
    const stateFilter = +!!queryParams.state;
    const patientFilter = +!!queryParams.patientUid;
    const unreadOnlyFilter = +!!queryParams.unreadOnly;
    const groupFilter = +!!queryParams.patientGroupList?.length;

    return subjectFilterCount + stateFilter + patientFilter + unreadOnlyFilter + groupFilter;
  }, [queryParams]);

  const modalMenuOptions = useMemo(() => {
    const options = [
      {
        key: 'exportAll',
        icon: <Export />,
        text: t('Issues.Export.All'),
        action: () => generateReport?.(),
        className: Style.issueSimpleActionsOption,
      },
    ];

    if (applyFilterCount > 0) {
      options.push({
        key: 'exportFiltered',
        icon: <Export />,
        text: t('Issues.Export.Filters'),
        action: () => generateReport?.(queryParams),
        className: Style.issueSimpleActionsOption,
      });
    }

    return options;
  }, [t, applyFilterCount, generateReport, queryParams]);

  const barMenuOptions = useMemo(() => {
    const options = [
      ...sortingOptions.map((option) => {
        return {
          key: option.key,
          icon:
            queryParams.sortBy === option.key ? (
              <Check className={Style.checkIcon} />
            ) : (
              <div className={Style.dummyIcon} />
            ),
          text: t(option.localizationKey),
          action: () => onChange({ sortBy: option.key as string, desc: option.desc }),
          className: Style.commonOption,
        } as DropDownMenuOption;
      }),
      {
        key: 'reset',
        icon: <Reset />,
        text: t('Issues.Filters.Reset'),
        action: () => onChange({ ...defaultIssueQueryParams }),
        className: clsx(Style.resetOption, Style.resetFilterOption),
      },
    ] as DropDownMenuOption[];

    if (hasPermissions(Permissions.ManageIssues)) {
      options.push(
        {
          key: 'issueTable',
          icon: <Table />,
          text: t('Issues.IssueTable.OpenTableButton'),
          action: openIssueTable!,
          className: clsx(Style.issueActionsOption),
        },
        ...modalMenuOptions,
      );
    }

    return options;
  }, [hasPermissions, modalMenuOptions, onChange, openIssueTable, queryParams.sortBy, t]);

  const statusFilterState = useMemo(() => {
    const anySelected = queryParams.state !== undefined;
    return {
      anySelected,
      text: !anySelected
        ? t('Issues.Filters.Status')
        : `${t(`Issues.Status.${queryParams.state}` as LocalizedResourceDictionaryKeys)}`,
    };
  }, [queryParams.state, t]);

  const subjectFilterState = useMemo(() => {
    const anySelected = queryParams.subjectList && queryParams.subjectList?.length > 0;
    return {
      anySelected,
      text: anySelected
        ? `${t(`Issues.Subject.${_.first(queryParams.subjectList)}` as LocalizedResourceDictionaryKeys)}`
        : t('Issues.Filters.Subject'),
    };
  }, [queryParams.subjectList, t]);

  const groupFilterState = useMemo(() => {
    const anySelected = queryParams.patientGroupList && queryParams.patientGroupList?.length > 0;
    return {
      anySelected,
      text: anySelected
        ? groups.find((g) => g.id === _.first(queryParams.patientGroupList))?.title
        : t('Issues.Filters.Group'),
    };
  }, [groups, queryParams.patientGroupList, t]);

  const commonFilters = useMemo(() => {
    return (
      <>
        <DropDownMenu
          colorSchema={statusFilterState.anySelected ? 'primary' : 'black'}
          text={statusFilterState.text}
          options={statuses}
          directionalHint={DirectionalHint.bottomLeftEdge}
          Icon={statusOptions.find((o) => IssuesStateEnum[o.key] === queryParams.state)?.icon}
          iconClassName={Style.statusFilterIcon}
          hasChevron={true}
        />

        <DropDownMenu
          colorSchema={subjectFilterState.anySelected ? 'primary' : 'black'}
          text={subjectFilterState.text}
          counter={
            queryParams.subjectList && queryParams.subjectList?.length > 1
              ? `(+${queryParams.subjectList?.length - 1})`
              : ''
          }
          options={subjects}
          closeCalloutByClick={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
          hasChevron={true}
          className={clsx({ [Style.dropdownMaxWidth]: type === 'bar' })}
        />

        <DropDownMenu
          colorSchema={groupFilterState.anySelected ? 'primary' : 'black'}
          text={groupFilterState.text}
          counter={
            queryParams.patientGroupList && queryParams.patientGroupList?.length > 1
              ? `(+${queryParams.patientGroupList?.length - 1})`
              : ''
          }
          options={groupOptions}
          closeCalloutByClick={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
          hasChevron={true}
          className={Style.topicDropdown}
        />
      </>
    );
  }, [
    groupFilterState,
    groupOptions,
    queryParams.patientGroupList,
    queryParams.state,
    queryParams.subjectList,
    statusFilterState,
    statuses,
    subjectFilterState,
    subjects,
    type,
  ]);

  const patientIdSearch = useMemo(
    () => (
      <AppInputLabel>
        <AppTextInput
          value={queryParams.patientUid || ''}
          placeholder={t('Issues.Filters.SearchPlaceholder')}
          onChange={(e) => onChange({ patientUid: e.target.value })}
          type={'search'}
        />
      </AppInputLabel>
    ),
    [onChange, queryParams.patientUid, t],
  );

  const hasAnyFilter = useMemo(() => {
    const filterFields: (keyof IssueQueryParamsType)[] = ['state', 'subjectList', 'patientGroupList'];

    return Object.entries(queryParams)
      .filter(([key]) => filterFields.includes(key as keyof IssueQueryParamsType))
      .some(([, value]) => (Array.isArray(value) ? !!value.length : !!value));
  }, [queryParams]);

  if (type === 'table') {
    return (
      <div className={Style.tableFiltersContainer}>
        <div className={Style.filters}>
          {commonFilters}

          {hasAnyFilter && (
            <AppButton
              variant={'icon-link'}
              colorSchema={'decline'}
              Icon={Reset}
              onClick={() => onChange({ ...defaultGroupedIssueQueryParams })}
            />
          )}
        </div>

        <div className={Style.searchAndExportContainer}>
          <div className={Style.searchWrapper}>{patientIdSearch}</div>
          <DropDownMenu colorSchema={'primary'} Icon={ExportBig} options={modalMenuOptions} />
        </div>
      </div>
    );
  }

  return (
    <div className={Style.container}>
      <div className={Style.header}>
        <div className={Style.filterButtonWrapper}>
          <AppButton
            variant={'icon-link'}
            colorSchema={'primary'}
            Icon={Filter}
            onClick={() => setIsHidden((s) => !s)}
            text={isHidden ? t('Issues.Filters.ShowFilters') : t('Issues.Filters.HideFilters')}
          />
          {isHidden && applyFilterCount > 0 && (
            <div className={clsx(TypographyStyles.plainText14, Style.filterCount)}>
              {t('Issues.Filters.ApplyCount', { count: applyFilterCount })}
            </div>
          )}
        </div>

        <DropDownMenu colorSchema={'basic'} Icon={Dots} options={barMenuOptions} testId={'issue-filter-menu'} />
      </div>

      {!isHidden && (
        <>
          {patientIdSearch}

          <div className={Style.filters}>
            {commonFilters}

            <div className={Style.checkboxWrapper}>
              <AppCheckboxInput
                label={t('Issues.Filters.UnreadFlag')}
                checked={queryParams.unreadOnly}
                onChange={(e) => onChange({ unreadOnly: e.target.checked })}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
