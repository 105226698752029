import React, { useMemo, FC, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useModal, UseModalProps } from '../../../../application/hooks/useModal';
import { useAppSelector } from '../../../../application/redux-store/store-types';
import { AppButton } from 'uikit/buttons';
import { DataType, LayoutProps } from '../../uiEditor/uiEditor';
import styles from '../../uiEditor/uiEditor.module.css';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../../application/localisation/useCommonLocalization';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { DateFormats, localFormat, toDateOrNull } from '../../../../helpers/date-helpers';
import { Deferred } from '../../../../helpers/Deferred';

export const useNotEditableFieldModal = () => {
  const modal = useModal('CLOSED');
  const deferred = useRef<Deferred>();

  return useMemo(
    () => ({
      open: () => {
        deferred.current = new Deferred();
        modal.openModal();
        return deferred.current.promise;
      },
      element: (notEditableFields: LayoutProps[], dataBlockValues: DataType | undefined) => (
        <NotEditableFieldsModal
          modal={modal}
          onSave={() => {
            modal.closeModal();
            deferred.current?.resolve();
          }}
          notEditableFields={notEditableFields}
          dataBlockValues={dataBlockValues}
        />
      ),
    }),
    [modal],
  );
};

export const NotEditableFieldsModal: FC<{
  modal: UseModalProps;
  notEditableFields: LayoutProps[];
  dataBlockValues: DataType | undefined;
  onSave: () => void;
}> = (props) => {
  const { t } = useScopedTranslation('Forms');
  const commonLocalizer = useCommonLocalization();
  const { modal, onSave, notEditableFields, dataBlockValues } = props;
  const groups = useAppSelector((state) => state.app.groups);

  const renderNotEditableFields = useMemo(() => {
    if (!dataBlockValues) {
      return <></>;
    }

    const result = notEditableFields.map((block) => {
      if (block.dataKey === 'GroupId') {
        return (
          <p key={block.dataKey}>
            <span>{block.label ?? block.text ?? block.dataKey}</span>
            <span>{groups.find((g) => g.id === Number(dataBlockValues.GroupId))?.title}</span>
          </p>
        );
      }

      const dateField = toDateOrNull(dataBlockValues[block.dataKey]);
      const isNotNumber = isNaN(Number(dataBlockValues[block.dataKey]));
      return (
        <p key={block.dataKey}>
          <span>{block.label ?? block.text ?? block.dataKey}</span>
          <span>
            {dateField && isNotNumber ? localFormat(dateField, DateFormats.longDate) : dataBlockValues[block.dataKey]}
          </span>
        </p>
      );
    });

    return <div className={styles.patientDataContainer}>{result}</div>;
  }, [dataBlockValues, groups, notEditableFields]);

  return (
    <AppModalContainer
      title={t('PatientDataModal.Header')}
      testId={'not-editable-fields-modal'}
      visible={modal.visible}
      bodyClassName={styles.modalBody}
      onHide={modal.closeModal}
    >
      <>
        <p className={styles.mainText}>
          <Trans i18nKey={'Forms.PatientDataModal.MainText'}>
            <span className={styles.awfulText} />
          </Trans>
        </p>

        {renderNotEditableFields}

        <div className={styles.buttonGroup}>
          <AppButton
            className={styles.buttonFlex}
            text={commonLocalizer('Common_Back')}
            variant={'button'}
            colorSchema={'secondary'}
            onClick={modal.closeModal}
          />

          <AppButton
            className={styles.buttonFlex}
            text={t('Controls.SaveButton')}
            variant={'button'}
            colorSchema={'primary'}
            onClick={onSave}
          />
        </div>
      </>
    </AppModalContainer>
  );
};
