import clsx from 'clsx';
import React, { FC, useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { logger } from 'src/application/logging/logging';
import { Tooltip } from 'src/components/uikit/tooltip/tooltip.component';
import { QueryFactory } from 'src/services/api';
import { ReactComponent as Bell } from '../../../assets/img/notification/bell_32.svg';
import Style from './NotificationBell.module.css';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { useGetNotificationCountsQuery } from '../../../services/api/api-client/NotificationQuery';
import { NotificationBellTooltipContent } from './NotificationBellTooltipContent';

export const NotificationBell: FC = () => {
  const { studyId } = useAppSelector((state) => state.app);
  const client = useQueryClient();

  const countsQuery = useGetNotificationCountsQuery(studyId ?? undefined, {
    enabled: !!studyId,
  });

  const onDismissHandler = useCallback(
    async (isVisible: boolean) => {
      if (isVisible) {
        return;
      }

      try {
        await QueryFactory.NotificationQuery.Client.readAllNotifications(studyId);
        await Promise.all([
          // We need to use refetch here instead of invalidate
          // because when we use the second one
          // before Tanstack/query checks whether the query is used
          // NotificationList is unmounted and the query became unused
          // so the query is not refetched and when we open the tooltip again
          // an old data is displayed while the query is being refeched
          client.refetchQueries(QueryFactory.NotificationQuery.getNotificationsQueryKey()),
          client.invalidateQueries(QueryFactory.NotificationQuery.getNotificationCountsQueryKey(studyId!)),
          client.invalidateQueries(
            QueryFactory.NotificationQuery.getNotificationBatchesQueryKey(undefined, [studyId!]),
          ),
        ]);
      } catch (ex: any) {
        logger().error('Mark notification as read has failed', ex);
      }
    },
    [client, studyId],
  );

  const unreadNotificationsCount = useMemo(() => {
    return countsQuery.data?.map((x) => x.unread).reduce((sum, x) => sum + x, 0) || 0;
  }, [countsQuery.data]);

  const hasNotifications = !!countsQuery.data?.length;
  if (!hasNotifications) {
    return <></>;
  }

  return (
    <Tooltip
      onDismiss={onDismissHandler}
      trigger={'click'}
      text={''}
      tooltipContent={<NotificationBellTooltipContent />}
      hostStyles={Style.host}
      calloutContainerStyles={Style.callout}
    >
      <div
        className={clsx({ [Style.iconWrapper]: unreadNotificationsCount > 0 })}
        data-count={unreadNotificationsCount}
        data-test-id={'notification-bell'}
      >
        <Bell />
      </div>
    </Tooltip>
  );
};
