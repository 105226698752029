import React from 'react';
import { IAdminStudyDto } from '../../../services/api/api-client';

import Style from '../../studies/tableColumns.module.css';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { expandRowButtonColumn } from 'uikit/table/columns/ExpandRowButtonColumn';
import TableCommonStyles from 'uikit/table/appTableCommonStyles.module.scss';

export const useStudyColumns = (): ColumnDef<IAdminStudyDto>[] => {
  const { t } = useTranslation();

  return [
    {
      accessorKey: 'id',
      header: t('StudiesList.Columns.Id'),
      cell: ({ row }) => row.original.id.toString().padStart(3, '0'),
      size: 20,
      meta: {
        centered: true,
      },
    },
    {
      accessorKey: 'studyNumber',
      header: t('StudiesList.Columns.Number'),
      enableSorting: false,
      size: 20,
      meta: {
        isColored: true,
        centered: true,
      },
    },
    {
      accessorKey: 'title',
      header: t('StudiesList.Columns.Title'),
      enableSorting: false,
      meta: {
        dontUseHeaderWidth: true,
      },
    },
    {
      accessorKey: 'duration',
      header: t('StudiesList.Columns.Duration'),
      enableSorting: false,
      size: 110,
      meta: {
        headerClassName: TableCommonStyles.wrap,
        centered: true,
        isColored: true,
      },
    },
    {
      id: 'groups',
      header: t('StudiesList.Columns.Groups'),
      size: 200,
      cell: ({ row }) => (
        <div className={Style.groupsContent}>
          {row.original.groups?.map((g) => (
            <p key={g.id}>{g.title}</p>
          ))}
        </div>
      ),
    },
    {
      accessorKey: 'isTokenActive',
      header: 'Is Token Active',
      size: 20,
      meta: {
        isColored: true,
        centered: true,
      },
    },
    expandRowButtonColumn({}),
  ];
};
