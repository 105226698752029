import React, { FC } from 'react';
import { FormModalComponent } from './formModalComponent';
import { UiEditorProvider, UiEditorProviderProps } from '../uiEditor/provider/formFillProvider';
import { FormModalComponentProps } from '../uiEditor/uiEditor';

export const FormModalWithContext: FC<FormModalComponentProps & UiEditorProviderProps> = (props) => {
  return (
    <UiEditorProvider {...props}>
      <FormModalComponent {...props} />
    </UiEditorProvider>
  );
};
