import React, { useCallback, useMemo, useState, FC } from 'react';
import Style from './manageSurveys.module.scss';
import { useTranslation } from 'react-i18next';
import { QueryFactory } from '../../../../services/api';
import { QuestionnaireVm } from './ManageSurveysModal';
import { compareAsc } from 'date-fns';
import clsx from 'clsx';
import { Row } from './Row';
import { RevokeConfirmationDialog } from './RevokeConfirmationDialog';
import { ReactComponent as TooltipIcon } from '../../../../assets/img/common/tip_16.svg';
import { Tooltip } from '../../../uikit/tooltip/tooltip.component';
import { SurveyDto } from '../../../../services/api/api-client';
import { Permissions } from '../../../../helpers/auth/auth-helper';
import { AdditionRow } from './AdditionRow';
import { PermissionsCheck } from '../../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { logger } from '../../../../application/logging/logging';
import { showErrorToast } from '../../../toast/toast-helper';
import { useQueryClient } from '@tanstack/react-query';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import { AppModalContainer } from '../../../uikit/modal/modal.component';

export const ManageSurveysModalComponent: FC<{
  visible: boolean;
  onHide: () => void;
  studyStartDate: Date;
  studyFinishDate: Date;
  patientId: string;
  studyId: number;
  surveys: SurveyDto[];
  groupId: number;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <AppModalContainer
      visible={props.visible}
      onHide={props.onHide}
      title={t('Dashboard.PatientAccordion.SurveySection.SurveyManaging.Modal.Header')}
      bodyClassName={Style.modal}
      testId={'survey-calendar-modal'}
    >
      <ModalContent {...props} />
    </AppModalContainer>
  );
};

// I extracted this component to prevent extra loading on patient card opening
const ModalContent: FC<{
  studyStartDate: Date;
  studyFinishDate: Date;
  patientId: string;
  studyId: number;
  surveys: SurveyDto[];
  groupId: number;
}> = (props) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection.SurveyManaging.Modal');
  const queryClient = useQueryClient();
  const surveys = props.surveys;

  //#region Questionnaires from backend

  const questionnaires = QueryFactory.SurveyQuery.useGetQuestionnairesQuery({
    patientId: props.patientId,
    surveyIds: surveys.map((x) => x.id),
    ended: false,
  });

  const predictedQuestionnaires = QueryFactory.SurveyQuery.useGetPredictedQuestionnairesQuery({
    patientId: props.patientId,
    surveyIds: surveys.map((x) => x.id),
  });

  const questionnaireVms = useMemo(() => {
    if (!questionnaires.data || !predictedQuestionnaires.data) return null;

    let arr: QuestionnaireVm[] =
      questionnaires.data.map((x) => ({
        id: x.id as number | null,
        surveyId: x.surveyId,
        surveyType: x.typeString,
        surveyIcon: surveys.find((y) => y.id === x.surveyId)!.icon,
        start: x.startDate,
        end: x.endDate,
        triggeredByDoctor: x.triggeredByDoctor,
      })) ?? [];

    arr = arr.concat(
      predictedQuestionnaires.data.map((x) => ({
        id: null,
        surveyId: x.surveyId,
        surveyType: x.surveyType,
        surveyIcon: surveys.find((y) => y.id === x.surveyId)!.icon,
        start: x.startDate,
        end: x.endDate,
        triggeredByDoctor: false,
      })) ?? [],
    );

    arr.sort((a, b) => compareAsc(a.start, b.start));
    return arr;
  }, [predictedQuestionnaires.data, questionnaires.data, surveys]);

  //#endregion

  //#region Answers from backend

  const { data: answers } = QueryFactory.SurveyQuery.useGetAnswersQuery({
    patientIds: [props.patientId],
    surveyIds: surveys.map((x) => x.id),
  });

  //#endregion

  //#region Questionnaire creating

  const onQuestionnaireCreated = useCallback(async () => {
    await queryClient.invalidateQueries(
      QueryFactory.SurveyQuery.getQuestionnairesQueryKey({
        patientId: props.patientId,
      }),
    );
  }, [props.patientId, queryClient]);

  //#endregion

  //#region Questionnaire deleting

  const [questionnaireToDelete, setQuestionnaireToDelete] = useState<QuestionnaireVm | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const onApplyDeleting = async () => {
    setIsDeleting(true);
    try {
      await QueryFactory.SurveyQuery.Client.deleteQuestionnaire(questionnaireToDelete!.id!);
      await questionnaires.refetch();
    } catch (err: any) {
      logger().error(err);
      showErrorToast(err);
    } finally {
      setQuestionnaireToDelete(null);
      setIsDeleting(false);
    }
  };

  const onCancelDeleting = () => {
    setQuestionnaireToDelete(null);
  };

  //#endregion

  return (
    <>
      <div className={clsx(Style.header)}>
        <div className={Style.typeColumn}>{t('Type')}</div>
        <div className={Style.periodColumn}>
          {t('Period')}
          <Tooltip hostStyles={Style.tooltip} text={t('PeriodTooltip')}>
            <TooltipIcon />
          </Tooltip>
        </div>
      </div>
      <div className={Style.rows}>
        {questionnaireVms && answers && (
          <PermissionsCheck permissions={Permissions.EditPatient}>
            <AdditionRow
              patientId={props.patientId}
              surveys={surveys}
              questionnaireVms={questionnaireVms}
              answers={answers}
              studyStartDate={props.studyStartDate}
              studyFinishDate={props.studyFinishDate}
              onCreated={onQuestionnaireCreated}
            />
          </PermissionsCheck>
        )}

        {questionnaireVms?.map((x, i) => (
          <Row questionnaire={x} key={x.id ?? i} onDelete={setQuestionnaireToDelete} />
        ))}
      </div>

      <RevokeConfirmationDialog
        questionnaireToDelete={questionnaireToDelete}
        isLoading={isDeleting}
        onApply={onApplyDeleting}
        onCancel={onCancelDeleting}
      />
    </>
  );
};
