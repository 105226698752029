import React from 'react';
import { IFieldGroupContainer } from '../../../../forms/base/controls/containers/FieldGroupContainer';
import { RPDF } from '../../../react-pdf';

export const FieldGroupContainer: IFieldGroupContainer = ({ children, title, hideTitle }) => {
  return (
    <RPDF.View style={{ marginVertical: 4 }}>
      {!hideTitle && (
        <RPDF.Text minPresenceAhead={40} style={[RPDF.typography.heading5b, { paddingVertical: 4 }]}>
          {title}
        </RPDF.Text>
      )}
      {children}
    </RPDF.View>
  );
};
