import React from 'react';
import DeniedImage from './../../assets/img/common/access_lock.svg';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { InformationPageContainer } from '../../components/informationPageContainer/InformationPage.Container';
import { useNavigate } from 'react-router';

export const AccessDenied = () => {
  const { t } = useScopedTranslation('AccessDenied');
  const navigate = useNavigate();

  return (
    <InformationPageContainer
      header={t('Header')}
      text={t('Description')}
      buttonText={t('Button')}
      buttonAction={() => navigate('/')}
      img={DeniedImage}
    />
  );
};
