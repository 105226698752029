import React, { FC } from 'react';
import Style from './appCheckboxInput.module.scss';
import clsx from 'clsx';

export type AppCheckboxInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  readOnly?: boolean;
};

export const AppCheckboxInput: FC<AppCheckboxInputProps> = ({ disabled, readOnly, className, label, ...rest }) => {
  return (
    <label data-disabled={disabled} className={Style.label}>
      <input
        className={clsx(Style.input, className)}
        type={'checkbox'}
        disabled={disabled || readOnly}
        data-read-only={readOnly}
        {...rest}
      />
      {label}
    </label>
  );
};
