/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useCallback, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UseModalProps } from '../../../application/hooks/useModal';
import Style from './issueTable.module.scss';
import { useIssueTableUserColumns } from './issueTable.columns';
import { QueryFactory } from '../../../services/api';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { IssueTableExpandedRow } from './issueTableExpandedRow.component';
import { ReactComponent as NoIssues } from '../../../assets/img/issues/no_issues.svg';
import { IssueFilters } from '../bar/issueFilters.component';
import {
  defaultGroupedIssueQueryParams,
  defaultPageParams,
  GroupedIssueQueryParamsType,
  IssueQueryParamsType,
  PageParamsType,
} from '../bar/issueFilters-helper';
import { GetIssuesGroupedByPatientsIssueQueryParameters } from '../../../services/api/api-client/IssueQuery';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { AppTable } from 'uikit/table/AppTable';
import { useSortBy } from 'uikit/table/updateSortByInUrl';
import { AppPagination } from 'uikit/pagination/AppPagination';
import { pagingSortingToBackendRequest } from '../../../helpers/pagination-helper';
import { Loading } from 'uikit/suspense/Loading';

export type IssueTableType = {
  modal: UseModalProps<undefined>;
};

export const IssueTable: FC<IssueTableType> = ({ modal }) => {
  const { t } = useTranslation();
  const { studyId } = useAppSelector((state) => state.app);

  const [queryParams, setQueryParams] = useState<GroupedIssueQueryParamsType & PageParamsType>({
    ...defaultGroupedIssueQueryParams,
    ...defaultPageParams,
  });

  const sortBy = useSortBy(queryParams, setQueryParams);

  const groupedIssues = QueryFactory.IssueQuery.useGetIssuesGroupedByPatientsQuery(
    {
      studyId: studyId!,
      patientUniqId: queryParams.patientUid,
      state: queryParams.state,
      subjectList: queryParams.subjectList,
      patientGroupIds: queryParams.patientGroupList,
      ...pagingSortingToBackendRequest(queryParams),
      // To use default sorting that places study-related issues on first
      sortBy: null,
    } as GetIssuesGroupedByPatientsIssueQueryParameters,
    {
      enabled: modal.visible,
      suspense: false,
      keepPreviousData: true,
    },
  );

  const setFiltersHandler = useCallback((params: IssueQueryParamsType, pageParams: PageParamsType) => {
    setQueryParams((prev) => {
      return {
        ...prev,
        ...params,
        ...pageParams,
      };
    });
  }, []);

  const dataTable = useReactTable({
    data: groupedIssues.data?.data || [],
    columns: useIssueTableUserColumns(),
    getCoreRowModel: getCoreRowModel(),
    getRowId: (group) => (group.uniqueId ? `${group.uniqueId}-patient` : 'study'),
    manualSorting: true,
    enableSortingRemoval: false,
    state: {
      sorting: sortBy.sortingState,
    },
    onSortingChange: sortBy.onSortingChange,
  });

  return (
    <AppModalContainer
      visible={modal.visible}
      onHide={modal.closeModal}
      title={t('Issues.IssueTable.Title')}
      bodyClassName={Style.modalBody}
      testId={'issue-table-modal'}
    >
      <div className={Style.modalContent}>
        <IssueFilters
          type={'table'}
          queryParams={queryParams}
          onChange={(params) => setFiltersHandler(params, defaultPageParams)}
        />

        <Loading
          containerClassName={Style.tableContainer}
          loading={groupedIssues.isLoading || groupedIssues.isPreviousData}
        >
          <AppTable
            testId="grouped-issues"
            table={dataTable}
            placeholder={{
              Icon: NoIssues,
              text: t('Issues.Filters.NoIssuesText'),
              hide: groupedIssues.isLoading || groupedIssues.isPreviousData,
            }}
            expandedRow={(row) => {
              if (!studyId) return <React.Fragment key={row.original.uniqueId} />;

              return (
                <IssueTableExpandedRow
                  key={row.original.uniqueId}
                  studyId={studyId}
                  patientUid={row.original.uniqueId}
                  queryParams={queryParams}
                />
              );
            }}
          />
          <AppPagination
            page={queryParams.page}
            perPage={queryParams.perPage}
            totalCount={groupedIssues.data?.totalCount || 0}
            changePagination={setQueryParams}
          />
        </Loading>
      </div>
    </AppModalContainer>
  );
};
