import React, { useCallback, useState, FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UseModalProps } from '../../../application/hooks/useModal';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { DialogModal } from '../../dialogModal/dialogModal.component';
import Style from './issueDeleteDialog.module.css';

export type IssueDeleteFormParams = {
  issueId: number;
  callback: () => Promise<void>;
};

export const IssueDeleteDialog: FC<{
  modal: UseModalProps<IssueDeleteFormParams>;
}> = ({ modal }) => {
  const { t } = useTranslation();
  const [isApplying, setIsApplying] = useState(false);

  const { issueId, callback } = modal.params;

  const onApplyHandler = useCallback(async () => {
    try {
      setIsApplying(true);
      await callback();
    } catch (error) {
    } finally {
      modal.closeModal();
      setIsApplying(false);
    }
  }, [callback, modal]);

  return (
    <DialogModal
      isVisible={modal.visible}
      onClose={modal.closeModal}
      isDisabled={isApplying}
      testId={'delete-issue-modal'}
      buttons={[
        <AppButton
          key={0}
          disabled={isApplying}
          variant={'button'}
          colorSchema={'secondary'}
          text={t('Common_Cancel')}
          type={'reset'}
          onClick={modal.closeModal}
        />,
        <AppButton
          key={1}
          disabled={isApplying}
          variant={'button'}
          colorSchema={'destroy'}
          text={t('Common_Delete')}
          onClick={onApplyHandler}
        />,
      ]}
      mainText={
        <Trans i18nKey={'Issues.DeleteModal.Text'} values={{ id: issueId }}>
          <strong />
          <strong className={Style.awfulText} />
        </Trans>
      }
      title={t('Issues.DeleteModal.Title')}
    />
  );
};
