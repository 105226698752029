/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { MetricActions } from '../../../application/analitics/matomo/matomo-actions';
import { MetricCategories } from '../../../application/analitics/matomo/matomo-categories';
import { trackEvent } from '../../../application/analitics/matomo/matomo-tracker';
import { logger } from '../../../application/logging/logging';
import { downloadFile } from '../../../helpers/file-helper';
import { QueryFactory } from '../../../services/api';
import { AppButton } from 'uikit/buttons';
import Style from './logExportModal.module.css';
import { ExportPatientLogProps, FileType, LogType } from './logExportModal';
import { generateRecordsReport, generateSurveyReport } from '../../../features/report';
import { triggerDownloadFileDialog } from '../../../features/report/report-helpers';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { FilledRecord, MissedRecord } from '../../../features/report/records/recordsReport';
import { IFormDto, IFormResultDto, RouteProgressStateEnum } from '../../../services/api/api-client';
import _ from 'lodash';
import { PatientCardContext } from '../PatientCardContext';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { SurveyReportOptionItem } from '../../../features/report/surveys/surveyReport';
import { AppCheckboxInput, AppRadioGroupInput, AppSwitcherInput } from 'uikit/inputs';
import { AppInputError, AppInputLabel } from 'uikit/wrappers';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { HardcodedSurveys } from '../../../application/constants/hardoced-entities';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from '../../../helpers/auth/auth-helper';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { Loading } from 'uikit/suspense/Loading';
import { useGetFormByIdQuery } from '../../../services/api/api-client/FormsQuery';

export const LogExportModal = (props: ExportPatientLogProps) => {
  const t = useCommonLocalization();
  const { modal, studyId, uniqueId, modalTitle, id, routeProgress, patientTags } = props;
  const { studyNumber, groups, hasPdfSigning } = useAppSelector((state) => state.app);
  const patientCardContext = useContext(PatientCardContext);
  const notesFormConfigId = patientCardContext?.group.formConfigIdForNotes;

  const { data: profile } = QueryFactory.ProfileQuery.useGetDoctorProfileInfoQuery({
    suspense: true,
  });

  const { data: notesForm } = useGetFormByIdQuery(notesFormConfigId!, {
    enabled: notesFormConfigId !== undefined && notesFormConfigId !== null,
  });

  const [error, setError] = useState('');
  const closeModal = useCallback(() => {
    setError('');
    modal.closeModal();
  }, [modal.closeModal]);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [logType, setLogType] = useState<LogType['key']>('auditlog');
  const [fileType, setFileType] = useState<FileType['key']>('csv');
  const [selectedSurveys, setSelectedSurveys] = useState<string[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [signPdf, setSignPdf] = useState(false);
  const [withMissedSurvey, setWithMissedSurveys] = useState(false);

  useEffect(() => {
    setError('');
  }, [logType, fileType, selectedRecords, selectedSurveys, signPdf, withMissedSurvey]);

  const { isInitialLoading, data } = QueryFactory.ExportLogQuery.useGetStatisticTypesToExportQuery(
    studyId,
    uniqueId,
    fileType === 'pdf' && withMissedSurvey,
    {
      enabled: modal.visible,
      suspense: false,
    },
  );
  const { surveyTypes: incomingSurveys } = data || {};
  const surveyTypes = incomingSurveys?.filter((x) => x !== HardcodedSurveys.prismaCheck);

  /**
   * This hook excludes not actualized survey types.
   *
   * e.g. When we toggle  "missing surveys" we could memorize "missing survey" in selectedSurveys (local state),
   * so we make extra check in this hook
   */
  useLayoutEffect(() => {
    if (!!surveyTypes && !selectedSurveys.every((x) => surveyTypes.includes(x))) {
      setSelectedSurveys(selectedSurveys.filter((x) => surveyTypes.includes(x)));
    }
  }, [surveyTypes, selectedSurveys]);

  const formTypesToExport = useMemo(
    () => data?.formTypes.filter((ft) => ft !== notesForm?.type),
    [data?.formTypes, notesForm?.type],
  );

  const formTypes = useMemo(() => {
    if (fileType === 'csv') {
      return formTypesToExport;
    }

    const missedTypes = routeProgress?.steps
      ?.filter((step) => step.state === RouteProgressStateEnum.Missed)
      .flatMap((step) => step.forms.filter((x) => x.formResultId === null).map((x) => x.formType) ?? []);

    return [...new Set([...(formTypesToExport ?? []), ...(missedTypes ?? [])])];
  }, [formTypesToExport, fileType, routeProgress]);

  const isAllSurveysSelected = useMemo(() => {
    return surveyTypes?.every((x) => selectedSurveys?.includes(x));
  }, [selectedSurveys, surveyTypes]);

  const isAllRecordsSelected = useMemo(() => {
    return formTypes?.every((x) => selectedRecords?.includes(x));
  }, [formTypes, selectedRecords]);

  const LogTypeOptions: LogType[] = useMemo(() => {
    return [
      {
        key: 'auditlog',
        optionText: t('LogExport.ExportTypes.AuditLogs'),
      },
      {
        key: 'statistics',
        optionText: t('LogExport.ExportTypes.PatientData'),
      },
    ];
  }, [t]);

  const FileTypeOptions: FileType[] = useMemo(() => {
    return [
      {
        key: 'csv',
        optionText: t('LogExport.ExportTypes.Csv'),
      },
      {
        key: 'pdf',
        optionText: t('LogExport.ExportTypes.Pdf'),
      },
    ] as FileType[];
  }, [t]);

  const generateRecordsPdfReport = useCallback(async () => {
    if (selectedRecords.length === 0) {
      return;
    }

    const formResults = await QueryFactory.FormsQuery.Client.getFormResults(undefined, selectedRecords, id);
    const configIds = [...new Set(formResults.data.map((fr) => fr.formConfigId))];
    const formConfigs = await QueryFactory.FormsQuery.Client.getForms(configIds);

    const multiInstanceFilledRecords = formResults.data
      .map((fr) => {
        return {
          form: _.find(formConfigs, { id: fr.formConfigId }) as IFormDto,
          resultDto: fr,
        };
      })
      .filter((x) => x.form && x.form.isMultiInstance && selectedRecords.some((type) => type === x.form?.type))
      .reverse()
      .map((multiform, i) => {
        return { ...multiform, stepName: `#${i + 1}` };
      });

    const filledRecords = routeProgress?.steps
      .map((step) => ({
        formResults: step.forms
          .filter((x) => x.formResultId !== null)
          .filter((fr) => selectedRecords.some((type) => type === fr.formType))
          .map((x) => ({ id: x.formResultId!, type: x.formType })),
        stepName: step.name,
      }))
      .filter((x) => x.formResults.length > 0)
      .reduce((acc: FilledRecord[], cur) => {
        return [
          ...acc,
          ...(cur.formResults?.map((fr) => ({
            form: _.find(formConfigs, { type: fr.type }) as IFormDto,
            resultDto: _.find(formResults.data, { id: fr.id }) as IFormResultDto,
            stepName: cur.stepName,
          })) ?? []),
        ];
      }, []);

    const missedRecords = routeProgress?.steps
      .filter((step) => step.state === RouteProgressStateEnum.Missed)
      .map((step) => {
        return {
          stepName: step.name,
          formTypes:
            step.forms
              .filter((x) => x.formResultId === null)
              .filter((fl) => selectedRecords.some((type) => type === fl.formType))
              .filter((fl) => !filledRecords?.some((fr) => fr.form.id === fl.formConfigId && fr.stepName === step.name))
              .map((fb) => fb.formType) ?? [],
        };
      })
      .reduce((acc: MissedRecord[], cur) => {
        return [
          ...acc,
          ...(cur.formTypes?.map((x) => ({
            stepName: cur.stepName,
            type: x,
          })) ?? []),
        ];
      }, []);

    let recordsBlob = await generateRecordsReport({
      filledRecords: [...(filledRecords ?? []), ...multiInstanceFilledRecords],
      missedRecords: missedRecords ?? [],
      studyNumber: studyNumber ?? '',
      patientUid: uniqueId ?? '',
      profileFullName: profile?.fullName ?? '',
      groups: groups,
      patientTags: patientTags,
    });

    const filename = `Study-${studyNumber}-Patient-${uniqueId}-${
      selectedRecords.length === 1 ? selectedRecords[0].replace(' ', '_') : 'Records'
    }-${localFormat(new Date(), DateFormats.forFiles)}`;

    if (signPdf) {
      const signedPdf = await QueryFactory.PdfClient.signPdf(
        { data: recordsBlob, fileName: filename },
        t('LogExport.SigningReason'),
        420,
        95,
        60,
        115,
      );
      recordsBlob = signedPdf.data;
    }

    triggerDownloadFileDialog(recordsBlob, filename);
    closeModal();
    return;
  }, [
    selectedRecords,
    id,
    routeProgress?.steps,
    studyNumber,
    uniqueId,
    profile?.fullName,
    groups,
    patientTags,
    signPdf,
    closeModal,
    t,
  ]);

  const generateSurveyPdfReport = useCallback(async () => {
    if (selectedSurveys.length === 0) {
      return;
    }

    const allSurveys: SurveyReportOptionItem[] = [];

    for (const selectedSurvey of selectedSurveys) {
      const resultData = await QueryFactory.SurveyQuery.Client.getAnswersStatistic(
        patientCardContext.patient.id,
        selectedSurvey,
      );
      allSurveys.push({ ...resultData, surveyTitle: selectedSurvey });
    }

    let blob = await generateSurveyReport({
      studyNumber: studyNumber || '',
      profileFullName: profile?.fullName || '',
      patientUid: uniqueId || '',
      allSurveys,
      includeMissingSurveys: withMissedSurvey,
      patientTags: patientTags,
    });

    const filename = `Study-${studyNumber}-Patient-${uniqueId}-${
      selectedSurveys.length === 1 ? selectedSurveys[0].replace(' ', '_') : 'Surveys'
    }-${localFormat(new Date(), DateFormats.forFiles)}`;

    if (signPdf) {
      const signedPdf = await QueryFactory.PdfClient.signPdf(
        { data: blob, fileName: filename },
        t('LogExport.SigningReason'),
        420,
        95,
        60,
        115,
      );
      blob = signedPdf.data;
    }

    triggerDownloadFileDialog(blob, filename);
    closeModal();
    return;
  }, [
    selectedSurveys,
    studyNumber,
    profile?.fullName,
    uniqueId,
    withMissedSurvey,
    patientTags,
    signPdf,
    closeModal,
    patientCardContext?.patient?.id,
    t,
  ]);

  const ExportHandler = useCallback(async () => {
    try {
      setError('');
      setDownloading(true);

      if (logType === 'auditlog') {
        const fileResponse = await QueryFactory.ExportLogQuery.Client.getEventLog(studyId, uniqueId);

        if (!fileResponse) {
          return;
        }

        downloadFile(fileResponse);
        closeModal();
        return;
      }

      if (logType === 'statistics' && fileType === 'csv') {
        const fileResponse = await QueryFactory.ExportLogQuery.Client.getStatistics(
          studyId,
          selectedSurveys,
          selectedRecords,
          uniqueId,
        );

        if (!fileResponse) {
          return;
        }

        downloadFile(fileResponse);
        closeModal();
        return;
      }

      if (logType === 'statistics' && fileType === 'pdf') {
        await generateRecordsPdfReport();
        await generateSurveyPdfReport();
      }

      trackEvent({
        category: MetricCategories.Patient,
        action: MetricActions.ButtonPressed,
        name: 'Export logs for patient',
      });
    } catch (err: any) {
      setError(t('LogExport.CommonError'));
      logger().error('Export log failed', err);
    } finally {
      setDownloading(false);
    }
  }, [
    logType,
    fileType,
    studyId,
    uniqueId,
    closeModal,
    selectedSurveys,
    selectedRecords,
    generateRecordsPdfReport,
    generateSurveyPdfReport,
    t,
  ]);

  const surveySection = useMemo(() => {
    if (!surveyTypes || surveyTypes.length === 0) {
      return <></>;
    }

    return (
      <div className={Style.surveySection}>
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
          <div className={clsx({ [Style.disabled]: isDownloading })}>{t('LogExport.SurveySectionHeader')}</div>
          {fileType === 'pdf' && (
            <AppSwitcherInput
              label={t('LogExport.IncludeMissingSurveys')}
              checked={withMissedSurvey}
              onChange={(e) => {
                setWithMissedSurveys(e.target.checked);
              }}
            />
          )}
        </div>
        <AppButton
          variant={'icon-link'}
          colorSchema={'primary'}
          text={isAllSurveysSelected ? t('Common_None') : t('Common_SelectAll')}
          className={Style.uncheckAllButton}
          disabled={isDownloading}
          onClick={() => (isAllSurveysSelected ? setSelectedSurveys([]) : setSelectedSurveys(surveyTypes))}
        />

        {surveyTypes.map((type) => (
          <AppCheckboxInput
            key={type}
            checked={selectedSurveys.includes(type)}
            label={type}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedSurveys((state) => [...state, type]);
                return;
              }

              setSelectedSurveys((state) => state.filter((s) => s !== type));
            }}
            disabled={isDownloading}
          />
        ))}
      </div>
    );
  }, [surveyTypes, isDownloading, t, fileType, withMissedSurvey, isAllSurveysSelected, selectedSurveys]);

  const recordSection = useMemo(() => {
    if (!formTypes || formTypes.length === 0) {
      return <></>;
    }

    return (
      <div className={Style.surveySection}>
        <div className={clsx({ [Style.disabled]: isDownloading })}>{t('LogExport.RecordSectionHeader')}</div>

        <AppButton
          variant={'icon-link'}
          colorSchema={'primary'}
          text={isAllRecordsSelected ? t('Common_None') : t('Common_SelectAll')}
          className={Style.uncheckAllButton}
          disabled={isDownloading}
          onClick={() =>
            isAllRecordsSelected
              ? setSelectedRecords((selected) => {
                  return selected.filter((x) => x === notesForm?.type);
                })
              : setSelectedRecords((selected) => [...selected, ...formTypes])
          }
        />

        {formTypes.map((type) => (
          <AppCheckboxInput
            key={type}
            checked={selectedRecords.includes(type)}
            label={type}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRecords((state) => [...state, type]);
                return;
              }

              setSelectedRecords((state) => state.filter((s) => s !== type));
            }}
            disabled={isDownloading}
          />
        ))}
      </div>
    );
  }, [formTypes, isDownloading, t, isAllRecordsSelected, notesForm?.type, selectedRecords]);

  const notesSection = useMemo(() => {
    const notesFormType = notesForm?.type;
    if (!notesFormType || !data?.formTypes.includes(notesFormType)) {
      return <></>;
    }

    return (
      <div className={Style.surveySection}>
        <div className={clsx({ [Style.disabled]: isDownloading })}>{t('LogExport.NotesSectionHeader')}</div>

        <AppCheckboxInput
          checked={selectedRecords.includes(notesFormType)}
          label={notesFormType}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRecords((state) => [...state, notesFormType]);
              return;
            }
            setSelectedRecords((state) => state.filter((s) => s !== notesFormType));
          }}
          disabled={isDownloading}
        />
      </div>
    );
  }, [notesForm?.type, data?.formTypes, isDownloading, t, selectedRecords]);

  const content = useMemo(() => {
    return (
      <div className={Style.content}>
        <AppInputLabel text={t('LogExport.ExportTypes.Header')}>
          <AppRadioGroupInput<LogType, 'key'>
            options={LogTypeOptions}
            labelField={'optionText'}
            onChange={(x) => {
              setLogType(x.key);
              setSelectedSurveys([]);
              setSelectedRecords([]);
            }}
            valueField={'key'}
            value={logType}
            disabled={isDownloading}
          />
        </AppInputLabel>

        {logType === 'statistics' && (
          <>
            {id && (
              <AppInputLabel text={t('LogExport.ExportTypes.FileTypeHeader')}>
                <AppRadioGroupInput<FileType, 'key'>
                  options={FileTypeOptions}
                  labelField={'optionText'}
                  onChange={(x) => {
                    setFileType(x.key);
                    setSelectedSurveys([]);
                    setSelectedRecords([]);
                  }}
                  valueField={'key'}
                  value={fileType}
                  disabled={isDownloading}
                />
              </AppInputLabel>
            )}

            {fileType === 'pdf' && hasPdfSigning && (
              <PermissionsCheck permissions={Permissions.SignExport}>
                <AppCheckboxInput
                  label={t('LogExport.SignPdf')}
                  checked={signPdf}
                  disabled={isDownloading}
                  onChange={(e) => {
                    setSignPdf(e.target.checked);
                  }}
                />
              </PermissionsCheck>
            )}

            <Loading loading={isInitialLoading} renderChildren={false} containerClassName={Style.loader}>
              {surveySection}
              {recordSection}
              {notesSection}
            </Loading>
          </>
        )}
      </div>
    );
  }, [
    isInitialLoading,
    t,
    LogTypeOptions,
    logType,
    isDownloading,
    id,
    FileTypeOptions,
    fileType,
    signPdf,
    surveySection,
    recordSection,
  ]);

  const buttons = useMemo(() => {
    return (
      <div className={Style.footer}>
        <AppInputError errors={error} hideBorder position={'top'}>
          <div className={Style.buttonGroup}>
            <AppButton text={t('Common_Cancel')} variant={'button'} colorSchema={'secondary'} onClick={closeModal} />
            <AppButton
              text={t('Common_Export')}
              variant={'button'}
              colorSchema={'primary'}
              onClick={ExportHandler}
              isLoading={isDownloading}
              disabled={
                isDownloading ||
                (logType === 'statistics' && selectedSurveys.length === 0 && selectedRecords.length === 0)
              }
            />
          </div>
        </AppInputError>
      </div>
    );
  }, [error, t, closeModal, ExportHandler, isDownloading, logType, selectedSurveys.length, selectedRecords.length]);

  return (
    <AppModalContainer
      title={modalTitle}
      visible={modal.visible}
      onHide={closeModal}
      onDismissed={() => {
        setSelectedSurveys([]);
        setFileType('csv');
        setLogType('auditlog');
        setWithMissedSurveys(false);
        setSignPdf(false);
      }}
      isClickOutside={true}
      bodyClassName={clsx(Style.modalBody, { [Style.patientDataExport]: logType === 'statistics' })}
      testId={'export-logs-from'}
      footer={buttons}
    >
      {content}
    </AppModalContainer>
  );
};
