import clsx from 'clsx';
import Style from './appAttachmentsField.module.css';
import React, { useCallback, useRef } from 'react';
import { TypographyStyles } from 'src/styles';
import { ReactComponent as CommonAddIcon } from 'src/assets/img/common/add.svg';
import { AppAttachmentValue, AppAttachmentsFieldProps } from './appAttachmentsField';
import { FileToAppAttachmentValue } from 'src/helpers/file-helper';
import { AppAttachmentsView } from '../attachmentsView/appAttachmentsView.component';
import { useScopedTranslation } from '../../../../../application/localisation/useScopedTranslation';
import { AppButton } from '../../../buttons';
import { AppInputError, AppInputLabel, ErrorSign } from '../../../wrappers';

export const AppAttachmentsField = (props: AppAttachmentsFieldProps) => {
  const { t } = useScopedTranslation('Forms.Controls.Attachments');
  const ref = useRef<HTMLInputElement | null>(null);

  const addFiles = useCallback(() => {
    if (ref && ref.current) {
      ref.current.click();
    }
  }, [ref]);

  const clearInputValue = useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  }, []);

  const deleteFile = useCallback(
    (file: AppAttachmentValue) => {
      if (!props.value) {
        return;
      }

      props.onChange(props.value.filter((f) => f !== file));
    },
    [props],
  );

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;

      if (files && files.length > 0) {
        const inputFilesArr = Array.from(files);

        if (!props.value || !props.multiple) {
          props.onChange(inputFilesArr.map(FileToAppAttachmentValue));
        } else {
          const difference: File[] = inputFilesArr.filter((x) => !props.value?.map((m) => m.fileName).includes(x.name));
          props.onChange([...props.value, ...difference.map(FileToAppAttachmentValue)]);
        }
      }
    },
    [props],
  );

  return (
    <div className={clsx(Style.container, props.extraStyles)}>
      <AppInputError errors={props.errorText}>
        <AppInputLabel
          text={props.label}
          disabled={props.disabled}
          isBold={props.isBold}
          tooltip={props.tooltip}
          rightSection={props.errorText ? <ErrorSign /> : <></>}
        >
          <div className={clsx(Style.filesWrapper, { [Style.disabled]: props.disabled })}>
            {props.value && props.value.length > 0 && (
              <AppAttachmentsView
                isEditMode={true}
                files={props.value}
                onDeletePress={deleteFile}
                disabled={props.disabled}
              />
            )}

            {!props.multiple && props.value && props.value?.length > 0 ? (
              <></>
            ) : (
              <AppButton
                disabled={props.disabled}
                className={clsx(TypographyStyles.paragraph14)}
                onClick={addFiles}
                variant={'icon-link'}
                colorSchema={'primary'}
                text={t('AttachTheFile')}
                Icon={CommonAddIcon}
              />
            )}
          </div>
        </AppInputLabel>
      </AppInputError>

      <input
        multiple={props.multiple}
        style={{ display: 'none' }}
        accept={props.fileTypes.join(',')}
        ref={ref}
        onChange={handleFileUpload}
        onClick={clearInputValue}
        type={'file'}
        disabled={props.disabled}
      />
    </div>
  );
};
