import {
  BaseControlSettings,
  defaultToolSettings,
} from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { UserComponent } from '@craftjs/core';
import { createId } from 'src/helpers/typeUtils';

export const unitsList = ['g/dL', 'mg/l', 'Cells/nl', 'l/s', 'l', '%', 'Zellen/nl', 'kg', 'cm', '  '] as const;

export interface ICalculatedInputProps extends BaseControlSettings {
  dataKey: string;
  label?: string;
  units?: (typeof unitsList)[number];
}

export type ICalculatedInput = UserComponent<ICalculatedInputProps>;

export function calculatedInputPropsFactory(): ICalculatedInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    label: undefined,
  };
}
