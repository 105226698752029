import React, { FC } from 'react';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { InfoField } from '../components/reportFields';
import { RPDF } from '../react-pdf';
import { ReportFirstPage } from '../components/ReportFirstPage';
import { ReportSignAndTitleField } from '../components/ReportSignAndTitleField';
import { StringKeyValueType } from '../../../helpers/typeUtils';

export type FirstPageDataType = {
  data: {
    profileFullName: string;
    studyNumber: string;
    filledRecordsCount: number;
    missedRecordsCount: number;
    patientUid: string;
    headerText: string;
    patientTags: StringKeyValueType[];
  };
};

export const FirstPage: FC<FirstPageDataType> = ({
  data: { profileFullName, studyNumber, filledRecordsCount, missedRecordsCount, patientUid, headerText, patientTags },
}) => {
  const { t } = useScopedTranslation('Reports.Records.FirstPage');

  const localStyles = RPDF.StyleSheet.create({
    fieldContainer: {
      flex: 1,
    },
  });

  const Header = (pages?: string | JSX.Element) => {
    return (
      <>
        <RPDF.View style={localStyles.fieldContainer}>
          <InfoField header={t('CreatedAt')} value={localFormat(new Date(), DateFormats.dateTime)} />
          <InfoField header={t('CreatedBy')} value={profileFullName} />
          <InfoField header={t('StudyNumber')} value={studyNumber} />
          <InfoField header={t('PatientId')} value={patientUid} />
          {patientTags.map((tag, index) => {
            return <InfoField key={index} header={tag.key} value={tag.value} />;
          })}
        </RPDF.View>
        <RPDF.View style={localStyles.fieldContainer}>
          <InfoField header={t('PageCount')} value={pages} />
          <InfoField header={t('FilledRecordsCount')} value={filledRecordsCount.toString()} />
          <InfoField header={t('MissedRecordsCount')} value={missedRecordsCount.toString()} />
        </RPDF.View>
      </>
    );
  };

  const Footer = () => <ReportSignAndTitleField customText={t('Signature', { name: profileFullName })} />;

  return <ReportFirstPage headerText={headerText} SignTitleComponent={Footer} HeaderComponent={Header} />;
};
