import { ITagInput } from '../../../../../features/forms/base/controls/inputs/TagInputProps';
import React, { useContext, useMemo } from 'react';
import { NoteContext } from '../../NoteContext';
import { NoteField } from '../../NoteField';

export const TagInput: ITagInput = (props) => {
  const noteContext = useContext(NoteContext);
  const inputValue = noteContext.formResult.result[props.dataKey] as string | string[] | undefined;

  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  if (!multipleInputValue?.length) return <></>;

  return <NoteField label={props.label ?? props.dataKey} value={multipleInputValue?.join(', ')} />;
};
