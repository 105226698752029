import React from 'react';
import { AppInputLabel, AppInputLabelProps } from '../../wrappers/label/appInputLabel.component';
import { AppInputError, AppInputErrorProps } from '../../wrappers/error/appInputError.component';
import { SkipCheckbox, SkipCheckboxProps } from '../../wrappers/skipCheckbox/skipCheckbox.component';
import { AppRadioGroupInputProps } from '../../inputs/radio/appRadioGroupInput';
import { FieldsWithType } from '../../../../helpers/typeUtils';
import Style from './appRadioGroupField.module.scss';
import { AppRadioGroupInput } from '../../inputs/radio/appRadioGroupInput.component';

export type AppRadioGroupFieldProps<
  TOption extends Record<Key, string | number>,
  Key extends FieldsWithType<TOption, string | number>,
> = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipCheckboxProps?: SkipCheckboxProps;
} & AppRadioGroupInputProps<TOption, Key>;

export function AppRadioGroupField<
  TOption extends Record<Key, string | number>,
  Key extends FieldsWithType<TOption, string | number>,
>({ errorProps, labelProps, skipCheckboxProps, disabled, ...rest }: AppRadioGroupFieldProps<TOption, Key>) {
  return (
    <AppInputError {...errorProps}>
      <SkipCheckbox className={Style.skipCheckboxContainer} disabled={disabled} {...skipCheckboxProps}>
        <AppInputLabel className={Style.labelContainer} disabled={disabled} {...labelProps}>
          <AppRadioGroupInput disabled={skipCheckboxProps?.isSkipped || disabled} {...rest} />
        </AppInputLabel>
      </SkipCheckbox>
    </AppInputError>
  );
}
