import React from 'react';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { hasFlag } from '../../../helpers/enum-helper';
import { IRouteProgressDto, TableColumnsEnum } from '../../../services/api/api-client';
import { PipelineComponent } from '../../pipeline/pipeline.component';
import Style from './pipelineSection.module.css';

export const PipelineSection = (props: {
  patientId: string;
  groupId: number;
  routeProgress?: IRouteProgressDto | null | undefined;
  uniqueId: string;
}) => {
  const { routeProgress, groupId, uniqueId, patientId } = props;
  const groups = useAppSelector((state) => state.app.groups);
  const groupColumns = groups.find((x) => x.id === groupId)?.tableColumns;

  if ((groupColumns && hasFlag(groupColumns, TableColumnsEnum.Stage)) || !routeProgress) {
    return <></>;
  }

  return (
    <div className={Style.container} data-test-id={`patient-card-pipeline--${uniqueId.toString()}`}>
      <PipelineComponent routeProgress={routeProgress} patientId={patientId} patientUniqueId={uniqueId} />
    </div>
  );
};
