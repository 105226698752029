import React, { PropsWithChildren } from 'react';
import { RPDF, Style } from '../react-pdf';
import { ReportHeader } from './ReportHeader';
import { ReportPagination } from './ReportPagination';

export type ReportFirstPageProps = {
  HeaderComponent?: (pageCount?: string | JSX.Element) => JSX.Element;
  SignTitleComponent?: () => JSX.Element;
  contentStyles?: Style | Style[];
  headerText: string;
};

export const ReportFirstPage: React.FC<PropsWithChildren<ReportFirstPageProps>> = (props) => {
  const localStyles = RPDF.StyleSheet.create({
    container: {
      ...RPDF.typography.text10,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    content: {
      flexDirection: 'column',
    },
    headerRow: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: 20,
    },
  });

  const pages = <RPDF.Text style={RPDF.typography.text10} render={({ totalPages }) => totalPages} />;

  return (
    <RPDF.Page size="A4" style={RPDF.styles.page}>
      <ReportHeader captionText={props.headerText} />
      <RPDF.View style={localStyles.container}>
        <RPDF.View style={localStyles.content}>
          {props.HeaderComponent && <RPDF.View style={localStyles.headerRow}>{props.HeaderComponent(pages)}</RPDF.View>}
          <RPDF.View style={props.contentStyles}>
            <>{props.children}</>
          </RPDF.View>
        </RPDF.View>

        {props.SignTitleComponent && <RPDF.View>{props.SignTitleComponent()}</RPDF.View>}
      </RPDF.View>

      <ReportPagination />
    </RPDF.Page>
  );
};
