/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { TypographyStyles } from 'src/styles';
import clsx from 'clsx';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import {
  checkBoxGroupInputDefaultPropsFactory,
  checkBoxGroupApplicableValidationRules,
  ICheckBoxGroupInputProps,
  ICheckBoxGroupInput,
} from '../../../../features/forms/base/controls/inputs/CheckBoxGroupInput';
import {
  useRefForDataBlockNavigation,
  useDefaultValues,
  useFieldDataFromUiEditorContext,
  useSetDefaultValueToAnswer,
  useFieldValidation,
} from './base/hooks';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { getStatusIcon } from './statusIcons/statusIcons.constants';
import { AppInputError } from '../../../uikit/wrappers/error/appInputError.component';
import { AppCheckboxInput } from '../../../uikit/inputs/checkbox/appCheckboxInput.component';
import { AppInputLabel } from '../../../uikit/wrappers/label/appInputLabel.component';
import { ConditionalFieldBehaviorEnum } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { Container } from '../Containers/Container';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import OptionsSettingsSection from '../../toolbox/settingsPanel/content/OptionsSettingsSection';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const CheckBoxGroupInput: ICheckBoxGroupInput = (props) => {
  const {
    actions: { setProp: setSelfProp },
    connectors: { connect, drag },
    id,
    linkedNodes,
  } = useNode((state) => ({
    linkedNodes: state.data.linkedNodes,
  }));

  const { size, dataKey, label, isDisabled } = props;
  const { multipleDefaultValue } = useDefaultValues(props);

  const {
    builderMode,
    allNodes,
    actions: { setProp },
  } = useEditor((state) => ({
    builderMode: state.options.enabled,
    allNodes: state.nodes,
  }));

  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);

  const { isOnlyView, multipleInputValue, setDataBlockFieldValue, markFieldAsTouched } =
    useFieldDataFromUiEditorContext(dataKey);

  useSetDefaultValueToAnswer(dataKey, props.defaultValue);
  const blockRef = useRefForDataBlockNavigation();

  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  // This effect update conditional options state
  useLayoutEffect(() => {
    if (builderMode) return;

    disableNotSelectedOptions();
  }, [multipleInputValue]);

  const disableNotSelectedOptions = useCallback(() => {
    const containersIds = Object.values(linkedNodes);
    if (!containersIds) {
      return;
    }

    const selectedOptions = props.options.filter((x) => multipleInputValue?.includes(x.value));

    const selectedOptionKeys = selectedOptions.map((o) => o.key.toString());
    Object.entries(linkedNodes).forEach(([key, containerId]) => {
      if (key === 'comment') return;

      // Disable option
      setProp(containerId, (p) => (p.isDisabled = !selectedOptionKeys.length || !selectedOptionKeys.includes(key)));

      // NOTE: Here was removing values of disabled nodes
      // but it caused that some values were not displayed
      // in editing mode,
      // because it deleted values of other fields,
      // if they had the same DataKey
    });
  }, [isOnlyView, linkedNodes, props.options, setSelfProp, allNodes, multipleInputValue]);

  const onChange = useCallback(
    (checked: boolean, itemValue: string) => {
      markFieldAsTouched?.(id);
      if (builderMode) {
        setSelfProp((setProps: ICheckBoxGroupInputProps) => {
          let checkedOptions = multipleDefaultValue?.slice() ?? [];
          if (checked) {
            checkedOptions.push(itemValue);
          } else {
            checkedOptions = checkedOptions.filter((v) => v !== itemValue);
          }

          setProps.defaultValue = checkedOptions;
        });
      } else {
        let checkedOptions = multipleInputValue?.slice() ?? [];
        if (checked) {
          checkedOptions.push(itemValue);
        } else {
          checkedOptions = checkedOptions.filter((v) => v !== itemValue);
        }

        setDataBlockFieldValue?.(dataKey, checkedOptions);
      }
    },
    [multipleInputValue, builderMode, multipleDefaultValue],
  );

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = props.options.filter((x) => x.withConditionalField);

    const finalArray = builderMode
      ? optionsWithConditionalField
      : optionsWithConditionalField.filter(
          (option) =>
            option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
            multipleInputValue?.includes(option.value),
        );

    return Array.from(finalArray, (option) => (
      <React.Fragment key={option.key}>
        {builderMode && <div className={styles.tabLabel}>{option.displayValue ?? ''}</div>}
        <Element key={option.value} id={option.key.toString()} is={Container} canvas={true} />
      </React.Fragment>
    ));
  }, [props.options, builderMode, multipleInputValue]);

  const editableText = (
    <div
      data-test-field-type={'checkbox-group-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={builderMode}
      ref={(ref) => builderMode && connect(drag(ref!))}
    >
      <div ref={blockRef} className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
        <AppInputError errors={dataBlockValidation?.text}>
          <AppInputLabel
            text={label || dataKey}
            tooltip={props.tooltip}
            disabled={isDisabled || isSubmitting}
            isBold={props.isBold}
            className={styles.label}
          >
            <div className={styles.optionVerticalContainer}>
              {props.options.map((x, index) => (
                <AppCheckboxInput
                  key={index}
                  value={x.value}
                  checked={
                    builderMode ? !!multipleDefaultValue?.includes(x.value) : !!multipleInputValue?.includes(x.value)
                  }
                  label={x.displayValue}
                  disabled={!props.isEditable || isOnlyView || isSubmitting}
                  onChange={(e) => onChange(e.target.checked, x.value)}
                />
              ))}
            </div>
          </AppInputLabel>
        </AppInputError>

        {conditionalContainer}
      </div>
    </div>
  );

  const displayValues = useMemo(() => {
    return props.options
      .filter((x) => multipleInputValue?.includes(x.value))
      .map((x) => (
        <span key={x.key}>
          {props.hasStatusIcons && getStatusIcon(x.statusIconName)} {x.displayValue}
        </span>
      ));
  }, [multipleInputValue, props.options, props.hasStatusIcons]);

  const notEditableText = (
    <>
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Records',
          topicAdditional: formConfig?.type,
          linkedPatientUniqId: patient?.patientId,
          fieldDescription: props.label ?? props.dataKey,
          resultId: formResult?.id ?? undefined,
          fieldId: id,
          stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
        }}
        ignoreFieldsForCount={['stepName']}
        position={{ right: 24, top: 6 }}
      >
        <div className={clsx(styles.previewRow, 'preview-row')}>
          <span className={TypographyStyles.heading2}>{label ?? dataKey}</span>

          <span className={TypographyStyles.plainText14}>{displayValues}</span>
        </div>
      </IssueMark>
      {conditionalContainer.length > 0 && conditionalContainer}
    </>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

const CheckBoxInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <OptionsSettingsSection />
      <LayoutSettings />
      <ValidationSettings applicableRules={checkBoxGroupApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

CheckBoxGroupInput.craft = {
  props: checkBoxGroupInputDefaultPropsFactory(),
  related: {
    settings: CheckBoxInputSettings,
  },
};
