import React, { FC } from 'react';
import { QuestionnaireVm } from './ManageSurveysModal';
import { DialogModal } from '../../../dialogModal/dialogModal.component';
import { AppButton } from '../../../uikit/buttons/button/button.component';
import { DateFormats, localFormat } from '../../../../helpers/date-helpers';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../../application/localisation/useCommonLocalization';

export const RevokeConfirmationDialog: FC<{
  onCancel: () => void;
  onApply: () => void;
  isLoading: boolean;
  questionnaireToDelete: QuestionnaireVm | null;
}> = (props) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection.SurveyManaging.Modal');
  const commonLocalizer = useCommonLocalization();

  return (
    <DialogModal
      isVisible={!!props.questionnaireToDelete}
      onClose={props.isLoading ? () => {} : props.onCancel}
      buttons={[
        <AppButton
          key={0}
          disabled={props.isLoading}
          variant={'button'}
          colorSchema={'secondary'}
          text={commonLocalizer('Common_no')}
          type={'reset'}
          onClick={props.onCancel}
        />,
        <AppButton
          key={1}
          variant={'button'}
          colorSchema={'destroy'}
          disabled={props.isLoading}
          isLoading={props.isLoading}
          hasLoaded={props.isLoading}
          text={t('DeleteConfirmationDialog.Apply')}
          onClick={props.onApply}
        />,
      ]}
      mainText={t('DeleteConfirmationDialog.MainText', {
        surveyType: props.questionnaireToDelete?.surveyType,
        date: props.questionnaireToDelete ? localFormat(props.questionnaireToDelete.start, DateFormats.longDate) : '',
      })}
      title={t('DeleteConfirmationDialog.Header')}
    />
  );
};
