/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import styles from '../controlsStyle.module.css';
import { Element, useEditor, useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from 'react-i18next';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import {
  ConditionalFieldBehaviorEnum,
  _isSkipped,
} from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import { TextInput } from './TextInput';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import { Container } from '../Containers/Container';
import {
  ISelectInputProps,
  selectInputDefaultPropsFactory,
  selectApplicableValidationRules,
  ISelectInput,
} from '../../../../features/forms/base/controls/inputs/SelectInput';
import { IFormFieldWithOptionsProps } from '../../../../features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import {
  useRefForDataBlockNavigation,
  useDefaultValues,
  useFieldDataFromUiEditorContext,
  useSetDefaultValueToAnswer,
  useFieldValidation,
} from './base/hooks';
import { CommentDataKey, FormFillContext } from '../../uiEditor/provider/formFill.context';
import { getStatusIcon } from './statusIcons/statusIcons.constants';
import { textInputDefaultPropsFactory } from '../../../../features/forms/base/controls/inputs/TextInput';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { AppDropdownField } from 'uikit/fields';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import OptionsSettingsSection from '../../toolbox/settingsPanel/content/OptionsSettingsSection';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const SelectInput: ISelectInput = (props) => {
  const { t } = useTranslation();
  const {
    actions: { setProp: setSelfProp },
    connectors: { connect, drag },
    id,
    linkedNodes,
  } = useNode((state) => ({
    linkedNodes: state.data.linkedNodes,
  }));
  const { size, dataKey, isDisabled, isEditable, skipCheckBoxText, hasSkipCheckBox } = props;
  const { multipleDefaultValue } = useDefaultValues(props);

  const {
    builderMode,
    actions: { setProp },
    allNodes,
  } = useEditor((state) => ({
    builderMode: state.options.enabled,
    allNodes: state.nodes,
  }));

  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);

  const { isOnlyView, inputValue, multipleInputValue, commentValue, setDataBlockFieldValue, markFieldAsTouched } =
    useFieldDataFromUiEditorContext(dataKey);

  useSetDefaultValueToAnswer(dataKey, props.defaultValue);
  const blockRef = useRefForDataBlockNavigation();
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  // This effect update conditional options state
  useLayoutEffect(() => {
    if (builderMode) return;

    disableNotSelectedOptions();
  }, [inputValue]);

  const disableNotSelectedOptions = useCallback(() => {
    const containersIds = Object.values(linkedNodes);
    if (!containersIds) {
      return;
    }

    const selectedOptions = props.options.filter(
      (x) => multipleInputValue?.includes(x.value) || inputValue === x.value,
    );

    const selectedOptionKeys = selectedOptions.map((o) => o.key.toString());
    Object.entries(linkedNodes).forEach(([key, containerId]) => {
      if (key === 'comment') return;

      // Disable option
      setProp(containerId, (p) => (p.isDisabled = !selectedOptionKeys.length || !selectedOptionKeys.includes(key)));

      // NOTE: Here was removing values of disabled nodes
      // but it caused that some values were not displayed
      // in editing mode,
      // because it deleted values of other fields,
      // if they had the same DataKey
    });
  }, [isOnlyView, linkedNodes, props.options, setProp, inputValue, allNodes]);

  type CustomDropDownOption = DropdownOption & { value: string };

  const dropDownOptions: CustomDropDownOption[] = useMemo(() => {
    return props.options.map((x) => {
      return {
        key: x.key,
        text: x.displayValue,
        value: x.value,
        icon: props.hasStatusIcons ? getStatusIcon(x.statusIconName) : undefined,
      };
    });
  }, [props.options, props.hasStatusIcons]);

  const selectedOptions = useMemo(() => {
    const currentValue = builderMode ? multipleDefaultValue : multipleInputValue;
    return dropDownOptions.filter((x) => currentValue?.includes(x.value));
  }, [dropDownOptions, multipleDefaultValue, multipleInputValue, builderMode]);

  const setScalarValue = useCallback(
    (opt: CustomDropDownOption | undefined) => {
      markFieldAsTouched?.(id);
      if (builderMode) {
        setSelfProp((p: IFormFieldWithOptionsProps) => {
          p.defaultValue = opt?.value;
        });
      } else {
        setDataBlockFieldValue?.(dataKey, opt?.value);
      }
    },
    [setSelfProp, builderMode],
  );

  const setVectorValues = useCallback(
    (opts: CustomDropDownOption[] | undefined) => {
      markFieldAsTouched?.(id);
      if (builderMode) {
        setSelfProp((p: IFormFieldWithOptionsProps) => {
          p.defaultValue = opts?.map((x) => x.value);
        });
      } else {
        setDataBlockFieldValue?.(
          dataKey,
          opts?.map((x) => x.value),
        );
      }
    },
    [setSelfProp, builderMode],
  );

  const dropDownComponent = props.isMulti ? (
    <AppDropdownField
      isMultiple={true}
      labelProps={{
        isBold: props.isBold,
        text: props.label || dataKey,
        tooltip: props.tooltip,
      }}
      placeholder={props.placeholder}
      value={selectedOptions}
      disabled={!isEditable || isDisabled || isSubmitting}
      options={dropDownOptions}
      onChange={setVectorValues}
      errorProps={{ errors: dataBlockValidation?.text }}
      isRequired={props.validation?.isRequired}
      skipCheckboxProps={{
        hasSkipCheckBox: hasSkipCheckBox,
        skipText: skipCheckBoxText,
        isSkipped: multipleInputValue?.[0] === _isSkipped,
        onSkippedChange: (val) => {
          markFieldAsTouched?.(id);
          setDataBlockFieldValue?.(dataKey, val ? _isSkipped : undefined);
        },
      }}
    />
  ) : (
    <AppDropdownField
      labelProps={{
        isBold: props.isBold,
        text: props.label || dataKey,
        tooltip: props.tooltip,
      }}
      placeholder={props.placeholder}
      isRequired={props.validation?.isRequired}
      disabled={!isEditable || isDisabled || isSubmitting}
      value={selectedOptions[0]}
      options={dropDownOptions}
      onChange={setScalarValue}
      errorProps={{ errors: dataBlockValidation?.text }}
      skipCheckboxProps={{
        hasSkipCheckBox: hasSkipCheckBox,
        skipText: skipCheckBoxText,
        isSkipped: multipleInputValue?.[0] === _isSkipped,
        onSkippedChange: (val) => {
          markFieldAsTouched?.(id);
          setDataBlockFieldValue?.(dataKey, val ? _isSkipped : undefined);
        },
      }}
    />
  );

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = props.options.filter((x) => x.withConditionalField);

    const finalArray = builderMode
      ? optionsWithConditionalField
      : optionsWithConditionalField.filter(
          (option) =>
            option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
            multipleInputValue?.includes(option.value),
        );

    return Array.from(finalArray, (option) => (
      <React.Fragment key={option.key}>
        {builderMode && <div className={styles.tabLabel}>{option.displayValue ?? ''}</div>}
        <Element key={option.value} id={option.key.toString()} is={Container} canvas={true} />
      </React.Fragment>
    ));
  }, [props.options, builderMode, multipleInputValue]);

  const editableText = (
    <div
      data-test-field-type={'select-input'}
      className={styles.container}
      style={{ width: size }}
      draggable={builderMode}
      ref={(ref) => builderMode && connect(drag(ref!))}
    >
      <div ref={blockRef} className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
        {dropDownComponent}

        {conditionalContainer}

        {props.withComment && (
          <Element
            id={'comment'}
            is={TextInput}
            {...textInputDefaultPropsFactory()}
            dataKey={CommentDataKey(dataKey)}
            isMultiline={true}
            withTrigger={true}
            isDisabled={!isEditable || isDisabled || !!isSubmitting}
            label={t('Forms.Controls.CommentFieldCaption')}
            triggerType={'Comment'}
            triggerState={commentValue ? 'Field' : 'Trigger'}
            size={size}
          />
        )}
      </div>
    </div>
  );

  const displayValues = useMemo(() => {
    return props.options
      .filter((x) => multipleInputValue?.includes(x.value))
      .map((x) => (
        <span key={x.key}>
          {props.hasStatusIcons && getStatusIcon(x.statusIconName)} {x.displayValue}
        </span>
      ));
  }, [multipleInputValue, props.options, props.hasStatusIcons]);

  const notEditableText = (
    <>
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Records',
          topicAdditional: formConfig?.type,
          linkedPatientUniqId: patient?.patientId,
          fieldDescription: props.label ?? props.dataKey,
          resultId: formResult?.id ?? undefined,
          fieldId: id,
          stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
        }}
        ignoreFieldsForCount={['stepName']}
        position={{ right: 24, top: 6 }}
      >
        <div className={clsx(styles.previewRow, 'preview-row')}>
          <span className={TypographyStyles.heading2}>{props.label ?? props.dataKey}</span>
          {multipleInputValue &&
            (multipleInputValue[0] === _isSkipped ? (
              <span className={clsx(TypographyStyles.plainText14, styles.textPlain)}>
                {props.skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox')}
              </span>
            ) : (
              <span className={TypographyStyles.plainText14}>{displayValues}</span>
            ))}
        </div>
        {commentValue && (
          <div className={clsx(styles.previewRow, 'preview-row')}>
            <span className={TypographyStyles.heading2}>{t('Forms.Controls.CommentFieldCaption')}</span>
            <span className={TypographyStyles.plainText14}>{commentValue}</span>
          </div>
        )}
      </IssueMark>
      {conditionalContainer.length > 0 && conditionalContainer}
    </>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

const SelectInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ISelectInputProps,
  }));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.placeholder')}
        expanded={!!props.placeholder}
        collapsible
        flexible
        onOpen={() => {
          setProp((prop: ISelectInputProps) => {
            prop.placeholder = 'Placeholder';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ISelectInputProps) => {
            prop.placeholder = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          multiple
          value={props.placeholder || ''}
          onChange={(e) => {
            setProp((setProps: ISelectInputProps) => {
              setProps.placeholder = e.target?.value ? e.target.value : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>

      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.isMultiselect')}
          isChecked={!!props.isMulti}
          setValue={(newValue) => setProp((p: ISelectInputProps) => (p.isMulti = newValue ? newValue : undefined))}
        />
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.withComment')}
          isChecked={!!props.withComment}
          setValue={(newValue) =>
            setProp((setProps: ISelectInputProps) => {
              setProps.withComment = newValue;
            })
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const SelectInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <SelectInputGeneralSettings />
      <OptionsSettingsSection />
      <LayoutSettings />
      <ValidationSettings applicableRules={selectApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

SelectInput.craft = {
  props: selectInputDefaultPropsFactory(),
  related: {
    settings: SelectInputSettings,
  },
};
