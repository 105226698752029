import React from 'react';
import { RPDF } from '../react-pdf';
import { ReportHeader } from '../components/ReportHeader';
import { ReportPagination } from '../components/ReportPagination';
import { ReportEmptyList } from '../components/ReportEmptyList';
import { IssueReportOptions } from './issueReport';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import i18n from 'i18next';
import { IssueReportTable } from './IssueTable';
import { FirstPageInfo } from './issueFirstPage';
import { IssueReportFilters } from './issueFilters';

export const IssueReportTemplate: React.FC<IssueReportOptions> = (props) => {
  const { t } = useScopedTranslation('Reports.Issue');
  const { data, filters, studyNumber, furtherInfo } = props;

  const firstPage = <FirstPageInfo data={{ issuesCount: data.length, furtherInfo, studyNumber: studyNumber }} />;

  const issueTablePage = (
    <RPDF.Page size="A4" orientation="landscape" style={RPDF.styles.landscapePage}>
      <ReportHeader
        captionText={t('DocumentHeader')}
        footer={filters && <IssueReportFilters filters={filters} groups={props.studyGroups} />}
      />
      {data.length > 0 ? (
        <RPDF.View>
          <IssueReportTable data={data} />
        </RPDF.View>
      ) : (
        <ReportEmptyList />
      )}
      <ReportPagination />
    </RPDF.Page>
  );

  return (
    <RPDF.Document
      language={i18n.language}
      producer={'LM Study'}
      title={t('DocumentHeader')}
      author={'LM user'}
      subject={studyNumber}
    >
      {firstPage}
      {issueTablePage}
    </RPDF.Document>
  );
};
