﻿import React, { FC, useMemo, useRef, useState } from 'react';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { useModal } from '../../../../application/hooks/useModal';
import { Trans, useTranslation } from 'react-i18next';
import styles from './ClosedStepModal.module.scss';
import { AppButton } from 'uikit/buttons';
import { Deferred } from '../../../../helpers/Deferred';

type OpenArgs = { formType: string; stepName: string };

export const useClosedStepModal = () => {
  const modal = useModal('CLOSED');
  const [modalProps, setModalProps] = useState<{ formType: string; stepName: string }>();
  const deferred = useRef<Deferred>();

  return useMemo(() => {
    return {
      open: (args: OpenArgs) => {
        setModalProps(args);
        modal.openModal();
        deferred.current = new Deferred();
        return deferred.current.promise;
      },
      element: modalProps && (
        <ClosedStepModal
          {...modalProps!}
          visible={modal.visible}
          onHide={() => {
            modal.closeModal();
            deferred.current?.resolve();
          }}
        />
      ),
    };
  }, [modal, modalProps]);
};

export const ClosedStepModal: FC<{
  visible: boolean;
  onHide: () => void;
  formType: string;
  stepName: string;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <AppModalContainer
      bodyClassName={styles.modalBody}
      title={t('Forms.ClosedStepModal.Header')}
      visible={props.visible}
      onHide={props.onHide}
      footer={
        <div className={styles.footer}>
          <AppButton
            colorSchema={'primary'}
            className={styles.button}
            onClick={props.onHide}
            text={t('Forms.ClosedStepModal.Button')}
          />
        </div>
      }
    >
      <span>
        <Trans
          t={t}
          i18nKey={'Forms.ClosedStepModal.Text'}
          values={{ formType: props.formType, stepName: props.stepName }}
          components={{ Bold: <span className={styles.bold} /> }}
        />
      </span>
    </AppModalContainer>
  );
};
