/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import {
  useDefaultValues,
  useFieldDataFromUiEditorContext,
  useRefForDataBlockNavigation,
  useSetDefaultValueToAnswer,
  useFieldValidation,
} from './base/hooks';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { addMilliseconds, startOfToday } from 'date-fns';
import { IFormFieldWithOptionsProps } from '../../../../features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import { ReactComponent as Clock } from '../../../../assets/img/common/clock_20.svg';
import {
  ITimeInputProps,
  timeInputApplicableValidationRules,
  timeInputDefaultPropsFactory,
} from '../../../../features/forms/base/controls/inputs/TimeInput';
import { DateFormats, localFormat } from '../../../../helpers/date-helpers';
import { DropdownOption } from '../../../uikit/inputs/dropdown/appDropdownInput';
import { AppDropdownField } from '../../../uikit/fields/dropdown/appDropdownField.component';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { _isSkipped } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

const millsToTimeString = (timeInMills: number) => {
  return localFormat(addMilliseconds(startOfToday(), timeInMills), DateFormats.timeOnly);
};

type CustomDropDownOption = DropdownOption & { value: string };

export const TimeInput = (props: ITimeInputProps) => {
  const { size, dataKey, isDisableWhenEditing, isDisabled, hasSkipCheckBox, skipCheckBoxText } = props;
  const { t } = useTranslation();
  const { enabled: isConstructorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    connectors: { connect, drag },
    id,
    actions: { setProp: setSelfProp },
  } = useNode();
  const { singleDefaultValue } = useDefaultValues(props);

  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);

  const { isOnlyView, isEditMode, singleInputValue, setDataBlockFieldValue, markFieldAsTouched } =
    useFieldDataFromUiEditorContext(dataKey);

  useSetDefaultValueToAnswer(dataKey, props.defaultValue);
  const blockRef = useRefForDataBlockNavigation();
  const [initialValue] = useState(singleInputValue);
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const dropDownOptions: CustomDropDownOption[] = useMemo(() => {
    const options: CustomDropDownOption[] = [];
    const totalMillsInADay = 24 * 60 * 60000;
    const intervalInMills = (props?.stepInMinutes || 1) * 60000;
    for (let timeInMills = 0; timeInMills < totalMillsInADay; timeInMills += intervalInMills) {
      const text = millsToTimeString(timeInMills);
      options.push({
        key: timeInMills,
        value: text,
        text: text,
      });
    }

    return options;
  }, [props.stepInMinutes]);

  const selectedOption = useMemo(() => {
    const comparableValue = isConstructorMode ? singleDefaultValue : singleInputValue;
    return dropDownOptions.find((x) => x.key.toString() === comparableValue);
  }, [singleInputValue, dropDownOptions, singleDefaultValue]);

  const onChange = useCallback(
    (opt: CustomDropDownOption | undefined) => {
      if (isConstructorMode) {
        setSelfProp((p: IFormFieldWithOptionsProps) => {
          p.defaultValue = opt?.key.toString();
        });
      } else {
        markFieldAsTouched?.(id);
        setDataBlockFieldValue?.(dataKey, opt?.key.toString());
      }
    },
    [setSelfProp, isConstructorMode],
  );

  const editableText = (
    <div
      data-test-field-type={'time-input'}
      className={styles.container}
      style={{ width: size }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef} className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
        <AppDropdownField
          labelProps={{
            isBold: props.isBold,
            text: props.label || dataKey,
            tooltip: props.tooltip,
          }}
          placeholder={props.placeholder}
          isRequired={props.validation?.isRequired}
          disabled={
            !props.isEditable || (isDisableWhenEditing && isEditMode && !!initialValue) || isDisabled || isSubmitting
          }
          errorProps={{ errors: dataBlockValidation?.text }}
          CaretIcon={Clock}
          value={selectedOption}
          options={dropDownOptions}
          onChange={onChange}
          skipCheckboxProps={{
            hasSkipCheckBox: hasSkipCheckBox,
            skipText: skipCheckBoxText,
            isSkipped: singleInputValue === _isSkipped,
            onSkippedChange: (val) => {
              markFieldAsTouched?.(id);
              setDataBlockFieldValue?.(dataKey, val ? _isSkipped : undefined);
            },
          }}
        />
      </div>
    </div>
  );

  const notEditableText = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResult?.id ?? undefined,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={{ right: 24, top: 6 }}
    >
      <div className={clsx(styles.previewRow, 'preview-row')}>
        <span className={TypographyStyles.heading2}>{props.label ?? props.dataKey}</span>
        {singleInputValue &&
          (singleInputValue === _isSkipped ? (
            <span className={clsx(TypographyStyles.plainText14, styles.textPlain)}>
              {props.skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox')}
            </span>
          ) : (
            <span className={TypographyStyles.plainText14}>{millsToTimeString(+singleInputValue)}</span>
          ))}
      </div>
    </IssueMark>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

const TimeInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ITimeInputProps,
  }));
  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.step')} flexible>
        <SettingsInput
          value={props.stepInMinutes}
          type={'number'}
          onChange={(e) => {
            setProp((setProps: ITimeInputProps) => {
              setProps.stepInMinutes = parseInt(e.target?.value) || 1;
            });
          }}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const TimeInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <TimeInputGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={timeInputApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

TimeInput.craft = {
  props: timeInputDefaultPropsFactory(),
  related: {
    settings: TimeInputSettings,
  },
};
