import { useModal } from '../../../application/hooks/useModal';
import React, { useCallback, useRef, useState } from 'react';
import { FormDto, FormResultDto, IRouteProgressDto, RouteProgressStateEnum } from '../../../services/api/api-client';
import { QueryFactory } from '../../../services/api';
import { useQueryClient } from '@tanstack/react-query';
import { AppButton } from 'uikit/buttons';
import { DialogModal } from '../../../components/dialogModal/dialogModal.component';
import { useTranslation } from 'react-i18next';
import { logger } from '../../../application/logging/logging';
import { FormModalWithContext } from 'src/components/formEditor/modals/formModalWithContext';
import getInitialAnswers from './getInitialAnswers';
import useApplyPatientFilter from '../../patients/useApplyPatientFilter';
import { useClosedStepModal } from './closedStepModal/ClosedStepModal';
import { getPatientRouteProgress } from '../../../services/api/api-client/StudyRoutesClient';
import { getFormById } from '../../../services/api/api-client/FormsClient';
import { Deferred } from '../../../helpers/Deferred';

const documentsWithPatientStatus = ['Stationäre Aufenthalt'];

export function useFormEditing() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const applyPatientFilter = useApplyPatientFilter();

  const editModal = useModal();
  const createModal = useModal();
  const clinicStateModal = useModal();
  const closedStepModal = useClosedStepModal();

  const [patientId, setPatientId] = useState<string>();
  const [formConfig, setFormConfig] = useState<FormDto>();
  const [formResult, setFormResult] = useState<FormResultDto>();
  const [routeStepName, setRouteStepName] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleDialogModal = useCallback(
    async (result: boolean) => {
      await applyPatientFilter(patientId!, 'isClinic', result);
      clinicStateModal.closeModal();
    },
    [applyPatientFilter, patientId, clinicStateModal],
  );

  //#region Start filling

  type FillingResult = { type: 'Saved'; formResult: FormResultDto } | { type: 'Canceled' };
  const deferredFillingResult = useRef<Deferred<FillingResult>>();

  type StartFillingArgs = {
    patientId: string;
    routeProgress?: Promise<IRouteProgressDto> | IRouteProgressDto;
    formId: number;
    stepName?: string;
  };

  const startFormFilling = useCallback(
    async (args: StartFillingArgs) => {
      setIsLoading(true);
      const deferredResult = new Deferred<FillingResult>();
      deferredFillingResult.current = deferredResult;
      deferredEditingResult.current = undefined;

      try {
        const formConfigFromServer = await getFormById(args.formId);
        if (args.stepName) {
          const _routeProgress = (await args.routeProgress) ?? (await getPatientRouteProgress(args.patientId));
          const stepState = _routeProgress.steps.find((x) => x.name === args.stepName)?.state;

          if (
            stepState !== RouteProgressStateEnum.InProgress &&
            stepState !== RouteProgressStateEnum.InProgressManually
          ) {
            await closedStepModal.open({ formType: formConfigFromServer.type, stepName: args.stepName });
            deferredResult.resolve({ type: 'Canceled' });
            return deferredResult.promise;
          }

          const initialAnswers = await getInitialAnswers(
            args.stepName,
            formConfigFromServer.type,
            _routeProgress!,
            args.patientId,
          );
          setFormResult(initialAnswers);
        } else {
          setFormResult(undefined);
        }

        setPatientId(args.patientId);
        setFormConfig(formConfigFromServer);
        setRouteStepName(args.stepName);
      } catch (error: any) {
        deferredResult.reject(error);
        logger().error(error);
      } finally {
        setIsLoading(false);
      }

      createModal.openModal();
      return deferredResult.promise;
    },
    [closedStepModal, createModal],
  );

  //#endregion

  //#region Start editing

  type EditingResult = { type: 'Saved'; formResult: FormResultDto } | { type: 'Canceled' };
  const deferredEditingResult = useRef<Deferred<EditingResult>>();

  type StartEditingArgs = {
    formResult: Promise<FormResultDto> | FormResultDto;
    form?: FormDto;
  };

  const startFormEditing = useCallback(
    async (args: StartEditingArgs) => {
      setIsLoading(true);
      const deferredResult = new Deferred<EditingResult>();
      deferredEditingResult.current = deferredResult;
      deferredFillingResult.current = undefined;

      try {
        const _formResult = await args.formResult;
        if (!args.form) {
          args.form = await getFormById(_formResult.formConfigId);
        }

        setPatientId(_formResult.patientId);
        setFormConfig(args.form);
        setFormResult(_formResult);
        setRouteStepName(undefined);
      } catch (error: any) {
        deferredResult.reject(error);
        logger().error(error);
      } finally {
        setIsLoading(false);
      }

      editModal.openModal();

      return deferredResult.promise;
    },
    [editModal],
  );

  //#endregion

  const onResultSaved = useCallback(
    async (savedFormResult: FormResultDto) => {
      await Promise.all([
        queryClient.invalidateQueries(QueryFactory.FormsQuery.getFormBindingsQueryKey(patientId!, undefined, true)),
        queryClient.invalidateQueries(QueryFactory.FormsQuery.getFormResultsQueryKey()),
        queryClient.invalidateQueries(QueryFactory.FormsQuery.getFormResultDatesQueryKey()),
      ]);

      if (documentsWithPatientStatus.includes(formConfig?.type || '')) {
        clinicStateModal.openModal();
      }

      deferredEditingResult.current?.resolve({ type: 'Saved', formResult: savedFormResult });
      deferredEditingResult.current = undefined;
      deferredFillingResult.current?.resolve({ type: 'Saved', formResult: savedFormResult });
      deferredEditingResult.current = undefined;
    },
    [queryClient, patientId, formConfig?.type, clinicStateModal],
  );

  return {
    isLoading,
    startFormFilling,
    startFormEditing,
    element: (
      <React.Fragment key={formConfig?.id}>
        {closedStepModal.element}
        {formConfig && (
          <FormModalWithContext
            mode={'Create'}
            formConfig={formConfig}
            formResult={formResult}
            modal={{
              title: formConfig.type,
              testId: `${formConfig.type}-form`,
              visible: createModal.visible,
              onHide: () => {
                createModal.closeModal();
                deferredFillingResult.current?.resolve({ type: 'Canceled' });
              },
            }}
            patientId={patientId!}
            stepName={routeStepName}
            onSubmitted={onResultSaved}
          />
        )}
        {formResult && formConfig && (
          <FormModalWithContext
            mode={'Edit'}
            formConfig={formConfig}
            formResult={formResult}
            modal={{
              title: formConfig.type,
              testId: `${formConfig.type}-form`,
              visible: editModal.visible,
              onHide: () => {
                editModal.closeModal();
                deferredEditingResult.current?.resolve({ type: 'Canceled' });
              },
            }}
            patientId={patientId!}
            onSubmitted={onResultSaved}
          />
        )}
        <DialogModal
          isVisible={clinicStateModal.visible}
          onClose={clinicStateModal.closeModal}
          buttons={[
            <AppButton
              variant={'button'}
              colorSchema={'primary'}
              text={t('Common_yes')}
              onClick={() => handleDialogModal(true)}
              key={0}
            />,
            <AppButton
              variant={'button'}
              colorSchema={'secondary'}
              text={t('Common_no')}
              onClick={() => handleDialogModal(false)}
              key={1}
            />,
          ]}
          mainText={t('Dashboard.InClinicModal.Text')}
          title={t('Dashboard.InClinicModal.Title')}
        />
      </React.Fragment>
    ),
  };
}
