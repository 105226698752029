import React, { FC } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { AppRoutes } from './routes';
import { NavAppMenu } from '../containers/navigationMenu/navAppBar.component';
import { NotificationProvider } from '../providers/notificationProvider';
import Style from './index.module.css';
import { logger } from '../application/logging/logging';
import { handle403Forbidden, useIsAuthorized } from '../helpers/auth/auth-interceptor';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { AccessDbProvider } from '../providers/accessDbProvider/accessDbProvider';
import { PageLoading } from 'uikit/suspense/PageLoading';
import { IssuesProvider } from '../components/issue/provider/issues.provider';
import { AppToastContainer } from '../components/toast/AppToastContainer';

export const Layout: FC = () => {
  const isAuth = useIsAuthorized();
  const navigation = useNavigate();
  const queryClient = useQueryClient();
  axios.interceptors.response.use(undefined, (err) => handle403Forbidden(navigation, queryClient, err));

  // not logged in so redirect to login page with the return url
  if (!isAuth) {
    queryClient.cancelQueries();
    logger().info('User is not authorized. Redirecting to Login page...');
    return (
      <>
        <PageLoading loading />
        <Navigate to={AppRoutes.Login} replace={true} />
      </>
    );
  }

  return (
    <div className={Style.authContainer}>
      <AccessDbProvider>
        <IssuesProvider>
          <NotificationProvider>
            <NavAppMenu />

            <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', flex: '1 1 auto' }}>
              <Outlet />
            </div>

            <AppToastContainer />
          </NotificationProvider>
        </IssuesProvider>
      </AccessDbProvider>
    </div>
  );
};
