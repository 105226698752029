import React from 'react';
import Style from './settingsInput.module.scss';
import clsx from 'clsx';
import { AppTextInputProps } from 'uikit/inputs';

type SettingsInputProps = AppTextInputProps & {
  badge?: JSX.Element;
  regex?: string;
  units?: string;
  inputClassname?: string;
};

export const SettingsInput = React.forwardRef<HTMLInputElement, SettingsInputProps>(function SettingsInput(
  { badge, className, units, type, multiple, disabled, ...rest },
  ref,
) {
  return (
    <div className={clsx(Style.container, className)}>
      {badge}
      {type === 'color' && <input type={'color'} className={Style.colorIndicator} disabled={disabled} {...rest} />}
      {multiple ? (
        <textarea
          // @ts-ignore
          ref={ref}
          className={Style.textArea}
          disabled={disabled}
          {...rest}
        />
      ) : (
        <input
          ref={ref}
          type={type === 'color' ? undefined : type}
          className={Style.input}
          disabled={disabled || type === 'color'}
          {...rest}
        />
      )}

      {units && <span>{units}</span>}
    </div>
  );
});
