import React, { FC, PropsWithChildren } from 'react';
import Style from './skipCheckbox.module.scss';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AppCheckboxInput } from 'uikit/inputs';

export type SkipCheckboxProps = {
  hasSkipCheckBox?: boolean;
  skipText?: string;
  isSkipped?: boolean;
  onSkippedChange?: (b: boolean) => void;
  disabled?: boolean;
  className?: string;
};

export const SkipCheckbox: FC<PropsWithChildren<SkipCheckboxProps>> = ({
  hasSkipCheckBox,
  isSkipped,
  onSkippedChange,
  children,
  disabled,
  className,
  skipText,
}) => {
  const { t } = useTranslation();

  if (!hasSkipCheckBox) {
    return <>{children}</>;
  }

  return (
    <div className={clsx(Style.skipCheckboxContainer, className)}>
      {children}
      <AppCheckboxInput
        label={skipText || t('Forms.Controls.SkipQuestionCheckBox')}
        disabled={disabled}
        onChange={(e) => onSkippedChange?.(e.target.checked)}
        checked={isSkipped}
      />
    </div>
  );
};
