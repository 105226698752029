/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { TypographyStyles } from 'src/styles';
import clsx from 'clsx';
import { useContextSelector } from 'use-context-selector';
import { AppAttachmentsView } from 'uikit/fields/attachments/attachmentsView/appAttachmentsView.component';
import { TextInput } from './TextInput';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import { useTranslation } from 'react-i18next';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import { FileTypes } from '../../../../helpers/fileValidation-helpers';
import styles from '../controlsStyle.module.css';
import {
  filesDefaultPropsFactory,
  filesApplicableValidationRules,
  IFilesProps,
  IFiles,
} from '../../../../features/forms/base/controls/inputs/Files';
import { useRefForDataBlockNavigation, useFieldDataFromUiEditorContext, useFieldValidation } from './base/hooks';
import { CommentDataKey, FormFillContext } from '../../uiEditor/provider/formFill.context';
import { textInputDefaultPropsFactory } from '../../../../features/forms/base/controls/inputs/TextInput';
import { AppAttachmentsField } from '../../../uikit/fields';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const Files: IFiles = (props) => {
  const { t } = useTranslation();
  const { connectors, id } = useNode();

  const { connect, drag } = connectors;

  const { size, dataKey, isDisabled } = props;

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);

  const { isOnlyView, setDataBlockFieldValue, markFieldAsTouched, commentValue } =
    useFieldDataFromUiEditorContext(dataKey);

  const inputValue = useContextSelector(FormFillContext, (v) => {
    // @ts-ignore
    return v.values ? (v.values[dataKey] as AppAttachmentValue[]) : null;
  });

  const blockRef = useRefForDataBlockNavigation();
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const notEditableControl = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResult?.id ?? undefined,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={{ right: 24, top: 6 }}
    >
      <div className={clsx(styles.previewRow, 'preview-row')}>
        <span className={TypographyStyles.heading2}>{props.label ?? props.dataKey}</span>
        <div style={{ minWidth: 0 }}>{inputValue && <AppAttachmentsView files={inputValue} />}</div>
      </div>
      {commentValue && (
        <div className={clsx(styles.previewRow, 'preview-row', styles.previewRowOverride)}>
          <div className={styles.radioButtonPreviewRow}>
            <span className={TypographyStyles.heading2}>{t('Forms.Controls.CommentFieldCaption')}</span>
            <span className={TypographyStyles.plainText14}>{commentValue}</span>
          </div>
        </div>
      )}
    </IssueMark>
  );

  const editableControl = (
    <div
      data-test-field-type={'file-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={enabled}
      ref={(ref) => enabled && connect(drag(ref!))}
    >
      <div ref={blockRef}>
        <AppAttachmentsField
          onChange={(x) => {
            markFieldAsTouched?.(id);
            // @ts-ignore
            setDataBlockFieldValue?.(dataKey, x);
          }}
          value={inputValue}
          disabled={!props.isEditable || isDisabled || isSubmitting}
          error={!!dataBlockValidation?.text}
          errorText={dataBlockValidation?.text}
          label={props.hideLabel ? undefined : props.label || dataKey}
          tooltip={props.tooltip}
          isBold={props.isBold}
          fileTypes={FileTypes}
          multiple={true}
        />

        {props.withComment && (inputValue?.length || enabled) && !dataBlockValidation?.text && (
          <Element
            is={TextInput}
            id={'comment'}
            {...textInputDefaultPropsFactory()}
            dataKey={CommentDataKey(dataKey)}
            isMultiline={true}
            withTrigger={true}
            isDisabled={!props.isEditable || isDisabled || !!isSubmitting}
            label={t('Forms.Controls.CommentFieldCaption')}
            triggerType={'Comment'}
            triggerState={commentValue ? 'Field' : 'Trigger'}
            size={size}
          />
        )}
      </div>
    </div>
  );

  return <>{!isOnlyView ? editableControl : notEditableControl}</>;
};

const FilesGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IFilesProps,
  }));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.withComment')}
          isChecked={!!props.withComment}
          setValue={(newValue) =>
            setProp((setProps: IFilesProps) => {
              setProps.withComment = newValue ? newValue : undefined;
            })
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const FilesSettings = () => {
  return (
    <>
      <TitleSettings />
      <FilesGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={filesApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

Files.craft = {
  props: filesDefaultPropsFactory(),
  related: {
    settings: FilesSettings,
  },
};
