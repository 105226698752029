import React, { useCallback, useEffect, useState } from 'react';
import { isConditionalOption } from 'src/helpers/question-helper';
import { QueryFactory } from 'src/services/api';
import { BaseOption, IBaseOption, IBaseQuestion, QuestionType, SkipOptions } from 'src/services/api/api-client';
import { changeField } from '../components/editableField/editableField.component';
import { createCommonOption } from '../helpers/option-helpers';
import {
  getQuestionById,
  moveQuestion,
  pasteQuestionAfter,
  removeQuestionById,
  toCreateQuestionDto,
} from '../helpers/questionScheme-helper';
import { Editor } from '../sections/editor/editor.component';
import { OptionToolBox } from '../sections/optionToolBox/optionToolBox.component';
import { QuestionToolBox as QuestionToolBox } from '../sections/questionToolBox/questionToolBox.component';
import { TopToolBox } from '../sections/topToolBox/topToolBox.component';
import { ErrorKeys, ValidationRules } from '../validation/ValidationRules';
import { Route } from './questionSchemeUIEditor';
import {
  defaultEditableQuestionValues,
  defaultQuestionSchemeEditorValues,
  EditableQuestionContext,
  QuestionSchemeEditorContext,
} from './questionSchemeUIEditor.context';
import Style from './questionSchemeUIEditor.module.css';

export const QuestionSchemeEditorContainer = () => {
  // QuestionSchemeEditorContext
  const [questionScheme, setQuestionScheme] = useState(defaultQuestionSchemeEditorValues.questionScheme);
  const [selectedQuestion, selectQuestion] = useState(defaultQuestionSchemeEditorValues.selectedQuestion);
  const [errors, setErrors] = useState(defaultQuestionSchemeEditorValues.errors);

  // EditableQuestionContext
  const [editableOptionId, setEditableOptionId] = useState(defaultEditableQuestionValues.editableOptionId);
  const [editableQuestion, setEditableQuestion] = useState<IBaseQuestion | null>(null);

  const resetErrorHandler = useCallback((key: ErrorKeys) => {
    setErrors((x) => {
      delete x[key];
      return { ...x };
    });
  }, []);

  const setErrorHandler = useCallback((key: ErrorKeys, text: string) => {
    setErrors((x) => {
      return { ...x, [key]: text };
    });
  }, []);

  // Validation
  useEffect(() => {
    if (!setErrorHandler || !resetErrorHandler || !editableQuestion) {
      return;
    }

    Object.values(ValidationRules).forEach((validationRule) => {
      if (validationRule.validate(editableQuestion)) {
        setErrorHandler(validationRule.key, validationRule.text);
      } else {
        resetErrorHandler(validationRule.key);
      }
    });
  }, [editableQuestion, resetErrorHandler, setErrorHandler]);

  // This effect reset toolbars when scheme changed
  useEffect(() => {
    selectQuestion(null);
    setEditableOptionId(null);
  }, [questionScheme]);

  // This effect reset option toolbar when question changed
  useEffect(() => {
    setEditableOptionId(null);
  }, [selectedQuestion]);

  const changeQuestionFieldHandler = useCallback(
    (key: string, newValue: string, route?: Route) => {
      const questionSchemeCopy = Object.assign({}, questionScheme);

      if (!route || !route.questionId || route.optionIndex === undefined) {
        questionSchemeCopy[key] = newValue;
      } else {
        const foundQuestion = getQuestionById(questionSchemeCopy, route.questionId);

        if (!foundQuestion?.options) {
          return;
        }

        const option = foundQuestion?.options[route.optionIndex];
        if (isConditionalOption(option) && option.questionScheme) {
          option.questionScheme[key] = newValue;
        }
      }

      setQuestionScheme(questionSchemeCopy);
    },
    [questionScheme],
  );

  const saveQuestionHandler = useCallback(
    async (questionId: string, newQuestion: IBaseQuestion | null) => {
      if (!newQuestion || !questionScheme) {
        return false;
      }

      if (!newQuestion.options?.length) {
        return false;
      }

      try {
        const questionFromServer = await QueryFactory.QuestionSchemeQuery.Client.createQuestion(
          toCreateQuestionDto(newQuestion),
        );

        const foundQuestion = getQuestionById(questionScheme, questionId);
        if (!foundQuestion || !questionFromServer) {
          return false;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        Object.keys(foundQuestion).forEach((key) => (foundQuestion[key] = questionFromServer[key]));
        const changedQuestionScheme = Object.assign({}, questionScheme);
        setQuestionScheme(changedQuestionScheme);
      } catch (error) {
        alert("Server error: can't create question. Question scheme is not valid");
        return false;
      }

      return true;
    },
    [questionScheme],
  );

  const createQuestionHandler = useCallback(
    async (route?: Route) => {
      if (!questionScheme) {
        return false;
      }

      const newQuestion: IBaseQuestion = {
        id: '',
        questionType: QuestionType.SingleChose,
        questionText: '',
        hashCode: 0,
        isSkippedRender: SkipOptions.NotSkip,
        canBeSkipped: false,
        options: [
          createCommonOption({
            optionText: 'Option0',
            optionValue: 'Value0',
            scoreValue: 0,
          }),
        ],
      };

      try {
        const questionFromServer = await QueryFactory.QuestionSchemeQuery.Client.createQuestion(
          toCreateQuestionDto(newQuestion),
        );

        if (!questionFromServer) {
          return false;
        }

        let changedQuestionScheme = null;

        // Add new question in other scheme
        if (route?.questionId && route.optionIndex !== undefined) {
          const foundQuestion = getQuestionById(questionScheme, route.questionId);

          if (!foundQuestion || !foundQuestion?.options) {
            return false;
          }

          const foundOption = foundQuestion?.options[route.optionIndex];
          if (isConditionalOption(foundOption) && foundOption.questionScheme) {
            foundOption.questionScheme.questions = [
              ...(foundOption.questionScheme?.questions ?? []),
              questionFromServer,
            ];

            changedQuestionScheme = Object.assign({}, questionScheme);
          } else {
            return false;
          }
        } else {
          // Add new question in root
          const newQuestionScheme = {
            ...questionScheme,
            questions: [...(questionScheme?.questions ?? []), questionFromServer],
          };
          changedQuestionScheme = Object.assign({}, questionScheme, newQuestionScheme);
        }

        setQuestionScheme(changedQuestionScheme);
      } catch (error) {
        alert("Server error: can't create question. Question scheme is not valid");
        return false;
      }

      return true;
    },
    [questionScheme],
  );

  const copySelectedQuestionHandler = useCallback(
    async (questionId: string) => {
      if (!questionScheme) {
        return false;
      }

      const newQuestion = getQuestionById(questionScheme, questionId);
      if (!newQuestion) {
        return false;
      }

      try {
        const questionFromServer = await QueryFactory.QuestionSchemeQuery.Client.createQuestion(
          toCreateQuestionDto(newQuestion),
        );

        if (!questionFromServer) {
          return false;
        }

        const changedQuestionScheme = pasteQuestionAfter(questionScheme, questionId, questionFromServer);
        setQuestionScheme(changedQuestionScheme);
      } catch (error) {
        alert("Server error: can't create question");
      }

      return true;
    },
    [questionScheme],
  );

  const deleteQuestionHandler = useCallback(
    async (questionId: string) => {
      if (!questionId || !questionScheme) {
        return false;
      }

      const changedQuestionScheme = removeQuestionById(questionScheme, questionId);
      setQuestionScheme(changedQuestionScheme);
      return true;
    },
    [questionScheme],
  );

  const moveQuestionHandler = useCallback(
    async (questionId: string, direction: 'Down' | 'Up') => {
      if (!questionId || !questionScheme?.questions) {
        return false;
      }

      const changedQuestionScheme = moveQuestion(questionScheme, questionId, direction);
      setQuestionScheme(changedQuestionScheme);
      return true;
    },
    [questionScheme],
  );

  const deleteOptionHandler = useCallback(
    (optionId: number) => {
      if (!editableQuestion || !editableQuestion.options) {
        return;
      }
      const newOptions = editableQuestion.options?.filter((option) => option !== editableQuestion.options![optionId]);
      setEditableQuestion(changeField<IBaseQuestion>(editableQuestion, 'options', newOptions));

      setEditableOptionId(null);
    },
    [editableQuestion],
  );

  const editOptionHandler = useCallback(
    (optionId: number, newOption: IBaseOption) => {
      if (!editableQuestion || !editableQuestion.options) {
        return;
      }

      const newOptions = Array.from(editableQuestion.options, (option, index) => {
        return index === optionId ? (newOption as BaseOption) : option;
      });

      setEditableQuestion(changeField<IBaseQuestion>(editableQuestion, 'options', newOptions));
    },
    [editableQuestion],
  );

  return (
    <QuestionSchemeEditorContext.Provider
      value={{
        questionScheme: questionScheme,
        setQuestionScheme: setQuestionScheme,

        selectedQuestion: selectedQuestion,
        selectQuestion: selectQuestion,

        errors: errors,
        setError: setErrorHandler,
        resetError: resetErrorHandler,

        saveQuestion: saveQuestionHandler,
        createQuestion: createQuestionHandler,
        deleteQuestion: deleteQuestionHandler,
        copySelectedQuestion: copySelectedQuestionHandler,
        moveQuestion: moveQuestionHandler,
        changeQuestionField: changeQuestionFieldHandler,
      }}
    >
      <div className={Style.container}>
        <div className={Style.topToolBox}>
          <TopToolBox />
        </div>
        <EditableQuestionContext.Provider
          value={{
            editableQuestion: editableQuestion,
            setEditableQuestion: setEditableQuestion,

            editableOptionId: editableOptionId,
            setEditableOptionId: setEditableOptionId,
            deleteOption: deleteOptionHandler,
            editOption: editOptionHandler,
          }}
        >
          <div className={Style.editor}>
            <Editor scheme={questionScheme} />
          </div>
          <div className={Style.questionToolBox}>
            <QuestionToolBox />
          </div>
          <div className={Style.optionToolBox}>
            {editableOptionId !== null && <OptionToolBox optionId={editableOptionId} />}
          </div>
        </EditableQuestionContext.Provider>
      </div>
    </QuestionSchemeEditorContext.Provider>
  );
};
