import { IGroupDto, ISurveyDto, TagFilters } from 'src/services/api/api-client';

export type ApplicationState = {
  app: string;
  studyId: number | null;
  studyName: string | null;
  faqId: number | null;
  studyNumber: string | null;
  hasTherapy: boolean;
  hasMonitoring: boolean;
  hasPdfSigning: boolean;
  hasDbImport: boolean;
  isTokenActive: boolean;
  isSyncInProgress: boolean;
  groups: IGroupDto[];
  surveys: ISurveyDto[];
  tagFilters: TagFilters | null;
  patientCreationFormId?: number;
  therapyAggregationPeriod: number | null;
};

export const defaultAppState: ApplicationState = {
  app: 'Study App Admin',
  studyId: null,
  studyName: null,
  faqId: null,
  studyNumber: null,
  hasTherapy: false,
  hasMonitoring: false,
  hasPdfSigning: false,
  hasDbImport: false,
  isTokenActive: false,
  isSyncInProgress: false,
  groups: [],
  surveys: [],
  tagFilters: null,
  patientCreationFormId: undefined,
  therapyAggregationPeriod: null,
};
