﻿import React, { FC } from 'react';
import { SkipCheckbox, SkipCheckboxProps } from '../../wrappers/skipCheckbox/skipCheckbox.component';
import { AppInputError, AppInputErrorProps, ErrorSign } from '../../wrappers/error/appInputError.component';
import { AppInputLabel, AppInputLabelProps } from '../../wrappers/label/appInputLabel.component';
import { AppAutoCompleteInput, AppAutoCompleteInputProps } from '../../inputs/autoComplete/appAutoCompleteInput';

export type AppAutoCompleteFieldProps = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipCheckboxProps?: SkipCheckboxProps;
  maxLength?: number;
  units?: string;
} & AppAutoCompleteInputProps;

export const AppAutoCompleteField: FC<AppAutoCompleteFieldProps> = ({
  errorProps,
  disabled,
  skipCheckboxProps,
  labelProps,
  ...props
}) => {
  return (
    <AppInputError {...errorProps}>
      <SkipCheckbox disabled={disabled} {...skipCheckboxProps}>
        <AppInputLabel disabled={disabled} rightSection={errorProps?.errors ? <ErrorSign /> : <></>} {...labelProps}>
          <AppAutoCompleteInput
            disabled={disabled || skipCheckboxProps?.isSkipped}
            invalid={!!errorProps?.errors}
            {...props}
          />
        </AppInputLabel>
      </SkipCheckbox>
    </AppInputError>
  );
};
