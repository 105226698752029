import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { ConditionalFieldBehaviorEnum } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { createId } from 'src/helpers/typeUtils';

export interface IFormFieldWithOptionsProps extends IFormFieldProps {
  /** Array of possible options */
  options: IFormFieldOption[];

  /** Options has icons, that will be displayed next to them */
  hasStatusIcons?: boolean | null;
}

export interface IFormFieldOption {
  /** Unique number of option.
   *
   * key !== index
   * */
  readonly key: number;

  /** Option value for form. (Under the hood for forms and UI controls). */
  value: string;
  /** Option value. (Visible for end user). */
  displayValue: string;

  /**
   * Name of icon that will be displayed next to the options.
   * If an icon is not found or `undefined` then default one is used.
   * */
  statusIconName?: string;

  //#region additional field

  /**
   * Flag which indicates that when user selects that option - he/she should fill one more field.
   *
   * @deprecated DEPRECATED - use "With conditional" and pass full new component
   * */
  withAdditionalField?: boolean;

  /**
   * Label for additional field of option
   *
   * @deprecated DEPRECATED - use "With conditional" and pass full new component
   * */
  additionalFieldLabel?: string | null;
  /**
   * Placeholder for additional field of option
   *
   * @deprecated DEPRECATED - use "With conditional" and pass full new component
   * */
  additionalFieldPlaceholder?: string | null;
  /**
   * Max length for additional field of option
   *
   * @deprecated DEPRECATED - use "With conditional" and pass full new component
   * */
  additionalFieldMaxLength?: number | null;
  /**
   * Flag for additional field if is it required
   *
   * @deprecated DEPRECATED - use "With conditional" and pass full new component
   * */
  additionalFieldIsRequired?: boolean;

  //#endregion

  /** Flag which indicates that when user selects that option - he/she should fill one more field. */
  withConditionalField?: boolean;
  /** Defines how to render conditional field. */
  conditionalFieldBehavior?: ConditionalFieldBehaviorEnum;

  /** Option's score for calculating */
  score?: number | null;
}

export function formFieldOptionFactory(key: number, text?: string): IFormFieldOption {
  return {
    key,
    displayValue: text || 'Option',
    value: createId(),
  };
}
