import React, { useEffect, useRef, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import clsx from 'clsx';
import { Container } from './Container';
import { AppStepper } from 'src/components/stepper/stepper.component';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import { UIEditorBlockWidth } from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import styles from '../controlsStyle.module.css';
import { useTranslation } from 'react-i18next';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { IStepContainer, IStepContainerProps } from '../../../../features/forms/base/controls/containers/StepContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { PanelContainer } from '../../toolbox/components/panelContainer';

export const StepContainer: IStepContainer = (props) => {
  const { t } = useTranslation();
  const pageRef = useRef(null);
  const { connectors, selected, id } = useNode((state) => ({
    selected: state.events.selected,
    id: state.id,
  }));

  const { connect, drag } = connectors;
  const { size, steps } = {
    ...StepContainerDefaultProps,
    ...props,
  };

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [currentStep, setCurrentStep] = useState(1);
  const currentPage = useContextSelector(FormFillContext, (v) => v.currentPageStack);
  const goToPage = useContextSelector(FormFillContext, (v) => v.goToPage);

  useEffect(() => {
    const findPage = currentPage?.find((p) => p.nodeId === id);
    if (findPage) {
      setCurrentStep(findPage.pageNumber);

      if (pageRef?.current) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        pageRef?.current?.scrollIntoView(true);
      }
    }
  }, [currentPage, id]);

  const content = Array.from({ length: steps }, (_, i) => (
    <React.Fragment key={i}>
      {enabled && <div className={styles.tabLabel}>{t('Forms.Controls.stepContainer.stepN', { n: i + 1 })}</div>}
      <Element id={i.toString()} is={Container} canvas />
    </React.Fragment>
  ));

  const viewControl = (
    <>
      <div className={styles.stepPaginator}>
        {content.map((_, i) => {
          // Steps start from 1
          const stepNumber = i + 1;
          return (
            <AppButton
              textClassName={clsx(styles.stepButton, {
                [styles.stepActiveButton]: stepNumber === currentStep,
              })}
              variant={'icon-link'}
              colorSchema={'primary'}
              key={stepNumber}
              text={stepNumber.toString()}
              onClick={() => goToPage?.({ nodeId: id, pageNumber: i + 1 })}
            />
          );
        })}
      </div>
      {content.map((x, i) => {
        // Steps start from 1
        const stepNumber = i + 1;
        return (
          <div
            data-test-step-number={stepNumber}
            key={stepNumber}
            className={clsx({ [styles.stepVisibility]: stepNumber !== currentStep, 'preview-step': isOnlyView })}
          >
            {x}
          </div>
        );
      })}
    </>
  );

  const editControl = (
    <>
      <AppStepper
        activeStep={currentStep ?? 1}
        stepCount={props.steps ?? 1}
        variant={'steps'}
        onStepClick={(stepNumber) => goToPage?.({ nodeId: id, pageNumber: stepNumber })}
      />
      <div className={styles.stepWrapper}>
        <div ref={pageRef}>
          {content.map((x, i) => {
            // Steps start from 1
            const stepNumber = i + 1;
            return (
              <div
                data-test-step-number={stepNumber}
                key={stepNumber}
                className={clsx({ [styles.stepVisibility]: stepNumber !== currentStep })}
              >
                {x}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );

  const constructorControl = (
    <div
      className={clsx({
        [styles.containerBlock]: true,
        [styles.previewContainer]: isOnlyView,
      })}
      style={{
        width: size,
      }}
      ref={(ref: HTMLDivElement) => enabled && connect(drag(ref))}
    >
      <>
        <div
          className={clsx({
            [styles.selected]: enabled && selected,
          })}
        >
          <AppStepper activeStep={1} stepCount={props.steps ?? 1} variant={'steps'} />
        </div>

        {content}
      </>
    </div>
  );

  return <>{enabled ? constructorControl : isOnlyView ? viewControl : editControl}</>;
};

export const StepContainerSettings = () => {
  const { t } = useTranslation('dev');
  const { props, actions } = useNode((node) => ({
    props: node.data.props as IStepContainerProps,
  }));
  const { setProp } = actions;
  const [steps, setSteps] = useState<number | undefined>(props.steps);

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')}>
      <ControlsGroup flexible header={t('StudySettingsPage.FormBuilder.settings.stepContainer.stepsCount')}>
        <SettingsInput
          type={'number'}
          value={steps}
          onChange={(e) => {
            let step = Number(e.target?.value);
            if (step && step > 100) {
              step = 100;
            }
            setSteps(step);
            setProp((setProps: IStepContainerProps) => (setProps.steps = step ?? 1));
          }}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

export const StepContainerDefaultProps: IStepContainerProps = {
  size: UIEditorBlockWidth.Stretch,
  steps: 2,
};

StepContainer.craft = {
  defaultProps: StepContainerDefaultProps,
  related: {
    settings: StepContainerSettings,
  },
};
