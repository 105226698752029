import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFactory } from '../../../services/api';
import { CommonStyles } from '../../../styles';
import { ExpandedRowHeader } from '../../expandedRow/header/expandedRowHeader.component';
import { AnalysisImportComponent } from '../import/analysisImport.component';
import { AnalysisViewComponent } from '../view/analysisView.component';
import { AnalyzesProps } from './analysisRow';
import Style from './analysisRow.module.css';
import { Permissions } from '../../../helpers/auth/auth-helper';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { PatientCardContext } from 'src/components/expandedRow/PatientCardContext';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const AnalysisRow = (props: AnalyzesProps) => {
  const { patientId, studyId } = props;
  const { t } = useTranslation();
  const patientCardContext = useContext(PatientCardContext);
  const patientUniqId = patientCardContext.patient.uniqueId;
  const { data: analysisPresets } = QueryFactory.AnalyzesQuery.useGetAllAnalysisPresetsForStudyQuery(studyId, {
    suspense: false,
  });

  return (
    <div data-test-id={'patient-card-analysis'}>
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Analyzes',
          linkedPatientUniqId: patientUniqId,
        }}
      >
        <ExpandedRowHeader text={t('ExternalAnalysis.Presets.SectionHeader')} />
      </IssueMark>

      <div className={Style.container}>
        {analysisPresets?.map((presetDto) => {
          return (
            <div key={presetDto.id} className={CommonStyles.tableRow}>
              <div className={CommonStyles.tableItemLeft}>{presetDto.name}</div>
              <div className={CommonStyles.tableItemRight}>
                <div className={Style.tableItemRightContainer}>
                  <AnalysisViewComponent
                    key={`view_${presetDto.id}`}
                    title={presetDto.name}
                    patientId={patientId}
                    presetId={presetDto.id}
                  />

                  <PermissionsCheck permissions={Permissions.EditPatient}>
                    <AnalysisImportComponent key={`import_${presetDto.id}`} preset={presetDto} patientId={patientId} />
                  </PermissionsCheck>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
