import { EmptyBlock } from 'src/components/formEditor/controls/content/EmptyBlock';
import { Container } from 'src/components/formEditor/controls/Containers/Container';
import { Text } from 'src/components/formEditor/controls/content/Text';
import { AttentionText } from 'src/components/formEditor/controls/content/AttentionText';
import { TextInput } from 'src/components/formEditor/controls/Inputs/TextInput';
import { DateInput } from 'src/components/formEditor/controls/Inputs/DateInput';
import { TimeInput } from 'src/components/formEditor/controls/Inputs/TimeInput';
import { CheckBoxInput } from 'src/components/formEditor/controls/Inputs/CheckBoxInput';
import { CheckBoxGroupInput } from 'src/components/formEditor/controls/Inputs/CheckBoxGroupInput';
import { RadioInput } from 'src/components/formEditor/controls/Inputs/RadioInput';
import { SelectInput } from 'src/components/formEditor/controls/Inputs/SelectInput';
import { Files } from 'src/components/formEditor/controls/Inputs/Files';
import { StepContainer } from 'src/components/formEditor/controls/Containers/StepContainer';
import { TabsContainer } from 'src/components/formEditor/controls/Containers/TabsContainer';
import { DateRangeInput } from 'src/components/formEditor/controls/Inputs/DateRangeInput';
import { BmiInput } from 'src/components/formEditor/controls/ConstantBlocks/BmiInput';
import { CalculatedBlock } from 'src/components/formEditor/controls/Containers/CalculatedBlock';
import { PatientGroupInput } from 'src/components/formEditor/controls/ConstantBlocks/PatientGroupInput';
import { ImageBlock } from 'src/components/formEditor/controls/content/ImageBlock';
import { TagInput } from 'src/components/formEditor/controls/Inputs/TagInput';
import { IFormControlsResolver } from 'src/features/forms/base/controls/IFormControlsResolver';
import { FieldGroupContainer } from 'src/components/formEditor/controls/Containers/FieldGroupContainer';
import { NumberInput } from './Inputs/NumberInput';
import { SliderInput } from './Inputs/SliderInput';

export const formBuilderControlsResolver: IFormControlsResolver = {
  EmptyBlock,
  FieldGroupContainer,
  Container,
  Text,
  AttentionText,
  TextInput,
  DateInput,
  TimeInput,
  CheckBoxInput,
  CheckBoxGroupInput,
  RadioInput,
  SelectInput,
  Files,
  StepContainer,
  TabsContainer,
  DateRangeInput,
  BmiInput,
  CalculatedBlock,
  GroupInput: PatientGroupInput,
  ImageBlock,
  TagInput,
  NumberInput,
  SliderInput,
};
