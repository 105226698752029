import { useModal } from '../../../application/hooks/useModal';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { FormDto, FormResultDto, IRouteProgressDto } from '../../../services/api/api-client';
import Style from './useFormOverview.module.scss';
import { AppButton } from 'uikit/buttons';
import clsx from 'clsx';
import { DateFormats, formatDateRange, localFormat } from '../../../helpers/date-helpers';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { EditReasonFormHeader } from './EditReasonFormHeader';
import { ReactComponent as EditIcon } from '../../../assets/img/common/edit_20.svg';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from '../../../helpers/auth/auth-helper';
import { logger } from '../../../application/logging/logging';
import { FormModalWithContext } from 'src/components/formEditor/modals/formModalWithContext';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { getStepNameByResultId } from '../../studyRoutes/studyRoute-helpers';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { getFormResults, getForms } from '../../../services/api/api-client/FormsClient';
import { useFormEditing } from '../editing/useFormEditing';
import { getPatientRouteProgress } from '../../../services/api/api-client/StudyRoutesClient';

export function useFormOverview() {
  const studyId = useAppSelector((s) => s.app.studyId);

  const { t } = useScopedTranslation('Forms.Overview');
  const overviewModal = useModal();

  const [routeProgress, setRouteProgress] = useState<IRouteProgressDto>();
  const [patientId, setPatientId] = useState<string>();
  const [patientUniqueId, setPatientUniqueId] = useState<string>();
  const [allFormResults, setAllFormResults] = useState<FormResultDto[]>();
  const [formConfig, setFormConfig] = useState<FormDto>();
  const [currentFormResult, setCurrentFormResult] = useState<FormResultDto>();
  const [nodeId, setNodeId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [stepName, setStepName] = useState<string>();

  //#region Editing

  const formEditing = useFormEditing();

  const onEditResult = useCallback(async () => {
    overviewModal.closeModal();

    const editingResult = await formEditing.startFormEditing({ formResult: currentFormResult!, form: formConfig! });

    if (editingResult.type === 'Saved') {
      const savedFormResult = editingResult.formResult;
      const updatedRouteProgress = await getPatientRouteProgress(savedFormResult.patientId);

      setCurrentFormResult(savedFormResult);
      setRouteProgress(updatedRouteProgress);
      setAllFormResults((old) => {
        const newArr = [...old!];
        const index = newArr.findIndex((x) => x.id === savedFormResult.id);
        newArr[index] = savedFormResult;
        return newArr;
      });
    }

    overviewModal.openModal();
  }, [currentFormResult, formConfig, formEditing, overviewModal]);

  //#endregion

  type OpenOverviewArgs = {
    formConfigIdOrType: number | string;
    resultId?: number;
    patientId: string;
    patientUniqueId?: string;
    stepName?: string;
    routeProgress?: Promise<IRouteProgressDto> | IRouteProgressDto;
    navigateToNodeId?: string;
  };

  const openOverview = useCallback(
    async (args: OpenOverviewArgs) => {
      setIsLoading(true);
      const formType = typeof args.formConfigIdOrType === 'string' ? args.formConfigIdOrType : undefined;
      const formIds = typeof args.formConfigIdOrType === 'number' ? [args.formConfigIdOrType] : undefined;

      try {
        const results = await getFormResults(formIds?.[0], formType ? [formType] : undefined, args.patientId);
        results.data.reverse();

        const formConfigsFromApi = await getForms(formIds, formType, studyId);
        if (formConfigsFromApi.length === 0)
          throw Error(`Form config with id or type ${args.formConfigIdOrType} was not found`);

        const formResult =
          args.resultId === undefined ? _.last(results.data) : results.data.find((r) => r.id === args.resultId);
        if (!formResult) throw Error(`Form result with id ${args.resultId} was not found`);

        setPatientId(args.patientId);
        setPatientUniqueId(args.patientUniqueId);
        setRouteProgress(await args.routeProgress);
        setAllFormResults(results.data);
        setFormConfig(formConfigsFromApi[0]);
        setCurrentFormResult(formResult);
        setNodeId(args.navigateToNodeId);
        setStepName(args.stepName);
      } catch (error: any) {
        logger().error(error);
      } finally {
        setIsLoading(false);
      }

      overviewModal.openModal();
    },
    [overviewModal, studyId],
  );

  const closeOverview = useCallback(() => {
    overviewModal.closeModal();
    setAllFormResults(undefined);
    setFormConfig(undefined);
    setPatientId(undefined);
    setPatientUniqueId(undefined);
    setRouteProgress(undefined);
    setCurrentFormResult(undefined);
    setNodeId(undefined);
  }, [overviewModal]);

  const overviewHeader: ReactElement = useMemo(() => {
    if (!currentFormResult?.updatedAt) {
      return <></>;
    }

    return (
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Records',
          topicAdditional: formConfig?.type,
          linkedPatientUniqId: patientUniqueId,
          resultId: currentFormResult.id || undefined,
          stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
        }}
        position={{ right: -22 }}
      >
        <div className={Style.modalHeaderContainer}>
          <EditReasonFormHeader
            updatedAt={currentFormResult?.updatedAt}
            editReason={currentFormResult?.editReason}
            updateReasonText={currentFormResult?.updateReasonText}
            editorName={currentFormResult?.doctor?.fullName}
          />

          <div className={Style.modalHeaderRightPart}>
            {currentFormResult.fillingProgress && (
              <>
                <span>{t('FillingProgress')}</span>
                <span>
                  {currentFormResult.fillingProgress.filled}/{currentFormResult.fillingProgress.total}
                </span>
              </>
            )}
            {currentFormResult.canBeEdited && (
              <PermissionsCheck permissions={Permissions.EditPatient}>
                <Tooltip
                  text={
                    currentFormResult.editingEnabledUntilDate
                      ? t('EditUntil', {
                          date: formatDateRange(
                            currentFormResult.editingEnabledUntilDate,
                            currentFormResult.editingEnabledUntilDate,
                          ),
                        })
                      : t('EditUntilNextFormFilledOut')
                  }
                  hostStyles={Style.editButton}
                >
                  <AppButton
                    text={t('Edit')}
                    Icon={EditIcon}
                    variant={'icon-link'}
                    colorSchema={'secondary'}
                    onClick={onEditResult}
                  />
                </Tooltip>
              </PermissionsCheck>
            )}
          </div>
        </div>
      </IssueMark>
    );
  }, [currentFormResult, formConfig?.isMultiInstance, formConfig?.type, patientUniqueId, stepName, t, onEditResult]);

  const navigation = useMemo(() => {
    return (
      <div className={Style.navContainer}>
        {allFormResults && (
          <div className={Style.pagination}>
            {allFormResults?.map((record, i) => (
              <AppButton
                textClassName={clsx(Style.navButton, {
                  [Style.navButtonSelected]: record.id === currentFormResult?.id,
                  [Style.navButtonDisabled]: allFormResults.length === 1,
                })}
                key={i}
                disabled={record.id === null}
                text={
                  getStepNameByResultId(routeProgress!, record.id) ||
                  localFormat(record.createdAt!, DateFormats.longDate)
                }
                variant={'icon-link'}
                colorSchema={'secondary'}
                onClick={() => setCurrentFormResult(record)}
              />
            ))}
          </div>
        )}
        {overviewHeader}
      </div>
    );
  }, [allFormResults, overviewHeader, currentFormResult?.id, routeProgress]);

  const header = useMemo(() => {
    return (
      <IssueMark
        key={formConfig?.type}
        issueContext={{
          subject: 'Patient',
          topic: 'Records',
          linkedPatientUniqId: patientUniqueId,
          topicAdditional: formConfig?.type,
        }}
        position={{ top: 2, right: 8 }}
        countDescendants={false}
        containerClassName={Style.issueMarkContainer}
      >
        <div className={Style.issueMarkTarget} />
      </IssueMark>
    );
  }, [formConfig?.type, patientUniqueId]);

  return {
    isLoading,
    open: openOverview,
    close: closeOverview,
    element: (
      <>
        {!!patientId && currentFormResult && formConfig && (
          <FormModalWithContext
            mode={'Overview'}
            formConfig={formConfig}
            formResult={currentFormResult}
            modal={{
              title: formConfig.type,
              testId: `${formConfig.type}-overview-form`,
              visible: overviewModal.visible,
              onHide: overviewModal.closeModal,
              header: header,
              headerClassName: Style.modalHeaderMark,
            }}
            navigationMenu={navigation}
            patientId={patientId!}
            onSubmitted={setCurrentFormResult}
            navigateToNodeId={nodeId}
            stepName={stepName}
          />
        )}
        {formEditing.element}
      </>
    ),
  };
}
