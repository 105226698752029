import React, { useDebugValue, useEffect, useState } from 'react';

/**
 * @param factory  - factory argument for useState.
 * @param deps - changeable (dependable) objects.
 * This hook works like {@link React.useState useState} but the saved state is recomputed
 * when one of {@param deps} is changed.
 */
export default function useStateWithDeps<T>(factory: () => T, deps: React.DependencyList) {
  const [state, setState] = useState(factory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setState(factory()), deps);
  useDebugValue(state);
  return [state, setState] as [typeof state, typeof setState];
}
