﻿import React, { useCallback } from 'react';
import { IRouteProgressDto } from 'src/services/api/api-client';
import { useFormEditing } from 'src/features/forms/editing/useFormEditing';
import { useFormOverview } from 'src/features/forms/overview/useFormOverview';
import { getFormResultById } from '../../services/api/api-client/FormsClient';
import { getPatientRouteProgress } from '../../services/api/api-client/StudyRoutesClient';

export const useFormEditingAndOverview = () => {
  const formEditing = useFormEditing();
  const formOverview = useFormOverview();

  const startFilling = useCallback(
    async (args: {
      formId: number;
      patientId: string;
      patientUniqueId: string;
      routeProgress?: IRouteProgressDto;
      stepName?: string;
    }) => {
      const _routeProgress = args.stepName ? args.routeProgress ?? getPatientRouteProgress(args.patientId) : undefined;

      const fillingResult = await formEditing.startFormFilling({
        formId: args.formId,
        patientId: args.patientId,
        routeProgress: _routeProgress,
        stepName: args.stepName,
      });

      if (fillingResult?.type === 'Canceled') return;

      const formResult = fillingResult.formResult;
      const updatedRouteProgress = await getPatientRouteProgress(formResult.patientId);

      await formOverview.open({
        formConfigIdOrType: formResult.formConfigId,
        patientId: formResult.patientId,
        resultId: formResult.id!,
        routeProgress: updatedRouteProgress,
        patientUniqueId: args.patientUniqueId,
      });
    },
    [formEditing, formOverview],
  );

  const startEditing = useCallback(
    async (args: { formResultId: number; patientUniqueId: string; routeProgress?: IRouteProgressDto }) => {
      const formResult = getFormResultById(args.formResultId);
      const _patientId = formResult.then((x) => x.patientId);
      const editingResult = await formEditing.startFormEditing({ formResult: formResult });

      if (editingResult.type === 'Canceled') return;

      const savedFormResult = editingResult.formResult;
      const updatedRouteProgress = await getPatientRouteProgress(await _patientId);

      await formOverview.open({
        resultId: savedFormResult.id!,
        formConfigIdOrType: savedFormResult.formConfigId,
        patientId: savedFormResult.patientId,
        patientUniqueId: args.patientUniqueId,
        routeProgress: updatedRouteProgress!,
      });
    },
    [formEditing, formOverview],
  );

  const openOverview = useCallback(
    async (args: {
      patientId: string;
      patientUniqueId?: string;
      formId: number;
      formResultId?: number;
      routeProgress?: IRouteProgressDto;
      navigateToNodeId?: string;
      stepName?: string;
    }) => {
      const routeProgressFromApi = args.routeProgress ?? getPatientRouteProgress(args.patientId);

      await formOverview.open({
        formConfigIdOrType: args.formId,
        patientId: args.patientId,
        resultId: args.formResultId,
        routeProgress: routeProgressFromApi,
        navigateToNodeId: args.navigateToNodeId,
        stepName: args.stepName,
        patientUniqueId: args.patientUniqueId,
      });
    },
    [formOverview],
  );

  return {
    startFilling: startFilling,
    startEditing: startEditing,
    openOverview: openOverview,
    formEditIsLoading: formEditing.isLoading,
    formOverviewIsLoading: formOverview.isLoading,
    element: (
      <>
        {formEditing.element}
        {formOverview.element}
      </>
    ),
  };
};
