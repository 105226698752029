import React, { useCallback, useMemo, useState } from 'react';
import { addTimeUnitToDate, getMaxDate, getMinDate, subTimeUnitFromDate } from '../../helpers/date-helpers';
import {
  addDays,
  compareAsc,
  compareDesc,
  format,
  isAfter,
  isEqual,
  startOfDay,
  startOfMonth,
  startOfYear,
  toDate,
} from 'date-fns';
import clsx from 'clsx';
import Calendar, { NavigationLabelFunc, TileClassNameFunc } from 'react-calendar';
import { ReactComponent as Calendar20Icon } from 'src/assets/img/common/calendar_20.svg';
import { ReactComponent as Year20Icon } from 'src/assets/img/common/year_view_20.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/img/common/arrow_32.svg';
import CalendarStyle from './daterange.extra.module.scss';
import {
  getPredefinedInterval,
  getSelectedIntervalAsPredefinedPeriod,
  intervalContains,
  isIntervalBoundsEqual,
  PredefinedIntervalType,
} from './daterange-helper';
import { Chips } from 'uikit/chips/chips.component';
import { getCurrentDateLocale } from 'src/application/localisation/localization';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import i18n from 'i18next';
import { LocalizedResourceDictionaryKeys } from 'src/application/localisation/i18next';
import { DateRangePickerV3CalendarProps } from './DateRangePickerV3';
import { isBeforeOrEqual, unionIntervals } from '../daterangePickerV2/daterange-helper';
import { Value } from 'react-calendar/src/shared/types';

const predefinedPeriods: PredefinedIntervalType[] = ['past-month', 'past-quarter', 'past-half-year'];

export const DateRangeCalendar = (props: DateRangePickerV3CalendarProps) => {
  const { onSelectRange, selectedInterval, maxFilledDate, minFilledDate, withPredefinedPeriods, filledDays } = props;
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.NotesSection.Overview');
  const [selectedPeriod, setSelectedPeriod] = useState<PredefinedIntervalType | null>(
    getSelectedIntervalAsPredefinedPeriod(selectedInterval),
  );

  const [isRangePickerActive, setRangePickerActive] = useState<boolean>(selectedInterval === null);

  const reservedRanges = useMemo(() => unionIntervals(props.reservedRanges ?? []), [props.reservedRanges]);
  let disabledRanges = useMemo(() => {
    return unionIntervals(
      (props.disabledRanges ?? [])
        .concat(reservedRanges)
        .concat(props.minDate ? [{ start: getMinDate(), end: props.minDate }] : [])
        .concat(props.maxDate ? [{ start: addDays(props.maxDate, 1), end: getMaxDate() }] : []),
    );
  }, [props.disabledRanges, props.maxDate, props.minDate, reservedRanges]);

  disabledRanges = useMemo(() => {
    if (!isRangePickerActive) return disabledRanges;

    const ranges = [] as Interval[];

    const leftDisabledRange =
      disabledRanges
        .filter((x) => isBeforeOrEqual(x.end, selectedInterval.start))
        .sort((a, b) => compareDesc(a.end, b.end))[0] ?? null;

    if (leftDisabledRange) ranges.push({ start: getMinDate(), end: leftDisabledRange.end });

    const rightDisabledRange =
      disabledRanges
        .filter((x) => isAfter(x.start, selectedInterval.start))
        .sort((a, b) => compareAsc(a.end, b.end))[0] ?? null;

    if (rightDisabledRange) ranges.push({ start: rightDisabledRange.start, end: getMaxDate() });

    return ranges;
  }, [disabledRanges, isRangePickerActive, selectedInterval.start]);

  const checkPredefinedPeriod = useCallback((value: Interval) => {
    const selectedPredefinedType = getSelectedIntervalAsPredefinedPeriod(value);
    setSelectedPeriod(selectedPredefinedType);
  }, []);

  const onChange = useCallback(
    (data: Value) => {
      const selectResult = Array.isArray(data) ? data : [data];
      const selectedIntervalValue: Interval = selectResult
        ? { start: selectResult[0]!, end: selectResult[selectResult.length - 1]! }
        : { start: Date.now(), end: Date.now() };

      onSelectRange(selectedIntervalValue);
      checkPredefinedPeriod(selectedIntervalValue);

      // When we only in `selection mode` we should stop it in the end.
      if (isRangePickerActive) {
        setRangePickerActive(false);
      }
    },
    [isRangePickerActive, onSelectRange, checkPredefinedPeriod],
  );

  const handlePreselectedPeriod = useCallback(
    (period: PredefinedIntervalType) => {
      const dateRange = getPredefinedInterval(period);
      onChange([new Date(dateRange.start), new Date(dateRange.end)]);
    },
    [onChange],
  );

  const tileClassName: TileClassNameFunc = useCallback(
    ({ date, view }) => {
      const isDisabledRange = disabledRanges.some((x) => intervalContains(x, date));
      const answeredDays: Date[] = filledDays || [];

      const isReservedRange = reservedRanges.some((x) => intervalContains(x, date));
      const isReservedRangeStart = isReservedRange && reservedRanges.some((x) => isEqual(date, x.start));
      const isReservedRangeEnd =
        isReservedRange && reservedRanges.some((x) => isEqual(date, subTimeUnitFromDate(x.end, 'days')));
      const isReservedRangeBothEnds = isReservedRangeStart && isReservedRangeEnd;

      const isFreeRange = !isReservedRange && !isDisabledRange;
      const isFreeRangeStart = isFreeRange && disabledRanges.some((x) => isEqual(date, x.end));
      const isFreeRangeEnd =
        isFreeRange && disabledRanges.some((x) => isEqual(addTimeUnitToDate(date, 'days'), x.start));
      const isFreeRangeBothEnds = isFreeRangeStart && isFreeRangeEnd;

      const isAnswerDay = view === 'month' && answeredDays.some((x) => isEqual(date, startOfDay(x)));
      const isAnswerMonth = view === 'year' && answeredDays.some((x) => isEqual(date, startOfMonth(x)));
      const isAnswerYear = view === 'decade' && answeredDays.some((x) => isEqual(date, startOfYear(x)));
      const isAnswerCell = isAnswerDay || isAnswerMonth;

      return clsx({
        [CalendarStyle.isDisabledRange]: isDisabledRange,

        [CalendarStyle.isFreeRange]: isFreeRange,
        [CalendarStyle.isFreeRangeStart]: isFreeRangeStart,
        [CalendarStyle.isFreeRangeEnd]: isFreeRangeEnd,
        [CalendarStyle.isFreeRangeBothEnds]: isFreeRangeBothEnds,

        [CalendarStyle.isReservedRange]: isReservedRange,
        [CalendarStyle.isReservedRangeStart]: isReservedRangeStart,
        [CalendarStyle.isReservedRangeEnd]: isReservedRangeEnd,
        [CalendarStyle.isReservedRangeBothEnds]: isReservedRangeBothEnds,

        [CalendarStyle.isReservedRange]: isReservedRange,
        [CalendarStyle.isReservedRangeStart]: isReservedRangeStart,
        [CalendarStyle.isReservedRangeEnd]: isReservedRangeEnd,
        [CalendarStyle.isReservedRangeBothEnds]: isReservedRangeBothEnds,

        [CalendarStyle.isAnswered]: isAnswerCell,
        [CalendarStyle.isAnsweredYear]: isAnswerYear,
      });
    },
    [filledDays, disabledRanges, reservedRanges],
  );

  const tileDisabled = useCallback(
    ({ date, view }: { date: Date; view: string }) =>
      !isRangePickerActive &&
      !!selectedInterval.start &&
      view === 'month' &&
      disabledRanges.some((r) => intervalContains(r, date)),
    [selectedInterval.start, isRangePickerActive, disabledRanges],
  );

  const navigationLabel: NavigationLabelFunc = useCallback((data) => {
    const { view, label, date } = data;
    return (
      <>
        {view === 'month' ? <Calendar20Icon /> : <Year20Icon />}
        <span>{view === 'month' ? format(date, 'MMM yyyy', getCurrentDateLocale()) : label}</span>
      </>
    );
  }, []);

  return (
    <div className={clsx(CalendarStyle.appDaterangePicker)} data-test-id={'notes-calendar'}>
      <Calendar
        tileClassName={tileClassName}
        tileDisabled={tileDisabled}
        navigationLabel={navigationLabel}
        nextLabel={<ArrowIcon />}
        prevLabel={<ArrowIcon />}
        locale={i18n.language}
        showFixedNumberOfWeeks={true}
        minDetail={'decade'}
        selectRange={isRangePickerActive}
        onClickDay={() => {
          setRangePickerActive(true);
        }}
        formatMonth={(locale, date) => format(date, 'MMM')}
        value={selectedInterval ? [toDate(selectedInterval.start), toDate(selectedInterval.end)] : null}
        onChange={onChange}
      />
      {withPredefinedPeriods && (
        <div className={CalendarStyle.preselectedContainer} data-test-id={'predefined-period-container'}>
          {predefinedPeriods.map((x, index) => {
            return (
              <Chips
                key={index}
                text={t(`PredefinedRanges.${x}` as LocalizedResourceDictionaryKeys)}
                checked={selectedPeriod === x}
                onClick={() => handlePreselectedPeriod(x)}
              />
            );
          })}
          <Chips
            text={t('PredefinedRanges.all')}
            checked={
              minFilledDate && maxFilledDate
                ? isIntervalBoundsEqual({ start: minFilledDate, end: maxFilledDate }, selectedInterval)
                : false
            }
            // @ts-ignore
            onClick={() => onChange([minFilledDate, maxFilledDate])}
          />
        </div>
      )}
    </div>
  );
};
