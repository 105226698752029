﻿import { AppButton } from 'uikit/buttons';
import { DialogModal } from '../../dialogModal/dialogModal.component';
import React, { FC } from 'react';
import { UseModalProps } from '../../../application/hooks/useModal';
import { useTranslation } from 'react-i18next';

export const CloseConfirmationModal: FC<{
  modal: UseModalProps;
  onSave: () => void;
  onDiscard: () => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogModal
      isVisible={props.modal.visible}
      onClose={props.modal.closeModal}
      testId={'discard-modal-confirmation'}
      buttons={[
        <AppButton
          key={0}
          variant={'button'}
          colorSchema={'destroy'}
          text={t('Forms.ConfirmationModal.DiscardBtn')}
          type={'reset'}
          onClick={() => {
            props.modal.closeModal();
            props.onDiscard();
          }}
        />,
        <AppButton
          key={1}
          variant={'button'}
          colorSchema={'primary'}
          text={t('Forms.Controls.SaveButton')}
          type={'submit'}
          onClick={props.onSave}
        />,
      ]}
      mainText={t('Forms.ConfirmationModal.MainText')}
      title={t('Forms.ConfirmationModal.Header')}
    />
  );
};
