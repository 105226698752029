import React, { useContext, useMemo, FC } from 'react';
import { IFormFieldWithOptionsProps } from '../../../../../features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import { NoteContext } from '../../NoteContext';
import { getStatusIcon } from '../../../../formEditor/controls/Inputs/statusIcons/statusIcons.constants';
import { Tooltip } from '../../../../uikit/tooltip/tooltip.component';

export const FieldWithOptions: FC<IFormFieldWithOptionsProps> = (props) => {
  const noteContext = useContext(NoteContext);
  const inputValue = noteContext.formResult.result[props.dataKey] as string | string[] | undefined;
  const isOverView = noteContext.mode === 'overview';
  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  const valueVms = useMemo(() => {
    if (!props.hasStatusIcons) return [];

    return props.options
      .filter((x) => multipleInputValue?.includes(x.value))
      .map((x) => ({
        key: x.key,
        title: props.label ?? props.dataKey,
        icon: getStatusIcon(x.statusIconName),
        text: x.displayValue,
      }));
  }, [props.hasStatusIcons, props.options, props.label, props.dataKey, multipleInputValue]);

  if (isOverView) {
    return <></>;
  }

  return (
    <>
      {valueVms.map((x) => (
        <Tooltip testIdPrefix={'note-status-icon'} headerText={x.title} text={x.text} key={x.key} styles={'d-contents'}>
          {x.icon}
        </Tooltip>
      ))}
    </>
  );
};
