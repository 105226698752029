import { Style } from '@react-pdf/types/style';
import { ScoreCalculationStatusEnum } from '../../../services/api/api-client';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { WarningScoreIcon } from './svg/WarningScoreIcon';
import { ReportColors } from '../report-colors';
import { NoScoreIcon } from './svg/NoScoreIcon';
import { RPDF } from '../react-pdf';
import React from 'react';
import { RecordNoScoreIcon } from './svg/RecordNoScoreIcon';

type SurveyScoreProblemItems = { legend: string; Icon: JSX.Element; styles?: Style };
type SurveyScoreIssuesLegendScore = {
  scoreStatus: ScoreCalculationStatusEnum | null;
  isLowScore?: boolean;
  view: 'records' | 'surveys';
  style?: Style;
};
export const ScoreProblemLegendSection = (props: SurveyScoreIssuesLegendScore) => {
  const { t } = useScopedTranslation('Reports.Controls.Score');
  const { scoreStatus, isLowScore, view, style } = props;

  const problems: SurveyScoreProblemItems[] = [];

  if (scoreStatus !== ScoreCalculationStatusEnum.Done) {
    problems.push({
      legend: t('ScoreError'),
      Icon: <WarningScoreIcon />,
      styles: { color: ReportColors.aweful },
    });
  }

  if (scoreStatus === ScoreCalculationStatusEnum.Done && isLowScore) {
    problems.push({
      legend: view === 'surveys' ? t('LowScore') : t('LowScoreRecords'),
      Icon: view === 'surveys' ? <NoScoreIcon /> : <RecordNoScoreIcon />,
      styles: { color: ReportColors.primary },
    });
  }

  if (problems.length === 0) {
    return <RPDF.View />;
  }

  const localStyles = RPDF.StyleSheet.create({
    container: {
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    gapSpace: {
      marginBottom: 4,
    },
    itemContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: 2,
      width: '100%',
    },
  });

  return (
    <RPDF.View style={[localStyles.container, style || {}]}>
      {problems.map((x, index) => {
        return (
          <RPDF.View
            key={index}
            style={[
              localStyles.itemContainer,
              RPDF.typography.text8,
              index < problems.length - 1 ? localStyles.gapSpace : {},
              x.styles || {},
            ]}
          >
            {x.Icon}
            <RPDF.Text style={RPDF.typography.text8}>{x.legend}</RPDF.Text>
          </RPDF.View>
        );
      })}
    </RPDF.View>
  );
};
