import React, { useCallback } from 'react';
import { trackEvent } from '../../application/analitics/matomo/matomo-tracker';
import { MetricCategories } from '../../application/analitics/matomo/matomo-categories';
import { MetricActions } from '../../application/analitics/matomo/matomo-actions';
import { useModal } from '../../application/hooks/useModal';
import { useAppSelector } from '../../application/redux-store/store-types';
import { ReactComponent as UserPlus } from '../../assets/img/patient/user_plus.svg';
import Style from './addPatientButton.module.scss';
import TablePageLayout from '../tablePageLayout/TablePageLayout';
import Portal from 'uikit/portal/Portal';
import { FormModalWithContext } from '../formEditor/modals/formModalWithContext';
import { useGetFormByIdQuery } from '../../services/api/api-client/FormsQuery';

export const AddPatientButton = () => {
  const createModal = useModal();
  const { patientCreationFormId } = useAppSelector((state) => state.app);

  const { data: initialForm } = useGetFormByIdQuery(patientCreationFormId ?? 0, {
    enabled: !!patientCreationFormId,
  });

  const onShow = useCallback(() => {
    createModal.openModal();
    trackEvent({ action: MetricActions.ButtonPressed, category: MetricCategories.Patient, name: 'AddPatientButton' });
  }, [createModal]);

  if (!initialForm) {
    return <></>;
  }

  return (
    <>
      <TablePageLayout.TableContainer.FloatingButton
        onClick={onShow}
        Icon={UserPlus}
        className={Style.button}
        testId={'create-patient-button'}
      />

      <Portal>
        {/* Portal is used because */}
        {/* some element from Fluent UI adds extra <span/> */}
        {/* and it causes a gap between the floating button and this <span/> */}
        <FormModalWithContext
          mode={'Create'}
          formConfig={initialForm}
          formResult={undefined}
          modal={{
            title: initialForm.type,
            testId: 'Initial-form',
            visible: createModal.visible,
            onHide: createModal.closeModal,
          }}
        />
      </Portal>
    </>
  );
};
