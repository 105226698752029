/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import { useTranslation } from 'react-i18next';
import styles from '../controlsStyle.module.css';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import {
  calculatedInputPropsFactory,
  ICalculatedInput,
} from '../../../../features/forms/base/controls/specials/CalculatedBlock';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const CalculatedBlock: ICalculatedInput = (props) => {
  const { t } = useTranslation();
  const { connectors, setProp, id } = useNode();
  const { connect, drag } = connectors;

  const { size, dataKey } = {
    ...calculatedInputPropsFactory(),
    ...props,
  };

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const { isEditorEnabled } = useEditor((state) => ({
    isEditorEnabled: state.options.enabled,
  }));

  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);

  const inputValue = useContextSelector(FormFillContext, (v) => (v.values ? v.values[dataKey] : undefined));

  useEffect(() => {
    if (isEditorEnabled) {
      setProp((setProps) => (setProps.label = !props.hideLabel ? props.label || dataKey : undefined));
    }
  }, []);

  const editableText = (
    <div
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isEditorEnabled}
      ref={(ref) => isEditorEnabled && connect(drag(ref!))}
    >
      {props.label && (
        <label
          aria-disabled={isSubmitting}
          className={clsx(TypographyStyles.paragraph12, styles.calculatedBlockLabel, {
            [styles.bold]: props.isBold,
          })}
        >
          {props.label}
        </label>
      )}
      <span
        aria-disabled={isSubmitting}
        className={clsx(TypographyStyles.paragraph14, styles.calculatedBlockValue)}
      >{`${inputValue ?? t('Common_dash')} ${props.units ?? ''}`}</span>
    </div>
  );

  const notEditableText = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label || props.dataKey,
        resultId: formResult?.id ?? undefined,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={{ right: 24, top: 6 }}
    >
      <div className={clsx(styles.previewRow, 'preview-row')}>
        <span className={TypographyStyles.heading2}>{props.label ?? props.dataKey}</span>
        {inputValue && <span className={TypographyStyles.plainText14}>{`${inputValue} ${props.units ?? ''}`}</span>}
      </div>
    </IssueMark>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

const CalculatedBlockSettings = () => {
  return <></>;
};

CalculatedBlock.craft = {
  defaultProps: calculatedInputPropsFactory(),
  related: {
    settings: CalculatedBlockSettings,
  },
};
