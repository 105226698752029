import React from 'react';
import { CardSectionsEnum, IPatientListDto } from '../../services/api/api-client';
import { AnalysisRow } from '../analyzes/row/analysisRow.component';
import { AppInformation } from './appInformation/appInformation.component';
import { GeneralPatientInfo } from './general/generalInfo.component';
import { PipelineSection } from './pipelineSection/pipelineSection.component';
import { StudyPeriodSection } from './studyPeriodSection/studyPeriodSection.component';
import { SurveyComponent } from './surveys/survey.component';
import { NotesSection } from './notesSection/NotesSection.component';
import { RecordsGroupedByType } from './records/RecordsGroupedByType';
import { RecordsGroupedByStep } from './records/RecordsGroupedByStep';

type SectionConstantsType = {
  [key in CardSectionsEnum]: (patient: IPatientListDto, isColored?: boolean) => JSX.Element;
};

export const SectionConstants: SectionConstantsType = {
  [CardSectionsEnum.GeneralInfo]: (patient: IPatientListDto) => <GeneralPatientInfo {...patient} />,
  [CardSectionsEnum.RecordsGroupedByType]: () => <RecordsGroupedByType />,
  [CardSectionsEnum.RecordsGroupedByStep]: () => <RecordsGroupedByStep />,
  [CardSectionsEnum.Stage]: (patient: IPatientListDto) => (
    <PipelineSection
      uniqueId={patient.uniqueId}
      routeProgress={patient.studyRouteProgress}
      patientId={patient.id}
      groupId={patient.group}
    />
  ),
  [CardSectionsEnum.StudyPeriod]: (patient: IPatientListDto) => <StudyPeriodSection {...patient} />,
  [CardSectionsEnum.Surveys]: (patient: IPatientListDto) => (
    <SurveyComponent
      {...patient}
      plannedStudyFinishedAt={patient.studyFinishDate}
      patientId={patient.id}
      groupId={patient.group}
    />
  ),
  [CardSectionsEnum.Notes]: (_: IPatientListDto, isColored?: boolean) => <NotesSection isColored={isColored} />,
  [CardSectionsEnum.Analyzes]: (patient: IPatientListDto) => (
    <AnalysisRow uniqueId={patient.uniqueId} patientId={patient.id} studyId={patient.studyId} />
  ),
  [CardSectionsEnum.AppInformation]: (patient: IPatientListDto) => <AppInformation {...patient} />,
};
