import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { _isSkipped } from '../../../../forms/base/controls/inputs/base/baseControlSettings';
import { ITextInput } from '../../../../forms/base/controls/inputs/TextInput';
import { RPDF } from '../../../react-pdf';
import { RecordsReportContext } from '../../recordsReport.context';

export const TextInput: ITextInput = ({ label, text, dataKey, units, skipCheckBoxText }) => {
  const { t } = useTranslation();

  const inputValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  const answerValue = useMemo(() => {
    if (!singleInputValue) {
      return null;
    }

    if (singleInputValue === _isSkipped) {
      return skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox');
    }

    return `${singleInputValue} ${units ?? ''}`;
  }, [singleInputValue, skipCheckBoxText, t, units]);

  return (
    <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
      <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? text ?? dataKey}</RPDF.Text>
      <RPDF.Text style={RPDF.controlStyles.answerCell}>{answerValue}</RPDF.Text>
    </RPDF.View>
  );
};
