/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';
import { useContextSelector } from 'use-context-selector';
import { useScopedTranslation } from '../../../../../application/localisation/useScopedTranslation';
import { IBmiInput } from '../../../../forms/base/controls/specials/BmiInput';
import { RPDF } from '../../../react-pdf';
import { RecordsReportContext } from '../../recordsReport.context';

export const BmiInput: IBmiInput = ({ dataKey }) => {
  const { t } = useScopedTranslation('Forms.Controls.BMI');
  const inputValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);
  const weightValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.Weight);
  const heightValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.Height);

  return (
    <RPDF.View>
      <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
        <RPDF.Text style={RPDF.controlStyles.questionCell}>{t('Weight')}</RPDF.Text>
        <RPDF.Text style={RPDF.controlStyles.answerCell}>{weightValue ? `${weightValue} kg` : ''}</RPDF.Text>
      </RPDF.View>
      <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
        <RPDF.Text style={RPDF.controlStyles.questionCell}>{t('Height')}</RPDF.Text>
        <RPDF.Text style={RPDF.controlStyles.answerCell}>{heightValue ? `${heightValue} cm` : ''}</RPDF.Text>
      </RPDF.View>
      <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
        <RPDF.Text style={RPDF.controlStyles.questionCell}>{'BMI'}</RPDF.Text>
        <RPDF.Text style={RPDF.controlStyles.answerCell}>{inputValue}</RPDF.Text>
      </RPDF.View>
    </RPDF.View>
  );
};
