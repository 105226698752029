import { IDateInput } from '../../../../../features/forms/base/controls/inputs/DateInput';
import React, { useContext } from 'react';
import { NoteContext } from '../../NoteContext';
import { DateFormats, localFormat, toDateOrNull } from '../../../../../helpers/date-helpers';
import Style from '../../notes.module.scss';

export const DateField: IDateInput = (props) => {
  // To rerender date format
  const noteContext = useContext(NoteContext);
  const inputValue = noteContext.formResult.result[props.dataKey] as string | string[] | undefined;
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  if (noteContext.noteDateDataKey !== props.dataKey) return <></>;

  const parsedDate = toDateOrNull(singleInputValue);

  if (!parsedDate) return <></>;

  return (
    <span className={Style.noteDate} data-test-id={'note-date'}>
      {parsedDate && localFormat(parsedDate, DateFormats.longDate)}
    </span>
  );
};
