import clsx from 'clsx';
import * as React from 'react';
import { useCallback } from 'react';
import { createId, FieldsWithType } from '../../../../helpers/typeUtils';
import { AppRadioGroupInputProps } from './appRadioGroupInput';
import styles from './appRadioGroupInput.module.scss';
import { AppRadioInput } from './appRadioInput.component';

export function AppRadioGroupInput<
  TOption extends Record<Key, string | number>,
  Key extends FieldsWithType<TOption, string | number>,
>(props: AppRadioGroupInputProps<TOption, Key>) {
  type TValue = TOption[Key];
  const { rootClassName, options, valueField, labelField, iconField, disabled, onChange, isVertical } = props;
  const getLabelForOption = useCallback((option: TOption) => option[labelField], [labelField]);
  const getIconForOption = useCallback((option: TOption) => (iconField && option[iconField]) || undefined, [iconField]);
  const groupName = createId();

  const getValueForOption: (option: TOption) => TValue = useCallback(
    (option: TOption) => option[valueField],
    [valueField],
  );

  const onOptionChanged = useCallback(
    (selectedOption: TOption | string | null) => (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.value !== props.value) {
        (onChange as (option: TOption | string | null) => void)(selectedOption);
      }
    },
    [onChange, props.value],
  );

  return (
    <div className={clsx(styles.rootContainer, { [styles.verticalContainer]: isVertical, rootClassName })}>
      {options.map((option, i, arr) => {
        const value = getValueForOption(option);
        const labelLocal = getLabelForOption(option)?.toString();
        const getIndex = () => {
          switch (props.numerationOrder) {
            case 'Ltr':
              return (props.numerationStartIndex ?? 0) + i;
            case 'Rtl':
              return (props.numerationStartIndex ?? 0) + arr.length - 1 - i;
            default:
            case 'NoNum':
              return undefined;
          }
        };
        const icon = getIconForOption(option) as JSX.Element | undefined;
        const prefix =
          getIndex() !== undefined || icon ? (
            <div className={styles.prefix}>
              {getIndex() !== undefined ? `(${getIndex()})` : undefined}
              {icon}
            </div>
          ) : undefined;

        return (
          <div
            key={i}
            className={clsx(styles.fieldContainer, {
              [styles.fieldContainerVertical]: isVertical,
            })}
          >
            <AppRadioInput
              key={value}
              checked={props.value === value}
              prefixComponent={prefix}
              name={groupName}
              caption={labelLocal}
              disabled={disabled}
              onChange={onOptionChanged(option)}
            />
          </div>
        );
      })}
    </div>
  );
}
