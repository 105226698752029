import React, { useMemo, useState, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseModalProps } from '../../../application/hooks/useModal';
import { logger } from '../../../application/logging/logging';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import { ValidationFormRules, ValidationConstants } from '../../../helpers/validation-helpers';
import { AppButton } from '../../uikit/buttons/button/button.component';
import Style from './rejectForm.module.css';
import { AppTextField } from '../../uikit/fields/text/appTextField.component';
import { AppModalContainer } from '../../uikit/modal/modal.component';
import { AppInputError } from '../../uikit/wrappers';

export type IssueRejectFormParams = {
  issueId: number;
  callback: (data: UseFormRejectIssueType) => Promise<void>;
};

export type UseFormRejectIssueType = {
  comment: string;
};

export type RejectIssueFormType = {
  modal: UseModalProps<IssueRejectFormParams>;
};

export const IssueRejectForm: FC<RejectIssueFormType> = ({ modal }) => {
  const { t } = useTranslation();
  const [internalError, setInternalError] = useState(false);

  const { issueId, callback } = modal.params;

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<UseFormRejectIssueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      comment: '',
    },
  });

  const onSubmit = handleSubmit(async (data: UseFormRejectIssueType) => {
    setInternalError(false);
    try {
      await callback(data);
      modal.closeModal();
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof UseFormRejectIssueType;
          setError(formKey, {
            type: 'server',
            message: ex.errors[errorKey][0],
          });
        }
      } else {
        logger().error(ex);
        setInternalError(true);
      }
    }
  });

  const buttons = useMemo(() => {
    return (
      <div className={Style.footer}>
        <AppInputError errors={internalError ? t('Issues.GeneralError') : undefined} hideBorder position={'top'}>
          <div className={Style.buttonGroup}>
            <AppButton
              text={t('Common_Cancel')}
              variant={'button'}
              colorSchema={'secondary'}
              onClick={modal.closeModal}
              disabled={isSubmitting}
            />
            <AppButton
              text={t('Issues.RejectModal.RejectButton')}
              variant={'button'}
              colorSchema={'destroy'}
              type={'submit'}
              disabled={isSubmitting}
              isLoading={isSubmitting}
              form={'rejectIssueForm'}
            />
          </div>
        </AppInputError>
      </div>
    );
  }, [internalError, isSubmitting, modal.closeModal, t]);

  const content = useMemo(() => {
    return (
      <form id={'rejectIssueForm'} onSubmit={onSubmit} autoComplete={'off'}>
        <Controller
          control={control}
          name={'comment'}
          rules={{
            ...ValidationFormRules().maxLengthRule,
            ...ValidationFormRules().requiredRule,
          }}
          render={({ field: { onChange, value } }) => (
            <AppTextField
              maxLength={ValidationConstants.textInputMaxLength}
              type={'text-area'}
              labelProps={{ text: t('Issues.RejectModal.CommentLabel') }}
              placeholder={t('Issues.RejectModal.CommentPlaceholder')}
              value={value}
              onChange={onChange}
              disabled={isSubmitting}
              errorProps={{ errors: errors?.comment?.message }}
            />
          )}
        />
      </form>
    );
  }, [control, errors?.comment?.message, isSubmitting, onSubmit, t]);

  return (
    <AppModalContainer
      bodyClassName={Style.modalBody}
      title={t('Issues.RejectModal.Title', { id: issueId })}
      visible={modal.visible}
      onHide={modal.closeModal}
      footer={buttons}
      onDismissed={reset}
      isDisabled={isSubmitting}
      testId={'reject-issue-modal'}
    >
      {content}
    </AppModalContainer>
  );
};
