import React from 'react';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import {
  AppRadioGroupField,
  AppRadioGroupFieldProps,
} from '../components/uikit/fields/radio/appRadioGroupField.component';
import { RadioGroupOption } from '../components/uikit/inputs/radio/appRadioGroupInput';

type HookFormType<D extends RadioGroupOption, TFieldValues extends FieldValues = FieldValues> = Omit<
  AppRadioGroupFieldProps<D, 'value'>,
  'onChange'
> & {
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  rules?: Exclude<RegisterOptions, 'valueAsDate' | 'setValueAs'>;
  onFocus?: () => void;
};

export function HookFormRadioGroup<D extends RadioGroupOption, TFieldValues extends FieldValues = FieldValues>(
  props: HookFormType<D, TFieldValues>,
) {
  const { control, name, rules, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <AppRadioGroupField<D, 'value'>
            {...rest}
            value={value}
            onChange={(val: RadioGroupOption | null) => {
              onChange(val?.value);
            }}
          />
        );
      }}
    />
  );
}
