import React, { ReactElement, useMemo } from 'react';
import { EditReasonEnum } from '../../../services/api/api-client';
import Style from './useFormOverview.module.scss';
import { editReasonsSelectOptions } from '../../../components/formEditor/modals/reasonModal/controlOptions';
import clsx from 'clsx';
import { TypographyStyles } from '../../../styles';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';

export const EditReasonFormHeader = (props: {
  updatedAt?: Date | null;
  editReason?: EditReasonEnum | null;
  updateReasonText?: string | null;
  editorName?: string;
  testId?: string;
}) => {
  const { editReason, updatedAt, updateReasonText, editorName, testId } = props;
  const { t } = useScopedTranslation('Forms.Overview');
  const commonLocalizer = useCommonLocalization();

  const editTooltipContent = useMemo(() => {
    if (!editReason) {
      return <></>;
    }

    return (
      <div className={Style.reasonTooltipContainer}>
        <div className={Style.reasonTooltipBlock}>
          <span className={Style.reasonTooltipHeader}>{t('ReasonTooltipHeader')}</span>
          <p className={Style.reasonTooltipText}>{commonLocalizer(`Forms.EditReasonModal.${editReason}`).toString()}</p>
        </div>

        {updateReasonText && (
          <div className={Style.reasonTooltipBlock}>
            <span className={Style.reasonTooltipHeader}>{t('ReasonUpdateTooltipHeader')}</span>
            <p className={Style.reasonTooltipText}>{updateReasonText}</p>
          </div>
        )}
      </div>
    );
  }, [editReason, updateReasonText, t, commonLocalizer]);

  const overviewHeader: ReactElement = useMemo(() => {
    const editReasonIcon = editReasonsSelectOptions().find((option) => option.key === editReason)?.icon;

    if (!updatedAt) {
      return <></>;
    }

    return (
      <div className={Style.modalHeader}>
        <span
          className={clsx(Style.modalHeaderEditDate, TypographyStyles.plainText14)}
          data-test-id={testId ?? 'form-filling-details'}
        >
          {!!editorName
            ? t(editReason ? 'Update' : 'Filled', {
                date: localFormat(updatedAt, DateFormats.longDate),
                name: editorName,
              })
            : t('Updated', { date: localFormat(updatedAt, DateFormats.longDate) })}
        </span>

        {editReason && (
          <Tooltip tooltipContent={editTooltipContent} text={''} styles={Style.modalHeaderTooltipIcon}>
            <div className={Style.modalHeaderTooltipIcon}>{editReasonIcon}</div>
          </Tooltip>
        )}
      </div>
    );
  }, [updatedAt, editorName, t, editReason, editTooltipContent]);

  return <>{overviewHeader}</>;
};
