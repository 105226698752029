import { IssueReportOptions } from 'src/features/report/issues/issueReport';
import { subDays } from 'date-fns';
import { IssueDto, IssuesStateEnum } from 'src/services/api/api-client';
import { issueTopicsForPatient } from 'src/components/issue/bar/issueFilters-helper';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';

export const issueReportFileNameTemplate = (studyNumber: string) =>
  `FollowUP-Letter-${studyNumber}-${localFormat(new Date(), DateFormats.forFiles)}.pdf`;

/**
 * Dummy data for DEBUG proposal
 */
export const issueOptions: IssueReportOptions = {
  data: Array(20)
    .fill(1)
    .map((_, index) => {
      return {
        id: index + 1,
        linkedPatientUniqId: Math.floor(Math.random() * 1000)
          .toString()
          .padStart(3, '0'),
        createdAt: subDays(Date.now(), Math.floor(Math.random() * 99)),
        deadlineAt: subDays(Date.now(), index),
        createdByUser: `User ${Math.floor(Math.random() * 100)}`,
        description: 'Some issue text could be placed here',
        state: Math.floor(Math.random() * 3),
        topic: issueTopicsForPatient[Math.floor(Math.random() * issueTopicsForPatient.length)],
      } as unknown as IssueDto;
    }),
  filters: {
    patientGroupList: [1],
    patientUid: '001',
    sortBy: 'id',
    state: IssuesStateEnum.Closed,
    subjectList: ['Patient', 'Study'],
  },
  studyGroups: [],
  studyNumber: 'test404',
};
