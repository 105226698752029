import React from 'react';
import { subTimeUnitFromDate, formatDateRange } from '../../../helpers/date-helpers';
import { IPatientListDto } from '../../../services/api/api-client';
import { FinishStudyComponent } from '../../finishStudy/finishStudy.component';
import { StartStudyComponent } from '../../startStudy/startStudy.component';
import Style from './studyPeriodSection.module.css';
import { Permissions } from '../../../helpers/auth/auth-helper';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';

export const StudyPeriodSection = (patient: IPatientListDto) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo');
  return (
    <div className={Style.container} data-test-id={'patient-card-period'}>
      <div>
        <p className={Style.periodHeader}>{t('PlannedStudyPeriod')}</p>
        <div className={Style.studyPeriodContainer}>
          {patient.studyStartedAt ? (
            <>
              <span>
                {formatDateRange(patient.studyStartedAt, subTimeUnitFromDate(patient.studyFinishDate, 'days'))}
              </span>
              <PermissionsCheck permissions={Permissions.EditPatient}>
                <FinishStudyComponent patient={patient} />
              </PermissionsCheck>
            </>
          ) : (
            <>
              <span>{t('StartStudy.NotSpecified')}</span>
              <PermissionsCheck permissions={Permissions.EditPatient}>
                <StartStudyComponent patientId={patient.id} studyId={patient.studyId} />
              </PermissionsCheck>
            </>
          )}
        </div>
      </div>
      {patient.studyStartedAt && patient.isStudyFinished && (
        <>
          <div>
            <p className={Style.periodHeader}>{t('RealStudyPeriod')}</p>
            <div>
              <span>
                {formatDateRange(patient.studyStartedAt, subTimeUnitFromDate(patient.studyFinishedAt, 'seconds'))}
              </span>
            </div>
          </div>
          <div>
            <p className={Style.periodHeader}>{t('ReasonOfFinish')}</p>
            <div className={Style.studyPeriodContainer}>
              <span>{patient.studyFinishedDescription ?? t('FinishStudy.Modal.Reason.Planned')}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
