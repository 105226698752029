export type AppSliderInputProps = {
  disabled?: boolean;
  caption?: {
    startText?: string;
    endText?: string;
  };
  className?: string;
  showMarks?: boolean;
  /** @default SingleColor */
  colorScheme?: SliderColorSchemeEnum;
  startWith: number;
  endWith: number;
  defaultValue?: number;
  value?: number;
  onChange?: (v: number) => void;
};

export enum SliderColorSchemeEnum {
  SingleColor = 1,
  GreenToRed = 2,
  RedToGreen = 3,
}
