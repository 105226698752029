/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import { _isSkipped } from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import { useEditor, useNode } from '@craftjs/core';
import { useAppSelector } from '../../../../application/redux-store/store-types';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import styles from '../controlsStyle.module.css';
import {
  IRadioInputProps,
  radioInputDefaultPropsFactory,
} from '../../../../features/forms/base/controls/inputs/RadioInput';
import { IFormFieldOption } from '../../../../features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import {
  useFieldDataFromUiEditorContext,
  useRefForDataBlockNavigation,
  useFieldValidation,
} from '../Inputs/base/hooks';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { IPatientGroupInput } from '../../../../features/forms/base/controls/specials/PatientGroupInput';
import { AppRadioGroupField } from '../../../uikit/fields/radio/appRadioGroupField.component';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const PatientGroupInput: IPatientGroupInput = (props) => {
  const { connectors, id } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
    allNodes: state.nodes,
  }));
  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);

  const { connect, drag } = connectors;
  const rowRef = useRef(null);

  const { size, text, dataKey, hasSkipCheckBox, isDisabled, isDisableWhenEditing } = props;

  const groups = useAppSelector((state) => state.app.groups);
  const options = useMemo(
    () =>
      groups.map((group) => {
        return {
          key: group.id,
          value: group.id.toString(),
          displayValue: group.title,
        } as IFormFieldOption;
      }),
    [groups],
  );

  const { isOnlyView, isEditMode, singleInputValue, setDataBlockFieldValue, markFieldAsTouched } =
    useFieldDataFromUiEditorContext(dataKey);

  const blockRef = useRefForDataBlockNavigation();
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const displayValue = useMemo(
    () => options.find((x) => x.value === singleInputValue)?.displayValue,
    [singleInputValue, options],
  );

  const editableText = (
    <div
      data-test-field-type={'radio-input'}
      className={styles.container}
      style={{ width: size }}
      draggable={enabled}
      ref={(ref) => enabled && connect(drag(ref!))}
    >
      <div ref={blockRef}>
        <AppRadioGroupField<IFormFieldOption, 'value'>
          labelProps={{
            isBold: props.isBold,
            tooltip: props.tooltip,
            text: !props.hideLabel ? props.label || text || dataKey : undefined,
          }}
          disabled={!props.isEditable || isDisabled || (isDisableWhenEditing && isEditMode) || isSubmitting}
          labelField={'displayValue'}
          valueField={'value'}
          options={options}
          numerationOrder={props.numerationOrder}
          numerationStartIndex={props.numerationStartIndex}
          value={singleInputValue}
          isVertical={props.isVertical}
          onChange={(option) => {
            markFieldAsTouched?.(id);
            if (option && typeof option === 'string') {
              setDataBlockFieldValue?.(dataKey, option);
              return;
            }

            setDataBlockFieldValue?.(dataKey, option?.value);
          }}
          skipCheckboxProps={{
            skipText: props.skipCheckBoxText,
            hasSkipCheckBox: hasSkipCheckBox,
            isSkipped: singleInputValue === _isSkipped,
          }}
          errorProps={{
            errors: dataBlockValidation?.text,
          }}
        />
      </div>
    </div>
  );

  const notEditableText = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label || props.text || props.dataKey,
        resultId: formResult?.id ?? undefined,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={{ right: 24, top: 6 }}
    >
      <div ref={rowRef} className={clsx(styles.previewRow, 'preview-row', styles.previewRowOverride)}>
        <div className={styles.radioButtonPreviewRow}>
          <span className={TypographyStyles.heading2}>{props.label || props.text || props.dataKey}</span>
          <span className={TypographyStyles.plainText14}>{displayValue}</span>
        </div>
      </div>
    </IssueMark>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

const RadioInputSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IRadioInputProps,
  }));

  return (
    <div className={styles.settings}>
      <CommonFieldSettings />

      <CheckboxSettings
        label={t('StudySettingsPage.FormBuilder.settings.isVertical')}
        isChecked={!!props.isVertical}
        setValue={(newValue) => setProp((setProps: IRadioInputProps) => (setProps.isVertical = newValue))}
      />

      <LayoutSettings />
      <ValidationSettings applicableRules={{ isRequired: true }} />
    </div>
  );
};

PatientGroupInput.craft = {
  props: radioInputDefaultPropsFactory(),
  related: {
    settings: RadioInputSettings,
  },
};
