import React from 'react';
import { useEditor } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import { ReactComponent as UndoSvg } from '../../../../assets/img/formBuilder/undo.svg';
import { AppButton } from 'uikit/buttons';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { FaqEditorContext } from '../../faqEditor.context';
import Style from './viewport.module.css';
import { useTranslation } from 'react-i18next';
import { Permissions } from '../../../../helpers/auth/auth-helper';
import { PermissionsCheck } from '../../../../helpers/components/PermissionCheck/PermissionCheck.component';

export const Header = () => {
  const { t } = useTranslation();
  const { enabled, canUndo, canRedo, actions, query } = useEditor((state, q) => ({
    enabled: state.options.enabled,
    canUndo: q.history.canUndo(),
    canRedo: q.history.canRedo(),
  }));

  const onSave = useContextSelector(FaqEditorContext, (x) => x.onSave);
  const setDesignerMode = useContextSelector(FaqEditorContext, (x) => x.setDesignerMode);

  return (
    <div className={Style.header}>
      <div className={Style.headerContainer}>
        {enabled && (
          <div className={Style.undoRedoContainer}>
            <Tooltip text={t('FaqEditor.Header.Undo')}>
              <AppButton
                disabled={!canUndo}
                Icon={UndoSvg}
                onClick={actions.history.undo}
                variant={'icon-link'}
                colorSchema={'primary'}
              />
            </Tooltip>
            <Tooltip text={t('FaqEditor.Header.Redo')}>
              <AppButton
                disabled={!canRedo}
                Icon={UndoSvg}
                onClick={actions.history.redo}
                variant={'icon-link'}
                colorSchema={'primary'}
                iconClassName={Style.redoButton}
              />
            </Tooltip>
          </div>
        )}
        <div className={Style.rightButtonContainer}>
          {enabled && (
            <AppButton
              variant={'button'}
              colorSchema={'primary'}
              text={t('Common_Save')}
              onClick={() => {
                onSave?.(query.getSerializedNodes());
                setDesignerMode?.(false);
              }}
            />
          )}
          <PermissionsCheck permissions={Permissions.AdministerStudy}>
            <AppButton
              variant={'icon-link'}
              colorSchema={'primary'}
              text={t(enabled ? 'FaqEditor.Header.Preview' : 'FaqEditor.Header.Edit')}
              onClick={() => setDesignerMode?.(!enabled)}
            />
          </PermissionsCheck>
        </div>
      </div>
    </div>
  );
};
