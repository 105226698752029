import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AppEvents } from '../../features/app/app-reducer';
import { IDoctorStudyDto } from '../../services/api/api-client';
import { navigateToRoute } from '../../navigation/routes';
import { Permissions, useHasPermissions } from '../../helpers/auth/auth-helper';
import { ColumnDef } from '@tanstack/react-table';
import { linkButtonColumn } from 'uikit/table/columns/LinkButtonColumn';
import styles from './studies.module.scss';
import { ReactComponent as Chevron } from 'src/assets/img/patient/chevronPatientRow.svg';

export const useGoToStudyButton = (): ColumnDef<IDoctorStudyDto> => {
  const dispatch = useDispatch();
  const hasPermissions = useHasPermissions();

  const onClickHandler = useCallback(
    (study: IDoctorStudyDto) => {
      dispatch(AppEvents.selectStudy(study));
    },
    [dispatch],
  );

  const navigationUrl = useCallback(
    (study: IDoctorStudyDto) => {
      if (hasPermissions(Permissions.ViewPatient)) {
        return navigateToRoute.dashboard(study.id);
      }

      if (hasPermissions(Permissions.AdministerStudy)) {
        return navigateToRoute.studySettings(study.id);
      }

      return navigateToRoute.dashboard(study.id);
    },
    [hasPermissions],
  );

  return useMemo(
    () =>
      linkButtonColumn({
        id: 'go-to-study-button',
        icon: Chevron,
        to: ({ row }) => navigationUrl(row.original),
        onClick: ({ row }) => onClickHandler(row.original),
        className: styles.goToStudyButton,
      }),
    [navigationUrl, onClickHandler],
  );
};
