import React, { FC, PropsWithChildren, useCallback, useMemo, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppRoutes } from './routes';
import { Permissions, useHasPermissions } from '../helpers/auth/auth-helper';
import { logger } from '../application/logging/logging';
import { useAuth } from '../helpers/auth/auth-interceptor';

const MAX_REDIRECT_RETRY_COUNT = 3;

type PrivateRouteProps = {
  permissions: Permissions | Permissions[];
};

type RedirectHistory = {
  url: string;
  attempt: number;
};

export const PrivateRoute: FC<PropsWithChildren<PrivateRouteProps>> = (props) => {
  const hasPermissions = useHasPermissions();
  const authState = useAuth();

  const redirectHistoryRef = useRef<RedirectHistory[]>([]);

  let isAllowed = true;
  if (props.permissions instanceof Array) {
    isAllowed = isAllowed && hasPermissions(...props.permissions);
  } else if (props.permissions !== undefined) {
    isAllowed = isAllowed && hasPermissions(props.permissions);
  }

  const location = useLocation();

  /**
   * @summary Tracks redirect to ref-object to have history and limit-border condition from loop.
   * If attempt number is less or equal than maximum available - will try to redirect to expected URL,
   * otherwise to AccessDenied page.
   * @param redirectedUrl - expected redirect URL
   * @returns redirectUrl string.
   */
  const trackRedirectHistoryAndGetActualUrl = useCallback((redirectedUrl: string): string => {
    const historyItemIndex = redirectHistoryRef?.current.findIndex((x) => x.url === redirectedUrl);
    if (historyItemIndex >= 0) {
      const historyItem = redirectHistoryRef.current[historyItemIndex];
      if (historyItem.attempt > MAX_REDIRECT_RETRY_COUNT) {
        logger().error(
          `The max redirects attempts has been reached - will redirect to ${
            AppRoutes.AccessDenied
          }. Full redirect history: ${JSON.stringify(redirectHistoryRef.current)}`,
        );
        return AppRoutes.AccessDenied;
      }

      redirectHistoryRef.current[historyItemIndex] = { ...historyItem, attempt: historyItem.attempt + 1 };
    } else {
      redirectHistoryRef.current.push({ url: redirectedUrl, attempt: 1 });
    }
    return redirectedUrl;
  }, []);

  const redirectUrl = useMemo(() => {
    if (hasPermissions(Permissions.ViewPatient)) {
      return trackRedirectHistoryAndGetActualUrl(AppRoutes.Dashboard);
    }

    if (hasPermissions(Permissions.AdministerStudy)) {
      return trackRedirectHistoryAndGetActualUrl(AppRoutes.StudySettings);
    }

    if (hasPermissions(Permissions.AdministerApp)) {
      return trackRedirectHistoryAndGetActualUrl(AppRoutes.Admin);
    }

    return AppRoutes.AccessDenied;
  }, [hasPermissions, trackRedirectHistoryAndGetActualUrl]);

  // not logged in so redirect to login page with the return url
  if (!authState) {
    logger().info('User is not authorized.');
  }

  // check if route is restricted by role
  if (!isAllowed) {
    logger().info(
      `User has not got right to see the page "${location.pathname}". Redirecting to available root page - "${redirectUrl}"`,
    );

    // role not authorised so redirect to home page
    return <Navigate to={{ pathname: redirectUrl }} replace={true} />;
  }

  return <>{props.children}</>;
};
