/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { QueryFactory } from 'src/services/api';
import { TypographyStyles } from 'src/styles';
import Style from './surveyAnswers.module.css';
import { formatDateRange } from 'src/helpers/date-helpers';
import { QualityMetric } from 'src/services/api/api-client';
import { SurveyAnswersProps } from './surveyAnswers';
import { AnswersTableRow } from './answerTableRow.component';
import { ScoreTableRow } from './scoreTableRow.component';
import placeholderIcon from '../../assets/img/survey_placeholder.svg';
import { HeaderTableRow } from './headerTableRow.component';
import { getScoreStyleColor } from 'src/helpers/color-helper';
import { Tooltip } from '../../components/uikit/tooltip/tooltip.component';
import { ReactComponent as Doctor } from '../../assets/img/survey/doctor_16.svg';
import { isNullOrEmpty } from '../../helpers/string-helper';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { AppModalContainer } from '../../components/uikit/modal/modal.component';
import { Loading } from 'uikit/suspense/Loading';

export const SurveyAnswers = (props: SurveyAnswersProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection');
  const [swipeIndex, setSwipeIndex] = useState<number>(0);

  const singleAnswerQuery = QueryFactory.SurveyQuery.useGetAnswerStatisticQuery(props.answerId!, props.patientId, {
    suspense: false,
    enabled: !!props.answerId,
  });

  const groupAnswerQuery = QueryFactory.SurveyQuery.useGetAnswersStatisticQuery(props.patientId, props.surveyType, {
    suspense: false,
    enabled: !props.answerId,
  });

  const { data: surveysData, isLoading } = useMemo(() => {
    if (props.answerId) {
      return {
        data: singleAnswerQuery.data,
        isLoading: singleAnswerQuery.isInitialLoading,
      };
    }

    return {
      data: groupAnswerQuery.data,
      isLoading: groupAnswerQuery.isInitialLoading,
    };
  }, [props.answerId, groupAnswerQuery, singleAnswerQuery]);

  const legend = useMemo(() => {
    if (!surveysData) {
      return null;
    }

    const scheme = surveysData.questionScheme;
    if (!scheme) {
      return null;
    }

    return scheme.legend;
  }, [surveysData]);

  const answersStatisticsList = useMemo(() => {
    return surveysData?.answers?.sort((a, b) => (!a || !b || a.answerDate > b.answerDate ? 1 : -1));
  }, [surveysData]);

  const questionsList = useMemo(() => {
    if (!surveysData) {
      return null;
    }

    const questionScheme = surveysData.questionScheme;
    if (!questionScheme) {
      return null;
    }

    return questionScheme.questions ?? null;
  }, [surveysData]);

  const answersList = useMemo(() => {
    return answersStatisticsList?.map((answer) => {
      if (!answer) {
        return null;
      }

      const answerScheme = answer.rawAnswer;
      if (!answerScheme) {
        return {
          answerScheme: null,
          answerId: answer.answerId,
          date: answer.answerDate,
          score: null,
          quality: QualityMetric.Empty,
        };
      }

      return {
        answerScheme: answerScheme ?? null,
        date: answer.answerDate,
        score: answer.score,
        quality: answer.quality,
        answeredBy: answer.answeredBy,
        scoreStatus: answer.scoreStatus,
        answerId: answer.answerId,
        canRetryScoreCalculation: answer.canRetryScoreCalculation,
      };
    });
  }, [answersStatisticsList]);

  const headerText = useMemo(() => {
    if (!surveysData) {
      return '';
    }

    if (props.answerId && surveysData && surveysData.answers) {
      return t('LastSurveyModal.ModalHeader', {
        date: formatDateRange(surveysData.answers[0].answerDate, surveysData.answers[0].answerDate),
      });
    }

    return t('StatisticsModal.Header', {
      period: surveysData.studyStartedAt
        ? formatDateRange(surveysData.studyStartedAt, surveysData.studyFinishedAt)
        : '',
    });
  }, [props.answerId, surveysData, t]);

  const header = useMemo(() => {
    const data = answersList?.map((option) => {
      return { date: option?.date, answeredBy: option?.answeredBy };
    });

    return (
      <div className={Style.modalHeader} data-test-id={'study-period-date'}>
        <span className={TypographyStyles.plainText14}>{headerText}</span>
        {props.answerId && data && data[0]?.answeredBy && (
          <Tooltip
            hostStyles={Style.doctorIcon}
            text={t('LastAvailableStats.FilledBy', {
              doctor: data[0].answeredBy.fullName,
            })}
          >
            <Doctor />
          </Tooltip>
        )}
      </div>
    );
  }, [answersList, headerText, props.answerId, t]);

  const footer = useMemo(() => {
    if (!legend || isNullOrEmpty(legend)) {
      return undefined;
    }

    return (
      <div className={clsx(Style.modalFooter, TypographyStyles.plainText12)}>
        {legend.split(',').map((value, index) => (
          <span key={index}>{value}</span>
        ))}
      </div>
    );
  }, [legend]);

  const modalContent = useMemo(() => {
    if (!answersStatisticsList || !questionsList || !answersList) {
      return (
        <Loading
          loading={isLoading}
          renderChildren={false}
          testId={'survey-results'}
          containerClassName={Style.placeholderContainer}
        >
          <img className={Style.placeholderIcon} alt={'survey placeholder icon'} src={placeholderIcon} />
          <p className={clsx(TypographyStyles.paragraph14, Style.placeholderText)}>
            {t('LastAvailableStats.NoDataYet')}
          </p>
        </Loading>
      );
    }

    const answerRows = questionsList.map((q, i) => {
      if (!q) {
        return <React.Fragment key={i} />;
      }

      const answerSchemes = answersList.map((option) => {
        return option?.answerScheme ?? null;
      });

      return (
        <div key={i}>
          <AnswersTableRow
            index={i + 1}
            parentQuestion={undefined}
            question={q}
            answers={answerSchemes}
            swipeIndex={swipeIndex}
            viewMode={props.answerId === undefined ? 'table' : 'single'}
          />
        </div>
      );
    });

    const data = answersList.map((option) => {
      return { date: option?.date, answeredBy: option?.answeredBy };
    });

    const dateRow = !props.answerId && (
      <div key={'date-row'} className={clsx(Style.stickyTop)}>
        <HeaderTableRow
          data={data}
          onSwipe={setSwipeIndex}
          swipeIndex={swipeIndex}
          isSingleAnswer={props.answerId !== undefined}
        />
      </div>
    );

    const scoreList = answersList.map((option) => {
      if (!option) {
        return null;
      }

      return {
        score: option.score,
        quality: option.quality,
        scoreStatus: option.scoreStatus,
        answerId: option.answerId,
        canRetryScoreCalculation: option.canRetryScoreCalculation,
      };
    });

    const scoreRow = (
      <div key={'score-row'} className={Style.stickyBottom}>
        <ScoreTableRow
          text={t('LastAvailableStats.Score')}
          scores={scoreList}
          swipeIndex={swipeIndex}
          containerClassName={clsx({ [getScoreStyleColor(scoreList[0]?.quality)]: props.answerId })}
          isSingleAnswer={props.answerId !== undefined}
        />
      </div>
    );

    return [dateRow, ...answerRows, scoreRow];
  }, [answersList, answersStatisticsList, isLoading, props.answerId, questionsList, swipeIndex, t]);

  return (
    <AppModalContainer
      title={props.title}
      header={header}
      visible={props.isVisible}
      onHide={props.onClose}
      isClickOutside={true}
      bodyClassName={Style.body}
      footer={footer}
      testId={`survey-form-result-${props.surveyType}`}
    >
      {modalContent}
    </AppModalContainer>
  );
};
