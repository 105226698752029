import { defaultToolSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import {
  ApplicableRules,
  ValidationDefaultSettings,
  ValidationProps,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { createId } from 'src/helpers/typeUtils';
import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { UserComponent } from '@craftjs/core';

export interface ITagInputProps extends IFormFieldProps {
  isMandatory?: boolean;
  validation: ValidationProps;
  placeholder?: string;
  withComment?: boolean;
}

export type ITagInput = UserComponent<ITagInputProps>;

export function tagInputDefaultPropsFactory(): ITagInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    validation: { ...ValidationDefaultSettings },
  };
}

export const tagApplicableValidationRules: ApplicableRules = {
  isRequired: true,
  maxLength: true,
  pattern: true,
};
