import {
  BaseQuestion,
  ConditionalOption,
  IQuestionScheme,
  QuestionScheme,
  QuestionType,
  SkipOptions,
} from 'src/services/api/api-client';
import i18n from 'i18next';

export function GetAnswerValue(question: BaseQuestion) {
  return question.options?.find((x) => x.answerValue !== null)?.answerValue || null;
}

export function GetAnswerScoreValue(question: BaseQuestion) {
  return question.options?.find((x) => x.answerValue !== null)?.scoreValue;
}

export function GetAnswerOptionText(question: BaseQuestion) {
  return question.options?.find((x) => x.answerValue !== null)?.optionText || null;
}

export function GetDefaultValue(question: BaseQuestion) {
  return question.options?.find((x) => x.defaultValue !== null)?.defaultValue || null;
}

export function isConditionalOption(x: any | ConditionalOption): x is ConditionalOption {
  return (x as ConditionalOption).questionScheme !== undefined;
}

export function questionSchemaToArray(
  schema: IQuestionScheme | null,
  ignoreConditionalAnswers: boolean = false,
): BaseQuestion[] | null {
  if (!schema || !schema.questions) {
    return null;
  }

  const arr: BaseQuestion[] = [];
  schema.questions.forEach((question) => {
    arr.push(question);

    question.options?.forEach((option) => {
      if (isConditionalOption(option) && option.questionScheme && (option.answerValue || ignoreConditionalAnswers)) {
        questionSchemaToArray(option.questionScheme, ignoreConditionalAnswers)?.forEach((q) => arr.push(q));
      }
    });
  });

  return arr;
}

export function formatScoreValue(value?: number | null, withPlaceholder?: boolean) {
  if (value !== null && value !== undefined) {
    const secondPrecision = value.toFixed(2);
    /*
          we do that because sometimes "rounds" happens.

          e.g
          7.95454545454 with `value.toFixed(1)` return 8.0 insteadof 7.9
      */
    return secondPrecision.substr(0, secondPrecision.length - 1);
  }

  return withPlaceholder ? i18n.t('Common_dash') : '';
}

export function getQuestionById(schema: IQuestionScheme, id: string): BaseQuestion | null {
  // @ts-ignore
  for (const question of schema.questions) {
    if (question.id === id) {
      return question;
    }

    // @ts-ignore
    for (const option of question.options) {
      if (isConditionalOption(option) && option.answerValue && option.questionScheme) {
        const result = getQuestionById(option.questionScheme, id);
        if (result) {
          return result;
        }
      }
    }
  }

  return null;
}

export function addAnswerToQuestionSchema(
  answerValue: any | null,
  question: BaseQuestion,
  questionScheme: QuestionScheme,
) {
  const questionInSchema = getQuestionById(questionScheme, question.id);

  switch (question.questionType) {
    case QuestionType.FreeInput:
      // @ts-ignore
      questionInSchema.options[0].answerValue = answerValue ?? null;
      break;

    case QuestionType.MultipleChose:
      // TODO: think about multiple choose
      break;

    default:
      // @ts-ignore
      questionInSchema.options.forEach((option) => {
        if (option.optionValue === String(answerValue)) {
          // @ts-ignore
          option.answerValue = String(answerValue) || null;
        } else {
          if (questionInSchema?.isSkippedRender === SkipOptions.NotSkip) {
            // @ts-ignore
            option.answerValue = null;
          }

          if (isConditionalOption(option)) {
            const innerScheme = (option as ConditionalOption).questionScheme;
            innerScheme?.questions?.forEach((q) => addAnswerToQuestionSchema(null, q, innerScheme));
          }
        }
      });
      break;
  }
}

export const getQuestionFromAnswerScheme = (
  answerScheme: IQuestionScheme,
  parentQuestion: BaseQuestion | undefined,
  question: BaseQuestion,
) => {
  if (!parentQuestion) return questionSchemaToArray(answerScheme)?.find((q) => q.id === question.id);

  // The parent question is needed to find the answer in the grouped options
  const parentQuestionFromAnswerScheme = questionSchemaToArray(answerScheme)?.find((q) => q.id === parentQuestion?.id);
  const answeredOption = parentQuestionFromAnswerScheme?.options.find(
    (o) => o.answerValue !== null,
  ) as ConditionalOption;

  return questionSchemaToArray(answeredOption?.questionScheme)?.find((q) => q.hashCode === question.hashCode);
};

export const groupOptionsByInnerScheme = (options: ConditionalOption[]) => {
  return options.reduce((result: Record<string, ConditionalOption[]>, option) => {
    const k = option.questionScheme.questions
      .map((q) => q.hashCode)
      .reduce((sum: number, cur) => sum + cur, 0)
      .toString();

    return {
      ...result,
      [k]: [...(result[k] || []), option],
    };
  }, {});
};
