import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IGroupDto, IUpdateGroupDto, UpdateGroupDto } from '../../../services/api/api-client';
import Style from './editStudy.module.scss';
import { ValidationFormRules } from '../../../helpers/validation-helpers';
import { AppButton } from 'uikit/buttons';
import { useQueryClient } from '@tanstack/react-query';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import { logger } from '../../../application/logging/logging';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import clsx from 'clsx';
import styles from '../../../components/formEditor/controls/controlsStyle.module.css';
import { AppStepper } from '../../../components/stepper/stepper.component';
import {
  mapPatientCardsToDropdownOptions,
  mapTableColumnsToDropdownOptions,
  PatientCardOption,
  patientCardsToDropdownOptions,
  tableColumnsOptions,
} from './study-helpers';
import { QueryFactory } from '../../../services/api';
import _ from 'lodash';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppInputLabel } from 'uikit/wrappers';
import { AppTagInputField } from 'uikit/fields';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { HookFormDropDown } from '../../../hookFormControls/hookFormDropDown';
import { AppInputError } from 'uikit/wrappers';
import { HookFormTextInput } from '../../../hookFormControls/hookFormTextInput';

export const EditStudyGroupsModalContent = (props: { groups: IGroupDto[]; onClose: () => void }) => {
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <div className={Style.container}>
      <AppStepper
        activeStep={currentTab}
        stepCount={props.groups.length}
        variant={'tabs'}
        labels={props.groups.map((x) => x.title)}
        onStepClick={setCurrentTab}
      />
      {props.groups.map((x, index) => (
        <React.Fragment key={x.title}>
          <div className={clsx({ [styles.stepVisibility]: currentTab !== index + 1 })}>
            <TabContent onClose={props.onClose} group={x} />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

const TabContent = (props: { onClose: () => void; group: IGroupDto }) => {
  const commonLocalizer = useCommonLocalization();
  const { t: modalLocalizer } = useScopedTranslation('Admin.StudyList.Groups.Modal', 'dev');
  const client = useQueryClient();
  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
    clearErrors,
    setError,
    control,
  } = useForm<IUpdateGroupDto & { patientCardSections: PatientCardOption[]; tableColumns: DropdownOption[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      tableColumns: mapTableColumnsToDropdownOptions(props.group.tableColumns),
      title: props.group.title,
      surveyColumns: props.group.surveyColumns,
      surveysToPatientCard: props.group.surveysToPatientCard,
      patientCardSections: mapPatientCardsToDropdownOptions(props.group.patientCardSections),
      formConfigIdForNotes: props.group.formConfigIdForNotes,
      noteDateDataKey: props.group.noteDateDataKey,
      mainNoteFieldDataKey: props.group.mainNoteFieldDataKey,
    },
  });

  const [internalError, setInternalError] = useState(false);

  const onSubmit = handleSubmit(
    async (
      data: Omit<IUpdateGroupDto, 'patientCardSections'> & {
        patientCardSections: PatientCardOption[];
        tableColumns: DropdownOption[];
      },
    ) => {
      try {
        setInternalError(false);
        clearErrors();
        const cardConfig = _(data.patientCardSections)
          .groupBy('column')
          .map((x) => {
            return x.flatMap((v) => v.card);
          })
          .value();
        const payload = new UpdateGroupDto({
          ...data,
          patientCardSections: cardConfig,
          tableColumns: _(data.tableColumns).sumBy('key'),
        });
        await QueryFactory.StudyQuery.Client.updateGroupConfiguration(props.group.id, payload);
        await client.invalidateQueries(QueryFactory.StudyQuery.getStudyListQueryKey());
        props.onClose();
      } catch (ex: any) {
        if (ex.errors && Object.keys(ex.errors).length > 0) {
          for (const errorKey of Object.keys(ex.errors)) {
            const formKey = pascalToCamelCase(errorKey) as keyof IUpdateGroupDto;
            setError(formKey, {
              type: 'server',
              message: commonLocalizer(
                `ServerErrors.${ex.errors[errorKey][0]}` as LocalizedResourceDictionaryKeys,
              ) as string,
            });
          }
        } else {
          logger().error(ex);
          setInternalError(true);
        }
      }
    },
  );

  const content = useMemo(() => {
    return (
      <>
        <HookFormTextInput
          control={control}
          name={'title'}
          labelProps={{ text: modalLocalizer('Title.FieldLabel') }}
          placeholder={modalLocalizer('Title.FieldPlaceholder')}
          rules={ValidationFormRules().requiredRule}
        />

        <HookFormDropDown
          isMultiple={true}
          labelProps={{ text: modalLocalizer('TableColumns.FieldLabel') }}
          placeholder={modalLocalizer('TableColumns.FieldPlaceholder')}
          options={tableColumnsOptions()}
          name={'tableColumns'}
          control={control}
        />

        <HookFormDropDown
          isMultiple={true}
          labelProps={{ text: modalLocalizer('PatientCardSections.FieldLabel') }}
          placeholder={modalLocalizer('PatientCardSections.FieldPlaceholder')}
          options={patientCardsToDropdownOptions()}
          name={'patientCardSections'}
          control={control}
        />

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <AppTagInputField
              labelProps={{
                text: modalLocalizer('SurveyColumns.FieldLabel'),
              }}
              placeholder={modalLocalizer('SurveyColumns.FieldPlaceholder')}
              values={value || []}
              onDeleteTag={(tagIndex) => {
                const newArray = value?.slice() ?? [];
                newArray.splice(tagIndex, 1);
                onChange(newArray);
              }}
              onAddTag={(newSurvey) => onChange([...(value || []), newSurvey])}
            />
          )}
          name={'surveyColumns'}
        />

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <AppTagInputField
              labelProps={{
                text: modalLocalizer('SurveysToPatientCard.FieldLabel'),
              }}
              placeholder={modalLocalizer('SurveysToPatientCard.FieldPlaceholder')}
              values={value || []}
              onDeleteTag={(tagIndex) => {
                const newArray = value?.slice() ?? [];
                newArray.splice(tagIndex, 1);
                onChange(newArray);
              }}
              onAddTag={(newSurvey) => onChange([...(value || []), newSurvey])}
            />
          )}
          name={'surveysToPatientCard'}
        />

        <AppInputLabel text={modalLocalizer('Notes.Caption')} isBold={true}>
          <HookFormTextInput
            control={control}
            name={'formConfigIdForNotes'}
            labelProps={{ text: modalLocalizer('Notes.FormConfigId.FieldLabel') }}
            placeholder={modalLocalizer('Notes.FormConfigId.FieldPlaceholder')}
          />

          <HookFormTextInput
            control={control}
            name={'mainNoteFieldDataKey'}
            labelProps={{ text: modalLocalizer('Notes.MainDataKey.FieldLabel') }}
            placeholder={modalLocalizer('Notes.MainDataKey.FieldPlaceholder')}
          />

          <HookFormTextInput
            control={control}
            name={'noteDateDataKey'}
            labelProps={{ text: modalLocalizer('Notes.DateDataKey.FieldLabel') }}
            placeholder={modalLocalizer('Notes.DateDataKey.FieldPlaceholder')}
          />
        </AppInputLabel>
      </>
    );
  }, [control, modalLocalizer]);

  return (
    <form onSubmit={onSubmit} className={Style.content} autoComplete={'off'}>
      {content}

      <AppInputError errors={internalError ? modalLocalizer('Error') : undefined} hideBorder position={'top'}>
        <div className={Style.buttonsGroup}>
          <AppButton
            variant={'button'}
            colorSchema={'secondary'}
            text={commonLocalizer('Common_Cancel')}
            type={'reset'}
            onClick={props.onClose}
            className={Style.button}
          />
          <AppButton
            variant={'button'}
            colorSchema={'primary'}
            text={commonLocalizer('Common_Save')}
            type={'submit'}
            disabled={!isValid || isSubmitting}
            isLoading={isSubmitting && !internalError}
            hasLoaded={isSubmitSuccessful && !internalError}
            className={Style.button}
          />
        </div>
      </AppInputError>
    </form>
  );
};
