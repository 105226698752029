import React, { useState } from 'react';
import { ConditionalOption, IQuestionScheme } from 'src/services/api/api-client';
import Style from './optionOverview.module.css';
import { ReactComponent as Delete } from '../../../../assets/img/common/delete.svg';
import { ReactComponent as Chevron } from '../../../../assets/img/common/blueChevron.svg';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import clsx from 'clsx';
import { isConditionalOption } from 'src/helpers/question-helper';
import { Editor } from '../../sections/editor/editor.component';
import { OptionOverviewProps } from './optionOverview';

export const OptionOverview = (props: OptionOverviewProps) => {
  const { option, isEditMode, route } = props;

  const [isSchemeDisplay, setSchemeDisplay] = useState<boolean>(false);
  const optionScheme = (option as ConditionalOption).questionScheme as IQuestionScheme;

  return (
    <div
      className={clsx(Style.containerWithScheme, { [Style.fullWidth]: isSchemeDisplay })}
      onClick={() => isEditMode && props.onEditOption()}
    >
      <div className={clsx(Style.container, { [Style.answered]: option.optionValue === option.answerValue })}>
        <div className={Style.score}>{option.scoreValue}</div>
        {option.optionText && <div>{option.optionText}</div>}
        {option.optionValue === option.defaultValue && <div className={Style.defaultValue}>def.</div>}
        {option.optionValue === option.answerValue && <div className={Style.defaultValue}>answ.</div>}
        {isConditionalOption(option) && <div className={Style.defaultValue}>{'con.'}</div>}
        <div className={Style.rightSide}>
          {isEditMode && (
            <div className={Style.buttons}>
              <AppButton
                disabled={false}
                variant={'icon-link'}
                colorSchema={'decline'}
                Icon={Delete}
                className={Style.button}
                onClick={props.onDeleteOption}
              />
            </div>
          )}
          {!isEditMode && isConditionalOption(option) && (
            <AppButton
              disabled={false}
              variant={'icon-link'}
              colorSchema={'primary'}
              Icon={Chevron}
              iconClassName={clsx({
                [Style.chevron]: !isSchemeDisplay,
              })}
              className={Style.button}
              onClick={() => setSchemeDisplay((s) => !s)}
            />
          )}
        </div>
      </div>
      {isSchemeDisplay && <div>{!isEditMode && optionScheme && <Editor route={route} scheme={optionScheme} />}</div>}
    </div>
  );
};
