/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FunctionComponent, SVGProps, useCallback, useMemo, useRef, useState } from 'react';
import Style from './appDropDownWithSuggestion.module.scss';
import { AppTextInput } from 'uikit/inputs';
import { Callout, DirectionalHint } from '@fluentui/react';
import { DropdownOption } from './appDropdownInput';
import { TypographyStyles } from 'styles';
import { ReactComponent as Cross } from 'src/assets/img/common/cross_16.svg';
import { ReactComponent as Chevron } from 'src/assets/img/common/blueChevron.svg';
import clsx from 'clsx';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DropdownOptionComponent } from './dropdownOption.component';
import { useId } from '@fluentui/react-hooks';

export type AppDropDownWithSuggestionProps<T extends DropdownOption> = {
  options: T[];
  disabled?: boolean;
  suggestion?: boolean;
  invalid?: boolean;
  placeholder?: string;
  CaretIcon?: FunctionComponent<SVGProps<any>>;
  isRequired?: boolean;
  className?: string;
} & (
  | {
      isMultiple: true;
      value?: T[];
      onChange?: (newValue: T[] | undefined) => void;
      emptyOptionLabel?: never;
    }
  | {
      isMultiple?: false | undefined;
      value?: T;
      onChange?: (newValue: T | undefined) => void;
      emptyOptionLabel?: string;
    }
);

export const AppDropDownWithSuggestionInput = <T extends DropdownOption>({
  options,
  value,
  onChange,
  isMultiple,
  disabled,
  suggestion,
  placeholder,
  invalid,
  CaretIcon,
  emptyOptionLabel,
  isRequired,
  className,
}: AppDropDownWithSuggestionProps<T>) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLElement | null>(null);
  const [showOptions, setShowOptions] = useState(false);
  const [input, setInput] = useState<string>(isMultiple ? '' : value?.text || '');
  const [filteredOptions, setFilteredOptions] = useState<T[]>([]);
  const [optionRefsArray] = useState<(HTMLButtonElement | null)[]>(() =>
    Array.from({ length: options.length }, () => null),
  );

  // This id is used to find dropdown options in UI tests
  const calloutId = useId();

  const onOptionClick = useCallback(
    (option: T) => () => {
      setShowOptions(false);
      if (isMultiple) {
        if (suggestion && input.length) {
          setInput('');
        }

        inputRef.current?.focus();
        return onChange?.([...(value || []), option]);
      }
      setInput(option?.text || '');

      inputRef.current?.focus();
      return onChange?.(option);
    },
    [input.length, isMultiple, onChange, suggestion, value],
  );

  const onEmptyOptionClick = useCallback(() => {
    setInput('');
    setShowOptions(false);
    inputRef.current?.focus();
    return onChange?.(undefined);
  }, [onChange]);

  const optionDeleteHandler = useCallback(
    (option: DropdownOption) => () => {
      if (!isMultiple || disabled) return;
      return onChange?.(value?.filter((x) => x.key !== option.key));
    },
    [isMultiple, onChange, value, disabled],
  );

  const optionsWithoutValue = useMemo(() => {
    if (isMultiple) {
      return options.filter((x) => !value?.map((v) => v.key).includes(x.key));
    }

    return options;
  }, [isMultiple, options, value]);

  const inputClickHandler = useCallback(() => {
    setShowOptions((state) => !!suggestion || !state);
    setFilteredOptions(optionsWithoutValue);
  }, [optionsWithoutValue, suggestion]);

  const keyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        setShowOptions((state) => !state);
        setFilteredOptions(optionsWithoutValue);
      }
      if (event.code === 'ArrowDown') _.first(optionRefsArray)?.focus();
      if (event.code === 'ArrowUp') _.last(optionRefsArray.filter(Boolean))?.focus();
    },
    [optionRefsArray, optionsWithoutValue],
  );

  const optionKeyDownHandler = useCallback((event: React.KeyboardEvent<HTMLButtonElement>) => {
    const active = document.activeElement;
    if (event.code === 'ArrowDown' && active?.nextElementSibling) {
      (active.nextElementSibling as HTMLElement).focus();
    }

    if (event.code === 'ArrowUp' && active?.previousElementSibling) {
      (active.previousElementSibling as HTMLElement).focus();
    }
  }, []);

  const inputChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
      setFilteredOptions(
        e.target.value.length
          ? optionsWithoutValue.filter((x) => x.text.toLowerCase().includes(e.target.value.toLowerCase()))
          : optionsWithoutValue,
      );
    },
    [optionsWithoutValue],
  );

  const blurHandler = useCallback(() => {
    if (!isMultiple && suggestion && value?.text.length) {
      setInput(value?.text || '');
    }
  }, [isMultiple, suggestion, value]);

  return (
    <div className={className}>
      <div className={Style.inputWrapper}>
        <AppTextInput
          ref={(r) => (inputRef.current = r)}
          onChange={inputChangeHandler}
          value={input}
          onBlur={blurHandler}
          onClick={inputClickHandler}
          onKeyDown={keyDownHandler}
          readOnly={!suggestion}
          disabled={disabled}
          placeholder={placeholder}
          invalid={invalid}
          aria-owns={showOptions ? calloutId : undefined}
          inputClassName={Style.input}
        />
        {CaretIcon ? (
          <CaretIcon className={Style.chevron} />
        ) : (
          <Chevron data-rotate={showOptions} className={Style.chevron} />
        )}
      </div>
      {showOptions && (
        <Callout
          id={calloutId}
          target={inputRef}
          isBeakVisible={false}
          className={Style.callout}
          onRestoreFocus={() => {}}
          onDismiss={() => setShowOptions(false)}
          calloutMaxHeight={window.innerHeight * 0.6}
          directionalHint={DirectionalHint.bottomLeftEdge}
          styles={() => ({ calloutMain: { width: inputRef.current?.clientWidth } })}
        >
          <div className={Style.options}>
            {!isRequired && !isMultiple && (
              <button className={Style.emptyOption} onClick={onEmptyOptionClick} onKeyDown={optionKeyDownHandler}>
                <span className={'truncate'}>{emptyOptionLabel || t('Common_NotSelected')}</span>
              </button>
            )}
            {filteredOptions.map((option, i) => (
              <button
                ref={(ref) => (optionRefsArray[i] = ref)}
                key={option.key}
                className={Style.option}
                data-selected={!isMultiple && option.key === value?.key}
                onClick={onOptionClick(option)}
                onKeyDown={optionKeyDownHandler}
              >
                <DropdownOptionComponent<T> item={option} />
              </button>
            ))}
          </div>
        </Callout>
      )}
      {isMultiple && value && value?.length > 0 && (
        <div className={Style.valuesContainer}>
          {value?.map((item) => (
            <div key={item.key} className={clsx(Style.valueWrapper, { [Style.isDisabled]: disabled })}>
              {item.icon}
              <p className={TypographyStyles.plainText12}>{item.text}</p>
              <Cross data-disabled={!!disabled} className={Style.deleteIcon} onClick={optionDeleteHandler(item)} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
