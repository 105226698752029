import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { CommentDataKey } from '../../../../../components/formEditor/uiEditor/provider/formFill.context';
import { IFiles } from '../../../../forms/base/controls/inputs/Files';
import { RPDF } from '../../../react-pdf';
import { RecordsReportContext } from '../../recordsReport.context';
import { AppAttachmentValue } from '../../../../../components/uikit/fields/attachments/attachmentsField/appAttachmentsField';

export const FilesInput: IFiles = ({ label, dataKey }) => {
  const { t } = useTranslation();
  const inputValue = useContextSelector(RecordsReportContext, (v) => {
    return v.formResult ? (v.formResult[dataKey] as AppAttachmentValue[]) : null;
  });
  const commentValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[CommentDataKey(dataKey)]);

  return (
    <RPDF.View>
      <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
        <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? dataKey}</RPDF.Text>
        <RPDF.Text style={RPDF.controlStyles.answerCell}>{inputValue?.map((v) => v.fileName).join('\n')}</RPDF.Text>
      </RPDF.View>
      {commentValue && (
        <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
          <RPDF.Text style={RPDF.controlStyles.questionCell}>{t('Forms.Controls.CommentFieldCaption')}</RPDF.Text>
          <RPDF.Text style={RPDF.controlStyles.answerCell}>{commentValue}</RPDF.Text>
        </RPDF.View>
      )}
    </RPDF.View>
  );
};
