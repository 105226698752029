import React from 'react';
import { useContextSelector } from 'use-context-selector';
import { LocalizedResourceDictionaryKeys } from '../../../../../application/localisation/i18next';
import { useScopedTranslation } from '../../../../../application/localisation/useScopedTranslation';
import { ICheckBoxInput } from '../../../../forms/base/controls/inputs/CheckBoxInput';
import { RPDF } from '../../../react-pdf';
import { RecordsReportContext } from '../../recordsReport.context';

export const CheckBoxInput: ICheckBoxInput = ({ label, dataKey }) => {
  const { t } = useScopedTranslation('Forms.Controls');

  const inputValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);

  return (
    <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
      <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? dataKey}</RPDF.Text>
      <RPDF.Text style={RPDF.controlStyles.answerCell}>
        {t(`checkBox_${inputValue}` as LocalizedResourceDictionaryKeys)}
      </RPDF.Text>
    </RPDF.View>
  );
};
