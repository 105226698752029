import React from 'react';
import { RPDF } from '../react-pdf';
import { IssueReportOptions } from './issueReport';
import { TableHeaderColumn } from '../components/table/TableHeaderColumn';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { TableRow } from '../components/table/TableRow';
import { TableCell } from '../components/table/TableCell';
import { IssueOperationStateEnum, IssuesStateEnum } from '../../../services/api/api-client';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { localFormat } from '../../../helpers/date-helpers';

export const IssueReportTable = (props: Omit<IssueReportOptions, 'studyNumber' | 'filters'>) => {
  const { t: tableScope } = useScopedTranslation('Reports.Issue.Table');
  const { t: issuesScope } = useScopedTranslation('Issues');

  const OwnStyles = RPDF.StyleSheet.create({
    idColumn: {
      width: '34px',
      overflow: 'hidden',
      alignSelf: 'flex-start',
    },
    patientColumn: {
      width: '27px',
      alignSelf: 'flex-start',
    },
    dateColumn: {
      width: '46px',
      alignSelf: 'flex-start',
    },
    statusColumn: {
      width: '59px',
      alignSelf: 'flex-start',
    },
    topicColumn: {
      width: '104px',
      alignSelf: 'flex-start',
    },
    descriptionColumn: {
      flex: 1,
      overflow: 'hidden',
      alignSelf: 'flex-start',
    },
    recommendedActions: {
      flex: 1,
      overflow: 'hidden',
      alignSelf: 'flex-start',
    },
  });

  const Header = () => {
    return (
      <RPDF.View style={[RPDF.tableStyles.columnsContainer, RPDF.tableStyles.divider]} fixed={true}>
        <TableHeaderColumn label={tableScope('Id')} styles={OwnStyles.idColumn} />
        <TableHeaderColumn label={tableScope('PatientId')} styles={OwnStyles.patientColumn} />
        <TableHeaderColumn label={tableScope('Date')} styles={OwnStyles.dateColumn} />
        <TableHeaderColumn label={tableScope('Status')} styles={OwnStyles.statusColumn} />
        <TableHeaderColumn label={tableScope('Topic')} styles={OwnStyles.topicColumn} />
        <TableHeaderColumn label={tableScope('Description')} styles={OwnStyles.descriptionColumn} />
        <TableHeaderColumn label={tableScope('RecommendedActions')} styles={OwnStyles.recommendedActions} />
        <TableHeaderColumn label={tableScope('Deadline')} styles={OwnStyles.dateColumn} />
        <TableHeaderColumn label={tableScope('DoneAt')} styles={OwnStyles.dateColumn} />
      </RPDF.View>
    );
  };

  const Body = () => {
    return (
      <RPDF.View break={false}>
        {props.data.map((x, index) => {
          return (
            <TableRow key={index}>
              <TableCell styles={OwnStyles.idColumn} value={x.id.toString()} />
              <TableCell
                styles={OwnStyles.patientColumn}
                value={x.linkedPatientUniqId || issuesScope('Subject.Study')}
              />
              <TableCell styles={OwnStyles.dateColumn} value={localFormat(x.createdAt)} />
              <TableCell
                styles={OwnStyles.statusColumn}
                value={issuesScope(`Status.${IssuesStateEnum[x.state]}` as LocalizedResourceDictionaryKeys)}
              />
              <TableCell
                styles={OwnStyles.topicColumn}
                value={`${issuesScope(`Topic.${x.topic}` as LocalizedResourceDictionaryKeys)}${
                  x.topicAdditional ? `:\n${x.topicAdditional}` : ''
                }`}
              />
              <TableCell styles={OwnStyles.descriptionColumn} value={x.description} />
              <TableCell styles={OwnStyles.recommendedActions} value={x.recommendedAction} />
              <TableCell styles={OwnStyles.dateColumn} value={localFormat(x.deadlineAt)} />
              <TableCell
                styles={OwnStyles.dateColumn}
                value={
                  x.lastOperation?.stateChangedAt && x.lastOperation.state === IssueOperationStateEnum.Approved
                    ? localFormat(x.lastOperation.stateChangedAt)
                    : ''
                }
              />
            </TableRow>
          );
        })}
      </RPDF.View>
    );
  };

  return (
    <>
      <Header />
      <Body />
    </>
  );
};
