import { Element, Resolver } from '@craftjs/core';
import { IEmptyBlock } from './content/EmptyBlock';
import { IContainer } from './containers/Container';
import { IText } from './content/Text';
import { IAttentionText } from './content/AttentionText';
import { IDateInput } from './inputs/DateInput';
import { ITimeInput } from './inputs/TimeInput';
import { ITextInput } from './inputs/TextInput';
import { ICheckBoxInput } from './inputs/CheckBoxInput';
import { ICheckBoxGroupInput } from './inputs/CheckBoxGroupInput';
import { IRadioInput } from './inputs/RadioInput';
import { ISelectInput } from './inputs/SelectInput';
import { IFiles } from './inputs/Files';
import { IStepContainer } from './containers/StepContainer';
import { ITabsContainer } from './containers/TabsContainer';
import { IDateRangeInput } from './inputs/DateRangeInput';
import { IBmiInput } from './specials/BmiInput';
import { ICalculatedInput } from './specials/CalculatedBlock';
import { IPatientGroupInput } from './specials/PatientGroupInput';
import { IImageBlock } from './content/ImageBlock';
import { ITagInput } from './inputs/TagInputProps';
import React, { FC, PropsWithChildren } from 'react';
import { IFieldGroupContainer } from './containers/FieldGroupContainer';
import { INumberInput } from './inputs/INumberInput';
import { ISliderInput } from './inputs/SliderInput';

export interface IFormControlsResolver extends Resolver {
  EmptyBlock: IEmptyBlock;
  FieldGroupContainer: IFieldGroupContainer;
  Container: IContainer;
  Text: IText;
  AttentionText: IAttentionText;
  TextInput: ITextInput;
  NumberInput: INumberInput;
  DateInput: IDateInput;
  TimeInput: ITimeInput;
  CheckBoxInput: ICheckBoxInput;
  CheckBoxGroupInput: ICheckBoxGroupInput;
  RadioInput: IRadioInput;
  SelectInput: ISelectInput;
  SliderInput: ISliderInput;
  Files: IFiles;
  StepContainer: IStepContainer;
  TabsContainer: ITabsContainer;
  DateRangeInput: IDateRangeInput;
  BmiInput: IBmiInput;
  CalculatedBlock: ICalculatedInput;
  GroupInput: IPatientGroupInput;
  ImageBlock: IImageBlock;
  TagInput: ITagInput;
}

export const StubContainer: FC<PropsWithChildren> = ({ children }) => <>{children}</>;

export const StubFieldGroupContainer = () => <Element is={StubContainer} id="drop" />;
export const StubContent = () => <></>;
