import React from 'react';
import { CheckboxSettings } from '../settingsInputs/CheckboxSettings';
import { useTranslation } from 'react-i18next';
import { PanelContainer } from '../../components/panelContainer';
import { ControlsGroup } from '../../components/controlsGroup';
import { SettingsInput } from '../settingsInputs/settingsInput';
import { useNode } from '@craftjs/core';
import { convertDateToString } from 'src/helpers/date-helpers';

export type ValidationProps = {
  isRequired?: boolean;
  minValue?: number | null;
  maxValue?: number | null;
  pattern?: string | null;
  maxLength?: number | null;
  minDate?: string | null;
  maxDate?: string | null;
};

export type ValidationSettingsProps = {
  validation: ValidationProps;
};

/** A list of validation rules that can be applied to an input */
export type ApplicableRules = { [key in keyof ValidationProps]?: boolean };

export type ValidationComponentProps = {
  props?: ValidationSettingsProps;
  setProp?: (func: (props: ValidationSettingsProps) => void) => void;
  setState?: (props: ValidationSettingsProps) => void;
  applicableRules: ApplicableRules;
};

export const ValidationSettings: React.FC<ValidationComponentProps> = ({
  applicableRules,
}: ValidationComponentProps) => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ValidationSettingsProps,
  }));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.Validation.Header')} expanded={false}>
      {applicableRules.isRequired && (
        <ControlsGroup flexible>
          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.Validation.Required')}
            isChecked={props.validation?.isRequired || false}
            setValue={(value) => {
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.isRequired = value ? value : undefined;
              }, 500);
            }}
          />
        </ControlsGroup>
      )}

      {applicableRules.minValue && (
        <ControlsGroup
          collapsible
          flexible
          expanded={props.validation?.minValue !== undefined && props.validation?.minValue !== null}
          header={t('StudySettingsPage.FormBuilder.Validation.Min')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.minValue = 0;
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.minValue = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            type={'number'}
            max={props.validation.maxValue || undefined}
            value={props?.validation?.minValue ?? ''}
            onChange={(e) => {
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.minValue = e.target.value ? parseFloat(e.target.value) : undefined;
              });
            }}
          />
        </ControlsGroup>
      )}

      {applicableRules.maxValue && (
        <ControlsGroup
          collapsible
          flexible
          expanded={props.validation?.maxValue !== undefined && props.validation?.maxValue !== null}
          header={t('StudySettingsPage.FormBuilder.Validation.Max')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.maxValue = (itemProps.validation.minValue || 0) + 100;
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.maxValue = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            type={'number'}
            min={props.validation.minValue || undefined}
            value={props?.validation?.maxValue ?? ''}
            onChange={(e) => {
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.maxValue = e.target.value ? parseFloat(e.target.value) : undefined;
              });
            }}
          />
        </ControlsGroup>
      )}

      {applicableRules.minDate && (
        <ControlsGroup
          collapsible
          flexible
          expanded={!!props.validation?.minDate}
          header={t('StudySettingsPage.FormBuilder.Validation.Min')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.minDate = convertDateToString(new Date());
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.minDate = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            type={'date'}
            max={props.validation.maxDate || undefined}
            value={props.validation.minDate || undefined}
            onChange={(e) => {
              const parsedTime: Date | null = e.target.value ? new Date(Date.parse(e.target.value)) : null;
              if (parsedTime?.toDateString() === 'Invalid Date') {
                return;
              }
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.minDate = e.target.value ? convertDateToString(parsedTime) : undefined;
              }, 500);
            }}
          />
        </ControlsGroup>
      )}

      {applicableRules.maxDate && (
        <ControlsGroup
          collapsible
          flexible
          expanded={!!props.validation?.maxDate}
          header={t('StudySettingsPage.FormBuilder.Validation.Max')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.maxDate = convertDateToString(new Date());
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.maxDate = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            type={'date'}
            min={props.validation.minDate || undefined}
            value={props.validation.maxDate || undefined}
            onChange={(e) => {
              const parsedTime: Date | null = e.target.value ? new Date(Date.parse(e.target.value)) : null;
              if (parsedTime?.toDateString() === 'Invalid Date') {
                return;
              }

              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.maxDate = e.target.value ? convertDateToString(parsedTime) : undefined;
              }, 500);
            }}
          />
        </ControlsGroup>
      )}

      {applicableRules.pattern && (
        <ControlsGroup
          collapsible
          flexible
          expanded={!!props.validation.pattern}
          header={t('StudySettingsPage.FormBuilder.Validation.Pattern')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.pattern = '^[a-zA-Z0-9]$';
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.pattern = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            placeholder={'Enter regular expressions'}
            value={props.validation.pattern || undefined}
            onChange={(e) =>
              setProp((p: ValidationSettingsProps) => {
                p.validation.pattern = e.target?.value ? e.target.value : undefined;
              }, 500)
            }
          />
        </ControlsGroup>
      )}

      {applicableRules.maxLength && (
        <ControlsGroup
          collapsible
          flexible
          header={t('StudySettingsPage.FormBuilder.Validation.MaxLength')}
          expanded={!!props.validation.maxLength}
          onOpen={() => {
            setProp((prop: ValidationSettingsProps) => {
              prop.validation.maxLength = 120;
            }, 500);
          }}
          onClose={() => {
            setProp((prop: ValidationSettingsProps) => {
              prop.validation.maxLength = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            placeholder={'Enter max length'}
            type={'number'}
            min={0}
            step={1}
            value={props.validation.pattern || undefined}
            onChange={(e) =>
              setProp((p: ValidationSettingsProps) => {
                p.validation.maxLength = e.target.value ? parseInt(e.target.value) : undefined;
              }, 500)
            }
          />
        </ControlsGroup>
      )}
    </PanelContainer>
  );
};

export const ValidationDefaultSettings: ValidationProps = {
  isRequired: undefined,
  minValue: undefined,
  maxValue: undefined,
  maxLength: undefined,
  pattern: undefined,
  minDate: undefined,
  maxDate: undefined,
};
