import React from 'react';
import { DialogModalProps } from './dialogModal';
import Style from './dialogModal.module.css';
import { AppModalContainer } from '../uikit/modal/modal.component';

export const DialogModal = (props: DialogModalProps) => {
  return (
    <AppModalContainer
      title={props.title}
      visible={props.isVisible}
      onHide={props.onClose}
      isClickOutside={true}
      bodyClassName={Style.confirmationModalBody}
      testId={props.testId}
      isDisabled={props.isDisabled}
    >
      <p className={Style.confirmationText}>{props.mainText}</p>
      <div className={Style.confirmationBtnsGroup}>{props.buttons}</div>
    </AppModalContainer>
  );
};
