import React, { useCallback, useMemo, FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UseModalProps } from '../../../application/hooks/useModal';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { IssueCreationType } from '../form/issueForm.component';
import Style from './createIssueConfirmationModal.module.scss';
import { AppModalContainer } from '../../uikit/modal/modal.component';

export type CreateIssueConfirmationModalParams = {
  issue: IssueCreationType;
  callback: () => Promise<void>;
};

export const CreateIssueConfirmationModal: FC<{
  modal: UseModalProps<CreateIssueConfirmationModalParams>;
}> = ({ modal }) => {
  const { t } = useTranslation();
  const { issue, callback } = modal.params;
  const onApplyHandler = useCallback(async () => {
    modal.closeModal();
    await callback();
  }, [callback, modal]);

  const content = useMemo(() => {
    if (!issue) return <></>;

    return (
      <>
        <p className={Style.mainText}>
          <Trans i18nKey={'Issues.CreateConfirmationModal.MainText'}>
            <strong />
            <strong className={Style.awfulText} />
          </Trans>
        </p>

        <div className={Style.dataContainer}>
          <p>
            <span>{t('Issues.CreateModal.SubjectLabel')}</span>
            <span>{t(`Issues.Subject.${issue.subject}` as LocalizedResourceDictionaryKeys)}</span>
          </p>
          {issue.linkedPatientUniqId && (
            <p>
              <span>{t('Issues.CreateModal.PatientIdLabel')}</span>
              <span>{issue.linkedPatientUniqId}</span>
            </p>
          )}
          {issue.topic && (
            <p>
              <span>{t('Issues.CreateModal.TopicLabel')}</span>
              <span>
                {t(`Issues.Topic.${issue.topic}` as LocalizedResourceDictionaryKeys)}
                {issue.topicAdditional && <span>{`: ${issue.topicAdditional}`}</span>}
              </span>
            </p>
          )}
          {issue.fieldDescription && (
            <p>
              <span>{t('Issues.CreateModal.FieldDescription')}</span>
              <span>{issue.fieldDescription}</span>
            </p>
          )}
          <p>
            <span>{t('Issues.CreateModal.DescriptionLabel')}</span>
            <span>{issue.description}</span>
          </p>
          <p>
            <span>{t('Issues.CreateModal.ActionLabel')}</span>
            <span>{issue.recommendedAction}</span>
          </p>
          {issue.files?.length && (
            <p>
              <span>{t('Issues.ViewModal.FieldLabels.files')}</span>
              <ul className={Style.fieldLabels}>
                {issue.files?.map((f) => {
                  return <li key={f.id}>{f.fileName}</li>;
                })}
              </ul>
            </p>
          )}
        </div>
      </>
    );
  }, [issue, t]);

  return (
    <AppModalContainer
      title={t('Issues.CreateConfirmationModal.Title')}
      visible={modal.visible}
      onHide={modal.closeModal}
      bodyClassName={Style.modalBody}
      testId={'confirm-creation-issue-modal'}
      footer={
        <div className={Style.buttonsGroup}>
          <AppButton
            key={0}
            variant={'button'}
            colorSchema={'secondary'}
            text={t('Common_Cancel')}
            type={'reset'}
            onClick={modal.closeModal}
          />

          <AppButton
            key={1}
            variant={'button'}
            colorSchema={'primary'}
            text={t('Issues.CreateConfirmationModal.ConfirmButton')}
            onClick={onApplyHandler}
          />
        </div>
      }
    >
      {content}
    </AppModalContainer>
  );
};
