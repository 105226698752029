﻿import { ITagFilters } from 'src/services/api/api-client';
import { toDictionary } from 'src/helpers/arrayHelpers';

export const getMultiValuedTagFilters = (tagFilters: ITagFilters): Record<string, string[]> => {
  return getTagKeys(tagFilters)
    .filter((key) => tagFilters[key].length > 1)
    .reduce(
      toDictionary(
        (key) => key,
        (key) => tagFilters[key] as string[],
      ),
      {},
    );
};

export const getSingleValuedTagFilters = (tagFilters: ITagFilters): Record<string, string[]> => {
  return getTagKeys(tagFilters)
    .filter((key) => tagFilters[key].length === 1)
    .reduce(
      toDictionary(
        (key) => key,
        (key) => tagFilters[key] as string[],
      ),
      {},
    );
};

export const getTagKeys = (tags: Record<string, string[]>): string[] => {
  return Object.keys(tags).filter((key) => Array.isArray(tags[key]));
};

export const filterTags = (
  tagFilters: ITagFilters | null | undefined,
  tags: Record<string, string[]>,
): Record<string, string[]> => {
  if (!tagFilters) return tags;

  const filterKeys = getTagKeys(tagFilters);

  return getTagKeys(tags)
    .map((key) => ({
      key,
      filterValues: filterKeys.includes(key) ? (tagFilters[key] as string[]) : null,
      tagValues: tags[key],
    }))
    .map((x) => ({
      key: x.key,
      values: x.filterValues ? x.tagValues.filter((value) => x.filterValues!.includes(value)) : x.tagValues,
    }))
    .filter((x) => x.values)
    .reduce(
      toDictionary(
        (x) => x.key,
        (x) => x.values,
      ),
      {},
    );
};
