/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Node, NodeTree } from '@craftjs/core';
import { DataType, Layout } from './uiEditor';
import { getRandomId } from '@craftjs/utils';
import { DataBlockValidation, Page, PageStack } from './provider/formFill.context';
import { createId } from 'src/helpers/typeUtils';

// This function convert page tree:
// Tab1 -- Tab2 [Step1 - Step2 - Step3] -- Tab3
// to list:
// [[Tab1], [Tab2, Step1], [Tab2, Step2], [Tab2, Step3], [Tab3]]
export const convertLayoutToPageList = (layout: Layout) => {
  const pages: PageStack[] = [];
  let blocks: {
    [blockId: string]: {
      pages?: PageStack;
      ref: any;
    };
  } = {};

  const findSteps = (key: string, subPages?: Page[]) => {
    let page: PageStack = [];

    if (layout[key].type.resolvedName === 'TabsContainer' || layout[key].type.resolvedName === 'StepContainer') {
      Object.keys(layout[key].linkedNodes).forEach((node, i) => {
        page = [...(subPages ?? []), { nodeId: key, pageNumber: i + 1 }];
        const lengthBefore = pages.length;
        findSteps(layout[key].linkedNodes[node], page);

        // Prevent double adding page to list
        if (lengthBefore === pages.length) {
          pages.push(page);
        }
      });
    } else {
      layout[key].nodes.forEach((node) => {
        blocks = { ...blocks, [node]: { pages: subPages, ref: null } };
        findSteps(node, subPages);
      });

      Object.keys(layout[key].linkedNodes).forEach((node) => {
        blocks = { ...blocks, [node]: { pages: subPages, ref: null } };
        findSteps(layout[key].linkedNodes[node], subPages);
      });
    }
  };

  findSteps('ROOT', undefined);
  return { pages, blocks };
};

/** This function remove additional part from NodeId or DataKey
 * @example _from in  dataKey_from */
export const normalizeDataKey = (nodeId: string) => {
  return nodeId.replace('_from', '').replace('_until', '');
};

export const findNodesWithDataKey = (layout: Layout, dataKey: string): string[] => {
  return Object.keys(layout).filter((key) => layout[key].props.dataKey === normalizeDataKey(dataKey));
};

export const scrollToRef = (ref: any) => {
  if (ref?.current) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    ref?.current?.scrollIntoView(true);
  }
};

export const isValid = (data: DataBlockValidation) => {
  return Object.keys(data).every((key) => data[key].validationState === 'Ok');
};

export const getAvailableInitialAnswersByConfig = (
  layout: Layout,
  results?: DataType | undefined,
): DataType | undefined => {
  if (!results) {
    return;
  }
  const resultValues = { ...results };

  Object.keys(layout)
    .filter((nodeKey) => layout[nodeKey].props?.ignoreInitialValuesFromPreviousForm)
    .forEach((nodeKey) => {
      const dataKeyRelatedFields = Object.keys(resultValues).filter(
        (x) => x === layout[nodeKey].props.dataKey || x.includes(`${layout[nodeKey].props.dataKey}_`),
      );

      for (const dataKeyRelatedFieldsKey of dataKeyRelatedFields) {
        delete resultValues[dataKeyRelatedFieldsKey];
      }
    });

  return resultValues;
};

/**
 * Clone node
 * @param query - query from the {@link useEditor useEditor} hook
 * @param source - id of the node or {@link NodeTree NodeTree} object
 * @returns new {@link NodeTree NodeTree} object
 */
export const getCloneTree = (query: any, source: string | NodeTree): NodeTree => {
  const tree = typeof source === 'object' ? source : query.node(source).toNodeTree();
  const newNodes: any = {};

  const changeNodeId = (node: Node, newParentId?: string) => {
    const newId = getRandomId();

    const childNodes = node.data.nodes.map((childId: string) => changeNodeId(tree.nodes[childId], newId));
    const linkedNodes = Object.keys(node.data.linkedNodes).reduce((accum, id) => {
      const newNodeId = changeNodeId(tree.nodes[node.data.linkedNodes[id]], newId);
      return {
        ...accum,
        [id]: newNodeId,
      };
    }, {});

    const newDataKey = node.data.props.dataKey ? createId() : undefined;
    const tmpNode = {
      ...node,
      id: newId,
      data: {
        ...node.data,
        parent: newParentId || node.data.parent,
        nodes: childNodes,
        linkedNodes,
        props: { ...node.data.props, dataKey: newDataKey },
      },
    };

    const freshNode = query.parseFreshNode(tmpNode).toNode();
    newNodes[newId] = freshNode;
    return newId;
  };

  const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId]);
  return {
    rootNodeId,
    nodes: newNodes,
  };
};

export const pageStacksIsEqual = (first: PageStack | undefined, second: PageStack | undefined) => {
  return JSON.stringify(first) === JSON.stringify(second);
};
