import React from 'react';
import clsx from 'clsx';
import { IGroupedIssueDto, IIssueDto, IssuesStateEnum } from '../../../services/api/api-client';
import { PipelineComponent } from '../../pipeline/pipeline.component';
import { localFormat } from '../../../helpers/date-helpers';
import Style from './issueTable.module.scss';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { stateIcons } from '../stateIndicator/issueStateIndicator.component';
import { ReactComponent as Warning } from '../../../assets/img/common/tiny_warning_16.svg';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Chevron } from 'src/assets/img/patient/chevronPatientRow.svg';
import { buttonColumn } from 'uikit/table/columns/ButtonColumn';
import { expandRowButtonColumn } from 'uikit/table/columns/ExpandRowButtonColumn';
import TableCommonStyles from 'uikit/table/appTableCommonStyles.module.scss';
import PatientTableStyles from 'src/components/patientTable/patientTable.module.scss';
import { Tooltip } from 'uikit/tooltip/tooltip.component';

export type IssueColumnPropsType = {
  openIssueView: (issueId: number) => void;
};

export const useIssueTableUserColumns = (): ColumnDef<IGroupedIssueDto>[] => {
  const { t } = useTranslation();

  return [
    {
      id: 'uniqueId',
      accessorFn: (issue) => (!issue.uniqueId ? t('Issues.Topic.Study') : issue.uniqueId),
      header: t('Issues.IssueTable.MainTableColumn.PatientId'),
      size: 20,
      meta: {
        cellClassName: Style.uniqueIdCell,
        centered: true,
      },
    },
    {
      accessorKey: 'group',
      header: t('Dashboard.PatientTable.Column.Group'),
      enableSorting: false,
      meta: {
        isColored: true,
        dontUseHeaderWidth: true,
      },
      cell: ({ row }) => (
        <div className={clsx({ [Style.colorPlain]: !row.original.group })}>
          {row.original.group || t('Issues.IssueTable.NoGroup')}
        </div>
      ),
    },
    {
      id: 'stage',
      header: t('Dashboard.PatientTable.Column.Stage'),
      meta: {
        cellClassName: clsx(PatientTableStyles.stageColumn),
        dontUseHeaderWidth: true,
      },
      cell: ({ row }) =>
        row.original.routeProgress ? (
          <PipelineComponent
            routeProgress={row.original.routeProgress}
            patientId={row.original.patientId!}
            patientUniqueId={row.original.uniqueId!}
          />
        ) : (
          <div className={Style.colorPlain}>{t('Issues.IssueTable.NoStage')}</div>
        ),
    },
    {
      accessorKey: 'issuesCount',
      header: t('Issues.IssueTable.MainTableColumn.Issues'),
      enableSorting: false,
      size: 140,
      meta: {
        centered: true,
        isColored: true,
      },
    },
    {
      accessorKey: 'openedCount',
      header: t('Issues.IssueTable.MainTableColumn.Opened'),
      enableSorting: false,
      size: 140,
      meta: {
        centered: true,
        isColored: true,
      },
    },
    {
      accessorKey: 'processedCount',
      header: t('Issues.IssueTable.MainTableColumn.Processed'),
      enableSorting: false,
      size: 140,
      meta: {
        centered: true,
        isColored: true,
      },
    },
    {
      accessorKey: 'closedCount',
      header: t('Issues.IssueTable.MainTableColumn.Closed'),
      enableSorting: false,
      size: 140,
      meta: {
        centered: true,
        isColored: true,
      },
    },
    expandRowButtonColumn({}),
  ];
};

export const useIssueTableColumns = ({ openIssueView }: IssueColumnPropsType): ColumnDef<IIssueDto>[] => {
  const { t } = useTranslation();

  return [
    {
      accessorKey: 'id',
      header: t('Issues.IssueTable.SubTableColumn.IssueId'),
      meta: {
        cellClassName: Style.commonIssueTableCell,
        centered: true,
        dontUseHeaderWidth: true,
        cellWidth: 120,
      },
    },
    {
      accessorKey: 'createdByUser',
      header: t('Issues.IssueTable.SubTableColumn.CreatedBy'),
      enableSorting: false,
      meta: {
        cellClassName: Style.commonIssueTableCell,
        dontUseHeaderWidth: true,
        cellWidth: 188,
      },
    },
    {
      accessorKey: 'createdAt',
      header: t('Issues.IssueTable.SubTableColumn.CreatedAt'),
      cell: ({ row }) => localFormat(row.original.createdAt),
      sortingFn: 'datetime',
      meta: {
        cellClassName: Style.commonIssueTableCell,
        dontUseHeaderWidth: true,
        cellWidth: 150,
      },
    },
    {
      accessorKey: 'state',
      header: t('Issues.IssueTable.SubTableColumn.State'),
      enableSorting: false,
      cell: ({ row }) => (
        <Tooltip text={t(`Issues.Status.${IssuesStateEnum[row.original.state]}` as LocalizedResourceDictionaryKeys)}>
          {stateIcons[row.original.state]}
        </Tooltip>
      ),
      meta: {
        cellClassName: Style.commonIssueTableCell,
        dontUseHeaderWidth: true,
        cellWidth: 90,
      },
    },
    {
      accessorKey: 'topic',
      header: t('Issues.IssueTable.SubTableColumn.Topic'),
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <>
            <div>{t(`Issues.Topic.${row.original.topic}` as LocalizedResourceDictionaryKeys)}</div>
            {row.original.topicAdditional && (
              <div className={Style.topicAdditional}>{row.original.topicAdditional}</div>
            )}
          </>
        );
      },
      meta: {
        cellClassName: Style.commonIssueTableCell,
        dontUseHeaderWidth: true,
        cellWidth: 188,
      },
    },
    {
      accessorKey: 'deadlineAt',
      header: t('Issues.IssueTable.SubTableColumn.DeadlineAt'),
      enableSorting: false,
      cell: ({ row }) => (
        <div data-is-expired={row.original.isExpired} className={Style.deadline}>
          {row.original.isExpired && <Warning />}
          {localFormat(row.original.deadlineAt)}
        </div>
      ),
      meta: {
        cellClassName: Style.commonIssueTableCell,
        dontUseHeaderWidth: true,
        cellWidth: 150,
      },
    },
    {
      accessorKey: 'lastOperation',
      header: t('Issues.IssueTable.SubTableColumn.ClosedAt'),
      enableSorting: false,
      cell: ({ row }) => (
        <div>
          {row.original.state === IssuesStateEnum.Closed &&
            row.original.lastOperation &&
            row.original.lastOperation.stateChangedAt &&
            localFormat(row.original.lastOperation.stateChangedAt)}
        </div>
      ),
      meta: {
        cellClassName: Style.commonIssueTableCell,
        dontUseHeaderWidth: true,
        cellWidth: 150,
      },
    },
    {
      accessorKey: 'description',
      header: t('Issues.IssueTable.SubTableColumn.Description'),
      enableSorting: false,
      meta: {
        cellClassName: Style.multilineCell,
        dontUseHeaderWidth: true,
      },
    },
    {
      accessorKey: 'recommendedAction',
      header: t('Issues.IssueTable.SubTableColumn.RecommendedAction'),
      enableSorting: false,
      meta: {
        cellClassName: Style.multilineCell,
        dontUseHeaderWidth: true,
        cellWidth: 240,
      },
    },
    buttonColumn({
      id: 'button',
      icon: Chevron,
      iconClassName: TableCommonStyles.rotate270,
      onClick: ({ row }) => openIssueView(row.original.id),
    }),
  ];
};
