import React from 'react';
import clsx from 'clsx';
import { formatRelative } from 'date-fns';
import { getCurrentDateLocale } from '../../../application/localisation/localization';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { IPatientListDto } from '../../../services/api/api-client';
import { CommonStyles } from '../../../styles';
import { CodeGeneration } from '../../patientAccessCodeGenerationForm/codeGeneration.component';
import { ExpandedRowHeader } from '../header/expandedRowHeader.component';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';

export const AppInformation = (patient: IPatientListDto) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo');
  const commonLocalizer = useCommonLocalization();
  const { studyNumber } = useAppSelector((state) => state.app);

  return (
    <div data-test-id={'patient-card-appinfo'}>
      <ExpandedRowHeader text={t('AppInformation')} />

      {!patient.isStudyFinished && (
        <div className={clsx(CommonStyles.tableRow)}>
          <div className={CommonStyles.tableItemLeft}>{t('AppCode.Caption')}</div>
          <div className={CommonStyles.tableItemRight}>
            <CodeGeneration
              codeGenerated={patient.codeGenerated}
              patientId={patient.id}
              studyNumber={studyNumber || ''}
            />
          </div>
        </div>
      )}

      <div className={CommonStyles.tableRow}>
        <div className={CommonStyles.tableItemLeft}>{t('LastAppUse')}</div>
        <div className={CommonStyles.tableItemRight}>
          {patient.lastVisitDateTime
            ? formatRelative(patient.lastVisitDateTime, Date.now(), getCurrentDateLocale())
            : commonLocalizer('Common_Never')}
        </div>
      </div>
    </div>
  );
};
