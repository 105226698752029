import React from 'react';
import { AppCheckboxInput } from 'uikit/inputs';

export const CheckboxSettings: React.FC<{
  label: string;
  isChecked: boolean;
  setValue: (newValue: boolean) => void;
}> = (props) => {
  return (
    <AppCheckboxInput
      name={props.label}
      label={props.label}
      checked={props.isChecked}
      onChange={(e) => props.setValue(e.target.checked)}
    />
  );
};
