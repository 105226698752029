import React, { useLayoutEffect, useState, FC } from 'react';
import { IssuesStateEnum } from '../../../services/api/api-client';
import { Loader } from '../../uikit/loader/loader.component';
import { useHasPermissions, Permissions } from '../../../helpers/auth/auth-helper';
import Style from './issueStateIndicator.module.css';

import { ReactComponent as Opened } from '../../../assets/img/issues/opened_24.svg';
import { ReactComponent as Closed } from '../../../assets/img/issues/closed_24.svg';
import { ReactComponent as Processed } from '../../../assets/img/issues/pre_close_24.svg';

const onHoverStateIcon = {
  [IssuesStateEnum.Opened]: <Processed />,
  [IssuesStateEnum.Processed]: <Opened />,
};

export const stateIcons = {
  [IssuesStateEnum.Opened]: <Opened />,
  [IssuesStateEnum.Processed]: <Processed />,
  [IssuesStateEnum.Closed]: <Closed />,
};

export type IssueStateType = {
  state: IssuesStateEnum;
  disabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
};

export const IssueStateIndicator: FC<IssueStateType> = ({ state, disabled, isLoading, onClick }) => {
  const hasPermissions = useHasPermissions();
  const [issueStateIcon, setIssueStateIcon] = useState<JSX.Element | undefined>(undefined);

  useLayoutEffect(() => {
    setIssueStateIcon(stateIcons[state]);
  }, [hasPermissions, state]);

  const enabled =
    hasPermissions(Permissions.ProcessIssues) &&
    (state === IssuesStateEnum.Opened || state === IssuesStateEnum.Processed);

  const onMouseEnterHandler = () => {
    setIssueStateIcon(onHoverStateIcon[state]);
  };

  const onMouseLeaveHandler = () => {
    setIssueStateIcon(stateIcons[state]);
  };

  return (
    <div className={Style.container} data-test-id={'issue-card-status-icon'}>
      {isLoading ? (
        <Loader testId={'issue-card-loader'} />
      ) : (
        <button
          className={Style.changeStateButton}
          disabled={!enabled || disabled}
          onClick={async (e) => {
            e.stopPropagation();
            onClick();
          }}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          {issueStateIcon}
        </button>
      )}
    </div>
  );
};
