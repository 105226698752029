/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AppButton } from 'uikit/buttons';
import { QueryFactory } from '../../../services/api';
import {
  EditReasonEnum,
  FileParameter,
  FormResultDto,
  IScoreSection,
  ScoreCalculationStatusEnum,
  ScoreGroupEnum,
} from '../../../services/api/api-client';
import { logger } from 'src/application/logging/logging';
import { useQueryClient } from '@tanstack/react-query';
import { AppAttachmentValueToFileParameter } from 'src/helpers/file-helper';
import { useModal } from 'src/application/hooks/useModal';
import { TypographyStyles } from 'src/styles';
import { Tooltip } from 'src/components/uikit/tooltip/tooltip.component';
import { ReactComponent as Tip } from '../../../assets/img/common/tip_20.svg';
import _ from 'lodash';
import { useAppSelector } from '../../../application/redux-store/store-types';
import styles from '../uiEditor/uiEditor.module.css';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { ErrorScoreCalculation } from '../../errorScoreCalculation/errorScoreCalculation.component';
import { useNotEditableFieldModal } from './notEditableFieldsModal/notEditableFieldsModal';
import { useContextSelector } from 'use-context-selector';
import clsx from 'clsx';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { AppInputError } from 'uikit/wrappers';
import { CloseConfirmationModal } from './closeConfirmationModal';
import { getMultiValuedTagFilters } from '../../../features/patientTags/tagsHelper';
import { useTagsModal } from './tagsModal/TagsModal.component';
import { FormFillContext, DataBlockValidation } from '../uiEditor/provider/formFill.context';
import { FormModalComponentProps, Layout } from '../uiEditor/uiEditor';
import { findNodesWithDataKey, isValid, pageStacksIsEqual } from '../uiEditor/uiEditor-helper';
import { useReasonModal } from './reasonModal/reasonModal.component';
import { UiEditorComponent } from '../uiEditor/uiEditor.component';

export const FormModalComponent: FC<FormModalComponentProps> = (props) => {
  const studyId = useAppSelector((state) => state.app.studyId);
  const tagFilters = useAppSelector((state) => state.app.tagFilters);
  const client = useQueryClient();
  const { t } = useScopedTranslation('Forms');
  const commonLocalizer = useCommonLocalization();

  const confirmationModal = useModal();
  const reasonModal = useReasonModal();
  const notEditableFieldsModal = useNotEditableFieldModal();
  const tagsModal = useTagsModal(tagFilters);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [calculating, setCalculating] = useState<boolean>(false);
  const [commonError, setCommonError] = useState<string | undefined>();

  const {
    mode,
    isSubmitting,
    formConfig,
    formResult,
    values: dataBlockValues,
    notEditableValues,
    dataBlockValidation,
    touchedFields,
    pageStacks,
    currentPageStack,
    setIsSubmitting,
    resetState,
    goToNextPage,
    goToPrevPage,
    navigateToBlock,
    triggerValidation,
    setDataBlockValidation,
  } = useContextSelector(FormFillContext, (x) => x);

  // This effect clear common error when any validation changes
  useEffect(() => {
    setCommonError(undefined);
  }, [dataBlockValidation]);

  const checkTouchedFields = useCallback(() => {
    const fieldsIsTouched = Object.keys(touchedFields ?? {}).length > 0;

    if (fieldsIsTouched) {
      return confirmationModal.openModal();
    }

    props.modal.onHide();
  }, [confirmationModal, props.modal, touchedFields]);

  const sendResult = useCallback(
    async (editReason?: EditReasonEnum, editReasonText?: string, patientTags?: Record<string, string>) => {
      setIsSubmitting?.(true);

      const getFilesDataKeys = (layout: Layout): string[] => {
        const keys = [];
        for (const key of Object.keys(layout)) {
          if (layout[key].type.resolvedName === 'Files') {
            keys.push(layout[key].props.dataKey);
          }
        }

        return keys;
      };

      if (!formConfig) throw new Error('formConfig is not provided');

      const filesDataKeys = getFilesDataKeys(formConfig?.layoutSchema);
      const fileNamesList: string[] = [];
      // @ts-ignore
      const files: FileParameter[] = filesDataKeys
        .flatMap((key) => {
          if (dataBlockValues && dataBlockValues[key]) {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            return dataBlockValues[key].map((f: AppAttachmentValue) => {
              if (fileNamesList.includes(f.fileName)) {
                f.fileName = `${key.replace(/[/\\ ?%*:|"<>]/g, '_')}_${f.fileName}`;
              }

              if (f.file && !f.id) {
                fileNamesList.push(f.fileName);
                return AppAttachmentValueToFileParameter(f);
              }

              return null;
            }) as FileParameter[];
          }

          return null;
        })
        .filter(Boolean);

      try {
        let newFormResult: FormResultDto;

        if (formConfig?.id === formResult?.formConfigId && formResult?.id) {
          newFormResult = await QueryFactory.FormsQuery.Client.patchResults(
            true,
            studyId!,
            props.patientId,
            formResult?.id,
            formConfig?.type,
            JSON.stringify(dataBlockValues),
            editReason,
            editReasonText,
            files,
          );
        } else {
          newFormResult = await QueryFactory.FormsQuery.Client.processResults(
            true,
            studyId!,
            props.patientId,
            formConfig?.id,
            JSON.stringify(dataBlockValues),
            props.stepName,
            files,
            patientTags,
          );
        }

        await Promise.all([
          client.invalidateQueries(QueryFactory.FormsQuery.getFormBindingsQueryKey(props.patientId!, true, false)),
          client.invalidateQueries(QueryFactory.FormsQuery.getFormBindingsQueryKey(props.patientId!, true, true)),
          client.invalidateQueries(QueryFactory.PatientQuery.getPatientStudyCountersQueryKey(studyId!)),
          client.invalidateQueries(QueryFactory.PatientQuery.getPatientsQueryKey()),
          client.invalidateQueries(QueryFactory.NotificationQuery.getNotificationCountsQueryKey(studyId!)),
          client.invalidateQueries(
            QueryFactory.NotificationQuery.getNotificationBatchesQueryKey(undefined, [studyId!]),
          ),
          client.invalidateQueries(QueryFactory.NotificationQuery.getNotificationsQueryKey(undefined, studyId)),
        ]);

        // For submitted animation
        setIsSubmitted(true);
        setTimeout(async () => {
          await props.onSubmitted?.(newFormResult);

          props.modal.onHide();

          setIsSubmitted(false);
          setIsSubmitting?.(false);
        }, 1700);
      } catch (error: any) {
        setIsSubmitting?.(false);

        if (error.errors && !Object.keys(error.errors).length) {
          logger().error(error);
          setCommonError(t('CommonError'));
          return;
        }

        for (const dataKey of Object.keys(error.errors)) {
          const nodeIds = findNodesWithDataKey(formConfig?.layoutSchema, dataKey);
          if (!nodeIds.length) {
            setCommonError(t('CommonError'));
            continue;
          }

          for (const nodeId of nodeIds) {
            if (!dataBlockValidation || !Object.keys(dataBlockValidation!).includes(nodeId)) continue;

            setDataBlockValidation?.({
              [nodeId]: {
                validationState: 'Fail',
                text: commonLocalizer(
                  `ServerErrors.${error.errors[dataKey][0]}` as LocalizedResourceDictionaryKeys,
                ) as string,
              },
            });
          }

          const firstErrorBlock = _.first(nodeIds);
          navigateToBlock?.(firstErrorBlock);
        }
      }
    },
    [
      setIsSubmitting,
      formConfig,
      dataBlockValues,
      formResult?.formConfigId,
      formResult?.id,
      client,
      props,
      studyId,
      t,
      navigateToBlock,
      dataBlockValidation,
      setDataBlockValidation,
      commonLocalizer,
    ],
  );

  const onSave = useCallback(async () => {
    setCommonError(undefined);
    const validationResult = await new Promise<DataBlockValidation>((resolve) => triggerValidation?.(resolve));

    if (!isValid(validationResult)) {
      const firstFailedBlock = _.first(
        Object.keys(validationResult).filter((key) => validationResult[key].validationState === 'Fail'),
      );
      navigateToBlock?.(firstFailedBlock);
      return;
    }

    const reasonModalResult = mode === 'Edit' ? await reasonModal.open() : null;

    if (notEditableValues!.length > 0) {
      await notEditableFieldsModal.open();
    }

    const multiValuedFilters = tagFilters && getMultiValuedTagFilters(tagFilters);
    const doctorHasMultiValuedFilters = multiValuedFilters && Object.keys(multiValuedFilters).length > 0;
    const patientTags =
      formConfig?.isPatientCreationForm && mode === 'Create' && doctorHasMultiValuedFilters
        ? await tagsModal.open()
        : undefined;

    await sendResult(reasonModalResult?.reason, reasonModalResult?.comment, patientTags);
  }, [
    formConfig,
    mode,
    reasonModal,
    notEditableValues,
    tagFilters,
    sendResult,
    triggerValidation,
    navigateToBlock,
    notEditableFieldsModal,
    tagsModal,
  ]);

  const recalculateFormScore = useCallback(async () => {
    setCalculating(true);
    try {
      const result = await QueryFactory.FormsQuery.Client.recalculateScore(formResult?.id ?? undefined);
      await props.onSubmitted?.(result);
    } catch (error: any) {
      logger().error(error);
    } finally {
      setCalculating(false);
    }
  }, [props, formResult?.id]);

  const emptyScore = useMemo(() => {
    return (
      <div className={styles.emptyScore}>
        <Tooltip text={t('Score.EmptyScoreTooltip')}>
          <Tip />
        </Tooltip>
        {commonLocalizer('Common_dash')}
      </div>
    );
  }, [t, commonLocalizer]);

  const legendBlock = useMemo(() => {
    if (!formConfig?.legend?.length) {
      return <></>;
    }

    return (
      <div
        className={clsx(TypographyStyles.plainText12, styles.legendContainer, {
          [styles.overviewMode]: mode === 'Overview',
          [styles.editMode]: mode !== 'Overview',
        })}
      >
        {formConfig?.legend?.map((text) => (
          <div key={text}>{text}</div>
        ))}
      </div>
    );
  }, [formConfig?.legend, mode]);

  const scoreBlock = useMemo(() => {
    if (
      formResult?.scoreStatus === ScoreCalculationStatusEnum.InProcess ||
      formResult?.scoreStatus === ScoreCalculationStatusEnum.Error
    ) {
      return (
        <div className={styles.scoreContainer}>
          <div className={styles.scoreSection}>
            <div>{t('Score.TotalScore')}</div>
            <ErrorScoreCalculation
              retryHandlerType={'RetryFormResult'}
              retryHandler={recalculateFormScore}
              canRetry={formResult?.canRetryScoreCalculation}
              isInProcess={formResult?.scoreStatus === ScoreCalculationStatusEnum.InProcess || calculating}
            />
          </div>
        </div>
      );
    }

    if (formResult?.score && mode === 'Overview') {
      return (
        <div className={styles.scoreContainer}>
          {formResult?.score?.sectionScore && formResult?.score.sectionScore.length > 0 && (
            <div className={styles.scoreSectionsContainer}>
              {formResult?.score.sectionScore?.map((section: IScoreSection) => (
                <div className={styles.scoreSection} key={section.scoreGroup} data-test-id={'score-group'}>
                  <div className={TypographyStyles.plainText14}>
                    {commonLocalizer(
                      `Forms.Score.ScoreGroup.${ScoreGroupEnum[section.scoreGroup]}` as LocalizedResourceDictionaryKeys,
                    )}
                  </div>
                  <div className={TypographyStyles.numeral}>
                    {section.score !== null ? section.score?.toFixed(formResult?.score?.decimal ?? 0) : emptyScore}
                  </div>
                </div>
              ))}
            </div>
          )}
          {formResult?.score.hasTotalScore && (
            <div className={styles.scoreSection} data-test-id={'total-score'}>
              <div className={TypographyStyles.plainText14}>{t('Score.TotalScore')}</div>
              <div className={TypographyStyles.numeral}>
                {formResult?.score.totalScore !== null
                  ? formResult?.score?.totalScore?.toFixed(formResult?.score.decimal ?? 0)
                  : emptyScore}
              </div>
            </div>
          )}
        </div>
      );
    }

    return <></>;
  }, [
    formResult?.scoreStatus,
    formResult?.score,
    formResult?.canRetryScoreCalculation,
    mode,
    t,
    recalculateFormScore,
    calculating,
    emptyScore,
    commonLocalizer,
  ]);

  const buttonsBlock = useMemo(() => {
    return (
      <div className={styles.footer}>
        <AppInputError errors={commonError} hideBorder position={'top'}>
          <div className={styles.buttonGroup}>
            {pageStacksIsEqual(_.first(pageStacks), currentPageStack) ? (
              <AppButton
                className={styles.buttonFlex}
                text={commonLocalizer('Common_Cancel')}
                variant={'button'}
                colorSchema={'secondary'}
                onClick={checkTouchedFields}
                disabled={isSubmitting || isSubmitted}
              />
            ) : (
              <AppButton
                className={styles.buttonFlex}
                text={commonLocalizer('Common_Back')}
                variant={'button'}
                colorSchema={'secondary'}
                onClick={goToPrevPage}
                disabled={isSubmitting || isSubmitted}
              />
            )}

            {pageStacksIsEqual(_.last(pageStacks), currentPageStack) || isSubmitting ? (
              <AppButton
                className={styles.buttonFlex}
                text={t('Controls.SaveButton')}
                variant={'button'}
                colorSchema={'primary'}
                onClick={onSave}
                disabled={isSubmitting || isSubmitted}
                isLoading={isSubmitting}
                hasLoaded={isSubmitted}
              />
            ) : (
              <AppButton
                className={styles.buttonFlex}
                text={commonLocalizer('Common_Next')}
                variant={'button'}
                colorSchema={'primary'}
                onClick={goToNextPage}
                disabled={isSubmitting || isSubmitted}
                isLoading={isSubmitting}
                hasLoaded={isSubmitted}
              />
            )}
          </div>
        </AppInputError>
      </div>
    );
  }, [
    commonError,
    pageStacks,
    currentPageStack,
    checkTouchedFields,
    isSubmitting,
    isSubmitted,
    goToPrevPage,
    onSave,
    goToNextPage,
    t,
    commonLocalizer,
  ]);

  return (
    <>
      <AppModalContainer
        {...props.modal}
        footer={
          mode === 'Overview' ? (
            <>
              {scoreBlock}
              {legendBlock}
            </>
          ) : (
            <>
              {legendBlock}
              {buttonsBlock}
            </>
          )
        }
        onHide={() => !isSubmitting && !isSubmitted && checkTouchedFields()}
        onDismissed={resetState}
        isDisabled={isSubmitting || isSubmitted}
        bodyClassName={styles.formModalBody}
      >
        <div>
          {props.navigationMenu}

          <div className={styles.data}>
            <UiEditorComponent isInDesignerMode={false} config={formConfig?.layoutSchema} />
          </div>
        </div>
      </AppModalContainer>

      {tagsModal.element}
      {reasonModal.element}
      {notEditableFieldsModal.element(notEditableValues!, dataBlockValues)}
      <CloseConfirmationModal
        modal={confirmationModal}
        onSave={() => {
          confirmationModal.closeModal();
          onSave();
        }}
        onDiscard={props.modal.onHide}
      />
    </>
  );
};
