import { createContext } from 'use-context-selector';
import { IIssueOperationDto, IssueDto } from '../../../services/api/api-client';
import { IssueQueryParamsType } from '../bar/issueFilters-helper';
import { IssueCreationType, IssueCreationTypeWithId } from '../form/issueForm.component';

export type IssuesContextType = {
  processingIssueId?: number;

  openIssueTable?: () => void;
  openIssueView?: (issueId: number) => void;
  createIssue?: (data?: Partial<IssueCreationType>) => void;
  editIssue?: (data?: IssueCreationTypeWithId) => void;

  deleteIssue?: (issueId: number) => void;
  performOperation?: (issueId: number) => void;
  rejectOperation?: (issueOperation: IIssueOperationDto) => void;
  approveOperation?: (issueOperation: IIssueOperationDto) => void;
  revokeOperation?: (issueOperation: IIssueOperationDto) => void;
  generateReport?: (queryParams?: IssueQueryParamsType) => Promise<void>;

  setLoadedPatientsUid?: (uniqIds: string[] | undefined) => void;
  loadedIssues?: IssueDto[];

  isIssuesVisible?: boolean;
  setIssuesVisible?: (state: boolean) => void;
  navigateToIssue?: (issueId: number) => Promise<void>;
};

export const defaultIssueContextValues: IssuesContextType = {
  processingIssueId: undefined,
};

export const IssuesContext = createContext<IssuesContextType>(defaultIssueContextValues);
