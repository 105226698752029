﻿import React, { FC } from 'react';
import { IFillingProgress } from '../../../services/api/api-client';
import clsx from 'clsx';
import { TypographyStyles } from 'styles';
import Style from './FillingProgress.module.scss';

export const FillingProgress: FC<{ value: IFillingProgress }> = ({ value }) => {
  if (value.filled === value.total) return null;

  return <span className={clsx(TypographyStyles.plainText14, Style.progress)}>{`${value.filled}/${value.total}`}</span>;
};
