/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import Style from './codegeneration.module.css';
import { SmallButton } from '../uikit/buttons/smallButton/smallButton.component';
import { QueryFactory } from '../../services/api';
import { logger } from '../../application/logging/logging';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as Redo } from './../../assets/img/code/redo.svg';
import { ReactComponent as CopyIcon } from './../../assets/img/code/copy.svg';
import { TypographyStyles } from '../../styles';
import { Tooltip } from '../uikit/tooltip/tooltip.component';
import { AppButton } from '../uikit/buttons/button/button.component';
import { trackEvent } from '../../application/analitics/matomo/matomo-tracker';
import { MetricCategories } from '../../application/analitics/matomo/matomo-categories';
import { MetricActions } from '../../application/analitics/matomo/matomo-actions';
import { DirectionalHint } from '@fluentui/react';
import { Permissions } from '../../helpers/auth/auth-helper';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../application/localisation/useCommonLocalization';
import { AppModalContainer } from '../uikit/modal/modal.component';

export const CodeGeneration = (props: { codeGenerated?: boolean; patientId: string; studyNumber: string }) => {
  const { t } = useScopedTranslation('Dashboard.CodeGeneration');
  const commonLocalizer = useCommonLocalization();
  const queryClient = useQueryClient();
  const [visible, setVisible] = useState<boolean>(false);
  const [inProgress, setProgress] = useState<boolean>(false);
  const [code, setCode] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const [accepted, setAccepted] = useState<boolean>(false);

  const onShow = useCallback(() => {
    setCode(null);
    setAccepted(false);
    setCopied(false);
    setVisible(true);
  }, []);
  const onHide = () => setVisible(false);
  const onCopy = () => {
    setCopied(true);
    trackEvent({
      category: MetricCategories.Patient,
      action: MetricActions.ButtonPressed,
      name: 'Copy to clipboard access code',
    });
  };

  const onShowAndGenerate = useCallback(() => {
    onRegenerateCode();
    onShow();
    trackEvent({
      category: MetricCategories.Patient,
      action: MetricActions.Generated,
      name: 'New access code',
    });
  }, []);

  const onRegenerateCode = useCallback(() => {
    QueryFactory.PatientQuery.Client.generatePatientAccessCode(props.patientId)
      .then((resp) => {
        queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientsQueryKey());
        setCode(resp);
        setProgress(false);
      })
      .catch((err) => {
        logger().error('Regenerate code operation has failed', err);
        setProgress(false);
      });
  }, [props.patientId]);

  const onAccept = useCallback(() => {
    setAccepted(true);
    onRegenerateCode();
    trackEvent({
      category: MetricCategories.Patient,
      action: MetricActions.ReGenerated,
      name: 'New access code',
    });
  }, []);

  const renderCodeInput = useCallback(
    (value: string, header?: string) => {
      return (
        <div>
          <div className={clsx(TypographyStyles.paragraph12, Style.codeInputHeader)}>{header}</div>
          <div className={Style.codeContainer}>
            <div className={clsx(TypographyStyles.codeText, Style.codeInput)}>{value}</div>
            <Tooltip text={t('Copied')} trigger={'click'} directionalHint={DirectionalHint.topCenter}>
              <CopyToClipboard text={value} onCopy={onCopy}>
                <button className={Style.copy}>
                  <CopyIcon className={Style.copyIcon} />
                </button>
              </CopyToClipboard>
            </Tooltip>
          </div>
        </div>
      );
    },
    [t],
  );

  const codeInput = useMemo(() => {
    if (inProgress || !code) {
      return <span className={TypographyStyles.paragraph14}>{commonLocalizer('Common_loading')}</span>;
    }

    return (
      <div className={Style.codes}>
        {renderCodeInput(props.studyNumber, t('StudyID'))}
        {renderCodeInput(code, t('Code'))}
      </div>
    );
  }, [inProgress, code, renderCodeInput, props.studyNumber, t]);

  const generationContent = useMemo(() => {
    return (
      <div className={Style.container}>
        {codeInput}
        <div className={clsx(Style.warning, TypographyStyles.buttonLinkText)}>{t('Warning')}</div>
        <div className={clsx(Style.descriptionText, TypographyStyles.paragraph14)}>{t('Description')}</div>
      </div>
    );
  }, [codeInput, code, accepted, copied]);

  const buttonGroups = useMemo(() => {
    return (
      <div className={Style.buttonGroup}>
        <AppButton
          className={Style.modalButton}
          variant={'button'}
          colorSchema={'secondary'}
          text={commonLocalizer('Common_Cancel')}
          onClick={onHide}
        />
        <AppButton
          className={Style.modalButton}
          variant={'button'}
          colorSchema={'primary'}
          text={t('Confirm_Button')}
          onClick={onAccept}
        />
      </div>
    );
  }, []);

  const confirmationContent = useMemo(() => {
    return (
      <div>
        <span>{t('DescriptionWithGeneratedCode')}</span>
        {buttonGroups}
      </div>
    );
  }, [code, inProgress, buttonGroups]);

  const titleText = useMemo(() => {
    return props.codeGenerated && !accepted ? t('TitleWithGeneratedCode') : t('Title');
  }, [accepted]);

  const modalContent = useMemo(() => {
    return props.codeGenerated && !accepted ? confirmationContent : generationContent;
  }, [accepted, code]);

  return (
    <div className={Style.componentContainer}>
      {props.codeGenerated ? (
        <>
          <span>{commonLocalizer('Dashboard.PatientAccordion.GeneralInfo.AppCode.generated')}</span>
          <PermissionsCheck permissions={Permissions.EditPatient}>
            <div className={Style.buttonContainer}>
              <SmallButton onPress={onShow} Icon={Redo} testId={'regenerate-code-button'} />
            </div>
          </PermissionsCheck>
        </>
      ) : (
        <PermissionsCheck permissions={Permissions.EditPatient}>
          <AppButton
            onClick={onShowAndGenerate}
            variant={'icon-link'}
            colorSchema={'primary'}
            text={commonLocalizer('Dashboard.PatientAccordion.GeneralInfo.AppCode.sentCode')}
            testId={'generate-code-button'}
          />
        </PermissionsCheck>
      )}

      <AppModalContainer
        containerClassName={Style.modalContainer}
        bodyClassName={Style.modalBody}
        title={titleText}
        visible={visible}
        onHide={onHide}
      >
        {modalContent}
      </AppModalContainer>
    </div>
  );
};
