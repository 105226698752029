import clsx from 'clsx';
import React, { FC, useCallback, useMemo } from 'react';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { IIssueDto, IssueOperationStateEnum, IssuesStateEnum } from '../../../services/api/api-client';
import { TypographyStyles } from '../../../styles';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { DropDownMenu } from '../../uikit/dropDownMenu/dropDownMenu.component';
import { Permissions } from '../../../helpers/auth/auth-helper';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { useTranslation } from 'react-i18next';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { Tooltip } from '../../uikit/tooltip/tooltip.component';
import { DropDownMenuOption } from '../../uikit/dropDownMenu/dropDownMenu';
import { IssueStateIndicator } from '../stateIndicator/issueStateIndicator.component';
import { useContextSelector } from 'use-context-selector';
import { IssuesContext } from '../provider/issues.context';
import { FileDtoToAppAttachmentValue } from 'src/helpers/file-helper';
import { IssueCreationTypeWithId } from '../form/issueForm.component';
import { FeatureFlags } from 'src/application/constants/feature-flags';
import Style from './issueCard.module.scss';
import { NumberParam, useQueryParams } from 'use-query-params';
import { patientFilterParams } from 'src/components/patientTable/patientFilters.helpers';

import { ReactComponent as Check } from 'assets/img/common/check_16.svg';
import { ReactComponent as Cross } from 'assets/img/common/cross_16.svg';
import { ReactComponent as Dots } from 'assets/img/common/dots_24.svg';
import { ReactComponent as Edit } from 'assets/img/common/edit_16.svg';
import { ReactComponent as Delete } from 'assets/img/common/delete_16.svg';
import { ReactComponent as Warning } from 'assets/img/common/tiny_warning_16.svg';
import { ReactComponent as Clip } from 'assets/img/issues/clip_24.svg';
import { ReactComponent as NavigateIcon } from 'assets/img/issues/navigate_20.svg';
import { ReactComponent as ResetNavigationIcon } from 'assets/img/issues/reset_navigation_20.svg';
import { defaultFilterParamsObject } from 'src/components/patientTable/patientFilters.component';

export const IssueCard: FC<IIssueDto & { disabled?: boolean }> = React.memo((issue) => {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useQueryParams({
    ...patientFilterParams(),
    issueId: NumberParam,
  });

  const {
    editIssue,
    deleteIssue,
    approveOperation,
    rejectOperation,
    openIssueView,
    performOperation,
    revokeOperation,
    processingIssueId,
    navigateToIssue,
  } = useContextSelector(IssuesContext, (x) => x);

  const openEditModal = useCallback(async () => {
    const {
      deadlineAt,
      description,
      files,
      linkedPatientUniqId,
      recommendedAction,
      subject,
      topic,
      topicAdditional,
      id,
    } = issue;

    editIssue?.({
      issueId: id,
      deadlineAt,
      description,
      files: files.map(FileDtoToAppAttachmentValue),
      recommendedAction,
      linkedPatientUniqId: linkedPatientUniqId || undefined,
      subject,
      topic,
      topicAdditional: topicAdditional || undefined,
    } as IssueCreationTypeWithId);
  }, [editIssue, issue]);

  const options = useMemo(() => {
    const optionList: DropDownMenuOption[] = [];

    if (issue.lastOperation?.state === IssueOperationStateEnum.Rejected) {
      optionList.push({
        key: 'Approve',
        icon: <Check />,
        text: t('Issues.Card.MenuOptions.Approve'),
        action: () => approveOperation?.(issue.lastOperation!),
        className: Style.commonOption,
      });
    }

    if (issue.lastOperation?.state === IssueOperationStateEnum.Approved) {
      optionList.push({
        key: 'Decline',
        icon: <Cross />,
        text: t('Issues.Card.MenuOptions.Decline'),
        action: () => rejectOperation?.(issue.lastOperation!),
        className: Style.commonOption,
      });
    }

    if (issue.state !== IssuesStateEnum.Closed) {
      optionList.push({
        key: 'Edit',
        icon: <Edit />,
        text: t('Issues.Card.MenuOptions.Edit'),
        action: openEditModal,
        className: Style.commonOption,
      });
    }

    if (issue.state === IssuesStateEnum.Opened) {
      optionList.push({
        key: 'Delete',
        icon: <Delete />,
        text: t('Issues.Card.MenuOptions.Delete'),
        action: () => deleteIssue?.(issue.id),
        className: Style.deleteOption,
      });
    }

    return optionList;
  }, [issue.lastOperation, issue.state, issue.id, t, approveOperation, rejectOperation, openEditModal, deleteIssue]);

  const issueSubject = useMemo(() => {
    let str: string = t(`Issues.Subject.${issue.subject}` as LocalizedResourceDictionaryKeys);

    if (issue.linkedPatientUniqId) {
      str = str.concat(' ', issue.linkedPatientUniqId);
    }

    if (issue.topic) {
      str = str.concat(', ', t(`Issues.Topic.${issue.topic}` as LocalizedResourceDictionaryKeys));
      if (issue.topicAdditional) {
        str = str.concat(': ', issue.topicAdditional);
      }
    }

    return str;
  }, [issue.linkedPatientUniqId, issue.subject, issue.topic, issue.topicAdditional, t]);

  const onStatusClickHandler = useCallback(() => {
    if (issue.lastOperation?.state === IssueOperationStateEnum.None) {
      revokeOperation?.(issue.lastOperation);
    } else {
      performOperation?.(issue.id);
    }
  }, [issue.id, issue.lastOperation, performOperation, revokeOperation]);

  const resetNavigationHandler = useCallback(() => {
    setQueryParams({ ...defaultFilterParamsObject, searchQuery: undefined });
  }, [setQueryParams]);

  return (
    <div
      className={clsx(Style.container, TypographyStyles.plainText12)}
      onClick={() => openIssueView?.(issue.id)}
      data-test-id={'issue-card'}
      data-selected={queryParams.issueId === issue.id}
    >
      <div className={Style.header}>
        <div className={clsx(Style.leftHeaderWrapper)}>
          {issue.isNew && <div className={Style.newMark} data-test-id={'issue-card-blue-mark'} />}
          <div className={Style.leftHeader} data-test-id={'issue-card-header'}>
            <div className={Style.userNameWrapper}>
              <div className={Style.userName} data-test-id={'issue-card-username'}>
                {issue.createdByUser}
              </div>
            </div>
            <div data-test-id={'issue-card-id'}>{`#${issue.id}`}</div>
            <Tooltip text={localFormat(issue.createdAt, DateFormats.timeOnly)} hostStyles={Style.tooltipHost}>
              <div data-test-id={'issue-card-created-date'}>{localFormat(issue.createdAt, DateFormats.longDate)}</div>
            </Tooltip>
          </div>
        </div>
        <div className={Style.rightHeader}>
          <PermissionsCheck permissions={Permissions.ManageIssues}>
            {options.length > 0 && (
              <DropDownMenu
                disabled={issue.disabled}
                Icon={Dots}
                options={options}
                colorSchema={'basic'}
                className={Style.visibility}
                testId={'issue-card-menu'}
              />
            )}
          </PermissionsCheck>
          <IssueStateIndicator
            state={issue.state}
            onClick={onStatusClickHandler}
            isLoading={processingIssueId === issue.id}
            disabled={issue.disabled}
          />
        </div>
      </div>

      <div className={Style.body}>
        <div className={Style.subject} data-test-id={'issue-card-subject'}>
          {issueSubject}
        </div>
        <div className={Style.description} data-test-id={'issue-card-description'}>
          {issue.description}
        </div>

        <div className={Style.deadlineAndPic}>
          <div
            className={clsx(Style.deadline, {
              [Style.isExpired]: issue.isExpired,
              [Style.isClosed]: issue.state === IssuesStateEnum.Closed,
            })}
          >
            {issue.isExpired && <Warning className={clsx({ [Style.isExpiredIcon]: issue.isExpired })} />}
            <div data-test-id={'issue-card-deadline-date'}>
              {t('Issues.Card.DeadlineAt', { date: localFormat(issue.deadlineAt, DateFormats.longDate) })}
            </div>
            {issue.files && issue.files.length > 0 && (
              <div>
                <Clip className={Style.attachments} />
                {issue.files.length > 1 && <span className={Style.attachmentsCount}>{`(${issue.files.length})`}</span>}
              </div>
            )}
          </div>

          {FeatureFlags.isIssueTargetEnabled() &&
            issue?.linkedPatientUniqId &&
            (queryParams.issueId === issue.id ? (
              <AppButton
                variant={'icon-link'}
                colorSchema={'decline'}
                Icon={ResetNavigationIcon}
                onClick={resetNavigationHandler}
              />
            ) : (
              <AppButton
                variant={'icon-link'}
                colorSchema={'primary'}
                Icon={NavigateIcon}
                onClick={() => navigateToIssue?.(issue.id)}
              />
            ))}
        </div>
      </div>

      <PermissionsCheck permissions={Permissions.ManageIssues}>
        {issue.state === IssuesStateEnum.Processed && (
          <div className={Style.footer}>
            <AppButton
              variant={'icon-link'}
              colorSchema={'confirm'}
              text={t('Issues.Card.MenuOptions.Approve')}
              Icon={Check}
              onClick={(e) => {
                e.stopPropagation();
                approveOperation?.(issue.lastOperation!);
              }}
              disabled={issue.disabled}
              textClassName={Style.lineHeight24}
            />
            <AppButton
              variant={'icon-link'}
              colorSchema={'decline'}
              text={t('Issues.Card.MenuOptions.Decline')}
              Icon={Cross}
              onClick={(e) => {
                e.stopPropagation();
                rejectOperation?.(issue.lastOperation!);
              }}
              disabled={issue.disabled}
              textClassName={Style.lineHeight24}
            />
          </div>
        )}
      </PermissionsCheck>
    </div>
  );
});
