import React, { useEffect } from 'react';
import { redirectToLoginPage } from 'src/helpers/auth/openid/openid-manager';
import { logger } from '../../application/logging/logging';
import { PageLoading } from 'uikit/suspense/PageLoading';
import styles from './login.module.scss';

export const LoginContainer: React.FC = () => {
  useEffect(() => {
    redirectToLoginPage().catch((e) => logger().error(e));
  }, []);

  return <PageLoading loading containerClassName={styles.loginContainer} />;
};
