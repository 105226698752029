import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { QueryFactory } from '../../services/api';
import { formatRelative, startOfDay } from 'date-fns';
import { getCurrentDateLocale } from '../../application/localisation/localization';
import { useEffect } from 'react';

export function useSyncInProgress(studyId: number | null): { result: boolean; date: string } {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const profile = QueryFactory.ProfileQuery.useGetDoctorProfileInfoQuery();
  const study = profile?.data?.studies?.find((x) => x.id === studyId);

  useEffect(() => {
    if (!study?.isSyncInProgress) return;

    const timeoutId = setTimeout(() => {
      queryClient.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey());
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [queryClient, study]);

  return {
    result: !!study?.isSyncInProgress,
    date: study?.lastSuccessSyncDate
      ? formatRelative(study.lastSuccessSyncDate, Date.now(), getCurrentDateLocale())
      : t('Common_Never'),
  };
}

export function useStudySinceDate(studyId: number | null) {
  const profile = QueryFactory.ProfileQuery.useGetDoctorProfileInfoQuery();

  if (studyId && profile && profile.data && profile.data.studies && profile.data.studies.length >= 1) {
    const study = profile.data.studies.find((x) => x.id === studyId);
    if (study) {
      return startOfDay(study.startDate);
    }
  }

  return startOfDay(new Date());
}

export function useStudyIdsOfCurrentUser() {
  const profile = QueryFactory.ProfileQuery.useGetDoctorProfileInfoQuery();

  return profile?.data?.studies?.map((x) => x.id) || [];
}
