/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import { convertDateToString, localFormat, toDateOrNull } from '../../../../helpers/date-helpers';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import {
  dateInputDefaultPropsFactory,
  dateInputApplicableValidationRules,
  IDateInputProps,
} from '../../../../features/forms/base/controls/inputs/DateInput';
import {
  useRefForDataBlockNavigation,
  useDefaultValues,
  useFieldDataFromUiEditorContext,
  useFieldValidation,
} from './base/hooks';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { AppTextField } from '../../../uikit/fields/text/appTextField.component';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { _isSkipped } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const DateInput = (props: IDateInputProps) => {
  const { t } = useTranslation();
  const {
    connectors: { connect, drag },
    id,
  } = useNode();
  const { size, dataKey, isDisableWhenEditing, isDisabled, hasSkipCheckBox, skipCheckBoxText } = props;
  const { singleDefaultValue } = useDefaultValues(props);

  const { enabled: isConstructorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);

  const { isOnlyView, isEditMode, singleInputValue, setDataBlockFieldValue, markFieldAsTouched } =
    useFieldDataFromUiEditorContext(dataKey);

  // This Effect set default value to answers
  useEffect(() => {
    if (singleInputValue === undefined && singleDefaultValue === 'true') {
      setDataBlockFieldValue?.(dataKey, convertDateToString(new Date()));
    }
  }, [singleDefaultValue]);

  const blockRef = useRefForDataBlockNavigation();
  const [initialValue] = useState(singleInputValue);
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const editableText = (
    <div
      data-test-field-type={'date-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef} className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
        <AppTextField
          labelProps={{
            isBold: props.isBold,
            text: !props.hideLabel ? props.label || props.text || dataKey : undefined,
            tooltip: props.tooltip,
          }}
          placeholder={props.placeholder}
          disabled={
            !props.isEditable || (isDisableWhenEditing && isEditMode && !!initialValue) || isDisabled || isSubmitting
          }
          onChange={(e) => {
            markFieldAsTouched?.(id);
            const parsedTime: Date | null = e.target.value ? new Date(Date.parse(e.target.value)) : null;
            if (parsedTime?.toDateString() === 'Invalid Date') {
              return;
            }
            setDataBlockFieldValue?.(dataKey, e.target.value || undefined);
          }}
          type={'date'}
          value={singleInputValue || ''}
          errorProps={{ errors: dataBlockValidation?.text }}
          skipCheckboxProps={{
            hasSkipCheckBox: hasSkipCheckBox,
            skipText: skipCheckBoxText,
            isSkipped: singleInputValue === _isSkipped,
            onSkippedChange: (val) => {
              markFieldAsTouched?.(id);
              setDataBlockFieldValue?.(dataKey, val ? _isSkipped : undefined);
            },
          }}
        />
      </div>
    </div>
  );

  const date = toDateOrNull(singleInputValue);
  const notEditableText = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResult?.id ?? undefined,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={{ right: 24, top: 6 }}
    >
      <div className={clsx(styles.previewRow, 'preview-row')}>
        <span className={TypographyStyles.heading2}>{props.label ?? props.dataKey}</span>

        {singleInputValue &&
          (singleInputValue === _isSkipped ? (
            <span className={clsx(TypographyStyles.plainText14, styles.textPlain)}>
              {props.skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox')}
            </span>
          ) : (
            <span className={TypographyStyles.plainText14}>{date && localFormat(date)}</span>
          ))}
      </div>
    </IssueMark>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

const DateInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IDateInputProps,
  }));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.defaultValueAsNow')}
          isChecked={props.defaultValue === 'true'}
          setValue={(newValue) =>
            setProp((setProps: IDateInputProps) => {
              setProps.defaultValue = newValue ? newValue.toString() : undefined;
            })
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const DateInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <DateInputGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={dateInputApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

DateInput.craft = {
  props: dateInputDefaultPropsFactory(),
  related: {
    settings: DateInputSettings,
  },
};
