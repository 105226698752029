import React, { useCallback, useEffect, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import { ValidationCustomForm } from '../../../../helpers/validation-helpers';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import { isNullOrEmpty } from '../../../../helpers/string-helper';
import {
  tagInputDefaultPropsFactory,
  tagApplicableValidationRules,
  ITagInput,
  ITagInputProps,
} from '../../../../features/forms/base/controls/inputs/TagInputProps';
import {
  useRefForDataBlockNavigation,
  useDefaultValues,
  useFieldDataFromUiEditorContext,
  useSetDefaultValueToAnswer,
  useFieldValidation,
} from './base/hooks';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { AppTagInputField } from '../../../uikit/fields/tagInput/appTagInputField.component';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { _isSkipped } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const TagInput: ITagInput = (props) => {
  const { size, dataKey, validation, isDisabled, skipCheckBoxText, hasSkipCheckBox } = props;
  const { t } = useTranslation();

  const { enabled: isConstructorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    connectors: { connect, drag },
    id,
    actions: { setProp },
  } = useNode((state) => ({
    linkedNodes: state.data.linkedNodes,
  }));

  const [tagInputValue, setTagInputValue] = useState<string>();
  const { multipleDefaultValue } = useDefaultValues(props);

  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);

  const { isOnlyView, multipleInputValue, setDataBlockFieldValue, markFieldAsTouched } =
    useFieldDataFromUiEditorContext(dataKey);

  useSetDefaultValueToAnswer(dataKey, props.defaultValue);
  const blockRef = useRefForDataBlockNavigation();
  // This is a validation of the entered tags
  const { dataBlockValidation, setDataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  // This is a validation of the tag that is being entered now
  const isValid = useCallback(
    (tag: string) => {
      if (isOnlyView) {
        return true;
      }

      const validationResult = isDisabled
        ? []
        : ValidationCustomForm({ pattern: validation.pattern, maxLength: validation.maxLength }, tag);

      setDataBlockValidation?.({
        [id]: {
          validationState: validationResult.length > 0 ? 'Fail' : 'Ok',
          text: validationResult.join('\n'),
        },
      });

      return validationResult.length === 0;
    },
    [id, isDisabled, isOnlyView, setDataBlockValidation, validation.maxLength, validation.pattern],
  );

  const resetValidationResult = useCallback(() => {
    setDataBlockValidation?.({
      [id]: {
        validationState: 'Ok',
        text: '',
      },
    });
  }, [id, setDataBlockValidation]);

  // This effect reset validation on input value changes
  useEffect(() => {
    resetValidationResult();
  }, [resetValidationResult, tagInputValue]);

  const onAddTagHandler = useCallback(
    (tag: string) => {
      markFieldAsTouched?.(id);
      if (isNullOrEmpty(tag)) return;
      tag = tag.toUpperCase();

      if (isConstructorMode) {
        setProp((setProps: ITagInputProps) => {
          if (!multipleDefaultValue) {
            setProps.defaultValue = [tag];
            return;
          }

          if (multipleDefaultValue?.includes(tag)) return;

          setProps.defaultValue = [...multipleDefaultValue, tag];
        });
      } else {
        if (!isValid(tag)) {
          throw new Error(t('LocalErrors.InvalidPattern'));
        }

        if (!multipleInputValue) {
          setDataBlockFieldValue?.(dataKey, [tag]);
          return;
        }

        if (multipleInputValue?.includes(tag)) {
          return;
        }

        setDataBlockFieldValue?.(dataKey, [...multipleInputValue, tag]);
      }
    },
    [
      markFieldAsTouched,
      id,
      isConstructorMode,
      setProp,
      multipleDefaultValue,
      isValid,
      multipleInputValue,
      setDataBlockFieldValue,
      dataKey,
      t,
    ],
  );

  const onDeleteTagHandler = useCallback(
    (index: number) => {
      markFieldAsTouched?.(id);
      if (isConstructorMode) {
        setProp((setProps: ITagInputProps) => {
          const newArray = multipleDefaultValue?.slice() ?? [];
          newArray.splice(index, 1);
          setProps.defaultValue = newArray;
        });
      } else {
        const newArray = multipleInputValue?.slice() ?? [];
        newArray.splice(index, 1);
        setDataBlockFieldValue?.(dataKey, newArray);
      }
    },
    [
      markFieldAsTouched,
      dataKey,
      isConstructorMode,
      setProp,
      multipleDefaultValue,
      multipleInputValue,
      setDataBlockFieldValue,
    ],
  );

  const editableText = (
    <div
      data-test-field-type={'tag-input'}
      className={styles.container}
      style={{ width: size }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef} className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
        <AppTagInputField
          labelProps={{
            text: !props.hideLabel ? props.label || dataKey : undefined,
            isBold: props.isBold,
            tooltip: props.tooltip,
          }}
          inputValue={tagInputValue}
          onInputChanged={setTagInputValue}
          placeholder={props.placeholder}
          values={isConstructorMode ? multipleDefaultValue ?? [] : multipleInputValue ?? []}
          onDeleteTag={onDeleteTagHandler}
          onAddTag={onAddTagHandler}
          disabled={!props.isEditable || isDisabled || isSubmitting}
          errorProps={{
            errors: dataBlockValidation?.text,
          }}
          skipCheckboxProps={{
            hasSkipCheckBox: hasSkipCheckBox,
            skipText: skipCheckBoxText,
            isSkipped: multipleInputValue?.[0] === _isSkipped,
            onSkippedChange: (val) => {
              markFieldAsTouched?.(id);
              setDataBlockFieldValue?.(dataKey, val ? _isSkipped : undefined);
            },
          }}
        />
      </div>
    </div>
  );

  const notEditableText = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResult?.id ?? undefined,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={{ right: 24, top: 6 }}
    >
      <div className={clsx(styles.previewRow, 'preview-row')}>
        <span className={TypographyStyles.heading2}>{props.label ?? props.dataKey}</span>
        {multipleInputValue &&
          (multipleInputValue?.[0] === _isSkipped ? (
            <span className={clsx(TypographyStyles.plainText14, styles.textPlain)}>
              {props.skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox')}
            </span>
          ) : (
            <span className={TypographyStyles.plainText14}>{multipleInputValue?.join(', ')}</span>
          ))}
      </div>
    </IssueMark>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

const TagInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ITagInputProps,
  }));
  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.placeholder')}
        expanded={!!props.placeholder}
        collapsible
        flexible
        onOpen={() => {
          setProp((prop: ITagInputProps) => {
            prop.placeholder = 'Placeholder';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ITagInputProps) => {
            prop.placeholder = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          multiple
          value={props.placeholder || ''}
          onChange={(e) => {
            setProp((setProps: ITagInputProps) => {
              setProps.placeholder = e.target?.value ? e.target.value : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const TagInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <TagInputGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={tagApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

TagInput.craft = {
  props: tagInputDefaultPropsFactory(),
  related: {
    settings: TagInputSettings,
  },
};
