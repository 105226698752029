/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Editor, Element, Frame, useEditor } from '@craftjs/core';
import { Container } from '../controls/Containers/Container';
import { UiEditorProps } from './uiEditor';
import { TopBar } from '../toolbox/topBar.component';
import { LeftBar } from '../toolbox/leftBar.component';
import { FormDto } from '../../../services/api/api-client';
import { QueryFactory } from '../../../services/api';
import styles from './uiEditor.module.css';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { formBuilderControlsResolver } from '../controls/index.';
import { RightBar } from '../toolbox/rightBar.component';
import { RenderNode } from '../toolbox/renderNodes/renderNode';

const emptyConfig = {
  ROOT: {
    type: {
      resolvedName: 'Container',
    },
    isCanvas: true,
    props: {
      isPadding: true,
      formSize: 'Small',
      overviewSize: 'Medium',
    },
    displayName: 'ROOT',
    hidden: false,
    nodes: [],
    linkedNodes: {},
  },
};

export const UiEditorComponent: FC<UiEditorProps> = React.memo(function UIEditor(props) {
  return (
    <Editor
      resolver={formBuilderControlsResolver}
      enabled={props.isInDesignerMode}
      onRender={RenderNode}
      indicator={{
        success: '#09a07c',
        error: '#e75223',
        thickness: 4,
        transition: 'unset',
      }}
    >
      <UiEditorInnerComponent {...props} />
    </Editor>
  );
});

// I needed extract the inner part of UiEditor to can use {@link useEditor}
const UiEditorInnerComponent: FC<UiEditorProps> = React.memo((props) => {
  const { actions, connectors } = useEditor();
  const currentStudyId = useAppSelector((state) => state.app.studyId);
  const [currentFormConfig, setCurrentFormConfig] = useState<FormDto | null>(null);
  const formConfigs = QueryFactory.FormsQuery.useGetFormsQuery(
    { studyId: currentStudyId! },
    { enabled: props.isInDesignerMode },
  );

  const onSelectFormConfig = useCallback(
    (configId: number | null) => {
      const remoteConfig = formConfigs?.data?.find((x) => x.id === configId);
      setCurrentFormConfig(remoteConfig ?? null);
    },
    [formConfigs.data],
  );

  const handleFormConfigSaved = useCallback(
    async (dto: FormDto) => {
      const newVar = await formConfigs.refetch();
      const fetchedForm = newVar?.data?.find((x) => x.id === dto.id);
      setCurrentFormConfig(fetchedForm ?? null);
    },
    [formConfigs],
  );

  const handleFormConfigDeleted = useCallback(() => {
    setCurrentFormConfig(null);
    formConfigs.refetch();
  }, [formConfigs]);

  useEffect(() => {
    actions.deserialize(JSON.stringify(currentFormConfig?.layoutSchema || props.config || emptyConfig));
    actions.history.clear();
  }, [actions, currentFormConfig, props.config]);

  return (
    <>
      {props.isInDesignerMode && currentStudyId !== null && (
        <TopBar
          studyId={currentStudyId}
          onConfigSelect={onSelectFormConfig}
          onConfigSaved={handleFormConfigSaved}
          onConfigDeleted={handleFormConfigDeleted}
          selectedConfig={currentFormConfig}
          formConfigs={formConfigs.data ?? []}
        />
      )}

      <div
        id="page-container"
        className={clsx({
          [styles.editorContainer]: !props.isInDesignerMode,
          [styles.editorDesigner]: props.isInDesignerMode,
        })}
      >
        {props.isInDesignerMode && <LeftBar />}

        <div
          className={clsx({
            [styles.editor]: true,
            [styles.editorBodyDesigner]: props.isInDesignerMode,
          })}
          ref={(ref) => {
            ref && connectors.select(connectors.hover(ref, ''), '');
          }}
          data-test-id={'ui-editor-container'}
        >
          <Frame>
            <Element canvas={true} id="rootCanvas" is={Container} />
          </Frame>
        </div>

        {props.isInDesignerMode && <RightBar />}
      </div>
    </>
  );
});
