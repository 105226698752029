export type FieldsWithType<TObject, TFieldType> = keyof PickFieldsWithType<TObject, TFieldType>;

export type PickFieldsWithType<TObject, TFieldType> = {
  [P in keyof TObject]: TObject[P] extends TFieldType ? P : never;
};

export function hasOwnProperty<X extends Record<string, unknown>, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function createId() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

/** @see {@link https://stackoverflow.com/a/54432326 source} */
export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export type OneOrArray<T> = T | T[];

export type FalsyNode = false | null | undefined;

export type StringKeyValueType = { key: string; value: string };
