import { useTranslation } from 'react-i18next';
import Style from './navAppBar.module.css';
import { NavButton } from './navButton.component';
import { AppRoutes } from '../../navigation/routes';
import React from 'react';

export const NavAdminBar = () => {
  const { t } = useTranslation();

  return (
    <div className={Style.secondaryNavContainer}>
      <div className={Style.tabsContainer}>
        <NavButton title={t('NavMenu.Tabs.Studies')} route={AppRoutes.AdminStudies} variant={'secondary'} />
        <NavButton title={t('NavMenu.Tabs.Users')} route={AppRoutes.Users} variant={'secondary'} />
      </div>
    </div>
  );
};
