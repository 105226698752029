import _ from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetAnswerValue,
  GetDefaultValue,
  GetAnswerScoreValue,
  questionSchemaToArray,
} from '../../../../helpers/question-helper';
import {
  BaseOption,
  BaseQuestion,
  QuestionType,
  ScaleQuestion,
  SingleChoseQuestion,
} from '../../../../services/api/api-client';
import { SurveyFillingComponentProps } from './surveyFilling';
import Style from './surveyFilling.module.css';
import { AppTextField } from '../../../uikit/fields/text/appTextField.component';
import { SkipCheckbox } from '../../../uikit/wrappers/skipCheckbox/skipCheckbox.component';
import { AppRadioGroupField } from '../../../uikit/fields/radio/appRadioGroupField.component';
import { AppSliderField } from '../../../uikit/fields/slider/appSliderField.component';
import { SliderColorSchemeEnum } from 'uikit/inputs/slider/appSliderInput';

export const SurveyFillingComponent = (props: SurveyFillingComponentProps) => {
  const { schema: schemaWithAnswers, onChange, validationObject, onSkip, skippedQuestions, onAddRef } = props;
  const { t } = useTranslation();

  const renderQuestion = useCallback(
    (question: BaseQuestion) => {
      switch (question.questionType) {
        case QuestionType.SingleChose:
        case QuestionType.YesNo:
        case QuestionType.Rate:
        case QuestionType.ConditionalQuestion:
          return (
            <AppRadioGroupField<BaseOption, 'optionValue'>
              data-test-id={`question-control-${question.questionText}`}
              labelProps={{
                isBold: true,
                text: question.questionText,
              }}
              isVertical={!(question as SingleChoseQuestion).isYesNoQuestion}
              options={question.options ?? []}
              labelField={'optionText'}
              onChange={(option) => onChange(question, option.optionValue)}
              valueField={'optionValue'}
              value={GetAnswerValue(question) ?? undefined}
              errorProps={{
                errors: Object.keys(validationObject).includes(question.id)
                  ? t('LocalErrors.FieldRequired')
                  : undefined,
              }}
              skipCheckboxProps={{
                hasSkipCheckBox: question.canBeSkipped,
                isSkipped: skippedQuestions.includes(question.id),
                onSkippedChange: (isSkipped) => onSkip(question, isSkipped),
              }}
            />
          );

        case QuestionType.Scale:
          return (
            <AppSliderField
              labelProps={{
                isBold: true,
                text: question.questionText,
              }}
              startWith={_.first(question.options)?.scoreValue ?? 0}
              endWith={_.last(question.options)?.scoreValue ?? 9}
              defaultValue={GetAnswerScoreValue(question) || Number(GetDefaultValue(question)) || undefined}
              onChange={(val) => onChange(question, val.toString())}
              caption={{
                startText: _.first(question.options)?.optionText,
                endText: _.last(question.options)?.optionText,
              }}
              showMarks={true}
              colorScheme={
                (question as ScaleQuestion).firsOptionIsPositive
                  ? SliderColorSchemeEnum.GreenToRed
                  : SliderColorSchemeEnum.RedToGreen
              }
              errorProps={{
                errors: Object.keys(validationObject).includes(question.id)
                  ? t('LocalErrors.FieldRequired')
                  : undefined,
              }}
              skipCheckboxProps={{
                hasSkipCheckBox: question.canBeSkipped,
                isSkipped: skippedQuestions.includes(question.id),
                onSkippedChange: (isSkipped) => onSkip(question, isSkipped),
              }}
            />
          );

        case QuestionType.FreeInput:
          return (
            <SkipCheckbox
              hasSkipCheckBox={question.canBeSkipped}
              isSkipped={skippedQuestions.includes(question.id)}
              onSkippedChange={(isSkipped) => onSkip(question, isSkipped)}
            >
              <AppTextField
                type={'text-area'}
                labelProps={{
                  isBold: true,
                  text: question.questionText,
                }}
                data-test-id={`question-control-${question.questionText}`}
                value={GetAnswerValue(question) ?? undefined}
                onChange={(e) => onChange(question, e.target.value)}
                errorProps={{
                  errors: Object.keys(validationObject).includes(question.id)
                    ? t('LocalErrors.FieldRequired')
                    : undefined,
                }}
              />
            </SkipCheckbox>
          );

        default:
          return <p key={question.id}>{`${QuestionType[question.questionType].toString()} type is not supported`}</p>;
      }
    },
    [onChange, onSkip, skippedQuestions, t, validationObject],
  );

  return (
    <div>
      {schemaWithAnswers &&
        questionSchemaToArray(schemaWithAnswers)
          ?.filter((q) => !q.isSkippedRender)
          .map((q, i) => (
            <div
              key={q.id}
              ref={(el) => onAddRef(el, q.id)}
              className={Style.questionContainer}
              data-test-id={`survey-question-container-${i + 1}`}
            >
              <div data-test-id={'question-number'} className={Style.questionNumber}>
                {i + 1}
              </div>
              <div data-test-id={'question'} className={Style.questionWrapper}>
                {renderQuestion(q)}
              </div>
            </div>
          ))}
    </div>
  );
};
