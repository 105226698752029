import React from 'react';
import { PatientTable } from '../../components/patientTable/patientTable.component';
import { useAppSelector } from '../../application/redux-store/store-types';
import { IssuesBar } from '../../components/issue/bar/issuesBar.container';

export const Dashboard = () => {
  const { studyId, isTokenActive, groups, hasTherapy, hasMonitoring, surveys } = useAppSelector((state) => state.app);

  if (!studyId) {
    return <></>;
  }

  return (
    <div data-test-id="main-page" style={{ display: 'flex', flex: '1 1 auto', overflow: 'auto' }}>
      <PatientTable
        studyId={studyId}
        isSyncActive={isTokenActive}
        groups={groups}
        surveys={surveys}
        hasTherapy={hasTherapy}
      />
      {hasMonitoring && <IssuesBar />}
    </div>
  );
};
