import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import Style from './analysisView.module.css';
import _ from 'lodash';
import { useModal } from '../../../application/hooks/useModal';
import { IAnalysisDto } from '../../../services/api/api-client';
import { TypographyStyles } from '../../../styles';
import { AppButton } from 'uikit/buttons';
import { localFormat } from '../../../helpers/date-helpers';
import { QueryFactory } from '../../../services/api';
import { ReactComponent as Delete } from '../../../assets/img/common/delete.svg';
import { useQueryClient } from '@tanstack/react-query';
import { logger } from '../../../application/logging/logging';
import { DialogModal } from '../../dialogModal/dialogModal.component';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import i18n from 'i18next';
import { AppModalContainer } from 'uikit/modal/modal.component';

export type AnalysisViewProps = {
  title: string;
  patientId: string;
  presetId: number;
};

export const AnalysisViewComponent = (props: AnalysisViewProps) => {
  const { t } = useScopedTranslation('ExternalAnalysis.Presets');
  const commonLocalizer = useCommonLocalization();
  const queryClient = useQueryClient();
  const confirmationModal = useModal();
  const modal = useModal();
  const { title, patientId, presetId } = props;
  const [currentDto, setCurrentDto] = useState<IAnalysisDto | undefined>();
  const { data: analyzes } = QueryFactory.AnalyzesQuery.useGetAllPatientAnalyzesQuery(patientId, presetId, {
    suspense: false,
    onSuccess: (data) => {
      setCurrentDto(_.first(data));
    },
  });
  const [isRemoving, setIsRemoving] = useState(false);

  const resetState = () => {
    setCurrentDto(undefined);
  };

  const deleteHandler = useCallback(async () => {
    if (!currentDto?.id) {
      return;
    }
    setIsRemoving(true);
    try {
      await QueryFactory.AnalyzesQuery.Client.deleteAnalysis(currentDto?.id);
      await queryClient.invalidateQueries(QueryFactory.AnalyzesQuery.getAllPatientAnalyzesQueryKey());

      confirmationModal.closeModal();
      modal.closeModal();
      resetState();
    } catch (ex: any) {
      logger().error(`Remove analysis with id: ${currentDto?.id} is failed`, ex);
    } finally {
      setIsRemoving(false);
    }
  }, [confirmationModal, currentDto?.id, modal, queryClient]);

  const navigation = useMemo(() => {
    return (
      <div className={Style.navContainer}>
        {analyzes && analyzes.length > 1 && currentDto && (
          <div className={Style.pagination}>
            {analyzes.map((analysis, i) => (
              <AppButton
                textClassName={clsx({ [Style.selectedPage]: currentDto.id === analysis?.id })}
                key={i}
                disabled={currentDto.id === null}
                text={localFormat(analysis.createdAt)}
                variant={'icon-link'}
                colorSchema={'secondary'}
                className={Style.navButton}
                onClick={() => setCurrentDto(analysis)}
              />
            ))}
          </div>
        )}
        <div className={Style.createdByContainer}>
          <span className={clsx(Style.createdBy, TypographyStyles.plainText14)}>
            {t('Uploaded', {
              date: currentDto && localFormat(currentDto.createdAt),
              name: currentDto?.createdBy,
            })}
          </span>
          {currentDto?.id && (
            <AppButton
              variant={'icon-link'}
              colorSchema={'decline'}
              text={commonLocalizer('Common_Delete')}
              Icon={Delete}
              onClick={() => confirmationModal.openModal()}
              disabled={isRemoving}
            />
          )}
        </div>
      </div>
    );
  }, [analyzes, currentDto, t, commonLocalizer, isRemoving, confirmationModal]);

  return (
    <div>
      {analyzes && analyzes?.length > 0 && (
        <AppButton
          onClick={() => modal.openModal()}
          variant={'icon-link'}
          colorSchema={'primary'}
          testId={'form-button'}
          text={t('View')}
        />
      )}

      <AppModalContainer title={title} visible={modal.visible} onHide={modal.closeModal}>
        {navigation}
        {currentDto && renderAnalysisDto(currentDto)}
      </AppModalContainer>

      <DialogModal
        isVisible={confirmationModal.visible}
        onClose={confirmationModal.closeModal}
        testId={'remove-modal-confirmation'}
        mainText={t('RemoveAnalysisModalText')}
        title={t('RemoveAnalysisModalHeader')}
        buttons={[
          <AppButton
            key={0}
            variant={'button'}
            colorSchema={'secondary'}
            text={commonLocalizer('Common_Cancel')}
            onClick={confirmationModal.closeModal}
            disabled={isRemoving}
          />,
          <AppButton
            key={1}
            variant={'button'}
            colorSchema={'destroy'}
            disabled={isRemoving}
            isLoading={isRemoving}
            hasLoaded={isRemoving}
            text={commonLocalizer('Common_Delete')}
            onClick={deleteHandler}
          />,
        ]}
      />
    </div>
  );
};

const renderAnalysisDto = (dto: IAnalysisDto) => {
  return (
    <div>
      {dto.data &&
        Object.entries(dto.data).map(([key, value]) => (
          <div key={key} className={Style.previewRow}>
            <span className={TypographyStyles.heading2}>{key}</span>
            <span className={TypographyStyles.plainText14}>{value?.toString() ?? i18n.t('Common_dash')}</span>
          </div>
        ))}
    </div>
  );
};
