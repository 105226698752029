import React, { FC } from 'react';
import { QuestionnaireToCreate } from './ManageSurveysModal';
import { Trans, useTranslation } from 'react-i18next';
import { DialogModal } from '../../../dialogModal/dialogModal.component';
import Style from './manageSurveys.module.scss';
import { AppButton } from '../../../uikit/buttons/button/button.component';
import { DateFormats, localFormat } from '../../../../helpers/date-helpers';

export const CreatingConfirmationDialog: FC<{
  onCancel: () => void;
  onApply: () => void;
  isLoading: boolean;
  questionnaireToCreate: QuestionnaireToCreate | null;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogModal
      isVisible={!!props.questionnaireToCreate}
      onClose={props.isLoading ? () => {} : props.onCancel}
      buttons={[
        <AppButton
          key={0}
          disabled={props.isLoading}
          variant={'button'}
          colorSchema={'secondary'}
          text={t('Common_no')}
          type={'reset'}
          onClick={props.onCancel}
        />,
        <AppButton
          key={1}
          variant={'button'}
          colorSchema={'destroy'}
          disabled={props.isLoading}
          isLoading={props.isLoading}
          hasLoaded={props.isLoading}
          text={t('Common_yes')}
          onClick={props.onApply}
        />,
      ]}
      mainText={
        <Trans
          i18nKey={'Dashboard.PatientAccordion.SurveySection.SurveyManaging.Modal.CreatingConfirmationDialog.MainText'}
          values={{
            surveyType: props.questionnaireToCreate?.survey.typeString,
            date: props.questionnaireToCreate
              ? localFormat(new Date(props.questionnaireToCreate.range.start), DateFormats.longDate)
              : '',
          }}
        >
          <strong className={Style.awfulText} />
        </Trans>
      }
      title={t('Dashboard.PatientAccordion.SurveySection.SurveyManaging.Modal.CreatingConfirmationDialog.Header')}
    />
  );
};
