import { RPDF } from '../react-pdf';
import React from 'react';
import { LmReportLogo } from './svg/LmReportLogo';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { ReportColors } from '../report-colors';

export const ReportPagination: React.FC = () => {
  return (
    <RPDF.View fixed style={RPDF.styles.paginationMarginTop}>
      <RPDF.View style={RPDF.styles.paginationContainer}>
        <LmReportLogo />
        <RPDF.View style={RPDF.styles.paginationDataContainer}>
          <RPDF.View style={RPDF.styles.paginationPages}>
            <RPDF.Text
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              style={[RPDF.typography.text10, { color: ReportColors.plain }]}
            />
          </RPDF.View>
          <RPDF.View style={RPDF.styles.paginationDate}>
            <RPDF.Text style={RPDF.typography.text10}>{localFormat(new Date(), DateFormats.dateTime)}</RPDF.Text>
          </RPDF.View>
        </RPDF.View>
      </RPDF.View>
    </RPDF.View>
  );
};
