/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { IPatientGroupInput } from '../../../../forms/base/controls/specials/PatientGroupInput';
import { RPDF } from '../../../react-pdf';
import { RecordsReportContext } from '../../recordsReport.context';

export const GroupInput: IPatientGroupInput = ({ label, text, dataKey }) => {
  const groups = useContextSelector(RecordsReportContext, (v) => v.studyGroups);
  const groupId = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);
  const displayValue = useMemo(() => groups.find((x) => x.id === Number(groupId))?.title, [groups, groupId]);

  return (
    <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
      <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? text ?? dataKey}</RPDF.Text>
      <RPDF.Text style={RPDF.controlStyles.answerCell}>{displayValue}</RPDF.Text>
    </RPDF.View>
  );
};
