import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Slider from 'rc-slider';
import Style from './appSliderInput.module.scss';
import { AppSliderInputProps, SliderColorSchemeEnum } from './appSliderInput';
import { Gradient } from '../../../../helpers/gradient';
import 'rc-slider/assets/index.css';
import './styles.css';
import { TypographyStyles } from 'styles';

export const AppSliderInput: FC<AppSliderInputProps> = ({
  startWith,
  endWith,
  defaultValue,
  value,
  onChange,
  showMarks,
  disabled,
  className,
  caption,
  colorScheme = SliderColorSchemeEnum.SingleColor,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState<number | null>(value ?? null);
  useEffect(() => {
    setInternalValue(value ?? null);
  }, [value]);

  const { min, max, isReversed } = useMemo(() => {
    const isRev = startWith > endWith;
    return isRev
      ? { min: endWith, max: startWith, isReversed: isRev }
      : { min: startWith, max: endWith, isReversed: isRev };
  }, [endWith, startWith]);

  const color = useMemo(() => {
    if (internalValue === undefined || internalValue === null) {
      return undefined;
    }

    const gradient = (() => {
      switch (colorScheme) {
        case SliderColorSchemeEnum.GreenToRed:
          return new Gradient().setColorGradient('#33CAB8', '#F7E06A', '#EC7750');
        case SliderColorSchemeEnum.RedToGreen:
          return new Gradient().setColorGradient('#EC7750', '#F7E06A', '#33CAB8');
        default:
          return new Gradient().setColorGradient('#BBC4EE', '#BBC4EE');
      }
    })();

    return gradient.setMidpoint(max - min).getColor(internalValue - min - 1);
  }, [internalValue, max, min, colorScheme]);

  const marks = useMemo(() => {
    if (!showMarks) return undefined;

    return Array.from({ length: max - min + 1 }, (_, i) => min + i).reduce((obj, cur) => {
      return {
        ...obj,
        [cur]: <div className={clsx(Style.mark, { [Style.markSelected]: cur === internalValue })}>{cur}</div>,
      };
    }, {});
  }, [showMarks, min, max, internalValue]);

  const onChangeHandler = useCallback(
    (val: number | number[]) => {
      if (Array.isArray(val)) return;

      setInternalValue(val);
      return onChange?.(val);
    },
    [onChange],
  );

  return (
    <div className={className}>
      {caption && (
        <div className={clsx(TypographyStyles.plainText14, Style.sliderCaption, { [Style.disabled]: disabled })}>
          <div>{caption?.startText}</div>
          <div>{caption?.endText}</div>
        </div>
      )}
      <div className={Style.main}>
        <Slider
          disabled={disabled}
          min={min}
          max={max}
          reverse={isReversed}
          defaultValue={defaultValue ?? startWith}
          // This trick removes active mark styles and handle
          // @ts-ignore
          value={internalValue}
          dots={showMarks}
          onChange={onChangeHandler}
          className={clsx(Style.slider, { [Style.disabled]: disabled })}
          styles={{
            track: {
              backgroundColor: color,
            },
          }}
          activeDotStyle={{ borderColor: color }}
          marks={marks}
          {...rest}
        />
      </div>
    </div>
  );
};
