import React, { useCallback, useState } from 'react';
import { Editor, Frame, Element, SerializedNodes } from '@craftjs/core';
import { Container } from '../../components/faqEditor/selectors/container';
import { Text } from '../../components/faqEditor/selectors/text';
import { Viewport } from '../../components/faqEditor/editor/viewport/viewport';
import { RenderNode } from '../../components/faqEditor/editor/renderNode';
import { QueryFactory } from '../../services/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../application/redux-store/store-types';
import { FaqEditorContext } from '../../components/faqEditor/faqEditor.context';
import { logger } from '../../application/logging/logging';
import { convertToErrorString } from '../../application/error-handling/useErrorHandler';
import { useQueryClient } from '@tanstack/react-query';
import { Collapsible } from '../../components/faqEditor/selectors/collapsible';
import { ImageBlock } from '../../components/formEditor/controls/content/ImageBlock';
import { MathBlock } from '../../components/faqEditor/selectors/mathBlock';
import { Video } from '../../components/faqEditor/selectors/video';

export const FAQ = () => {
  const faqId = useSelector((state: RootState) => state.app.faqId);
  const studyId = useSelector((state: RootState) => state.app.studyId);
  const faqQuery = QueryFactory.FaqQuery.useGetByIdQuery(faqId!, { enabled: !!faqId });
  const queryClient = useQueryClient();
  const [enabled, setEnabled] = useState(false);
  const [nodeRefs, setNodeRefs] = useState({});

  const saveHandler = useCallback(
    async (newLayout: SerializedNodes) => {
      if (!studyId) {
        return;
      }

      try {
        if (faqId) {
          await QueryFactory.FaqQuery.Client.putFaq(newLayout, faqId);
          await queryClient.invalidateQueries(QueryFactory.FaqQuery.getByIdQueryKey(faqId));
        } else {
          await QueryFactory.FaqQuery.Client.createFaq(newLayout, studyId);
        }

        await queryClient.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey());
      } catch (error: any) {
        logger().error(convertToErrorString(error), error);
      }
    },
    [faqId, queryClient, studyId],
  );

  const addToNodeRefs = useCallback((nodeId: string, ref: React.RefObject<HTMLElement>) => {
    setNodeRefs((state) => {
      return { ...state, [nodeId]: ref };
    });
  }, []);

  const removeFromNodeRefs = useCallback((nodeId: string) => {
    setNodeRefs((state) => {
      delete state[nodeId];
      return { ...state };
    });
  }, []);

  return (
    <FaqEditorContext.Provider
      value={{
        onSave: saveHandler,
        setDesignerMode: setEnabled,
        nodeRefs,
        addToNodeRefs,
        removeFromNodeRefs,
      }}
    >
      <Editor
        resolver={{
          Container,
          Text,
          Collapsible,
          ImageBlock,
          MathBlock,
          Video,
        }}
        onRender={RenderNode}
        enabled={enabled}
      >
        <Viewport>
          <Frame data={faqQuery.data?.layout as SerializedNodes} key={faqQuery.data?.id}>
            <Element
              canvas
              is={Container}
              width="800px"
              height="1200px"
              background={'#fcfcfc'}
              padding={[0, 0, 0, 0]}
              custom={{ displayName: 'ROOT' }}
            />
          </Frame>
        </Viewport>
      </Editor>
    </FaqEditorContext.Provider>
  );
};
