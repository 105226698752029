/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { ICheckBoxGroupInput } from '../../../../forms/base/controls/inputs/CheckBoxGroupInput';
import { RPDF } from '../../../react-pdf';
import { RecordsReportContext } from '../../recordsReport.context';

export const CheckBoxGroupInput: ICheckBoxGroupInput = ({ label, dataKey, options, hasStatusIcons }) => {
  const inputValue = useContextSelector(RecordsReportContext, (v) => v.formResult?.[dataKey]);
  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  const displayValues = useMemo(() => {
    return options
      .filter((x) => multipleInputValue?.includes(x.value))
      .map((x) => x.displayValue)
      .join('\n');
  }, [multipleInputValue, options]);

  return (
    <RPDF.View wrap={false} style={RPDF.controlStyles.recordRow}>
      <RPDF.Text style={RPDF.controlStyles.questionCell}>{label ?? dataKey}</RPDF.Text>
      <RPDF.Text style={RPDF.controlStyles.answerCell}>{displayValues}</RPDF.Text>
    </RPDF.View>
  );
};
