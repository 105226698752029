﻿import React, { ChangeEvent, useCallback, useMemo, useRef, useState, FC } from 'react';
import Fuse from 'fuse.js';
import { Callout, DirectionalHint } from '@fluentui/react';
import Style from './appAutoCompleteInput.module.scss';
import { AppTextInput } from '../text/appTextInput.component';

export type AppAutoCompleteInputProps = {
  suggestions: string[];
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  onFocus?: () => void;
  inputFieldStyle?: string;
  calloutMaxHeight?: number;
};

export const AppAutoCompleteInput: FC<AppAutoCompleteInputProps> = (props) => {
  const inputRef = useRef<HTMLElement | null>(null);
  const { onChange } = props;

  const [showSuggestions, setShowSuggestions] = useState(false);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      onChange(newValue);
      setShowSuggestions(true);
    },
    [onChange],
  );

  const onFocus = useCallback(() => {
    setShowSuggestions(true);
    props.onFocus?.();
  }, [props]);

  const onSelectSuggestion = useCallback(
    (value: string) => {
      setShowSuggestions(false);
      onChange(value);
    },
    [onChange],
  );

  const fuseInstance = useMemo(() => new Fuse(props.suggestions), [props.suggestions]);

  const filteredSuggestions = useMemo(() => {
    return props.value
      ? fuseInstance
          .search(props.value)
          .map((x) => x.item)
          .slice(0, 5)
      : props.suggestions;
  }, [props.value, fuseInstance, props.suggestions]);

  return (
    <div className={Style.autocompleteInputWrapper}>
      <AppTextInput
        ref={(ref) => (inputRef.current = ref)}
        onChange={onInputChange}
        value={props.value}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onFocus={onFocus}
        invalid={props.invalid}
      />
      {showSuggestions && (
        <Callout
          target={inputRef}
          isBeakVisible={false}
          className={Style.callout}
          onRestoreFocus={() => {}}
          onDismiss={() => setShowSuggestions(false)}
          calloutMaxHeight={props.calloutMaxHeight ?? window.innerHeight * 0.6}
          directionalHint={DirectionalHint.bottomLeftEdge}
          styles={() => ({ calloutMain: { width: inputRef.current?.clientWidth } })}
        >
          <div className={Style.suggestions}>
            {filteredSuggestions.map((suggestion) => (
              <button key={suggestion} className={Style.suggestionItem} onClick={() => onSelectSuggestion(suggestion)}>
                <span className={'truncate'}>{suggestion}</span>
              </button>
            ))}
          </div>
        </Callout>
      )}
    </div>
  );
};
