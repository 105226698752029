/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import { DateRangeComponent } from '../../../daterangePicker/daterange.component';
import { convertDateToString, formatDateRange, toDateOrNull } from '../../../../helpers/date-helpers';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import {
  dateRangeInputDefaultPropsFactory,
  dateRangeApplicableValidationRules,
  IDateRangeInputProps,
  IDateRangeInput,
} from '../../../../features/forms/base/controls/inputs/DateRangeInput';
import { useRefForDataBlockNavigation, useFieldDataFromUiEditorContext, useFieldValidation } from './base/hooks';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import { useTranslation } from 'react-i18next';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { _isSkipped } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const DateRangeInput: IDateRangeInput = (props) => {
  const { size, dataKey, hasSkipCheckBox, skipCheckBoxText } = props;
  const { t } = useTranslation();
  const {
    connectors: { connect, drag },
    id,
  } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { isSubmitting, formConfig, formResult, patient, stepName } = useContextSelector(FormFillContext, (x) => x);
  const { isOnlyView, setDataBlockFieldValue, markFieldAsTouched } = useFieldDataFromUiEditorContext(dataKey);

  const inputValueStartDate = useContextSelector(
    FormFillContext,
    (v) => v.values?.[`${dataKey}_from`] as string | undefined,
  );
  const inputValueEndDate = useContextSelector(
    FormFillContext,
    (v) => v.values?.[`${dataKey}_until`] as string | undefined,
  );

  // This Effect set default value to answers
  useEffect(() => {
    if (props.defaultValueFromIsNow) {
      setDataBlockFieldValue?.(`${dataKey}_from`, convertDateToString(new Date()));
    }
    if (props.defaultValueUntilIsNow) {
      setDataBlockFieldValue?.(`${dataKey}_until`, convertDateToString(new Date()));
    }
  }, [props.defaultValueFromIsNow, props.defaultValueUntilIsNow]);

  const { dataBlockValidation: startDataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
    customNodeId: `${id}_from`,
    customDataKey: `${dataKey}_from`,
  });

  const { dataBlockValidation: endDataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
    customNodeId: `${id}_until`,
    customDataKey: `${dataKey}_until`,
  });

  const blockRef = useRefForDataBlockNavigation();

  const editableText = (
    <div
      data-test-field-type={'date-range-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={enabled}
      ref={(ref) => enabled && connect(drag(ref!))}
    >
      <div
        ref={blockRef}
        className={clsx({
          [styles.validationError]: !!startDataBlockValidation?.text || !!endDataBlockValidation?.text,
        })}
      >
        <DateRangeComponent
          isBold={props.isBold}
          label={!props.hideLabel ? props.label || dataKey : ''}
          tooltip={props.tooltip}
          disabled={!props.isEditable || isSubmitting}
          startDate={inputValueStartDate}
          endDate={inputValueEndDate}
          onStartChange={(e) => {
            const parsedTime: Date | null = e.target.value ? new Date(Date.parse(e.target.value)) : null;
            if (parsedTime?.toDateString() === 'Invalid Date') {
              return;
            }
            markFieldAsTouched?.(`${id}_from`);
            setDataBlockFieldValue?.(`${dataKey}_from`, e.target?.value || undefined);
          }}
          onEndChange={(e) => {
            const parsedTime: Date | null = e.target.value ? new Date(Date.parse(e.target.value)) : null;
            if (parsedTime?.toDateString() === 'Invalid Date') {
              return;
            }
            markFieldAsTouched?.(`${id}_until`);
            setDataBlockFieldValue?.(`${dataKey}_until`, e.target?.value || undefined);
          }}
          startDateErrorText={startDataBlockValidation?.text}
          endDateErrorText={endDataBlockValidation?.text}
          skipCheckboxProps={{
            hasSkipCheckBox: hasSkipCheckBox,
            skipText: skipCheckBoxText,
            isSkipped: inputValueStartDate === _isSkipped || inputValueEndDate === _isSkipped,
            onSkippedChange: (val) => {
              markFieldAsTouched?.(`${id}_from`);
              markFieldAsTouched?.(`${id}_until`);
              setDataBlockFieldValue?.(`${dataKey}_from`, val ? _isSkipped : undefined);
              setDataBlockFieldValue?.(`${dataKey}_until`, val ? _isSkipped : undefined);
            },
          }}
        />
      </div>
    </div>
  );

  const notEditableText = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResult?.id ?? undefined,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={{ right: 24, top: 6 }}
    >
      <div className={clsx(styles.previewRow, 'preview-row')}>
        <span className={TypographyStyles.heading2}>{props.label ?? props.dataKey}</span>

        {inputValueStartDate === _isSkipped || inputValueEndDate === _isSkipped ? (
          <span className={clsx(TypographyStyles.plainText14, styles.textPlain)}>
            {props.skipCheckBoxText || t('Forms.Controls.SkipQuestionCheckBox')}
          </span>
        ) : (
          <span className={TypographyStyles.plainText14}>
            {formatDateRange(toDateOrNull(inputValueStartDate), toDateOrNull(inputValueEndDate))}
          </span>
        )}
      </div>
    </IssueMark>
  );

  return <>{!isOnlyView ? editableText : notEditableText}</>;
};

const DateRangeInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IDateRangeInputProps,
  }));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.defaultValueFromAsNow')}
          isChecked={!!props.defaultValueFromIsNow}
          setValue={(newValue) =>
            setProp((setProps: IDateRangeInputProps) => (setProps.defaultValueFromIsNow = newValue))
          }
        />

        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.defaultValueToAsNow')}
          isChecked={!!props.defaultValueUntilIsNow}
          setValue={(newValue) =>
            setProp((setProps: IDateRangeInputProps) => (setProps.defaultValueUntilIsNow = newValue))
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const DateRangeInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <DateRangeInputGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={dateRangeApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

DateRangeInput.craft = {
  props: dateRangeInputDefaultPropsFactory(),
  related: {
    settings: DateRangeInputSettings,
  },
};
