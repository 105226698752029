import React from 'react';
import { AppInputError, AppInputErrorProps, ErrorSign } from '../../wrappers/error/appInputError.component';
import { AppInputLabel, AppInputLabelProps } from '../../wrappers/label/appInputLabel.component';
import { SkipCheckbox, SkipCheckboxProps } from '../../wrappers/skipCheckbox/skipCheckbox.component';
import { DropdownOption } from '../../inputs/dropdown/appDropdownInput';
import {
  AppDropDownWithSuggestionInput,
  AppDropDownWithSuggestionProps,
} from 'uikit/inputs/dropdown/appDropDownWithSuggestion';

export type AppDropdownFieldProps<D extends DropdownOption> = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipCheckboxProps?: SkipCheckboxProps;
} & AppDropDownWithSuggestionProps<D>;

export function AppDropdownField<D extends DropdownOption>({
  errorProps,
  labelProps,
  skipCheckboxProps,
  disabled,
  ...rest
}: AppDropdownFieldProps<D>) {
  return (
    <AppInputError {...errorProps}>
      <SkipCheckbox disabled={disabled} {...skipCheckboxProps}>
        <AppInputLabel disabled={disabled} rightSection={errorProps?.errors ? <ErrorSign /> : <></>} {...labelProps}>
          <AppDropDownWithSuggestionInput
            disabled={skipCheckboxProps?.isSkipped || disabled}
            invalid={!!errorProps?.errors}
            {...rest}
          />
        </AppInputLabel>
      </SkipCheckbox>
    </AppInputError>
  );
}
