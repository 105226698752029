import _ from 'lodash';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataType, Layout, LayoutProps } from '../uiEditor';
import {
  convertLayoutToPageList,
  getAvailableInitialAnswersByConfig,
  normalizeDataKey,
  scrollToRef,
} from '../uiEditor-helper';
import { DataBlockValidation, Page, FormFillContext } from './formFill.context';
import { IFormDto, IFormResultDto } from 'src/services/api/api-client';
import { useGetPatientQuery } from 'src/services/api/api-client/PatientQuery';

export type UiEditorProviderProps = {
  mode: 'Create' | 'Edit' | 'Overview';
  formConfig: IFormDto;
  formResult: IFormResultDto | undefined;
  patientId?: string;
  navigateToNodeId?: string;
  stepName?: string;
};

export const UiEditorProvider: FC<PropsWithChildren<UiEditorProviderProps>> = (props) => {
  const { formConfig, mode, formResult, patientId, navigateToNodeId, stepName } = props;
  const { data: patient } = useGetPatientQuery(patientId!, { enabled: !!patientId, suspense: false });

  //#region Values

  const [dataBlockValues, setDataBlockValues] = useState<DataType>({});

  const changeDataBlockValue = useCallback((key: string, value: any) => {
    setDataBlockValues((prevValues) => {
      return {
        ...prevValues,
        [key]: value,
      };
    });
  }, []);

  const removeDataBlockValue = useCallback((key: string) => {
    setDataBlockValues((prevValues) => {
      if (!prevValues) {
        return undefined;
      }

      const result = { ...prevValues };
      delete result[key];
      return result;
    });

    setTouchedFields((prev) => {
      const result = { ...prev };
      delete result[key];
      return result;
    });
  }, []);

  const initialValues = useMemo(() => {
    if (mode === 'Create') {
      return getAvailableInitialAnswersByConfig(formConfig.layoutSchema, formResult?.result);
    }

    return formResult?.result;
  }, [formConfig.layoutSchema, formResult?.result, mode]);

  useEffect(() => {
    setDataBlockValues(initialValues ?? {});
  }, [initialValues]);

  const notEditableValues: LayoutProps[] = useMemo(() => {
    const layout = formConfig.layoutSchema as Layout;
    return Object.keys(layout)
      .filter((key) => {
        const initValue = initialValues && initialValues[layout[key].props.dataKey];
        const currentValue = dataBlockValues && dataBlockValues[layout[key].props.dataKey];

        return layout[key].props.isDisableWhenEditing && layout[key].props.isEditable && !initValue && currentValue;
      })
      .map((key) => layout[key].props);
  }, [dataBlockValues, formConfig.layoutSchema, initialValues]);

  //#endregion

  //#region Navigation

  const [dataBlockNavigation, setDataBlockNavigation] = useState(
    convertLayoutToPageList(formConfig.layoutSchema).blocks,
  );

  const addRefToDataBlockNavigation = useCallback((id: string, ref: any) => {
    setDataBlockNavigation((state) => {
      return {
        ...state,
        [id]: { ...state[id], ref: ref },
      };
    });
  }, []);

  const navigateToBlock = useCallback(
    (nodeId: string | undefined) => {
      if (!nodeId) {
        return;
      }

      setCurrentPageStack(dataBlockNavigation[normalizeDataKey(nodeId)]?.pages);
      setTimeout(() => {
        scrollToRef(dataBlockNavigation[normalizeDataKey(nodeId)]?.ref);
      }, 0);
    },
    [dataBlockNavigation, formConfig.layoutSchema],
  );

  useEffect(() => {
    if (navigateToNodeId) {
      navigateToBlock(navigateToNodeId);
    }
  }, [navigateToBlock, navigateToNodeId]);

  //#endregion

  //#region Validation

  const [dataBlockValidation, setDataBlockValidation] = useState<DataBlockValidation>({});
  const [isValidating, setIsValidating] = useState(false);

  const setDataBlockValidationHandler = useCallback((data: DataBlockValidation) => {
    setDataBlockValidation((state) => {
      return { ...state, ...data };
    });
  }, []);

  const removeDataBlockValidation = useCallback((key: string) => {
    setDataBlockValidation((state) => {
      delete state[key];
      return { ...state };
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const validationCallback = useRef((result: DataBlockValidation) => {});

  const triggerValidation = useCallback(
    (callback: (validationResult: DataBlockValidation) => void) => {
      setIsValidating(true);
      const newState = { ...dataBlockValidation };
      Object.keys(newState).forEach((key) => (newState[key] = { ...newState[key], validationState: 'Validating' }));

      setDataBlockValidation(newState);
      validationCallback.current = callback;
    },
    [dataBlockValidation],
  );

  useEffect(() => {
    if (
      isValidating &&
      !Object.keys(dataBlockValidation).some((key) => dataBlockValidation[key].validationState === 'Validating')
    ) {
      setIsValidating(false);
      validationCallback.current(dataBlockValidation);
    }
  }, [isValidating, dataBlockValidation, navigateToBlock]);

  //#endregion

  //#region Touched fields

  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});

  const markFieldAsTouched = useCallback((nodeId: string) => {
    setTouchedFields((prev) => ({
      ...prev,
      [nodeId]: true,
    }));
  }, []);

  useEffect(() => {
    setTouchedFields({});
  }, [initialValues]);

  //#endregion

  //#region Paging

  const pageStacks = useMemo(() => {
    return convertLayoutToPageList(formConfig.layoutSchema).pages;
  }, [formConfig.layoutSchema]);

  const [currentPageStack, setCurrentPageStack] = useState(_.first(pageStacks));

  const goToPage = useCallback(
    (page: Page) => {
      setCurrentPageStack(() => {
        const index = pageStacks.findIndex((x) =>
          x.some((val) => val.nodeId === page.nodeId && val.pageNumber === page.pageNumber),
        );
        return pageStacks[index];
      });
    },
    [pageStacks],
  );

  const goToNextPage = useCallback(() => {
    setCurrentPageStack((state) => {
      const index = pageStacks.findIndex((x) =>
        x.every((val, i) => val.nodeId === state![i].nodeId && val.pageNumber === state![i].pageNumber),
      );
      return pageStacks[index + 1] || _.last(pageStacks);
    });
  }, [pageStacks]);

  const goToPrevPage = useCallback(() => {
    setCurrentPageStack((state) => {
      const index = pageStacks.findIndex((x) =>
        x.every((val, i) => val.nodeId === state![i].nodeId && val.pageNumber === state![i].pageNumber),
      );
      return pageStacks[index - 1] ?? _.first(pageStacks);
    });
  }, [pageStacks]);

  //#endregion

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const resetState = useCallback(() => {
    setDataBlockValues(initialValues ?? {});
    setDataBlockValidation({});
    setIsValidating(false);
    setIsSubmitting(false);
    setTouchedFields({});
    setCurrentPageStack(_.first(pageStacks));
  }, [initialValues, pageStacks]);

  return (
    <FormFillContext.Provider
      value={{
        mode,
        formResult,
        stepName,
        formConfig,
        resetState,

        patient,

        isSubmitting,
        setIsSubmitting,

        values: dataBlockValues,
        notEditableValues,
        setValue: changeDataBlockValue,
        removeValue: removeDataBlockValue,

        touchedFields,
        markFieldAsTouched,

        dataBlockValidation,
        setDataBlockValidation: setDataBlockValidationHandler,
        removeDataBlockValidation,
        triggerValidation,

        addRefToDataBlockNavigation,
        navigateToBlock,

        pageStacks,
        currentPageStack,
        goToPage,
        goToNextPage,
        goToPrevPage,
      }}
    >
      {props.children}
    </FormFillContext.Provider>
  );
};
