import React, { FC } from 'react';
import { AppInputError, AppInputErrorProps, ErrorSign } from '../../wrappers/error/appInputError.component';
import { AppInputLabel, AppInputLabelProps } from '../../wrappers/label/appInputLabel.component';
import { SkipCheckbox, SkipCheckboxProps } from '../../wrappers/skipCheckbox/skipCheckbox.component';
import { AppTagInput } from '../../inputs/tagInput/appTagInput.component';
import { AppTagInputProps } from '../../inputs/tagInput/appTagInput';
import { _isSkipped } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';

export type AppTagInputFieldType = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipCheckboxProps?: SkipCheckboxProps;
} & AppTagInputProps;

export const AppTagInputField: FC<AppTagInputFieldType> = ({
  errorProps,
  labelProps,
  skipCheckboxProps,
  disabled,
  values,
  ...rest
}) => {
  return (
    <AppInputError {...errorProps}>
      <SkipCheckbox disabled={disabled} {...skipCheckboxProps}>
        <AppInputLabel disabled={disabled} rightSection={errorProps?.errors ? <ErrorSign /> : <></>} {...labelProps}>
          <AppTagInput
            disabled={skipCheckboxProps?.isSkipped || disabled}
            invalid={!!errorProps?.errors}
            values={skipCheckboxProps?.isSkipped ? values.filter((v) => v !== _isSkipped) : values}
            {...rest}
          />
        </AppInputLabel>
      </SkipCheckbox>
    </AppInputError>
  );
};
