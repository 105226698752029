import React from 'react';
import { createReport } from './report-helpers';
import { IssueReportOptions } from './issues/issueReport';
import { IssueReportTemplate } from './issues/issueReport.layout';
import { RecordsReportOptions } from './records/recordsReport';
import { RecordsReportTemplate } from './records/recordsReport.layout';
import { SurveyReportLayout } from './surveys/surveyReport.layout';
import { SurveysReportOptions } from './surveys/surveyReport';

export const generateIssueReport = (options: IssueReportOptions): Promise<Blob> => {
  return createReport(<IssueReportTemplate {...options} />);
};

export const generateRecordsReport = (options: RecordsReportOptions): Promise<Blob> => {
  return createReport(<RecordsReportTemplate {...options} />);
};

export const generateSurveyReport = (options: SurveysReportOptions): Promise<Blob> => {
  return createReport(<SurveyReportLayout {...options} />);
};
