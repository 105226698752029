import React, { useContext, useMemo } from 'react';
import Style from './survey.module.scss';
import { SurveyComponentProps } from './survey';
import { ExpandedRowHeader } from '../header/expandedRowHeader.component';
import { StatisticsComponent } from './surveyStatistics.component';
import { QueryFactory } from 'src/services/api';
import clsx from 'clsx';
import { StatisticsItemDto, SurveyIcon } from 'src/services/api/api-client';
import { TypographyStyles } from '../../../styles';
import placeholderIcon from '../../../assets/img/survey_placeholder.svg';
import _ from 'lodash';
import { differenceInDays, differenceInMonths, differenceInWeeks, differenceInYears } from 'date-fns';
import { ReactComponent as ClipboardIcon } from '../../../assets/img/common/clipboard_20.svg';
import { useModal } from '../../../application/hooks/useModal';
import { ManageSurveysModalComponent } from './manageSurveys/ManageSurveysModal.component';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { useAppSelector } from '../../../application/redux-store/store-types';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { Loading } from 'uikit/suspense/Loading';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { PatientCardContext } from '../PatientCardContext';

export const SurveyComponent = (props: SurveyComponentProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection');
  const { groups } = useAppSelector((state) => state.app);
  const ctx = useContext(PatientCardContext);
  const surveyListToRender = groups.find((g) => g.id === props.groupId)?.surveysToPatientCard;

  const statistics = QueryFactory.SurveyQuery.useGetLastSurveysStatisticsQuery(props.patientId, {
    suspense: false,
  });

  //#region Survey managing

  const manageSurveyModal = useModal();

  const manageableSurveys = QueryFactory.SurveyQuery.useGetSurveysQuery(
    {
      studyIds: [props.studyId],
      canBeTriggeredByDoctor: true,
    },
    {
      suspense: false,
    },
  );

  const thereAreManageableSurveys = manageableSurveys.data && manageableSurveys.data.length > 0;

  //#endregion

  const questionnairesQuery = QueryFactory.SurveyQuery.useGetQuestionnairesQuery(
    {
      patientId: props.patientId,
      active: true,
      completed: false,
      canBeFilledByDoctor: true,
      triggeredByPatient: false,
    },
    {
      suspense: false,
    },
  );

  const isStatisticsEmpty = (statisticsToCheck: StatisticsItemDto[] | undefined) => {
    return statisticsToCheck === undefined || statisticsToCheck.length === 0;
  };

  const getExplainerText = (statisticsToCount: StatisticsItemDto[] | undefined) => {
    const startDate = _.last(statisticsToCount)?.startDate;
    const dateNow = new Date();

    if (!startDate) {
      return '';
    }

    const years = differenceInYears(dateNow, startDate);
    if (years > 0) {
      return t('LastAvailableStats.LastYears_plural', { count: years + 1 });
    }

    const month = differenceInMonths(dateNow, startDate);
    if (month > 0) {
      return t('LastAvailableStats.LastMonths_plural', { count: month + 1 });
    }

    const week = differenceInWeeks(dateNow, startDate);
    if (week > 0) {
      return t('LastAvailableStats.LastWeeks_plural', { count: week + 1 });
    }

    const day = differenceInDays(dateNow, startDate);
    if (day > 0) {
      return t('LastAvailableStats.LastDays_plural', { count: day + 1 });
    }

    return t('LastAvailableStats.LastDays');
  };

  const emptyPlaceHolder = useMemo(() => {
    return (
      <div className={Style.placeholderContainer}>
        <img className={Style.placeholderIcon} alt={'survey placeholder icon'} src={placeholderIcon} />
        <span className={clsx(TypographyStyles.paragraph14, Style.placeholderText)}>{t('NoData')}</span>
      </div>
    );
  }, [t]);

  const manageButtonIsDisabled = useMemo(
    () =>
      !props.studyStartedAt ||
      props.isStudyFinished ||
      (props.plannedStudyFinishedAt && props.plannedStudyFinishedAt < new Date()),
    [props.isStudyFinished, props.plannedStudyFinishedAt, props.studyStartedAt],
  );
  return (
    <div className={clsx({ [Style.container]: true })} data-test-id={'patient-card-survey'}>
      <ExpandedRowHeader
        text={
          <IssueMark
            issueContext={{
              subject: 'Patient',
              linkedPatientUniqId: ctx?.patient.uniqueId,
              topic: 'Surveys',
            }}
            countDescendants={false}
            containerClassName={Style.issueMarkContainer}
          >
            {t('Header')}
          </IssueMark>
        }
        rightHeader={
          thereAreManageableSurveys && (
            <Tooltip
              text={
                !props.studyStartedAt
                  ? t('SurveyManaging.StartIsNotSpecified')
                  : props.isStudyFinished || (props.plannedStudyFinishedAt && props.plannedStudyFinishedAt < new Date())
                  ? t('SurveyManaging.StudyIsFinished')
                  : ''
              }
            >
              <AppButton
                disabled={manageButtonIsDisabled ?? undefined}
                onClick={() => manageSurveyModal.openModal()}
                Icon={ClipboardIcon}
                variant={'icon-link'}
                colorSchema={'primary'}
                text={t('SurveyManaging.Modal.Header')}
                testId={'survey-calendar-button'}
              />
            </Tooltip>
          )
        }
      />

      <Loading
        containerClassName={clsx(Style.infoContainer)}
        renderChildren={false}
        loading={statistics.isInitialLoading || questionnairesQuery.isInitialLoading}
        testId={'patient-card-survey'}
      >
        {statistics.data && surveyListToRender
          ? surveyListToRender.map((surveyType) => {
              const hasStatisticsObj = surveyType in statistics.data;
              const icon = hasStatisticsObj ? statistics.data[surveyType].icon : SurveyIcon.Default;

              return (
                <StatisticsComponent
                  key={surveyType}
                  patientId={props.patientId}
                  surveyType={surveyType}
                  headerExplainerText={hasStatisticsObj ? getExplainerText(statistics.data[surveyType].statistics) : ''}
                  statisticValues={hasStatisticsObj ? statistics.data[surveyType].statistics : undefined}
                  isStatisticsEmpty={
                    hasStatisticsObj ? isStatisticsEmpty(statistics.data[surveyType].statistics) : true
                  }
                  surveyIcon={icon}
                  questionnaire={questionnairesQuery.data?.find((q) => q.typeString === surveyType)}
                  isStudyFinished={props.isStudyFinished}
                />
              );
            })
          : emptyPlaceHolder}
      </Loading>
      {thereAreManageableSurveys &&
        props.studyStartedAt !== null &&
        (props.studyFinishedAt ?? props.plannedStudyFinishedAt) && (
          <ManageSurveysModalComponent
            surveys={manageableSurveys.data!}
            visible={manageSurveyModal.visible}
            onHide={manageSurveyModal.closeModal}
            studyStartDate={props.studyStartedAt}
            studyFinishDate={props.studyFinishedAt ?? props.plannedStudyFinishedAt!}
            patientId={props.patientId}
            studyId={props.studyId}
            groupId={props.groupId}
          />
        )}
    </div>
  );
};
