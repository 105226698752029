import { useTranslation } from 'react-i18next';
import Style from './navAppBar.module.css';
import { NavButton } from './navButton.component';
import { AppRoutes } from '../../navigation/routes';
import React from 'react';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from '../../helpers/auth/auth-helper';
import { useAppSelector } from '../../application/redux-store/store-types';

export const NavStudyBar = () => {
  const { t } = useTranslation();
  const { hasDbImport } = useAppSelector((state) => state.app);

  return (
    <div className={Style.secondaryNavContainer}>
      <div className={Style.tabsContainer}>
        <NavButton title={t('NavMenu.Tabs.RecordsEditor')} route={AppRoutes.FormBuilder} variant={'secondary'} />
        <NavButton
          title={t('NavMenu.Tabs.SurveyEditor')}
          route={AppRoutes.QuestionSchemeBuilder}
          variant={'secondary'}
        />
        {hasDbImport && (
          <NavButton title={t('NavMenu.Tabs.Analyzes')} route={AppRoutes.Analyzes} variant={'secondary'} />
        )}
        <PermissionsCheck permissions={[Permissions.ViewPatient, Permissions.AdministerStudy]}>
          <NavButton
            title={t('NavMenu.Tabs.PatientPipelines')}
            route={AppRoutes.PatientPipelines}
            variant={'secondary'}
          />
        </PermissionsCheck>
      </div>
    </div>
  );
};
