import { PropsWithChildren, ReactNode } from 'react';
import { UserComponent } from '@craftjs/core';

export type ContainerSize = (typeof containerSizes)[number];
export const containerSizes = ['Small', 'Medium', 'Large', 'Content'] as const;
export interface IContainerProps {
  formSize: ContainerSize;
  overviewSize: ContainerSize;
  isFlexBox?: boolean;
  flexBoxGap?: number;
  children?: ReactNode;
  isDisabled?: boolean;
  padding?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };

  /**
   * @deprecated DEPRECATED - use "padding object" instead
   */
  isPadding?: boolean;
}

export type IContainer = UserComponent<PropsWithChildren<Partial<IContainerProps>>>;
