import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DailyCheckCalendarProps, SortedAnswers } from './dailyCheckCalendar';
import Style from './dailyCheckCalendar.module.css';
import { TypographyStyles } from '../../styles';
import groupBy from 'lodash.groupby';
import { addMonths, startOfDay } from 'date-fns';
import { MonthComponent } from './Month.component';
import { QueryFactory } from 'src/services/api';
import { formatDateRange } from 'src/helpers/date-helpers';
import { DailycheckPaginator } from './dailyCheckPaginator.component';
import { eachMonthOfInterval } from 'date-fns/esm';
import { AppModalContainer } from '../../components/uikit/modal/modal.component';
import { Loading } from 'uikit/suspense/Loading';

export const DailyCheckCalendar = (props: DailyCheckCalendarProps) => {
  const { t } = useTranslation();
  const dailyStatsQuery = QueryFactory.SurveyQuery.useGetAnswersStatisticQuery(props.patientId, 'Atemnot', {
    suspense: false,
  });
  const [pageCounter, setPageCounter] = useState(1);
  const [numberOfMonths, setNumberOfMonths] = useState(0);

  const dailyStats = dailyStatsQuery.data;

  const header = (
    <p className={clsx(Style.modalHeader, TypographyStyles.plainText14)}>
      {t('Dashboard.PatientAccordion.SurveySection.StatisticsModal.Header', {
        period:
          dailyStats && dailyStats.studyStartedAt
            ? formatDateRange(dailyStats.studyStartedAt, dailyStats.studyFinishedAt)
            : '',
      })}
    </p>
  );

  const handlePagination = (pageNumber: number) => setPageCounter(pageNumber);

  const content = useMemo(() => {
    if (!dailyStats) {
      return null;
    }

    const answersGroupedByDays: SortedAnswers = groupBy(dailyStats.answers, (answer) =>
      startOfDay(answer.answerDate).toString(),
    );
    const { studyStartedAt, studyFinishedAt } = dailyStats;

    const months: JSX.Element[] = [];
    const numberOfMonthsToRender = eachMonthOfInterval({ start: studyStartedAt, end: studyFinishedAt }).length;
    const monthsPerPage = Math.min(6, numberOfMonthsToRender);
    const numberOfPages = Math.ceil(numberOfMonthsToRender / monthsPerPage);
    const maxNumberOfMonths = numberOfPages === pageCounter ? numberOfMonthsToRender : pageCounter * monthsPerPage;
    for (let i = (pageCounter - 1) * monthsPerPage; i < maxNumberOfMonths; i++) {
      const consideredDate = addMonths(studyStartedAt, i);
      const newMonth = (
        <MonthComponent
          answers={answersGroupedByDays}
          activeDate={consideredDate}
          key={consideredDate.toString()}
          maxAnsweredDate={dailyStats.maxAnswerDate ?? new Date(Date.now())}
          minAnsweredDate={dailyStats.minAnswerDate ?? studyStartedAt}
        />
      );
      months.push(newMonth);
    }
    setNumberOfMonths(months.length);
    return (
      <div
        className={clsx({
          [Style.monthsContainer]: true,
          [Style.monthsContainerWithLoader]: dailyStatsQuery.isInitialLoading,
          [Style.oneMonthContainer]: numberOfMonths === 1,
        })}
      >
        {months}
      </div>
    );
  }, [dailyStats, pageCounter, dailyStatsQuery.isInitialLoading, numberOfMonths]);

  const footer = useMemo(() => {
    if (!dailyStats || dailyStatsQuery.isInitialLoading) {
      return <></>;
    }

    const { studyStartedAt, studyFinishedAt } = dailyStats;
    return (
      <div className={Style.footer}>
        <DailycheckPaginator
          itemsPerPage={6}
          totalNumberOfItems={eachMonthOfInterval({ start: studyStartedAt, end: studyFinishedAt }).length}
          handlePagination={handlePagination}
          counterValue={pageCounter}
        />
      </div>
    );
  }, [dailyStats, dailyStatsQuery.isInitialLoading, pageCounter]);

  return (
    <AppModalContainer
      title={t('Dashboard.PatientAccordion.SurveySection.StatisticsModal.DailyCheck.Title')}
      header={header}
      visible={props.isVisible}
      onHide={props.onClose}
      isClickOutside={true}
      bodyClassName={Style.modalBody}
      footer={footer}
    >
      <Loading loading={dailyStatsQuery.isInitialLoading} containerClassName={Style.loading} renderChildren={false}>
        {dailyStats ? content : <p>{t('Common_NoData')}</p>}
      </Loading>
    </AppModalContainer>
  );
};
