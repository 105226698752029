import React, { useMemo } from 'react';
import Style from './navAppBar.module.css';
import { LanguageSwitcher } from '../../components/languageSwitcher/languageSwitcher.component';
import { AppRoutes, navigateToRoute } from '../../navigation/routes';
import { NavButton } from './navButton.component';
import { Permissions } from '../../helpers/auth/auth-helper';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { useMatch } from 'react-router';
import { useAppSelector } from '../../application/redux-store/store-types';
import { ReactComponent as BackIcon } from '../../assets/img/common/backArrow.svg';
import { QueryFactory } from '../../services/api';
import { AccountInfoComponent } from '../../components/accoutInfo/accountInfo.component';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';

export const NavAppMenu = () => {
  const { t } = useScopedTranslation('NavMenu');

  const { studyId } = useAppSelector((state) => state.app);

  const studiesMatch = useMatch({ path: AppRoutes.Studies });
  const adminMatch = useMatch({ path: AppRoutes.Admin, end: false });

  const profile = QueryFactory.ProfileQuery.useGetDoctorProfileInfoQuery({
    suspense: true,
  });

  const navigations = useMemo(() => {
    return (
      <div className={Style.tabsContainer}>
        <PermissionsCheck permissions={Permissions.AdministerApp}>
          {(canAdministerApp) =>
            (profile.data?.studies?.length !== 1 || canAdministerApp) && (
              <NavButton
                title={''}
                route={AppRoutes.Studies}
                variant={'link'}
                Icon={BackIcon}
                isHidden={!!studiesMatch || !!adminMatch}
                styles={Style.backButton}
              />
            )
          }
        </PermissionsCheck>

        {studyId && (
          <>
            <PermissionsCheck permissions={Permissions.ViewPatient}>
              <NavButton
                title={t('Tabs.Study')}
                route={navigateToRoute.dashboard(studyId)}
                variant={'primary'}
                isHidden={!!studiesMatch || !!adminMatch}
                end={true}
              />
            </PermissionsCheck>
            <PermissionsCheck permissions={Permissions.AdministerStudy}>
              <NavButton
                title={t('Tabs.Settings')}
                route={navigateToRoute.studySettings(studyId)}
                variant={'primary'}
                isHidden={!!studiesMatch || !!adminMatch}
              />
            </PermissionsCheck>
          </>
        )}

        <PermissionsCheck permissions={Permissions.AdministerApp}>
          <NavButton
            title={t('Tabs.Studies')}
            route={AppRoutes.Studies}
            variant={'primary'}
            isHidden={!studiesMatch && !adminMatch}
          />
          <NavButton
            title={t('Tabs.Admin')}
            route={AppRoutes.Admin}
            variant={'primary'}
            isHidden={!studiesMatch && !adminMatch}
          />
        </PermissionsCheck>
      </div>
    );
  }, [profile.data?.studies?.length, studiesMatch, adminMatch, studyId, t]);

  return (
    <>
      <div className={Style.navContainer} data-test-id={'navbar-container'}>
        {navigations}

        <div className={Style.languageContainer}>
          <LanguageSwitcher />
        </div>

        <div className={Style.navButtons}>
          {studyId && (
            <NavButton
              title={t('FAQ')}
              route={navigateToRoute.faqPage(studyId)}
              variant={'primary'}
              testId={'faq-button'}
            />
          )}
          <NavButton
            title={t('Impressum')}
            route={AppRoutes.Impressum}
            variant={'primary'}
            testId={'impressum-button'}
          />
        </div>

        <AccountInfoComponent profile={profile.data} />
      </div>
    </>
  );
};
