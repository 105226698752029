import { useContextSelector } from 'use-context-selector';
import { useEffect, useMemo, useRef } from 'react';
import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { PropType } from 'src/helpers/typeUtils';
import { ValidationCustomForm } from 'src/helpers/validation-helpers';
import { CommentDataKey, OptionalDataKey, FormFillContext } from '../../../uiEditor/provider/formFill.context';
import { useNode } from '@craftjs/core';

export function useFieldDataFromUiEditorContext(dataKey: string) {
  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const isEditMode = useContextSelector(FormFillContext, (v) => v.mode === 'Edit');
  const inputValue = useContextSelector(FormFillContext, (v) => v.values?.[dataKey]);
  const isAnswered =
    inputValue && ((Array.isArray(inputValue) && inputValue.length > 0) || typeof inputValue === 'object');
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;
  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);
  const commentValue = useContextSelector(FormFillContext, (v) => v.values?.[CommentDataKey(dataKey)]);
  const additionalTextValue = useContextSelector(FormFillContext, (v) => v.values?.[OptionalDataKey(dataKey)]);
  const setDataBlockFieldValue = useContextSelector(FormFillContext, (v) => v.setValue);
  const removeFieldValue = useContextSelector(FormFillContext, (v) => v.removeValue);
  const addRefToDataBlockNavigation = useContextSelector(FormFillContext, (v) => v.addRefToDataBlockNavigation);
  const markFieldAsTouched = useContextSelector(FormFillContext, (v) => v.markFieldAsTouched);

  return {
    isOnlyView,
    isEditMode,
    isAnswered,
    inputValue,
    singleInputValue,
    multipleInputValue,
    commentValue,
    additionalTextValue,
    setDataBlockFieldValue,
    removeFieldValue,
    addRefToDataBlockNavigation,
    markFieldAsTouched,
  };
}

export function useDefaultValues(props: IFormFieldProps) {
  return {
    defaultValue: props.defaultValue,
    singleDefaultValue: Array.isArray(props.defaultValue) ? props.defaultValue[0] : props.defaultValue,
    multipleDefaultValue: useMemo(() => {
      return Array.isArray(props.defaultValue)
        ? props.defaultValue
        : props.defaultValue !== undefined
        ? [props.defaultValue]
        : undefined;
    }, [props.defaultValue]),
  };
}

export function useSetDefaultValueToAnswer(dataKey: string, defaultValue: PropType<IFormFieldProps, 'defaultValue'>) {
  const inputValue = useContextSelector(FormFillContext, (v) => v.values?.[dataKey]);
  const setDataBlockFieldValue = useContextSelector(FormFillContext, (v) => v.setValue);

  useEffect(() => {
    if (inputValue === undefined) {
      setDataBlockFieldValue?.(dataKey, defaultValue);
    }
  }, [setDataBlockFieldValue, inputValue, defaultValue, dataKey]);
}

export function useRefForDataBlockNavigation() {
  const { id } = useNode();
  const blockRef = useRef(null);
  const addRefToDataBlockNavigation = useContextSelector(FormFillContext, (v) => v.addRefToDataBlockNavigation);

  useEffect(() => {
    addRefToDataBlockNavigation?.(id, blockRef);
  }, [addRefToDataBlockNavigation, id, blockRef]);

  return blockRef;
}

export function useAddFieldToValidation(nodeId: string) {
  const setDataBlockValidation = useContextSelector(FormFillContext, (v) => v.setDataBlockValidation);
  const removeDataBlockValidation = useContextSelector(FormFillContext, (v) => v.removeDataBlockValidation);

  useEffect(() => {
    setDataBlockValidation?.({ [nodeId]: { validationState: 'Ok', text: '' } });
    return () => removeDataBlockValidation?.(nodeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);
}

export const useFieldValidation = ({
  isEditable,
  isDisabled,
  rules,
  customNodeId,
  customDataKey,
}: {
  isEditable: boolean | undefined;
  isDisabled: boolean | undefined;
  rules: PropType<IFormFieldProps, 'validation'>;
  customNodeId?: string;
  customDataKey?: string;
}) => {
  const { key, id } = useNode((node) => ({
    key: node.data.props.dataKey,
  }));

  const nodeId = customNodeId || id;
  const dataKey = customNodeId || key;

  useAddFieldToValidation(nodeId);

  const dataBlockValidation = useContextSelector(FormFillContext, (v) => v.dataBlockValidation?.[nodeId]);
  const setDataBlockValidation = useContextSelector(FormFillContext, (v) => v.setDataBlockValidation);
  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const inputValue = useContextSelector(FormFillContext, (v) => v.values?.[customDataKey || dataKey]);
  const wasTouched = useContextSelector(FormFillContext, (v) => v.touchedFields?.[nodeId]);
  const isValidating = dataBlockValidation?.validationState === 'Validating';

  useEffect(() => {
    if (!isOnlyView && (wasTouched || isValidating)) {
      const validationResult = !isEditable || isDisabled ? [] : ValidationCustomForm(rules, inputValue);
      setDataBlockValidation?.({
        [nodeId]: {
          validationState: validationResult.length > 0 ? 'Fail' : 'Ok',
          text: validationResult.join('\n'),
        },
      });
    }
  }, [nodeId, setDataBlockValidation, wasTouched, inputValue, isValidating, isEditable, isOnlyView, rules]);

  useEffect(() => {
    if (isDisabled) {
      setDataBlockValidation?.({
        [nodeId]: { validationState: 'Ok', text: '' },
      });
    }
  }, [nodeId, setDataBlockValidation, isDisabled]);

  return { dataBlockValidation, setDataBlockValidation };
};
