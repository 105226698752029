import React, { FC, PropsWithChildren, useContext } from 'react';
import Style from './notificationMessage.module.css';
import { ReactComponent as PrismaCheck } from '../../../assets/img/notification/prisma_check_16.svg';
import { ReactComponent as ReportFilled } from '../../../assets/img/notification/report_filled_16.svg';
import { ReactComponent as NewComment } from '../../../assets/img/notification/new_comment_16.svg';
import { ReactComponent as IssueNotification } from '../../../assets/img/notification/issue_notification_16.svg';
import { ReactComponent as FormNotification } from '../../../assets/img/notification/form_notification_16.svg';
import { TypographyStyles } from 'src/styles';
import clsx from 'clsx';
import { INotificationDto, NotificationType } from 'src/services/api/api-client';
import { Trans } from 'react-i18next';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { useContextSelector } from 'use-context-selector';
import { IssuesContext } from '../../issue/provider/issues.context';
import { notificationsContext } from '../../../providers/notificationProvider';
import { Permissions, useHasPermissions } from '../../../helpers/auth/auth-helper';

const icon: { [key in NotificationType]: any } = {
  [NotificationType.Other]: <></>,
  [NotificationType.PrismaCloudCheck]: <ReportFilled />,
  [NotificationType.PrismaCheck]: <PrismaCheck />,
  [NotificationType.NewSurveyAssigned]: <></>,
  [NotificationType.SurveyIsExpired]: <></>,
  [NotificationType.NewIssueComment]: <NewComment />,
  [NotificationType.IssueDeadline]: <IssueNotification />,
  [NotificationType.FormDeadline]: <FormNotification />,
};

export const NotificationMessage = (notification: INotificationDto) => {
  const openIssueView = useContextSelector(IssuesContext, (x) => x.openIssueView);

  const type = NotificationType[notification.type] as keyof typeof NotificationType;

  return (
    <div className={Style.container}>
      <div className={Style.icon}>{icon[notification.type]}</div>
      <div className={Style.content}>
        <div className={clsx(TypographyStyles.paragraph14, Style.message)}>
          <Trans
            i18nKey={`Notifications.Message.${type}` as LocalizedResourceDictionaryKeys}
            values={notification.data ?? {}}
            components={{
              bold: <span className={Style.bold} />,
              IssueLink: (
                <a className={Style.link} onClick={() => openIssueView?.(parseInt(notification.data!.IssueId))} />
              ),
              FormLink:
                notification.type === NotificationType.FormDeadline ? (
                  <FormLink payload={notification.data ?? {}} />
                ) : (
                  <></>
                ),
            }}
          />
        </div>
        <div className={clsx(TypographyStyles.paragraph14, Style.date)}>
          {localFormat(notification.createdAt, DateFormats.longDate)}
        </div>
      </div>
    </div>
  );
};

const FormLink: FC<
  PropsWithChildren<{
    payload: Record<string, string | null | undefined>;
  }>
> = ({ payload, children }) => {
  const { openForm } = useContext(notificationsContext);
  const hasPermissions = useHasPermissions();

  const formId = parseInt(payload.FormId!);
  const formType = payload.FormType!;
  const patientId = payload.PatientId!;
  const patientUniqueId = payload.PatientUniqueId!;
  const stepName = payload.StepName!;
  const resultId = payload.FormResultId;

  if (!resultId && !hasPermissions(Permissions.EditPatient)) return <>{children}</>;

  return (
    <a className={Style.link} onClick={() => openForm({ formId, patientId, patientUniqueId, formType, stepName })}>
      {children}
    </a>
  );
};
