import React, { ReactNode, useCallback } from 'react';
import Style from './controlsGroup.module.scss';
import { ReactComponent as Plus } from 'assets/img/formBuilder/plus_24.svg';
import { ReactComponent as Minus } from 'assets/img/formBuilder/minus_24.svg';
import { ReactComponent as TooltipIcon } from 'assets/img/formBuilder/tip_16.svg';
import { useEditor } from '@craftjs/core';
import { AppButton } from 'uikit/buttons';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import clsx from 'clsx';
import { DirectionalHint } from '@fluentui/react';

export type ControlsGroupProps = {
  collapsible?: boolean;
  header?: string;
  children: ReactNode;
  contentClassName?: string;
  tooltip?: string;
  flexible?: boolean;
} & (
  | {
      collapsible: true;
      expanded?: boolean;
      onClose?: () => Promise<void> | void;
      onOpen?: () => Promise<void> | void;
    }
  | {
      collapsible?: false;
      expanded?: never;
      onClose?: never;
      onOpen?: never;
    }
);

export const ControlsGroup = ({
  header,
  expanded,
  collapsible,
  onClose,
  onOpen,
  children,
  tooltip,
  contentClassName,
  flexible,
}: ControlsGroupProps) => {
  const {
    connectors: { hover },
  } = useEditor();

  const clickHandler = useCallback(async () => {
    if (expanded) {
      await onClose?.();
    } else {
      await onOpen?.();
    }
  }, [expanded, onClose, onOpen]);

  return (
    <div className={Style.container}>
      {header && (
        <div className={Style.header}>
          {header}
          {collapsible && ((tooltip && expanded) || !tooltip) && (
            <AppButton
              variant={'icon-link'}
              colorSchema={'primary'}
              onClick={clickHandler}
              Icon={expanded ? Minus : Plus}
            />
          )}
          {tooltip && !expanded && (
            <Tooltip text={tooltip} trigger={'click'}>
              <TooltipIcon className={Style.tooltipIcon} />
            </Tooltip>
          )}
        </div>
      )}

      {(expanded || !collapsible) && (
        <div
          className={clsx({ [Style.flexContainer]: flexible }, contentClassName)}
          ref={(ref) => {
            ref && hover(ref, '');
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
